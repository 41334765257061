import { Injectable } from '@angular/core';
import { Login } from './Login';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  REST_API: string;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}login`;
    } else {
      this.REST_API = `${environment.apiurl}login`;
    }
  }

  // Add
  checkLogin(data: Login): Observable<any> {
    let API_URL = `${this.REST_API}`;
    return this.httpClient.post(API_URL, data)
      .pipe(map((res: any) => {
        return res || {}
      }),
        catchError(this.handleError)
      )
  }

  forgotPassword(data:any): Observable<any> {
    let API_URL = `${environment.apiurl}forgot-password`;
    return this.httpClient.post(API_URL, data).pipe(map((res:any) => {
      return res || {}
    }),
      catchError(this.handleError)
    )
  }

  // Error
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }
}
