import { Component, OnInit } from '@angular/core';
import { CrudService } from '../../../service/web_users/web_users.service';
import { TokenStorageService } from '../../../service/login/token-storage.service';
import { environment } from './../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-resume-details',
  templateUrl: './resume-details.component.html',
  styleUrls: ['./resume-details.component.scss']
})
export class ResumeDetailsComponent implements OnInit {
  resumeData: any = [];
  genderList: any = [
    { value: "m", name: "Male" },
    { value: "f", name: "Female" },
  ];
  getId: any;
  redirectURL: any;

  //for images  
  apiBaseUrl = environment.apiBaseUrl;
  imgPhotoUrl: string;
  currentUser: any;

  constructor(
    private crudService: CrudService,
    private activatedRoute: ActivatedRoute,
    private token: TokenStorageService
  ) {
    this.currentUser = this.token.getUser();
    this.getId = this.activatedRoute.snapshot.paramMap.get('id');
    this.crudService.GetCandidateProfileDetailId(this.getId).subscribe((res: any) => {
      let filteredArr = this.genderList.filter(item => item.value == res.data.gender);
      this.resumeData = res.data;
      this.resumeData.gender=filteredArr[0]?.name;
      if(this.currentUser?.user_type == 2) {
        this.redirectURL = "/candidate-dashboard";
      } else {
        this.redirectURL = "/employer-dashboard";
      }
      if(!this.resumeData.photo_url){
        this.imgPhotoUrl = "../../../../assets/img/default1.png";
      } else {
        this.imgPhotoUrl = `${environment.apiBaseUrl}candidate/profile/${this.resumeData.photo}`;
      }
    });
  }

  ngOnInit(): void {
  }

}
