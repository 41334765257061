import { Component, OnInit, NgZone } from '@angular/core';
import { TokenStorageService } from 'src/app/service/login/token-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-style-one',
  templateUrl: './footer-style-one.component.html',
  styleUrls: ['./footer-style-one.component.scss']
})
export class FooterStyleOneComponent implements OnInit {

  currentUser: any;
  isLoggedIn = false;

  constructor(
    private token: TokenStorageService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.token.getToken()) {
      this.isLoggedIn = true;
      this.currentUser = this.token.getUser();
    }
  }

  reloadPage() {
    window.location.reload()
  }

  gotoMyJobs() {
    this.token.setTest(true);
  }

  gotoAccountant() {
    const payload={ job_category:13 };
    const title = "accountant";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`));
  }

  gotoMarketingManager() {
    const payload={ job_category:25 };
    const title = "marketing-manager";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`));
  }

  gotoConsultant() {
    const payload={ job_category:22 };
    const title = "consultant";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`));
  }

  gotoSoftwareDev() {
    const payload={ job_category:16 };
    const title = "software-dev";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`));
  }

  gotoTechnician() {
    const payload={ job_category:6 };
    const title = "technician";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`));
  }

  gotoPlumber() {
    const payload={ job_category:5 };
    const title = "plumber";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`));
  }

  // candidate
  gotoCandidateAccountant() {
    const payload={ candidate_category:13 };
    const title = "accountant";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/candidates/${title}`));
  }

  gotoCandidateMarketingManager() {
    const payload={ candidate_category:25 };
    const title = "marketing-manager";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/candidates/${title}`));
  }

  gotoCandidateConsultant() {
    const payload={ candidate_category:22 };
    const title = "consultant";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/candidates/${title}`));
  }

  gotoCandidateSoftwareDev() {
    const payload={ candidate_category:16 };
    const title = "software-dev";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/candidates/${title}`));
  }

  gotoCandidateTechnician() {
    const payload={ candidate_category:6 };
    const title = "technician";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/candidates/${title}`));
  }
  
  gotoCandidatePlumber() {
    const payload={ candidate_category:5 };
    const title = "plumber";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/candidates/${title}`));
  }

}
