<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Dashboard</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Dashboard</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Dashboard -->
<div class="dashboard-area ptb-100">
    <div class="container">

        <div *ngIf="saveSucess" class="alert alert-success">
            <strong [class]="FadeOutSuccessMsg()">{{ successMessage }}</strong>
        </div>
        <div *ngIf="error" class="alert alert-danger">
            {{ errorMessage }}
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div class="profile-item">
                    <div class="image-container">
                        <img src="{{ imgPhotoUrl }}" onerror="this.src='../../../../assets/img/default1.png'"
                            alt="Profile" class="actual-image">
                    </div>
                </div>

                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link active" id="my-dashboard-tab" data-bs-toggle="pill" href="#my-dashboard"
                        role="tab" aria-controls="my-dashboard" aria-selected="true"><i class='bx bx-user'></i>
                        Dashboard</a>

                    <a class="nav-link" id="my-profile-tab" data-bs-toggle="pill" href="#my-profile" role="tab"
                        aria-controls="my-profile" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bx-user'></i>
                            My Profile <i *ngIf="isProfileDone" class="bx bx-check-circle" style="color:green"></i>
                        </div>
                    </a>

                    <a class="nav-link" id="my-applied-jobs-tab" data-bs-toggle="pill" href="#my-applied-jobs"
                        role="tab" aria-controls="my-applied-jobs" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>Applied Jobs
                        </div>
                    </a>

                    <a class="nav-link" id="my-favourite-jobs-tab" data-bs-toggle="pill" href="#my-favourite-jobs"
                        role="tab" aria-controls="my-favourite-jobs" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>
                            Saved Jobs
                        </div>
                    </a>

                    <a class="nav-link" id="my-favourite-jobs-tab" data-bs-toggle="pill" href="#my-education" role="tab"
                        aria-controls="my-favourite-jobs" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>
                            My Education <i *ngIf="isEducationCompleted" class="bx bx-check-circle"
                                style="color:green"></i>
                        </div>
                    </a>

                    <a class="nav-link" id="my-favourite-jobs-tab" data-bs-toggle="pill" href="#my-experience"
                        role="tab" aria-controls="my-favourite-jobs" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>
                            My Experience <i *ngIf="isExperienceCompleted" class="bx bx-check-circle"
                                style="color:green"></i>
                        </div>
                    </a>

                    <a class="nav-link" id="my-favourite-jobs-tab" data-bs-toggle="pill" href="#my-skills" role="tab"
                        aria-controls="my-favourite-jobs" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>
                            My Skills <i *ngIf="isSkillCompleted" class="bx bx-check-circle" style="color:green"></i>
                        </div>
                    </a>

                    <a class="nav-link" id="my-certificates" data-bs-toggle="pill" href="#my-cert" role="tab"
                        aria-controls="my-certificates" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>
                            My Certificates <i *ngIf="isCertificateCompleted" class="bx bx-check-circle"
                                style="color:green"></i>
                        </div>
                    </a>

                    <a class="nav-link" id="my-favourite-jobs-tab" data-bs-toggle="pill" href="#my-resume" role="tab"
                        aria-controls="my-favourite-jobs" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>
                            My Resume <i *ngIf="isResumeCompleted" class="bx bx-check-circle" style="color:green"></i>
                        </div>
                    </a>

                    <a class="nav-link" id="my-cv-template-tab" data-bs-toggle="pill" href="#my-template" role="tab"
                        aria-controls="my-cv-template" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>
                            CV Template
                        </div>
                    </a>

                    <a class="nav-link" id="my-language-tab" data-bs-toggle="pill" href="#my-language" role="tab"
                        aria-controls="my-language" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>
                            My Language <i *ngIf="isLanguageCompleted" class="bx bx-check-circle"
                                style="color:green"></i>
                        </div>
                    </a>

                    <a class="nav-link" id="my-documents-tab" data-bs-toggle="pill" href="#my-documents" role="tab"
                        aria-controls="my-documents" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>
                            My Documents <i *ngIf="isDocumentCompleted" class="bx bx-check-circle"
                                style="color:green"></i>
                        </div>
                    </a>

                    <a class="nav-link" id="my-alert-tab" data-bs-toggle="pill" href="#my-alert" role="tab"
                        aria-controls="my-alert" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>
                            Job Alerts
                        </div>
                    </a>

                    <a class="nav-link" id="my-password-tab" data-bs-toggle="pill" href="#my-password" role="tab"
                        aria-controls="my-password" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>
                            Change Password
                        </div>
                    </a>

                    <a class="nav-link" style="cursor:pointer;" (click)="logout()">
                        <div class="profile-list"><i class='bx bx-log-out'></i> Logout</div>
                    </a>
                </div>
            </div>

            <div class="col-lg-9 col-md-12">
                <div class="tab-content" id="v-pills-tabContent">

                    <!-- dashboard -->
                    <div class="tab-pane fade show active" id="my-dashboard" role="tabpanel"
                        aria-labelledby="my-dashboard-tab">
                        <div class="profile-content">
                            <div class="profile-content-inner">
                                <div>

                                </div>
                                <h2>Dashboard</h2>

                                <div class="dashboard-row" *ngIf="isValidForApplication == 'false'">
                                    <div class="card notification-card">
                                        <div class="card-body">
                                            <span style="color:red">
                                                To start applying to Jobs, you should complete your Profile, Education,
                                                Experience(if any), Skills, <br> Certificates(if any), Language and
                                                upload your Resume and required Documents.
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="dashboard-row">
                                    <div class="card notification-card">
                                        <div class="card-body">
                                            <h4>Notifications</h4>
                                            <hr>
                                            <div *ngIf="showNotification">

                                                <ul style="list-style-type: none;">
                                                    <li style="margin-bottom: 1rem;"
                                                        *ngFor="let item of selectedStateArray[0] | slice:0:1">
                                                        <i class="bx bxs-chevron-right"></i>
                                                        {{ item.job.employer_company.company_name }} has updated your
                                                        status to {{ item.selection_state.state_name }}
                                                    </li>
                                                    <ng-container *ngIf="isShowMore">
                                                        <ng-container
                                                            *ngFor="let expandedItem of selectedStateArray[0]; let i = index">
                                                            <li style="margin-bottom: 1rem;" *ngIf="i >= 1">
                                                                <i class="bx bxs-chevron-right"></i>
                                                                {{ expandedItem.job.employer_company.company_name }} has
                                                                updated your status to {{
                                                                expandedItem.selection_state.state_name }}
                                                            </li>
                                                        </ng-container>
                                                    </ng-container>
                                                </ul>

                                                <div *ngIf="selectedStateArray[0]?.length >= 1">
                                                    <button *ngIf="!isShowMore" type="button"
                                                        style="background-color: transparent; border: none; font-size: 13px;"
                                                        (click)="expandItems()">
                                                        Show More <i class="bx bxs-chevron-down-circle"
                                                            aria-hidden="true"></i>
                                                    </button>
                                                    <button *ngIf="isShowMore" type="button"
                                                        style="background-color: transparent; border: none; font-size: 13px;"
                                                        (click)="expandItems()">
                                                        Show Less <i class="bx bxs-chevron-up-circle"
                                                            aria-hidden="true"></i>
                                                    </button>
                                                </div>

                                            </div>

                                            <div class="card" *ngIf="!showNotification">
                                                <div class="card-body">
                                                    <h4> You have no notifications. </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="dashboard-row">

                                    <div class="card dash-card">
                                        <div class="card-body">
                                            <h5 class="card-title" style="font-size: 20px;">Profile Completed</h5>
                                            <circle-progress [percent]="profileCompleted" [radius]="80"
                                                [unitsFontSize]="27" [unitsFontWeight]="600"
                                                [outerStrokeColor]="'#fe4a55'" [outerStrokeWidth]="8"
                                                [titleFontSize]="27" [titleFontWeight]="600" [subtitleFontSize]="15"
                                                [subtitleFontWeight]="400" [showInnerStroke]="false"
                                                [startFromZero]=false [animation]="true" [animationDuration]="1000">
                                            </circle-progress>
                                        </div>
                                    </div>

                                    <div class="card dash-card">
                                        <div class="card-body">
                                            <h5 class="card-title" style="font-size: 20px;">Total Applied Jobs</h5>
                                            <span
                                                style="position: relative;top: 80px;font-size: 50px;position: relative;">
                                                {{ totalAppliedItems }} </span>
                                        </div>
                                    </div>

                                    <div class="card dash-card">
                                        <div class="card-body">
                                            <h5 class="card-title" style="font-size: 20px;">Total Saved Jobs</h5>
                                            <span
                                                style="position: relative;top: 80px;font-size: 50px;position: relative;">
                                                {{ totalFavItems }} </span>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <h2>Summary</h2>

                                    <div class="">
                                        <h4 style="font-weight: bold; text-align:center">
                                            My Education
                                        </h4>

                                        <div class="col-lg-12 col-md-12">
                                            <ng-template #no_data>
                                                No data found!!!
                                            </ng-template>
                                            <div class="table-responsive"
                                                *ngIf="CandidateEducation?.length > 0;else no_data">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Education</th>
                                                            <th scope="col">Course</th>
                                                            <th scope="col">University</th>
                                                            <th scope="col">Course Type</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let rows of CandidateEducation | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }; let i = index">
                                                            <td>{{ rows.education.name }}</td>
                                                            <td>
                                                                {{ rows.course.name }}
                                                            </td>
                                                            <td>{{ rows.university.name }}</td>
                                                            <td> {{ rows.course_type ? rows.course_type : "NA" }} </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>

                                    <!-- <div class="">
                                        <h4>My Experience</h4>

                                        <div class="col-lg-12 col-md-12">
                                            <ng-template #no_data>
                                                No data found!!!
                                            </ng-template>
                                            <div class="table-responsive"
                                                *ngIf="CandidateExperience?.length > 0;else no_data">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Organization</th>
                                                            <th scope="col">Working From</th>
                                                            <th scope="col">Working Till</th>
                                                            <th scope="col">Total Duration</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let rows of CandidateExperience | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }">
                                                            <td>{{ rows.organization }}</td>
                                                            <td>{{ rows.working_from_month }}/{{ rows.working_from_year
                                                                }}
                                                            </td>
                                                            <td>{{ rows.working_till_month }}/{{ rows.working_till_year
                                                                }}
                                                            </td>
                                                            <td>{{ rows.total_duration }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="">
                                        <!-- Conditional alignment and bold styling -->
                                        <h4 style="font-weight: bold; text-align:center">
                                            My Experience
                                        </h4>
                                    
                                        <!-- <div class="col-lg-12 col-md-12">
                                            <ng-template #no_data>
                                                No data found!!!
                                            </ng-template>
                                            
                                            <div class="table-responsive" *ngIf="CandidateExperience?.length > 0; else no_data">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Organization</th>
                                                            <th scope="col">Working From</th>
                                                            <th scope="col">Working Till</th>
                                                            <th scope="col">Total Duration</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let rows of CandidateExperience | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }">
                                                            <td>{{ rows.organization }}</td>
                                                            <td>{{ rows.working_from_month }}/{{ rows.working_from_year }}</td>
                                                            <td>{{ rows.working_till_month }}/{{ rows.working_till_year }}</td>
                                                            <td>{{ rows.total_duration }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> -->
                                        <div class="col-lg-12 col-md-12">
                                            <ng-template #no_data>
                                                <!-- Center-aligned "No data found" message -->
                                                <p style="text-align: center;">No data found!!!</p>
                                            </ng-template>
                                            
                                            <div class="table-responsive" *ngIf="CandidateExperience?.length > 0; else no_data">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Organization</th>
                                                            <th scope="col">Working From</th>
                                                            <th scope="col">Working Till</th>
                                                            <th scope="col">Total Duration</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let rows of CandidateExperience | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }">
                                                            <td>{{ rows.organization }}</td>
                                                            <td>{{ rows.working_from_month }}/{{ rows.working_from_year }}</td>
                                                            <td>{{ rows.working_till_month }}/{{ rows.working_till_year }}</td>
                                                            <td>{{ rows.total_duration }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <hr>

                                    <div class="">
                                        <!-- <h4>My Skills</h4> -->
                                        <h4 style="font-weight: bold; text-align:center">
                                            My Skills
                                        </h4>

                                        <div class="col-lg-12 col-md-12">
                                            <ng-template #no_data>
                                                No data found!!!
                                            </ng-template>
                                            <div class="table-responsive"
                                                *ngIf="CandidateSkill?.length > 0;else no_data">
                                                <table class="table table-striped">
                                                    <!-- <thead>
                                                        
                                                            <th scope="col">My Skills</th> 
                                                        
                                                        
                                                    </thead> -->
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span
                                                                    *ngFor="let rows of CandidateSkill; let last = last">
                                                                    {{ rows.key_skill.name }}<span *ngIf="!last">,
                                                                    </span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>

                                    <div class="">
                                        <h4 style="font-weight: bold; text-align:center">
                                            My Certificates
                                        </h4> <!-- Bold heading -->

                                        <div class="col-lg-12 col-md-12">
                                            <ng-template #no_data>
                                                No Data Found
                                            </ng-template>
                                            <div class="table-responsive"
                                                *ngIf="candidateCertificate?.length > 0; else no_data">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Certification Name</th>
                                                            <th>Certification Number/ID</th>
                                                            <th>Issuing Organization</th>
                                                            <th>Expiration Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let rows of candidateCertificate | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }; let i = index">
                                                            <td>{{ rows.certificate_name}}</td>
                                                            <td>{{ rows.certificate_number}}</td>
                                                            <td>{{ rows.issuing_organization}}</td>
                                                            <td>{{ rows.expiry_date}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>

                                    <!-- <div class="">
                                        <h4>My Language</h4>

                                        <div class="col-lg-12 col-md-12">
                                            <ng-template #no_data>
                                                No data found!!!
                                            </ng-template>
                                            <div class="table-responsive"
                                                *ngIf="candidateLanguage?.length > 0;else no_data">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Language</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let rows of candidateLanguage | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }; let i = index">
                                                            <td>{{ rows.language?.name }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="">
                                        <h4 style="font-weight: bold;">My Language</h4> <!-- Bold heading -->
                                    
                                        <div class="col-lg-12 col-md-12">
                                            <ng-template #no_data>
                                                No data found!!!
                                            </ng-template>
                                            
                                            <div *ngIf="candidateLanguage?.length > 0; else no_data">
                                                <p>
                                                    <!-- Display languages separated by commas -->
                                                    <span *ngFor="let rows of candidateLanguage; let i = index">
                                                        {{ rows.language?.name }}<span *ngIf="i < candidateLanguage.length - 1">, </span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- my profile -->
                    <div class="tab-pane fade" id="my-profile" role="tabpanel" aria-labelledby="my-profile-tab">
                        <div class="profile-content" *ngIf="showProfile">
                            <form [formGroup]="candidateProfileForm" (ngSubmit)="onSubmit()"
                                enctype="multipart/form-data">
                                <div class="profile-content-inner">
                                    <h2>Basic Info</h2>

                                    <div class="row">

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>First Name: <span class="text-danger">*</span></label>
                                                <input class="form-control" type="text" formControlName="first_name"
                                                    placeholder="First Name">
                                                <div *ngIf="f.first_name.invalid && (f.first_name.dirty || f.first_name.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.first_name.errors?.required">
                                                        First Name is required.
                                                    </div>
                                                    <div *ngIf="f.first_name.errors?.pattern">
                                                        Only Alphabets are Allowed.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Last Name: <span class="text-danger">*</span></label>
                                                <input class="form-control" type="text" formControlName="last_name"
                                                    placeholder="Last Name">
                                                <div *ngIf="f.last_name.invalid && (f.last_name.dirty || f.last_name.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.last_name.errors?.required">
                                                        Last Name is required.
                                                    </div>
                                                    <div *ngIf="f.last_name.errors?.pattern">
                                                        Only Alphabets are Allowed.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Email Id: <span class="text-danger">*</span></label>
                                                <input class="form-control" readonly type="email"
                                                    formControlName="emailid" placeholder="Email Id">
                                                <div *ngIf="f.emailid.invalid && (f.emailid.dirty || f.emailid.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.emailid.errors?.required">
                                                        Email Id is required.
                                                    </div>
                                                    <div *ngIf="f.emailid.errors?.email">
                                                        Email must be a valid email address
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-2 col-md-12">
                                            <div class="form-group">
                                                <label>Dial Code: <span class="text-danger">*</span></label>
                                                <select class="form-control" *ngIf="isCountryLoaded"
                                                    formControlName="dial_code">
                                                    <option value="" disabled>Select Code</option>
                                                    <option *ngFor="let item of dialCodeList"
                                                        [value]="item.id.toString()">{{ item.name }}({{ item.dial_code
                                                        }})
                                                    </option>
                                                </select>
                                                <div *ngIf="f.dial_code.invalid && (f.dial_code.dirty || f.dial_code.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.dial_code.errors?.required">
                                                        Dial Code is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-12">
                                            <div class="form-group">
                                                <label>Mobile No.: <span class="text-danger">*</span></label>
                                                <input class="form-control" type="text" formControlName="mobileno"
                                                    placeholder="Mobile No.">
                                                <div *ngIf="f.mobileno.invalid && (f.mobileno.dirty || f.mobileno.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.mobileno.errors?.required">
                                                        Mobile No is required.
                                                    </div>
                                                    <div *ngIf="f.mobileno.errors?.pattern">
                                                        Only Numbers are Allowed.
                                                    </div>
                                                    <!-- <div *ngIf="f.mobileno.errors?.minlength">
                                                        Number length should atleat 5.
                                                    </div> -->
                                                    <div
                                                        *ngIf="this.candidateProfileForm.get('mobileno').errors?.invalidMobileNumber">
                                                        Enter a valid mobile number.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Date of Birth: <span class="text-danger">*</span></label>
                                                <input class="form-control" type="date" formControlName="dob"
                                                    placeholder="Date of Birth" [max]="getToday()"
                                                    (change)="calculateAge()">
                                                <div *ngIf="f.dob.invalid && (f.dob.dirty || f.dob.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.dob.errors?.required">
                                                        Date of Birth is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Profile Photo:</label>
                                                <input class="form-control" type="file"
                                                    (change)="fileChangeEvent($event)">
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12" *ngIf="showEditImage">
                                            <div class="form-group cropper-wrapper">
                                                <image-cropper [imageChangedEvent]="imageChangedEvent"
                                                    [maintainAspectRatio]="true" [aspectRatio]="1/1"
                                                    [resizeToWidth]="128" format="png"
                                                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                                    (cropperReady)="cropperReady()"
                                                    (loadImageFailed)="loadImageFailed()">
                                                </image-cropper>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Age: <span class="text-danger">*</span></label>
                                                <input class="form-control" type="text" formControlName="age"
                                                    placeholder="Age">
                                                <div *ngIf="f.age.invalid && (f.age.dirty || f.age.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.age.errors?.required">
                                                        Age is required.
                                                    </div>
                                                    <div *ngIf="f.age.errors?.pattern">
                                                        Please enter numeric.
                                                    </div>
                                                    <div *ngIf="f.age.errors?.maxlength">
                                                        Only 2 digits are allowed
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Gender: <span class="text-danger">*</span></label><br />
                                                <select class="form-control" *ngIf="showCandidateEducationList"
                                                    formControlName="gender">
                                                    <option value="" disabled>Select Gender</option>
                                                    <option *ngFor="let item of genderList"
                                                        [value]="item.id.toString()">{{ item.value }}
                                                    </option>
                                                </select>
                                                <div *ngIf="f.gender.invalid && (f.gender.dirty || f.gender.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.gender.errors?.required">
                                                        Gender is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Job Category: <span class="text-danger">*</span></label>
                                                <select class="form-control" formControlName="job_category_id">
                                                    <option value="" selected disabled>Job Category</option>
                                                    <option *ngFor="let jobCategory of jobCategoryList"
                                                        [ngValue]="jobCategory.id.toString()"> {{jobCategory.name}}
                                                    </option>
                                                </select>
                                                <div *ngIf="f.job_category_id.invalid && (f.job_category_id.dirty || f.job_category_id.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.job_category_id.errors?.required">
                                                        Job Category is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Job Title: <span class="text-danger">*</span></label>
                                                <input class="form-control" type="text" formControlName="job_title"
                                                    placeholder="e.g UI/UX Developer">
                                                <div *ngIf="f.job_title.invalid && (f.job_title.dirty || f.job_title.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.job_title.errors?.required">
                                                        Job Title is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-md-12">
                                            <div class="form-group">
                                                <label>Current Salary: <span class="text-danger">*</span></label>
                                                <input class="form-control" type="text" formControlName="current_salary"
                                                    placeholder="Current Salary">
                                                <div *ngIf="f.current_salary.invalid && (f.current_salary.dirty || f.current_salary.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.current_salary.errors?.required">
                                                        Current Salary is required.
                                                    </div>
                                                    <div *ngIf="f.current_salary.errors?.pattern">
                                                        Please enter numeric.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-md-12">
                                            <div class="form-group">
                                                <label>Currency: <span class="text-danger">*</span></label>
                                                <select class="form-control" *ngIf="isCurrencyLoaded"
                                                    formControlName="currency_id">
                                                    <option value="" disabled>Select Currency</option>
                                                    <option *ngFor="let item of currencyList"
                                                        [value]="item.id.toString()">{{ item.currency }}</option>
                                                </select>
                                                <div *ngIf="f.currency_id.invalid && (f.currency_id.dirty || f.currency_id.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.currency_id.errors?.required">
                                                        Currency is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Total Experience: <span class="text-danger">*</span></label>
                                                <input class="form-control" type="text"
                                                    formControlName="total_experience" placeholder="Total Experience">
                                                <div *ngIf="f.total_experience.invalid && (f.total_experience.dirty || f.total_experience.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.total_experience.errors?.required">
                                                        Total Experience is required.
                                                    </div>
                                                    <div *ngIf="f.total_experience.errors?.pattern">
                                                        Please enter numeric.
                                                    </div>
                                                    <div *ngIf="f.total_experience.errors?.maxlength">
                                                        Only 2 digits are allowed
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Country:</label>
                                                <select class="form-control" formControlName="country_id"
                                                    id="country_id" *ngIf="isCountryLoaded"
                                                    (change)="getState($event.target.value)">
                                                    <option value="" disabled>Select Country</option>
                                                    <option *ngFor="let countryRows of countryList"
                                                        [ngValue]="countryRows.id.toString()"> {{ countryRows.name }}
                                                    </option>
                                                </select>
                                                <div *ngIf="f.country_id.invalid && (f.country_id.dirty || f.country_id.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.country_id.errors?.required">
                                                        Select Country
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>State:</label>
                                                <select class="form-control" formControlName="state_id" id="state_id"
                                                    *ngIf="isCountryLoaded" (change)="getCity($event.target.value)">
                                                    <option value="" disabled>Select State</option>
                                                    <option *ngFor="let stateRows of stateList"
                                                        [ngValue]="stateRows.id.toString()">
                                                        {{ stateRows.name }}
                                                    </option>
                                                </select>
                                                <div *ngIf="f.state_id.invalid && (f.state_id.dirty || f.state_id.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.state_id.errors?.required">
                                                        Select State
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>City:</label>
                                                <select class="form-control" formControlName="city_id" id="city_id"
                                                    *ngIf="isCountryLoaded">
                                                    <option value="" disabled>Select City</option>
                                                    <option *ngFor="let cityRows of cityList"
                                                        [ngValue]="cityRows.id.toString()">
                                                        {{ cityRows.name }}
                                                    </option>
                                                </select>
                                                <div *ngIf="f.city_id.invalid && (f.city_id.dirty || f.city_id.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.city_id.errors?.required">
                                                        Select City
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label>About Me: <span class="text-danger">*</span></label>
                                                <textarea class="form-control" formControlName="about_me" rows="5"
                                                    placeholder="About Me"></textarea>
                                                <div *ngIf="f.about_me.invalid && (f.about_me.dirty || f.about_me.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.about_me.errors?.required">
                                                        About me is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <button type="submit" class="btn dashboard-btn" *ngIf="isAgeValid"
                                    [disabled]="!candidateProfileForm.valid">Update</button>
                            </form>
                        </div>
                    </div>

                    <!-- applied jobs -->
                    <div class="tab-pane fade" id="my-applied-jobs" role="tabpanel"
                        aria-labelledby="my-applied-jobs-tab">
                        <div class="row">
                            <ng-template class="col-lg-12 col-md-12" #no_applied_data>
                                <div class="candidate-applied-item d-flex justify-content-center">
                                    <h3>No Applied Jobs Found!!</h3>
                                </div>
                            </ng-template>
                            <div class="col-lg-12 col-md-12"
                                *ngIf="CandidateAppliedJobs?.length > 0;else no_applied_data">

                                <!-- <div class="candidate-applied-item"
                                    *ngFor="let rows of CandidateAppliedJobs | paginate : { id:'applied_jobs',itemsPerPage: 10,currentPage: page,totalItems: totalAppliedItems};let i = index">
                                    <a routerLink="/job-details/{{ rows.job.id }}">
                                        <img src="{{ rows.appliedJobProfileUrl }}" width="70" alt="Employer">
                                        <h3>{{ rows.job.title }}</h3>
                                        <ul>
                                            <li>
                                                <img src="assets/icons/location.png" alt="Details"
                                                    style="height: 15px;width: 15px;position: absolute;left: 150px;top: 60px;">
                                                {{ rows.job.city.name }}, {{ rows.job.state.name }}, {{
                                                rows.job.country.name }}
                                            </li>
                                            <li>
                                                <img src="assets/icons/createdAt.png" alt="Details"
                                                    style="height: 15px;width: 15px;position: absolute;left: 150px;top: 80px;">
                                                {{ rows.job.createdAt | dateAgo }}
                                            </li>
                                            <li>
                                                <img src="assets/icons/job-description.png" alt="Details"
                                                    style="height: 15px;width: 15px;position: absolute;left: 150px;top: 100px;">
                                                {{ rows.job.job_description }}
                                            </li>
                                        </ul>
                                    </a>
                                    <div>
                                        <span class="span-one">{{ rows.job.job_category.name }}</span>
                                        <span class="span-two">{{ rows.job.job_type.name }}</span>
                                    </div>
                                </div> -->

                                <div class="candidate-applied-list"
                                    *ngFor="let rows of CandidateAppliedJobs | paginate : { id:'applied_jobs',itemsPerPage: 10,currentPage: page,totalItems: totalAppliedItems};let i = index">
                                    <div class="candidate-applied-img"
                                        (click)="gotoJobDetail(rows.jobs_id, rows.job.title)">
                                        <img src="{{ rows.appliedJobProfileUrl }}"
                                            onerror="this.src='../../../../assets/img/default1.png'" width="70"
                                            alt="Employer">
                                    </div>
                                    <div class="candidate-applied-data"
                                        (click)="gotoJobDetail(rows.jobs_id, rows.job.title)">
                                        <h3>{{ rows.job.title }}</h3>
                                        <div>
                                            <ul>
                                                <li>
                                                    <img src="assets/icons/location.png" alt="Details"
                                                        style="height: 15px;width: 15px">
                                                    {{ rows.job.city.name }}, {{ rows.job.state.name }}, {{
                                                    rows.job.country.name }}
                                                </li>
                                                <li>
                                                    <img src="assets/icons/createdAt.png" alt="Details"
                                                        style="height: 15px;width: 15px;">
                                                    {{ rows.job.createdAt | dateAgo }}
                                                </li>
                                                <li>
                                                    <img src="assets/icons/job-description.png" alt="Details"
                                                        style="height: 15px;width: 15px">
                                                    {{ rows.job.job_description }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="span-control">
                                            <span class="span-one">{{ rows.job.job_category.name }}</span>
                                            <span class="span-two">{{ rows.job.job_type.name }}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="pagination-area">
                                <pagination-controls id="applied_jobs" class="pagi"
                                    (pageChange)="candidateAppliedJobsListData(page = $event)" (directionLinks)="true"
                                    (responsive)="true"></pagination-controls>
                            </div>
                        </div>
                    </div>

                    <!-- saved jobs -->
                    <div class="tab-pane fade" id="my-favourite-jobs" role="tabpanel"
                        aria-labelledby="my-favourite-jobs-tab">
                        <div class="row">
                            <ng-template class="col-lg-12 col-md-12" #no_favourite_data>
                                <div class="employer-item d-flex justify-content-center">
                                    <h3>No Saved Jobs Found!!</h3>
                                </div>
                            </ng-template>
                            <div class="col-lg-12 col-md-12"
                                *ngIf="CandidateFavouriteJobs?.length > 0;else no_favourite_data">

                                <!-- <div class="candidate-applied-item"
                                    *ngFor="let rows of CandidateFavouriteJobs | paginate : {id:'fav_jobs',itemsPerPage: 10,currentPage: page,totalItems: totalFavItems}; let i = index">
                                    <a routerLink="/job-details/{{ rows.job.id }}">
                                        <img src="{{ rows.appliedJobProfileUrl }}" width="70" alt="Employer">
                                        <h3>{{ rows.job.title }}</h3>
                                        <ul>
                                            <li>
                                                <img src="assets/icons/location.png" alt="Details"
                                                    style="height: 15px;width: 15px;position: absolute;left: 150px;top: 60px;">
                                                {{ rows.job.city.name }}, {{ rows.job.state.name }}, {{
                                                rows.job.country.name }}
                                            </li>
                                            <li>
                                                <img src="assets/icons/createdAt.png" alt="Details"
                                                    style="height: 15px;width: 15px;position: absolute;left: 150px;top: 80px;">
                                                {{ rows.job.createdAt | dateAgo }}
                                            </li>
                                            <li>
                                                <img src="assets/icons/job-description.png" alt="Details"
                                                    style="height: 15px;width: 15px;position: absolute;left: 150px;top: 100px;">
                                                {{ rows.job.job_description }}
                                            </li>
                                        </ul>
                                    </a>

                                    <span class="span-one">{{ rows.job.job_category.name }}</span>
                                    <span class="span-two">{{ rows.job.job_type.name }}</span>
                                </div> -->


                                <!-- <div class="candidate-applied-list" style="cursor: pointer;"
                                    *ngFor="let rows of CandidateFavouriteJobs | paginate : { id:'fav_jobs',itemsPerPage: 10,currentPage: page,totalItems: totalAppliedItems};let i = index">
                                        <div class="candidate-applied-img" (click)="gotoJobDetail(rows.jobs_id, rows.job.title)">
                                            <img src="{{ rows.appliedJobProfileUrl }}" onerror="this.src='../../../../assets/img/default1.png'" width="70" alt="Employer">
                                        </div>
                                        <div class="candidate-applied-data" (click)="gotoJobDetail(rows.jobs_id, rows.job.title)">
                                            <h3>{{ rows.job.title }}</h3>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <img src="assets/icons/location.png" alt="Details"
                                                            style="height: 15px;width: 15px">
                                                        {{ rows.job.city.name }}, {{ rows.job.state.name }}, {{
                                                        rows.job.country.name }}
                                                    </li>
                                                    <li>
                                                        <img src="assets/icons/createdAt.png" alt="Details"
                                                            style="height: 15px;width: 15px;">
                                                        {{ rows.job.createdAt | dateAgo }}
                                                    </li>
                                                    <li>
                                                        <img src="assets/icons/job-description.png" alt="Details"
                                                            style="height: 15px;width: 15px">
                                                        {{ rows.job.job_description }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="span-control">
                                                <span class="span-one">{{ rows.job.job_category.name }}</span>
                                                <span class="span-two">{{ rows.job.job_type.name }}</span>
                                            </div>
                                        </div>
                                </div> -->
                                <div class="candidate-applied-list"
                                    *ngFor="let rows of CandidateFavouriteJobs | paginate : { id:'fav_jobs',itemsPerPage: 10,currentPage: page,totalItems: totalAppliedItems};let i = index">

                                    <div class="candidate-applied-img"
                                        (click)="gotoJobDetail(rows.jobs_id, rows.job.title)">
                                        <img src="{{ rows.appliedJobProfileUrl }}"
                                            onerror="this.src='../../../../assets/img/default1.png'" width="70"
                                            alt="Employer">
                                    </div>

                                    <div class="candidate-applied-data"
                                        (click)="gotoJobDetail(rows.jobs_id, rows.job.title)">
                                        <h3 style="cursor: pointer;">{{ rows.job.title }}</h3>
                                        <!-- Cursor pointer only on the title -->

                                        <div>
                                            <ul>
                                                <li>
                                                    <img src="assets/icons/location.png" alt="Details"
                                                        style="height: 15px;width: 15px">
                                                    {{ rows.job.city.name }}, {{ rows.job.state.name }}, {{
                                                    rows.job.country.name }}
                                                </li>
                                                <li>
                                                    <img src="assets/icons/createdAt.png" alt="Details"
                                                        style="height: 15px;width: 15px;">
                                                    {{ rows.job.createdAt | dateAgo }}
                                                </li>
                                                <li>
                                                    <img src="assets/icons/job-description.png" alt="Details"
                                                        style="height: 15px;width: 15px">
                                                    {{ rows.job.job_description }}
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="span-control">
                                            <span class="span-one">{{ rows.job.job_category.name }}</span>
                                            <span class="span-two">{{ rows.job.job_type.name }}</span>
                                        </div>
                                    </div>
                                </div>


                                <div class="pagination-area">
                                    <pagination-controls id="fav_jobs" class="pagi"
                                        (pageChange)="candidateFavouriteJobsListData((page = $event))"
                                        (directionLinks)="true" (responsive)="true"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- education -->
                    <div class="tab-pane fade" id="my-education" role="tabpanel" aria-labelledby="my-education-tab">

                        <div class="profile-content" *ngIf="showCandidateEducationList">
                            <div class="profile-content-inner">
                                <h2>Education</h2>

                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="addCandidateEducation()">Add New</button>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <ng-template #no_data>
                                            No data found!!!
                                        </ng-template>
                                        <div class="table-responsive"
                                            *ngIf="CandidateEducation?.length > 0;else no_data">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Education</th>
                                                        <th scope="col">Course</th>
                                                        <th scope="col">University</th>
                                                        <th scope="col">Course Type</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let rows of CandidateEducation | paginate : { id:'candidate_education', itemsPerPage: 10, currentPage: page, totalItems: totalCandidateEducationItems }; let i = index">
                                                        <td>{{ rows.education.name }}</td>
                                                        <td>
                                                            {{ rows.course.name }}
                                                        </td>
                                                        <td>{{ rows.university.name }}</td>
                                                        <td> {{ rows.course_type ? rows.course_type : "NA" }} </td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-primary me-1"
                                                                (click)="editCandidateEducation(rows.id)">Edit</button>
                                                            <button class="btn btn-sm btn-danger"
                                                                (click)="deleteCandidateEducation(rows.id, i)">Delete</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <pagination-controls id="candidate_education" class="pagi"
                                                (pageChange)="candidateEducationListData(page = $event)"
                                                (directionLinks)="true" (responsive)="true"></pagination-controls>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="profile-content" *ngIf="showCandidateEducationForm">
                            <div class="profile-content-inner">
                                <h2>Add Education</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="viewCandidateEducation()">View All</button>
                                        <!-- <button class="btn btn-sm btn-primary" (click)="getSavedCandidateEducation()">View All</button> -->
                                    </div>
                                    <div class="col-lg-12 col-md-4">
                                        <form [formGroup]="candidateEducationForm"
                                            (ngSubmit)="candidateEducationSubmit()">

                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Education: <span class="text-danger">*</span></label>
                                                        <select class="form-control" formControlName="education_id"
                                                            *ngIf="isEducationLoaded"
                                                            (change)="courseTypeDropdown($event.target.value)">
                                                            <option value="" [selected]="true" disabled>Select
                                                                Education</option>
                                                            <option *ngFor="let educationRows of educationList"
                                                                [ngValue]="educationRows.id.toString()">{{
                                                                educationRows.name }}</option>
                                                        </select>
                                                        <div *ngIf="fEdu.education_id.invalid && (fEdu.education_id.dirty || fEdu.education_id.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEdu.education_id.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Course: <span class="text-danger">*</span></label>
                                                        <select class="form-control" formControlName="course_id">
                                                            <option value="" disabled>Select Course</option>
                                                            <option *ngFor="let courseRows of courseTypeList"
                                                                [value]="courseRows.id.toString()"> {{
                                                                courseRows.name }}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="fEdu.course_id.invalid && (fEdu.course_id.dirty || fEdu.course_id.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEdu.course_id.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>University: <span class="text-danger">*</span></label>
                                                        <!-- <select class="form-control" formControlName="university_id">
                                                    <option value="">Select</option>
                                                    <option *ngFor="let universityRows of universityList"
                                                        [ngValue]="universityRows.id.toString()">
                                                        {{ universityRows.name }}
                                                    </option>
                                                </select> -->
                                                        <ng-select formControlName="university_id" bindLabel="name"
                                                            bindValue="id" [items]="university" [multiple]="false"
                                                            [closeOnSelect]="true" [searchable]="true"
                                                            [hideSelected]="false" placeholder="Select University"
                                                            [ngModel]="selectedUniversity" addTagText="Add New"
                                                            [addTag]="addNewUniversity">
                                                        </ng-select>
                                                        <div *ngIf="fEdu.university_id.invalid && (fEdu.university_id.dirty || fEdu.university_id.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEdu.university_id.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Course Type:</label>
                                                        <input class="form-control" type="text"
                                                            formControlName="course_type" placeholder="Course Type">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Passing Year: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="passing_year" placeholder="Passing Year">
                                                        <div *ngIf="fEdu.passing_year.invalid && (fEdu.passing_year.dirty || fEdu.passing_year.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEdu.passing_year.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fEdu.passing_year.errors?.pattern">
                                                                Please enter numeric.
                                                            </div>
                                                            <div *ngIf="fEdu.passing_year.errors?.minlength">
                                                                Minimum 4 digits are allowed
                                                            </div>
                                                            <div *ngIf="fEdu.passing_year.errors?.maxlength">
                                                                Maximum 4 digits are allowed
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Marks: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" formControlName="marks"
                                                            placeholder="Marks">
                                                        <div *ngIf="fEdu.marks.invalid && (fEdu.marks.dirty || fEdu.marks.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEdu.marks.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fEdu.marks.errors?.pattern">
                                                                Only Numbers are allowed.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <button type="submit" class="btn dashboard-btn"
                                                [disabled]="!candidateEducationForm.valid">Save</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="profile-content" *ngIf="showEditCandidateEducationForm">
                            <div class="profile-content-inner">
                                <h2>Edit Education</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="viewCandidateEducation()">View
                                            All</button>
                                    </div>
                                    <div class="col-lg-12 col-md-4">
                                        <form [formGroup]="candidateEducationEditForm"
                                            (ngSubmit)="candidateEducationUpdate()">

                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Education: <span class="text-danger">*</span></label>
                                                        <select class="form-control" formControlName="education_id"
                                                            (change)="getCourse($event.target.value)">
                                                            <option value="" disabled>Select</option>
                                                            <option *ngFor="let educationRows of educationList"
                                                                [ngValue]="educationRows.id.toString()">
                                                                {{ educationRows.name }}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="eduEdit.education_id.invalid && (eduEdit.education_id.dirty || eduEdit.education_id.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="eduEdit.education_id.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Course: <span class="text-danger">*</span></label>
                                                        <select class="form-control" formControlName="course_id">
                                                            <option value="" disabled>Select</option>
                                                            <option *ngFor="let courseRows of courseList"
                                                                [ngValue]="courseRows.id.toString()">
                                                                {{ courseRows.name }}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="eduEdit.course_id.invalid && (eduEdit.course_id.dirty || eduEdit.course_id.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="eduEdit.course_id.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>University: <span class="text-danger">*</span></label>
                                                        <select class="form-control" formControlName="university_id">
                                                            <option value="" disabled>Select</option>
                                                            <option *ngFor="let universityRows of universityList"
                                                                [ngValue]="universityRows.id.toString()">
                                                                {{ universityRows.name }}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="eduEdit.university_id.invalid && (eduEdit.university_id.dirty || eduEdit.university_id.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="eduEdit.university_id.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Course Type:</label>
                                                        <!-- <select class="form-control" formControlName="course_type"
                                                    id="course_type_id">
                                                    <option value="">Select</option>
                                                    <option *ngFor="let courseTypeRows of courseTypeList"
                                                        [ngValue]="courseTypeRows.id.toString()">
                                                        {{ courseTypeRows.name }}
                                                    </option>
                                                </select> -->
                                                        <input class="form-control" type="text"
                                                            formControlName="course_type" placeholder="Course Type">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Passing Year: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="passing_year" placeholder="Passing Year">
                                                        <div *ngIf="eduEdit.passing_year.invalid && (eduEdit.passing_year.dirty || eduEdit.passing_year.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="eduEdit.passing_year.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="eduEdit.passing_year.errors?.pattern">
                                                                Please enter numeric.
                                                            </div>
                                                            <div *ngIf="eduEdit.passing_year.errors?.minlength">
                                                                Minimum 4 digits are allowed
                                                            </div>
                                                            <div *ngIf="eduEdit.passing_year.errors?.maxlength">
                                                                Maximum 4 digits are allowed
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Marks: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" formControlName="marks"
                                                            placeholder="Marks">
                                                        <div *ngIf="eduEdit.marks.invalid && (eduEdit.marks.dirty || eduEdit.marks.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="eduEdit.marks.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="eduEdit.marks.errors?.pattern">
                                                                Only Numbers are allowed.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <button type="submit" class="btn dashboard-btn"
                                                [disabled]="!candidateEducationEditForm.valid">Update</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- experience -->
                    <div class="tab-pane fade" id="my-experience" role="tabpanel" aria-labelledby="my-experience-tab">

                        <div class="profile-content" *ngIf="showCandidateExperienceList">
                            <div class="profile-content-inner">
                                <h4 style="font-weight: bold; text-align:center">
                                    My Experience
                                </h4>

                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="addCandidateExperience()">Add New</button>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <ng-template #no_data>
                                            No data found!!!
                                        </ng-template>
                                        <div class="table-responsive"
                                            *ngIf="CandidateExperience?.length > 0;else no_data">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Organization</th>
                                                        <th scope="col">Working From</th>
                                                        <th scope="col">Working Till</th>
                                                        <th scope="col">Total Duration</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let rows of CandidateExperience | paginate : { id:'canidate_experience', itemsPerPage: 10, currentPage: page, totalItems: totalItems }">
                                                        <td>{{ rows.organization }}</td>
                                                        <td>{{ rows.working_from_month }}/{{ rows.working_from_year
                                                            }}
                                                        </td>
                                                        <td *ngIf="rows.is_current_company == 0">{{
                                                            rows.working_till_month }}/{{ rows.working_till_year }}
                                                        </td>
                                                        <td *ngIf="rows.is_current_company == 1">Present</td>
                                                        <td>{{ rows.total_duration }}</td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-primary me-1"
                                                                (click)="editCandidateExperience(rows.id)">Edit</button>
                                                            <button class="btn btn-sm btn-danger"
                                                                (click)="deleteCandidateExperience(rows.id)">Delete</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <pagination-controls id="canidate_experience" class="pagi"
                                                (pageChange)="candidateExperienceListData(page = $event)"
                                                (directionLinks)="true" (responsive)="true"></pagination-controls>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="profile-content" *ngIf="showCandidateExperienceForm">
                            <div class="profile-content-inner">
                                <h2>Add Experience</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="viewCandidateExperience()">View
                                            All</button>
                                    </div>
                                    <div class="col-lg-12 col-md-4">
                                        <form class="form-inline" [formGroup]="candidateExperienceForm"
                                            (ngSubmit)="candidateExperienceSubmit()">

                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Organization: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="organization"
                                                            placeholder="Organization Name">
                                                        <div *ngIf="fExp.organization.invalid && (fExp.organization.dirty || fExp.organization.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fExp.organization.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Designation: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="designation" placeholder="Designation">
                                                        <div *ngIf="fExp.designation.invalid && (fExp.designation.dirty || fExp.designation.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fExp.designation.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Working From(Year): <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="working_from_year" placeholder="YYYY"
                                                            [(ngModel)]="working_from_year">
                                                        <div *ngIf="fExp.working_from_year.invalid && (fExp.working_from_year.dirty || fExp.working_from_year.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fExp.working_from_year.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fExp.working_from_year.errors?.pattern">
                                                                Only Numbers are Allowed.
                                                            </div>
                                                            <div *ngIf="fExp.working_from_year.errors?.minlength">
                                                                Minimum 4 digits are allowed
                                                            </div>
                                                            <div *ngIf="fExp.working_from_year.errors?.maxlength">
                                                                Maximum 4 digits are allowed
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Working From(Month):</label>
                                                        <input class="form-control" type="text"
                                                            formControlName="working_from_month" placeholder="MM"
                                                            [(ngModel)]="working_from_month">
                                                        <div *ngIf="fExp.working_from_month.invalid && (fExp.working_from_month.dirty || fExp.working_from_month.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fExp.working_from_month.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fExp.working_from_month.errors?.pattern">
                                                                Only Numbers are Allowed.
                                                            </div>
                                                            <div *ngIf="fExp.working_from_month.errors?.maxlength">
                                                                Maximum 2 digits are allowed
                                                            </div>
                                                            <div *ngIf="fExp.working_from_month.errors?.max">
                                                                Month greater than 12 are not allowed
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group d-flex justify-content-between">
                                                        <label>Is your Current Company?: <span
                                                                class="text-danger">*</span></label>
                                                        <div class="form-check form-switch">
                                                            <input mdbCheckbox checked="false"
                                                                [(ngModel)]="isCurrentCompanyToggle"
                                                                class="form-check form-switch form-check-input"
                                                                formControlName="is_current_company"
                                                                id="is_current_company" type="checkbox"
                                                                (click)="addHideWorking()" />
                                                        </div>
                                                        <div *ngIf="fExp.is_current_company.invalid && (fExp.is_current_company.dirty || fExp.is_current_company.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fExp.is_current_company.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                </div>

                                                <!-- *ngIf="isShown" -->
                                                <div class="col-lg-6 col-md-12" *ngIf="isShown">
                                                    <div class="form-group">
                                                        <label>Worked Till(Year): <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="number"
                                                            formControlName="working_till_year" placeholder="YYYY"
                                                            [(ngModel)]="working_till_year" [min]="fExp.working_from_year.value">
                                                        <div *ngIf="fExp.working_till_year.invalid && (fExp.working_till_year.dirty || fExp.working_till_year.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fExp.working_till_year.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fExp.working_till_year.errors?.pattern">
                                                                Only numbers are allowed.
                                                            </div>
                                                            <div *ngIf="fExp.working_till_year.errors?.minlength">
                                                                Minimum 4 digits are allowed
                                                            </div>
                                                            <div *ngIf="fExp.working_till_year.errors?.maxlength">
                                                                Maximum 4 digits are allowed
                                                            </div>
                                                            <div *ngIf="!fExp.working_till_year.errors?.require && fExp.working_till_year.errors?.min">
                                                                 Year should not be past
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- *ngIf="isShown" -->
                                                <div class="col-lg-6 col-md-12" *ngIf="isShown">
                                                    <div class="form-group">
                                                        <label>Worked Till(Month): <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="working_till_month" placeholder="MM"
                                                            [(ngModel)]="working_till_month" [min]="fExp.working_from_month.value" max="12">
                                                        <div *ngIf="fExp.working_till_month.invalid && (fExp.working_till_month.dirty || fExp.working_till_month.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fExp.working_till_month.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fExp.working_till_month.errors?.pattern">
                                                                Only Numbers are Allowed.
                                                            </div>
                                                            <div *ngIf="fExp.working_till_month.errors?.maxlength">
                                                                Maximum 2 digits are allowed
                                                            </div>
                                                            <div *ngIf="fExp.working_till_month.errors?.max">
                                                                Month greater than 12 are not allowed
                                                            </div>
                                                            <div *ngIf="fExp.working_till_month.errors?.required && fExp.working_till_year.errors?.min">
                                                                Month should not be past
                                                           </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group total-working-div">
                                                        <label>Total Working(In Years): <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control mr-sm-2" type="text"
                                                            formControlName="total_duration" placeholder="Eg. 5 Years">
                                                        <!-- <div class="input-group-append totalwork-button d-md-inline-block"> -->
                                                        <button type="button" class="btn btn-primary total-working-btn"
                                                            (click)="calculateTotalWorking()">
                                                            <i class='bx bx-calculator'></i>
                                                        </button>
                                                        <!-- </div> -->
                                                        <div *ngIf="fExp.total_duration.invalid && (fExp.total_duration.dirty || fExp.total_duration.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fExp.total_duration.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div
                                                                *ngIf="this.candidateExperienceForm.get('total_duration').errors?.invalidTotal">
                                                                Total Working should not be 0.
                                                            </div>
                                                            <div
                                                                *ngIf="this.candidateExperienceForm.get('total_duration').errors?.invalidDate">
                                                                In-Appropriate Total Working. Kindly check your
                                                                Working From(Year)/Working From(Month) OR Worked
                                                                Till(Year)/Worked Till(Month).
                                                            </div>
                                                        </div>
                                                        <div class="alert alert-danger"
                                                            *ngIf="this.candidateExperienceForm.get('total_duration').errors?.invalidTotal">
                                                            Total Working should not be 0.
                                                        </div>
                                                        <div class="alert alert-danger"
                                                            *ngIf="this.candidateExperienceForm.get('total_duration').errors?.invalidDate">
                                                            In-Appropriate Total Working. Kindly check your Working
                                                            From(Year)/Working From(Month) OR Worked
                                                            Till(Year)/Worked Till(Month).
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-3 col-md-12">
                                                    <div class="form-group">
                                                        <label>Salary(Per Month): <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="current_salary" placeholder="Salary">
                                                        <div *ngIf="fExp.current_salary.invalid && (fExp.current_salary.dirty || fExp.current_salary.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fExp.current_salary.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fExp.current_salary.errors?.pattern">
                                                                Only numbers are allowed.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-3 col-md-12">
                                                    <div class="form-group">
                                                        <label>Currency: <span class="text-danger">*</span></label>
                                                        <select class="form-control" *ngIf="isCurrencyLoaded"
                                                            formControlName="currency_id">
                                                            <option value="" disabled>Select Currency</option>
                                                            <option *ngFor="let item of currencyList"
                                                                [value]="item.id.toString()">{{ item.currency }}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="fExp.currency_id.invalid && (fExp.currency_id.dirty || fExp.currency_id.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fExp.currency_id.errors?.required">
                                                                Currency is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Description: <span class="text-danger">*</span></label>
                                                        <textarea class="form-control" rows="5"
                                                            formControlName="description"
                                                            placeholder="Description"></textarea>
                                                        <div *ngIf="fExp.description.invalid && (fExp.description.dirty || fExp.description.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fExp.description.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Notice Period(In Days): <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="notice_period" placeholder="Eg. 30 Days">
                                                        <div *ngIf="fExp.notice_period.invalid && (fExp.notice_period.dirty || fExp.notice_period.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fExp.notice_period.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fExp.notice_period.errors?.pattern">
                                                                Only Numbers are Allowed.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Is your Current Company?: <span class="text-danger">*</span></label>
                                                <div class="form-check form-switch" >
                                                    <input
                                                      mdbCheckbox
                                                      checked="false"
                                                      [(ngModel)]="isCurrentCompanyToggle"
                                                      class="form-check form-switch form-check-input"
                                                      formControlName="is_current_company" 
                                                      id="is_current_company"
                                                      type="checkbox" 
                                                      style="position: relative;left: 45px;bottom: 7px;"
                                                      (click)="hideWorking()"
                                                    />
                                                </div>
                                                <div *ngIf="fExp.is_current_company.invalid && (fExp.is_current_company.dirty || fExp.is_current_company.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="fExp.is_current_company.errors?.required">
                                                        This is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->

                                            </div>

                                            <button type="submit" class="btn dashboard-btn"
                                                [disabled]="!candidateExperienceForm.valid">Save</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="profile-content" *ngIf="showEditCandidateExperienceForm">
                            <div class="profile-content-inner">
                                <h2>Edit Experience</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="viewCandidateExperience()">View
                                            All</button>
                                    </div>
                                    <div class="col-lg-12 col-md-4">
                                        <form class="form-inline" [formGroup]="candidateExperienceEditForm"
                                            (ngSubmit)="candidateExperienceUpdate()">

                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Organization: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="organization"
                                                            placeholder="Organization Name">
                                                        <div *ngIf="fEditExp.organization.invalid && (fEditExp.organization.dirty || fEditExp.organization.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEditExp.organization.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Designation: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="designation" placeholder="Designation">
                                                        <div *ngIf="fEditExp.designation.invalid && (fEditExp.designation.dirty || fEditExp.designation.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEditExp.designation.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Working From(Year): <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="working_from_year" placeholder="YYYY"
                                                            [(ngModel)]="edit_working_from_year">
                                                        <div *ngIf="fEditExp.working_from_year.invalid && (fEditExp.working_from_year.dirty || fEditExp.working_from_year.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEditExp.working_from_year.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fEditExp.working_from_year.errors?.pattern">
                                                                Only Numbers are Allowed.
                                                            </div>
                                                            <div *ngIf="fEditExp.working_from_year.errors?.minlength">
                                                                Minimum 4 digits are allowed
                                                            </div>
                                                            <div *ngIf="fEditExp.working_from_year.errors?.maxlength">
                                                                Maximum 4 digits are allowed
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Working From(Month):</label>
                                                        <input class="form-control" type="text"
                                                            formControlName="working_from_month" placeholder="MM"
                                                            [(ngModel)]="edit_working_from_month">
                                                        <div *ngIf="fEditExp.working_from_month.invalid && (fEditExp.working_from_month.dirty || fEditExp.working_from_month.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEditExp.working_from_month.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fEditExp.working_from_month.errors?.pattern">
                                                                Only numbers are allowed.
                                                            </div>
                                                            <div *ngIf="fEditExp.working_from_month.errors?.maxlength">
                                                                Maximum 2 digits are allowed
                                                            </div>
                                                            <div *ngIf="fEditExp.working_from_month.errors?.max">
                                                                Month greater than 12 are not allowed
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group d-flex justify-content-between">
                                                        <label>Is your Current Company?: <span
                                                                class="text-danger">*</span></label>
                                                        <!-- <input class="form-control" type="text" formControlName="is_current_company" placeholder="MM"> -->
                                                        <div class="form-check form-switch">
                                                            <input mdbCheckbox checked="isCurrentCompanyToggle"
                                                                [(ngModel)]="isCurrentCompanyToggle"
                                                                class="form-check form-switch form-check-input"
                                                                formControlName="is_current_company"
                                                                id="is_current_company" type="checkbox"
                                                                (click)="hideWorking()" />
                                                        </div>
                                                        <div *ngIf="fEditExp.is_current_company.invalid && (fEditExp.is_current_company.dirty || fEditExp.is_current_company.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEditExp.is_current_company.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                </div>

                                                <div class="col-lg-6 col-md-12" *ngIf="isShown">
                                                    <div class="form-group">
                                                        <label>Worked Till(Year): <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="working_till_year" placeholder="YYYY"
                                                            [(ngModel)]="edit_working_till_year">
                                                        <div *ngIf="fEditExp.working_till_year.invalid && (fEditExp.working_till_year.dirty || fEditExp.working_till_year.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEditExp.working_till_year.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fEditExp.working_till_year.errors?.pattern">
                                                                Only Numbers are Allowed.
                                                            </div>
                                                            <div *ngIf="fEditExp.working_till_year.errors?.minlength">
                                                                Minimum 4 digits are allowed.
                                                            </div>
                                                            <div *ngIf="fEditExp.working_till_year.errors?.maxlength">
                                                                Maximum 4 digits are allowed.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12" *ngIf="isShown">
                                                    <div class="form-group">
                                                        <label>Worked Till(Month): <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="working_till_month" placeholder="MM"
                                                            [(ngModel)]="edit_working_till_month">
                                                        <div *ngIf="fEditExp.working_till_month.invalid && (fEditExp.working_till_month.dirty || fEditExp.working_till_month.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEditExp.working_till_month.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fEditExp.working_till_month.errors?.pattern">
                                                                Only numbers are allowed.
                                                            </div>
                                                            <div *ngIf="fEditExp.working_till_month.errors?.maxlength">
                                                                Maximum 2 digits are allowed
                                                            </div>
                                                            <div *ngIf="fEditExp.working_till_month.errors?.max">
                                                                Month greater than 12 are not allowed
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group total-working-div">
                                                        <label>Total Working(In Years): <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="total_duration" placeholder="Eg. 5 Years">
                                                        <button type="button" class="btn btn-primary total-working-btn"
                                                            (click)="calculateTotalWorkingEdit()">
                                                            <i class='bx bx-calculator'></i>
                                                        </button>
                                                        <div *ngIf="fEditExp.total_duration.invalid && (fEditExp.total_duration.dirty || fEditExp.total_duration.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEditExp.total_duration.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div
                                                                *ngIf="this.candidateExperienceEditForm.get('total_duration').errors?.invalidEditTotal">
                                                                Total Working should not be 0.
                                                            </div>
                                                            <div
                                                                *ngIf="this.candidateExperienceEditForm.get('total_duration').errors?.invalidEditDate">
                                                                In-Appropriate Total Working. Kindly check your
                                                                Working From(Year)/Working From(Month) OR Worked
                                                                Till(Year)/Worked Till(Month).
                                                            </div>
                                                        </div>
                                                        <div class="alert alert-danger"
                                                            *ngIf="this.candidateExperienceEditForm.get('total_duration').errors?.invalidEditTotal">
                                                            Total Working should not be 0.
                                                        </div>
                                                        <div class="alert alert-danger"
                                                            *ngIf="this.candidateExperienceEditForm.get('total_duration').errors?.invalidEditDate">
                                                            In-Appropriate Total Working. Kindly check your Working
                                                            From(Year)/Working From(Month) OR Worked
                                                            Till(Year)/Worked Till(Month).
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-3 col-md-12">
                                                    <div class="form-group">
                                                        <label>Salary(Per Month): <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="current_salary" placeholder="Salary">
                                                        <div *ngIf="fEditExp.current_salary.invalid && (fEditExp.current_salary.dirty || fEditExp.current_salary.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEditExp.current_salary.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fEditExp.current_salary.errors?.pattern">
                                                                Only numbers are allowed.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-3 col-md-12">
                                                    <div class="form-group">
                                                        <label>Currency: <span class="text-danger">*</span></label>
                                                        <select class="form-control" *ngIf="isCurrencyLoaded"
                                                            formControlName="currency_id">
                                                            <option value="" disabled>Select Currency</option>
                                                            <option *ngFor="let item of currencyList"
                                                                [value]="item.id.toString()">{{ item.currency }}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="fEditExp.currency_id.invalid && (fEditExp.currency_id.dirty || fEditExp.currency_id.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEditExp.currency_id.errors?.required">
                                                                Currency is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Description: <span class="text-danger">*</span></label>
                                                        <textarea class="form-control" rows="5"
                                                            formControlName="description"
                                                            placeholder="Description"></textarea>
                                                        <div *ngIf="fEditExp.description.invalid && (fEditExp.description.dirty || fEditExp.description.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEditExp.description.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Notice Period(In Days): <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="notice_period" placeholder="Eg. 30 Days">
                                                        <div *ngIf="fEditExp.notice_period.invalid && (fEditExp.notice_period.dirty || fEditExp.notice_period.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEditExp.notice_period.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <button type="submit" class="btn dashboard-btn"
                                                [disabled]="!candidateExperienceEditForm.valid">Update</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- skill -->
                    <div class="tab-pane fade" id="my-skills" role="tabpanel" aria-labelledby="my-skill-tab">

                        <div class="profile-content" *ngIf="showCandidateSkillList">
                            <div class="profile-content-inner">
                                <h2>My Skills</h2>

                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="addCandidateSkill()">Add New</button>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <ng-template #no_data>
                                            No data found!!!
                                        </ng-template>
                                        <div *ngIf="CandidateSkill?.length > 0;else no_data">
                                            <table class="table table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Skill Name</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let rows of CandidateSkill | paginate : { id:'candidate_skill', itemsPerPage: 10, currentPage: page, totalItems: totalCandidateSkillItems }; let i = index">
                                                        <td>{{ rows.key_skill.name }}</td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-danger"
                                                                (click)="deleteCandidateSkill(rows.id, i)">Delete</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="pagination-area">
                                                <pagination-controls id="candidate_skill" class="pagi"
                                                    (pageChange)="candidateSkillListData(page = $event)"
                                                    (directionLinks)="true" (responsive)="true"></pagination-controls>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="profile-content" *ngIf="showCandidateSkillForm">
                            <div class="profile-content-inner">
                                <h2>Add Skill</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="viewCandidateSkill()">View All</button>
                                    </div>
                                    <div class="col-lg-12 col-md-4">
                                        <form [formGroup]="candidateSkillForm" (ngSubmit)="candidateSkillSubmit()">

                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Skill: <span class="text-danger">*</span></label>
                                                        <!-- <select class="form-control" formControlName="key_skills_ids" multiple id="key_skills_ids">
                                                    <option value="">Select</option>
                                                    <option *ngFor="let educationRows of keySkillDropdown" [ngValue]="educationRows.id.toString()">
                                                        {{ educationRows.name }}
                                                    </option>
                                                </select> -->
                                                        <ng-select formControlName="key_skills_ids" bindLabel="name"
                                                            bindValue="id" [items]="skill" [multiple]="true"
                                                            [closeOnSelect]="true" [searchable]="true"
                                                            [hideSelected]="true" placeholder="Select Skill"
                                                            [(ngModel)]="selectedSkills" addTagText="Create New"
                                                            [addTag]="createNewSkill">
                                                        </ng-select>
                                                        <div *ngIf="fEdu.education_id.invalid && (fEdu.education_id.dirty || fEdu.education_id.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fEdu.education_id.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <button type="submit" class="btn dashboard-btn"
                                                [disabled]="!candidateSkillForm.valid">Save</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- certificates -->
                    <div class="tab-pane fade" id="my-cert" role="tabpanel" aria-labelledby="my-certificate-tab">

                        <div class="profile-content" *ngIf="showCertificateList">
                            <div class="profile-content-inner">
                                <h4 style="font-weight: bold; text-align:center">
                                    My Certificates
                                </h4>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="viewAddCertificate()">Add New</button>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <ng-template #no_data>
                                            No Data Found
                                        </ng-template>
                                        <div class="table-responsive"
                                            *ngIf="candidateCertificate?.length > 0; else no_data">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Certification Name</th>
                                                        <th scope="col">Certification Number/ID</th>
                                                        <th scope="col">Issuing Organization</th>
                                                        <th scope="col">Expiration Date</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let rows of candidateCertificate | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalCandidateCertificateItems }; let i = index">
                                                        <td>{{ rows.certificate_name}}</td>
                                                        <td>{{ rows.certificate_number}}</td>
                                                        <td>{{ rows.issuing_organization}}</td>
                                                        <td>{{ rows.expiry_date}}</td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-primary me-1"
                                                                (click)="editCertificateForm(rows.id)">Edit</button>
                                                            <button class="btn btn-sm btn-danger"
                                                                (click)="deleteCertificate(rows.id)">Delete</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="profile-content" *ngIf="showCertificateForm">
                            <div class="profile-content-inner">
                                <h2>Add Certificates</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="viewCertificateList()">View All</button>
                                    </div>
                                    <div class="col-lg-12 col-md-4">
                                        <form [formGroup]="addCandidateCertificateForm"
                                            (ngSubmit)="candidateCertificateSubmit()">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Certification Name: <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            placeholder="Certification Name"
                                                            formControlName="certificate_name">
                                                    </div>
                                                    <div *ngIf="fCert.certificate_name.invalid && (fCert.certificate_name.dirty || fCert.certificate_name.touched)"
                                                        class="alert alert-danger">
                                                        <div *ngIf="fCert.certificate_name.errors?.required">
                                                            This is required.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Certification Number/ID:</label>
                                                        <input class="form-control" type="text"
                                                            placeholder="Certification Number/ID"
                                                            formControlName="certificate_number">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Issuing Organization: <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            placeholder="Issuing Organization"
                                                            formControlName="issuing_organization">
                                                    </div>
                                                    <div *ngIf="fCert.issuing_organization.invalid && (fCert.issuing_organization.dirty || fCert.issuing_organization.touched)"
                                                        class="alert alert-danger">
                                                        <div *ngIf="fCert.issuing_organization.errors?.required">
                                                            This is required.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Issuing Date: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="date"
                                                            placeholder="Issuing Date" formControlName="issue_date">
                                                    </div> 
                                                    <div *ngIf="fCert.issue_date.invalid && (fCert.issue_date.dirty || fCert.issue_date.touched)"
                                                        class="alert alert-danger">
                                                        <div *ngIf="fCert.issue_date.errors?.required">
                                                            This is required.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Expiration Date:</label>
                                                        <input class="form-control" type="date"
                                                            placeholder="Expiration Date" formControlName="expiry_date" [min]="fCert.issue_date.value">
                                                    </div>
                                                    <!-- <div *ngIf="fCert.expiry_date.invalid && (fCert.expiry_date.dirty || fCert.expiry_date.touched)" -->
                                                    <!-- class="alert alert-danger"> -->
                                                    <!-- <div *ngIf="fCert.expiry_date.errors?.isIssueDateGreater" class="alert alert-danger">
                                                            Issue Date of Certificate cannot be Greater or Equal to Expiry Date.
                                                        </div> -->
                                                    <!-- </div> -->
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Country: <span class="text-danger">*</span></label>
                                                        <select class="form-control" formControlName="country_id"
                                                            id="country_id" *ngIf="isCountryLoaded"
                                                            (change)="getState($event.target.value)">
                                                            <option value="" disabled>Select</option>
                                                            <option *ngFor="let countryRows of countryList"
                                                                [ngValue]="countryRows.id.toString()">
                                                                {{ countryRows.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div *ngIf="fCert.country_id.invalid && (fCert.country_id.dirty || fCert.country_id.touched)"
                                                        class="alert alert-danger">
                                                        <div *ngIf="fCert.country_id.errors?.required">
                                                            This is required.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>State/Province: <span
                                                                class="text-danger">*</span></label>
                                                        <select class="form-control" formControlName="state_id"
                                                            id="state_id" (change)="getCity($event.target.value)">
                                                            <option value="" disabled>Select</option>
                                                            <option *ngFor="let stateRows of stateList"
                                                                [ngValue]="stateRows.id.toString()">
                                                                {{ stateRows.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div *ngIf="fCert.state_id.invalid && (fCert.state_id.dirty || fCert.state_id.touched)"
                                                        class="alert alert-danger">
                                                        <div *ngIf="fCert.state_id.errors?.required">
                                                            This is required.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>City/Region: <span class="text-danger">*</span></label>
                                                        <select class="form-control" formControlName="city_id"
                                                            id="city_id" *ngIf="isCountryLoaded">
                                                            <option value="" disabled>Select</option>
                                                            <option *ngFor="let cityRows of cityList"
                                                                [ngValue]="cityRows.id.toString()">
                                                                {{ cityRows.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div *ngIf="fCert.city_id.invalid && (fCert.city_id.dirty || fCert.city_id.touched)"
                                                        class="alert alert-danger">
                                                        <div *ngIf="fCert.city_id.errors?.required">
                                                            This is required.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" class="btn dashboard-btn"
                                                [disabled]="!addCandidateCertificateForm.valid">Save</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="profile-content" *ngIf="showEditCertificateForm">
                            <div class="profile-content-inner">
                                <h2>Edit Certificates</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="viewCertificateList()">View All</button>
                                    </div>
                                    <div class="col-lg-12 col-md-4">
                                        <form [formGroup]="editCandidateCertificateForm"
                                            (ngSubmit)="updateCandidateCertificate()">
                                            <div class="row">
                                                <div class="col-lg-5 col-md-12">
                                                    <div class="form-group">
                                                        <label>Certification Name: <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            placeholder="Certification Name"
                                                            formControlName="certificate_name">
                                                    </div>
                                                    <div *ngIf="fCertEdit.certificate_name.invalid && (fCertEdit.certificate_name.dirty || fCertEdit.certificate_name.touched)"
                                                        class="alert alert-danger">
                                                        <div *ngIf="fCertEdit.certificate_name.errors?.required">
                                                            This is required.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5 col-md-12">
                                                    <div class="form-group">
                                                        <label>Certification Number/ID:</label>
                                                        <input class="form-control" type="text"
                                                            placeholder="Certification Number/ID"
                                                            formControlName="certificate_number">
                                                    </div>
                                                    <div *ngIf="fCertEdit.certificate_number.invalid && (fCertEdit.certificate_number.dirty || fCertEdit.certificate_number.touched)"
                                                        class="alert alert-danger">
                                                        <div *ngIf="fCertEdit.certificate_number.errors?.required">
                                                            This is required.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5 col-md-12">
                                                    <div class="form-group">
                                                        <label>Issuing Organization: <span
                                                                class="text-danger">*</span></label>
                                                        <input class="form-control" type="text"
                                                            placeholder="Issuing Organization"
                                                            formControlName="issuing_organization">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Issuing Date: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="date"
                                                            placeholder="Issuing Date" formControlName="issue_date">
                                                    </div>
                                                    <div *ngIf="fCert.issue_date.invalid && (fCert.issue_date.dirty || fCert.issue_date.touched)"
                                                        class="alert alert-danger">
                                                        <div *ngIf="fCert.issue_date.errors?.required">
                                                            This is required.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5 col-md-12">
                                                    <div class="form-group">
                                                        <label>Expiration Date:</label>
                                                        <input class="form-control" type="date"
                                                            placeholder="Expiration Date" formControlName="expiry_date">
                                                    </div>
                                                    <!-- <div *ngIf="groupForm.controls.expiry_date.errors?.isIssueDateGreater" class="alert alert-danger">
                                                        Issue Date of Certificate cannot be Greater or Equal to Expiry Date.
                                                    </div> -->
                                                </div>
                                                <div class="col-lg-5 col-md-12">
                                                    <div class="form-group">
                                                        <label>Country: <span class="text-danger">*</span></label>
                                                        <select class="form-control" formControlName="country_id"
                                                            id="country_id" *ngIf="isCountryLoaded"
                                                            (change)="getState($event.target.value)">
                                                            <option value="" disabled>Select</option>
                                                            <option *ngFor="let countryRows of countryList"
                                                                [ngValue]="countryRows.id.toString()">
                                                                {{ countryRows.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div *ngIf="fCertEdit.country_id.invalid && (fCertEdit.country_id.dirty || fCertEdit.country_id.touched)"
                                                        class="alert alert-danger">
                                                        <div *ngIf="fCertEdit.country_id.errors?.required">
                                                            This is required.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5 col-md-12">
                                                    <div class="form-group">
                                                        <label>State/Province: <span
                                                                class="text-danger">*</span></label>
                                                        <select class="form-control" formControlName="state_id"
                                                            id="state_id" (change)="getCity($event.target.value)">
                                                            <option value="" disabled>Select</option>
                                                            <option *ngFor="let stateRows of stateList"
                                                                [ngValue]="stateRows.id.toString()">
                                                                {{ stateRows.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div *ngIf="fCertEdit.state_id.invalid && (fCertEdit.state_id.dirty || fCertEdit.state_id.touched)"
                                                        class="alert alert-danger">
                                                        <div *ngIf="fCertEdit.state_id.errors?.required">
                                                            This is required.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5 col-md-12">
                                                    <div class="form-group">
                                                        <label>City/Region: <span class="text-danger">*</span></label>
                                                        <select class="form-control" formControlName="city_id"
                                                            id="city_id" *ngIf="isCountryLoaded">
                                                            <option value="" disabled>Select</option>
                                                            <option *ngFor="let cityRows of cityList"
                                                                [ngValue]="cityRows.id.toString()">
                                                                {{ cityRows.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div *ngIf="fCertEdit.city_id.invalid && (fCertEdit.city_id.dirty || fCertEdit.city_id.touched)"
                                                        class="alert alert-danger">
                                                        <div *ngIf="fCertEdit.city_id.errors?.required">
                                                            This is required.
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <button type="submit" class="btn dashboard-btn"
                                                [disabled]="!editCandidateCertificateForm.valid">Update</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Resume -->
                    <div class="tab-pane fade" id="my-resume" role="tabpanel" aria-labelledby="my-resume-tab">

                        <div class="profile-content" *ngIf="showResumeList">
                            <div class="profile-content-inner">
                                <h2>My Resume</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="viewAddResume()">Add New</button>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <ng-template #no_data>
                                            No data found!!!
                                        </ng-template>
                                        <div *ngIf="candidateResume?.length > 0;else no_data">
                                            <table class="table table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Resume Name</th>
                                                        <th scope="col">View</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let rows of candidateResume | paginate : { id:'candidate_resume', itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalResumeItems }; let i = index">
                                                        <td>{{ rows.resume_name }}</td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-primary"
                                                                (click)="previewResume(rows.id, i)">Preview</button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-danger"
                                                                (click)="deleteCandidateResume(rows.id, i)">Delete</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <pagination-controls id="candidate_resume" class="pagi"
                                                (pageChange)="candidateResume(page = $event)" (directionLinks)="true"
                                                (responsive)="true"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="profile-content" *ngIf="showResumeUpload">
                            <div class="profile-content-inner">
                                <h2>Upload Resume</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="viewResumeList()">View All</button>
                                    </div>
                                    <div class="col-lg-12 col-md-4">
                                        <form [formGroup]="candidateResumeForm" enctype="multipart/form-data">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="col-lg-12 col-md-12">
                                                        <input class="form-control" type="file" formControlName="file"
                                                            (change)="onResumeUpload($event)">
                                                    </div>
                                                    <div class="resume-action-button"
                                                        style="display: flex;justify-content: space-evenly;margin-top: 40px;margin-bottom: 50px;">
                                                        <button type="button" class="btn btn-sm btn-primary"
                                                            (click)="uploadCV()">Upload CV</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Languages -->
                    <div class="tab-pane fade" id="my-language" role="tabpanel" aria-labelledby="my-language-tab">

                        <div class="profile-content" *ngIf="showCandidateLanguageList">
                            <div class="profile-content-inner">
                                <h2>My Language</h2>

                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="addCandidateLanguage()">Add New</button>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <ng-template #no_data>
                                            No data found!!!
                                        </ng-template>
                                        <div class="table-responsive"
                                            *ngIf="candidateLanguage?.length > 0;else no_data">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Language</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let rows of candidateLanguage | paginate : { id:'candidate_language', itemsPerPage: 10, currentPage: page, totalItems: totalCandidateLanguageItems }; let i = index">
                                                        <td>{{ rows.language?.name }}</td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-danger"
                                                                (click)="deleteCandidatelanguage(rows.id, i)">Delete</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <pagination-controls id="candidate_language" class="pagi"
                                                (pageChange)="candidateLanguageListData(page = $event)"
                                                (directionLinks)="true" (responsive)="true"></pagination-controls>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="profile-content" *ngIf="showCandidateLanguageForm">
                            <div class="profile-content-inner">
                                <h2>Add Language</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="viewCandidateLanguage()">View All</button>
                                    </div>
                                    <div class="col-lg-12 col-md-4">
                                        <form [formGroup]="addCandidateLanguageForm"
                                            (ngSubmit)="candidateLanguageSubmit()">

                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Language: <span class="text-danger">*</span></label>
                                                        <!-- <select class="form-control" formControlName="key_skills_ids" multiple id="key_skills_ids">
                                                    <option value="">Select</option>
                                                    <option *ngFor="let educationRows of keySkillDropdown" [ngValue]="educationRows.id.toString()">
                                                        {{ educationRows.name }}
                                                    </option>
                                                </select> -->
                                                        <ng-select formControlName="language_id" bindLabel="name"
                                                            bindValue="id" [items]="language" [multiple]="true"
                                                            [closeOnSelect]="true" [searchable]="true"
                                                            [hideSelected]="true" placeholder="Select Language"
                                                            [(ngModel)]="selectedLanguages" addTagText="Create New"
                                                            [addTag]="createNewLanguage">
                                                        </ng-select>
                                                        <div *ngIf="fLang.language_id.invalid && (fLang.language_id.dirty || fLang.language_id.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fLang.language_id.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <button type="submit" class="btn dashboard-btn"
                                                [disabled]="!addCandidateLanguageForm.valid">Save</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Documents -->
                    <div class="tab-pane fade" id="my-documents" role="tabpanel" aria-labelledby="my-documents-tab">

                        <div class="profile-content" *ngIf="showDocumentList">
                            <div class="profile-content-inner">
                                <h2>My Documents</h2>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12" *ngIf="candidateDocumentData?.length < 5">
                                        <button class="btn btn-sm btn-primary m-2" (click)="viewPassportUpload()">Add
                                            Passport</button>
                                        <button class="btn btn-sm btn-primary m-2" (click)="viewCertificateUpload()">Add
                                            Certificate</button>
                                        <button class="btn btn-sm btn-primary m-2" (click)="viewCoverLetterUpload()">Add
                                            Cover Letter</button>
                                        <button class="btn btn-sm btn-primary m-2" (click)="viewOtherUpload()">Add Other
                                            Document</button>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <ng-template #no_data>
                                            No data found!!!
                                        </ng-template>
                                        <div class="card" *ngIf="candidateDocumentData?.length >= 5">
                                            <div class="card-body">
                                                <span style="color:red">
                                                    You can add maximum 5 files.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="table-responsive"
                                            *ngIf="candidateDocumentData?.length > 0;else no_data">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Document Type</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">View</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let rows of candidateDocumentData | paginate : { id:'candidate_documents', itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalDocumentItems }; let i = index">
                                                        <td *ngIf="rows.document_type == 1"> Passport </td>
                                                        <td *ngIf="rows.document_type == 2"> Educational
                                                            Certificates </td>
                                                        <td *ngIf="rows.document_type == 3"> Cover Letter </td>
                                                        <td *ngIf="rows.document_type == 4"> Others </td>
                                                        <td>{{ rows.document_name }}</td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-primary"
                                                                (click)="previewFile(rows.id)">Preview</button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-danger"
                                                                (click)="deleteCandidateDocument(rows.id, i)">Delete</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <pagination-controls id="candidate_documents" class="pagi"
                                                (pageChange)="candidateDocumentList(page = $event)"
                                                (directionLinks)="true" (responsive)="true"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="profile-content" *ngIf="showDocumentUpload">
                            <div class="profile-content-inner">
                                <h2 *ngIf="passportPage == true">Upload Passport</h2>
                                <h2 *ngIf="certificatePage == true">Upload Certificate</h2>
                                <h2 *ngIf="coverLetterPage == true">Upload Cover Letter</h2>
                                <h2 *ngIf="otherPage == true">Upload Other Documents</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn"
                                            (click)="viewDocumentList()">View Document</button>
                                    </div>
                                    <div class="col-lg-12 col-md-4">
                                        <form [formGroup]="candidateResumeForm" enctype="multipart/form-data">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="col-lg-12 col-md-12">
                                                        <input class="form-control" type="file" formControlName="file"
                                                            (change)="onFileUpload($event)">
                                                    </div>
                                                    <div class="resume-action-button"
                                                        style="display: flex;justify-content: space-evenly;margin-top: 40px;margin-bottom: 50px;">
                                                        <button *ngIf="passportPage == true" type="button"
                                                            class="btn btn-sm btn-primary"
                                                            (click)="uploadFile(1)">Upload Passport</button>
                                                        <button *ngIf="certificatePage == true" type="button"
                                                            class="btn btn-sm btn-primary"
                                                            (click)="uploadFile(2)">Upload Certificate</button>
                                                        <button *ngIf="coverLetterPage == true" type="button"
                                                            class="btn btn-sm btn-primary"
                                                            (click)="uploadFile(3)">Upload Cover Letter</button>
                                                        <button *ngIf="otherPage == true" type="button"
                                                            class="btn btn-sm btn-primary"
                                                            (click)="uploadFile(4)">Upload Document</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- CV Template -->
                    <div class="tab-pane fade" id="my-template" role="tabpanel" aria-labelledby="my-cv-template-tab">
                        <div class="template-profile-content">
                            <div class="template-content-inner">
                                <h2>Make your Resume</h2>
                                <h4>Fill it online, and download in seconds. Build a professional Design C.V, add
                                    ready-to-use suggestions, and get the job.</h4>
                                <h4>Choose a CV template, fill it out, and download in seconds. Create a
                                    professional curriculum vitae in a few clicks. Just pick one of designed CV
                                    templates below, add ready-to-use suggestions, and get the job.</h4>
                                <ul class="experience">
                                    <li>
                                        Select design and click on generate PDF in A4 size.
                                    </li>
                                    <li>
                                        Can print your resume with your printer.
                                    </li>
                                    <li>
                                        Can use while applying on new jobs.
                                    </li>
                                    <li>
                                        Can upload to CV manager as a default for your applied jobs.
                                    </li>
                                </ul>
                            </div>
                            <div class="template-btn">
                                <button type="button" class="btns" (click)="navigateResume()"> View CV </button>
                                <button type="button" class="btns" (click)="downloadResume()"> Download CV </button>
                            </div>
                        </div>
                    </div>

                    <!-- Job Alert -->
                    <div class="tab-pane fade" id="my-alert" role="tabpanel" aria-labelledby="my-alert-tab">
                        <div class="profile-content">
                            <div class="profile-content-inner">
                                <h2>Job Alerts</h2>
                                <div class="row">
                                    <div class="card" *ngFor="let item of filterData">
                                        <div class="card-body">
                                            {{item.employer_company.company_name}} has posted a New Job as
                                            {{item.title}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Change Password -->
                    <div class="tab-pane fade" id="my-password" role="tabpanel" aria-labelledby="my-password-tab">
                        <div class="profile-content">
                            <form [formGroup]="candidateChangePasswordForm" (ngSubmit)="updateCandidatePassword()">
                                <div class="profile-content-inner">
                                    <h2>Change Password</h2>

                                    <div class="row">

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group total-working-div">
                                                <label>Old Password: <span class="text-danger">*</span></label>
                                                <input class="form-control" [type]="old_show ? 'text' : 'password'"
                                                    formControlName="old_password" placeholder="Old Password">
                                                <button class="new-password-btn" type="button"
                                                    (click)="showOldPassword()">
                                                    <i class="bx bx-lock" aria-hidden="true" *ngIf="!old_show"></i>
                                                    <i class="bx bx-lock-open" aria-hidden="true" *ngIf="old_show"></i>
                                                </button>
                                                <div *ngIf="fPass.old_password.invalid && (fPass.old_password.dirty || fPass.old_password.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="fPass.old_password.errors?.required">
                                                        Old Password is required.
                                                    </div>
                                                    <div
                                                        *ngIf="fPass.old_password.errors?.pattern || fPass.old_password.errors?.minlength">
                                                        Old Password Must Contain Atleast 8 Characters, 1 Uppercase
                                                        Letter, 1 Lowercase Letter, 1 Numeric & 1 Special Character.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group total-working-div">
                                                <label>New Password: <span class="text-danger">*</span></label>
                                                <input class="form-control" [type]="show ? 'text' : 'password'"
                                                    formControlName="password" placeholder="New Password">
                                                <button class="new-password-btn" type="button" (click)="showpassword()">
                                                    <i class="bx bx-lock" aria-hidden="true" *ngIf="!show"></i>
                                                    <i class="bx bx-lock-open" aria-hidden="true" *ngIf="show"></i>
                                                </button>
                                                <div *ngIf="fPass.password.invalid && (fPass.password.dirty || fPass.password.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="fPass.password.errors?.required">
                                                        Password is required.
                                                    </div>
                                                    <div
                                                        *ngIf="fPass.password.errors?.pattern || fPass.password.errors?.minlength">
                                                        Password Must Contain Atleast 8 Characters, 1 Uppercase
                                                        Letter, 1 Lowercase Letter, 1 Numeric & 1 Special Character.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group total-working-div">
                                                <label>Confirm New Password: <span class="text-danger">*</span></label>
                                                <input class="form-control" [type]="confirm_show ? 'text' : 'password'"
                                                    formControlName="confirm_password"
                                                    placeholder="Confirm New Password">
                                                <button class="new-password-btn" type="button"
                                                    (click)="showConfirmPassword()">
                                                    <i class="bx bx-lock" aria-hidden="true" *ngIf="!confirm_show"></i>
                                                    <i class="bx bx-lock-open" aria-hidden="true"
                                                        *ngIf="confirm_show"></i>
                                                </button>
                                                <div *ngIf="fPass.confirm_password.invalid && (fPass.confirm_password.dirty || fPass.confirm_password.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="fPass.confirm_password.errors?.required">
                                                        Password is required.
                                                    </div>
                                                    <div
                                                        *ngIf="fPass.confirm_password.errors?.pattern || fPass.confirm_password.errors?.minlength">
                                                        Password Must Contain Atleast 8 Characters, 1 Uppercase
                                                        Letter, 1 Lowercase Letter, 1 Numeric & 1 Special Character.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <button type="submit" class="btn dashboard-btn"
                                    [disabled]="!candidateChangePasswordForm.valid">
                                    Change Password
                                </button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- End Dashboard -->

    <!-- Footer -->
    <app-footer-style-two></app-footer-style-two>
    <!-- End Footer -->