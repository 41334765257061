import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TokenStorageService } from '../../../service/login/token-storage.service';
import { EmployerCompaniesService } from '../../../service/employer_companies/employer_companies.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryService } from '../../../service/country/country.service';
import { StateService } from 'src/app/service/state/state.service';
import { CityService } from 'src/app/service/city/city.service';

@Component({
  selector: 'app-company-edit-form',
  templateUrl: './company-edit-form.component.html',
  styleUrls: ['./company-edit-form.component.scss']
})
export class CompanyEditFormComponent {
  getId: any;
  saveSucess: boolean = false;
  error: boolean = false;
  errorMessage: string = "";
  successMessage: string = "";
  employerCompanyEditForm: FormGroup
  isLoggedIn: boolean;
  currentUser: any;
  submitTypeToggle: Number = 0;
  formData: any;
  employerId: any;
  isCountryLoaded: boolean;
  countryList: any;
  stateList: any;
  cityList: any;

  constructor(
    public formBuilder: FormBuilder,
    private token: TokenStorageService,
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private employerCompaniesService: EmployerCompaniesService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService
  ) {

    this.currentUser = this.token.getUser();
    if (this.currentUser.admin_id == 0 || this.currentUser.admin_id == null) {
      this.employerId = this.currentUser.id;
    } else {
      this.employerId = this.currentUser.admin_id;
    }

    this.getId = this.activatedRoute.snapshot.paramMap.get('id');

    this.employerCompaniesService.GetEmployerCompaniesDetail(this.getId).subscribe((res: any) => {
      this.formData = res.data;
      this.getState(`${res.data[0].country_id?.toString()}: ${res.data[0].country_id?.toString()}`)
      this.getCity(`${res.data[0].state_id?.toString()}: ${res.data[0].state_id?.toString()}`);
      this.employerCompanyEditForm.setValue({
        company_name: res.data[0].company_name.toString(),
        emailid: res.data[0].emailid.toString(),
        dial_code: res.data[0].dial_code.toString(),
        mobileno: res.data[0].mobileno.toString(),
        website_url: res.data[0].website_url.toString(),
        company_dial_code: res.data[0].company_dial_code.toString(),
        company_contactno: res.data[0].company_contactno.toString(),
        about_company: res.data[0].about_company.toString(),
        company_mission: res.data[0].company_mission.toString(),
        company_vision: res.data[0].company_vision,
        country_id: res.data[0].country_id.toString(),
        state_id: res.data[0].state_id.toString(),
        city_id: res.data[0].city_id.toString(),
        status: 1,
        employer_name: res.data[0].employer_name.toString(),
        employer_emailid: res.data[0].employer_emailid.toString(),
        employer_dial_code: res.data[0].employer_dial_code.toString(),
        employer_mobileno: res.data[0].employer_mobileno,
        employer_designation: res.data[0].employer_designation.toString(),
        employer_department: res.data[0].employer_department.toString()
      })
    })

    this.employerCompanyEditForm = this.formBuilder.group({
      company_name: ['', Validators.required],
      emailid: ['', [Validators.required, Validators.email]],
      dial_code: ['', Validators.required],
      mobileno: ['', Validators.required],
      //website_url: ['', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      website_url: ['', [Validators.required, Validators.pattern('([\\w.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      company_dial_code: ['', Validators.required],
      company_contactno: ['', Validators.required],
      about_company: [''],
      company_mission: [''],
      company_vision: [''],
      country_id: [''],
      state_id: [''],
      city_id: [''],
      status: ['', Validators.required],
      employer_name: [''],
      employer_emailid: ['', Validators.email],
      employer_dial_code: [''],
      employer_mobileno: [''],
      employer_designation: [''],
      employer_department: [''],
    });
  }

  employerCompaniesUpdate(): any {
    const activeStatus = this.employerCompanyEditForm.get('status').value == 1 ? 1 : 0;
    this.employerCompanyEditForm.controls['status'].setValue(activeStatus.toString());
    this.employerCompaniesService.updateEmployerCompanies(this.getId, this.employerCompanyEditForm.value)
      .subscribe(() => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = "Company Updated Successfully";
        // this.viewEmployerCompanies();
        this.ngZone.run(() => this.router.navigateByUrl('/employer-dashboard'))
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
  }

  ngOnInit(): void {
    if (this.token.getToken()) {
      this.isLoggedIn = true;
      this.currentUser = this.token.getUser();
    }
    this.countryDropdown();

    this.employerCompanyEditForm.get('mobileno').valueChanges.subscribe(value => {
      const dialCode = this.employerCompanyEditForm.get('dial_code').value;
      if (this.isCountryLoaded == true) {
        const numberLength = this.countryList.filter((item: any) => { return item.id == dialCode });
        const numberLength_ = JSON.parse(numberLength[0].number_length);
        const validPattern = /^[0-9]*$/.test(value);
        if (!validPattern) {
          this.employerCompanyEditForm.get('mobileno').setErrors({ 'invalidMobileNumber': false, pattern: true });
        } else if (!numberLength_.includes(value.length)) {
          this.employerCompanyEditForm.get('mobileno').setErrors({ 'invalidMobileNumber': true, pattern: false })
        } else {
          this.employerCompanyEditForm.get('mobileno').setErrors(null);
        }
      }
    });

    this.employerCompanyEditForm.get('company_contactno').valueChanges.subscribe(value => {
      const dialCode = this.employerCompanyEditForm.get('company_dial_code').value;
      if (this.isCountryLoaded == true) {
        const numberLength = this.countryList.filter((item: any) => { return item.id == dialCode });
        const numberLength_ = JSON.parse(numberLength[0].number_length);
        const validPattern = /^[0-9]*$/.test(value);
        if (!validPattern) {
          this.employerCompanyEditForm.get('company_contactno').setErrors({ 'invalidMobileNumber': false, pattern: true });
        } else if (!numberLength_.includes(value.length)) {
          this.employerCompanyEditForm.get('company_contactno').setErrors({ 'invalidMobileNumber': true, pattern: false })
        } else {
          this.employerCompanyEditForm.get('company_contactno').setErrors(null);
        }
      }
    });

    this.employerCompanyEditForm.get('employer_mobileno').valueChanges.subscribe(value => {
      const dialCode = this.employerCompanyEditForm.get('employer_dial_code').value;
      if (this.isCountryLoaded == true) {
        const numberLength = this.countryList.filter((item: any) => { return item.id == dialCode });
        const numberLength_ = JSON.parse(numberLength[0].number_length);
        const validPattern = /^[0-9]*$/.test(value);
        if (!validPattern) {
          this.employerCompanyEditForm.get('employer_mobileno').setErrors({ 'invalidMobileNumber': false, pattern: true });
        } else if (!numberLength_.includes(value.length)) {
          this.employerCompanyEditForm.get('employer_mobileno').setErrors({ 'invalidMobileNumber': true, pattern: false })
        } else {
          this.employerCompanyEditForm.get('employer_mobileno').setErrors(null);
        }
      }
    });

  }

  countryDropdown() {
    this.countryService.getCountryDropdown().subscribe((res: any) => {
      this.isCountryLoaded = true;
      this.countryList = res.data;
    });
  }

  getState(countryId: string) {
    const toArray = countryId.split(": ");
    const country_id = toArray[1];
    this.stateService.getStateListDropdown(country_id).subscribe((res: any) => {
      this.stateList = res.data;
    });
  }

  getCity(stateId: string) {
    const toArray = stateId.split(": ");
    const state_id = toArray[1];
    this.cityService.getCityListDropdown(state_id).subscribe((res: any) => {
      this.cityList = res.data;
    });
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.saveSucess = false;
    }, 5000);
  }

  get fComp() {
    return this.employerCompanyEditForm.controls;
  }

}
