import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenStorageService } from '../../../service/login/token-storage.service';
import { jwtDecode } from "jwt-decode";

@Injectable()
export class AuthGuard implements CanActivate {
    isLoggedIn = false;
    storedToken: string;
    constructor(
        private token: TokenStorageService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.storedToken = this.token.getToken();
        if (!this.isTokenExpired(this.storedToken)) {
            return true;
        } else {
            this.token.signOut();
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }

    isTokenExpired(token: string): boolean {
        try {
            const decodedToken: any = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            return decodedToken.exp < currentTime;
        } catch (error) {
            console.error('Error decoding token:', error);
            return true;
        }
    }
}