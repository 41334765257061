import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { NavbarStyleThreeComponent } from './components/common/navbar-style-three/navbar-style-three.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { EmployersDetailsComponent } from './components/pages/employers-details/employers-details.component';
import { EmployerDashboardComponent } from './components/pages/dashboard/employer-dashboard.component';
import { CandidateDashboardComponent } from './components/pages/dashboard/candidate-dashboard.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { ResumeDetailsComponent } from './components/pages/resume-details/resume-details.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { AboutComponent } from './components/pages/about/about.component';
import { JobsComponent } from './components/pages/jobs/jobs.component';
import { FavouriteJobsComponent } from './components/pages/favourite-jobs/favourite-jobs.component';
import { JobDetailsComponent } from './components/pages/job-details/job-details.component';
import { PostAJobComponent } from './components/pages/post-a-job/post-a-job.component';
import { EditJobComponent } from './components/pages/edit-job/edit-job.component';
import { CandidatesComponent } from './components/pages/applied-candidates/candidates.component';
import { CandidatesDetailsComponent } from './components/pages/candidates-details/candidates-details.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { AuthGuard } from './components/pages/login/auth.guard';
import { EmployerJobsComponent } from './components/pages/employer-jobs/employer-jobs.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { SearchResultComponent } from './components/pages/search-result/search-result.component';
import { CompanyRegistrationFormComponent } from './components/pages/company-registration-form/company-registration-form.component';
import { CompanyEditFormComponent } from './components/pages/company-edit-form/company-edit-form.component';
import { AddCandidateDetailsComponent } from './components/pages/add-candidate-details/add-candidate-details.component';
import { ResumeLayout1Component } from './components/pages/resume-layout1/resume-layout1.component';
import { ResumeLayout2Component } from './components/pages/resume-layout2/resume-layout2.component';
import { ResumeLayout3Component } from './components/pages/resume-layout3/resume-layout3.component';
import { ResumeLayout4Component } from './components/pages/resume-layout4/resume-layout4.component';
import { ResumeLayout5Component } from './components/pages/resume-layout5/resume-layout5.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AllCandidateComponent } from './components/pages/all-candidate/all-candidate.component';
import { AllJobsComponent } from './components/pages/all-jobs/all-jobs.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GenericListFilterModule } from 'generic-list-filter';
import { OtpValidationComponent } from './components/pages/otp-validation/otp-validation.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AllCompaniesComponent } from './components/pages/all-companies/all-companies.component';
import { CompanyDetailComponent } from './components/pages/company-detail/company-detail.component';
import { DatePipe } from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import { NgOptimizedImage } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    PreloaderComponent,
    NavbarStyleOneComponent,
    FooterStyleOneComponent,
    NavbarStyleTwoComponent,
    NavbarStyleThreeComponent,
    FooterStyleTwoComponent,
    LoginComponent,
    RegisterComponent,
    EmployersComponent,
    EmployersDetailsComponent,
    DashboardComponent,
    EmployerDashboardComponent,
    CandidateDashboardComponent,
    EmployerJobsComponent,
    ResumeDetailsComponent,
    TestimonialsComponent,
    PricingComponent,
    FaqComponent,
    ComingSoonComponent,
    ErrorComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    AboutComponent,
    JobsComponent,
    FavouriteJobsComponent,
    JobDetailsComponent,
    PostAJobComponent,
    EditJobComponent,
    CandidatesComponent,
    CandidatesDetailsComponent,
    BlogDetailsComponent,
    BlogComponent,
    ContactComponent,
    DateAgoPipe,
    SearchResultComponent,
    CompanyRegistrationFormComponent,
    CompanyEditFormComponent,
    AddCandidateDetailsComponent,
    ResumeLayout1Component,
    ResumeLayout2Component,
    ResumeLayout3Component,
    ResumeLayout4Component,
    ResumeLayout5Component,
    AllCandidateComponent,
    AllJobsComponent,
    OtpValidationComponent,
    AllCompaniesComponent,
    CompanyDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    NgSelectModule,
    Ng2SearchPipeModule,
    GenericListFilterModule,
    NgOtpInputModule,
    NgCircleProgressModule.forRoot({}),
    ImageCropperModule,
    NgOptimizedImage
  ],
  providers: [
    authInterceptorProviders,
    AuthGuard,
    DatePipe,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
