<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Rules -->
<div class="rules-area ptb-100">
    <div class="container">
        <div class="rules-item">
            <p>This Privacy Policy explains how AbroadWork.com ("we" or "us") collects, uses, shares, and protects 
                information about you when you use our website, located at www.abroadwork.com (the "Site"), and 
                the services provided through the Site (the "Services"). By accessing or using the Site or the Services, 
                you consent to the collection, use, and disclosure of your information in accordance with this Privacy 
                Policy.</p>
            <h3>1 - Information We Collect</h3>
            <ol type="a">
                <li>
                    <p>
                        Information You Provide: When you create an account, apply for a job, or otherwise 
                        interact with the Site or the Services, we may collect personal information such as 
                        your name, email address, phone number, resume, employment history, and other 
                        information you choose to provide.
                    </p>
                </li>
                <li>
                    <p>
                        Automatically Collected Information: We may automatically collect certain 
                        information about your device and usage of the Site, such as your IP address, 
                        browser type, operating system, and browsing activity.
                    </p>
                </li>
            </ol>

            <h3>2 - Use of Information</h3>
            <p>We may use the information we collect to:</p>
            <ol type="a">
                <li><p>Provide and improve the Site and the Services.</p></li>
                <li><p>Communicate with you about your account and our services.</p></li>
                <li><p>Customize your experience on the Site.</p></li>
                <li><p>Analyze trends and user behavior.</p></li>
                <li><p>Comply with legal and regulatory requirements.</p></li>
            </ol>
            <p>We will not use your personal information for purposes other than those described in this Privacy Policy without your consent.</p>

            <h3>3 -  Sharing of Information</h3>
            <p>We may share your information with:</p>
            <ol type="a">
                <li><p>Employers and HR agencies when you apply for a job through the Site.</p></li>
                <li><p>Service providers who assist us in operating the Site or providing the Services.</p></li>
                <li><p>Law enforcement or other third parties when required by law or to protect our rights or the rights of others.</p></li>
                <li><p>We may also share aggregated or de-identified information that cannot be used to identify you personally.</p></li>
            </ol>

            <h3>4 - Data Security</h3>
            <p>We take reasonable measures to protect your information from unauthorized access, use, or 
                disclosure. However, no method of transmission over the internet or electronic storage is 
                100% secure, and we cannot guarantee the absolute security of your information.</p>

            <h3>5 - Third-Party Links</h3>
            <p>The Site may contain links to third-party websites or resources. We are not responsible for 
                the privacy practices or content of such websites or resources.</p>

            <h3>6 - Children's Privacy</h3>
            <p>The Site and the Services are not intended for children under the age of 18, and we do not 
                knowingly collect personal information from children under the age of 18.</p>
    
            <h3>7 - Your Choices</h3>
            <p>You may update or correct your account information at any time by logging into your account 
                seƫngs.<br>
                You may opt out of receiving promotional communications from us by following the 
                instructions provided in such communications or by contacting us directly.</p>

            <h3>8 - Changes to this Privacy Policy</h3>
            <p>We reserve the right to modify this Privacy Policy at any time, without prior notice. Any 
                changes will be effective immediately upon posting on the Site. Your continued use of the 
                Site or the Services aŌer any such changes constitutes your acceptance of the revised Privacy 
                Policy.</p>
        
            <h3>9 - Contact Us</h3>
            <p>If you have any questions about this Privacy Policy or our privacy practices, please contact us
                at info@abroadwork.ph <br>
                By using the Site or the Services, you acknowledge that you have read, understood, and 
                agree to be bound by this Privacy Policy</p>
        </div>
    </div>
</div>
<!-- End Rules -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->