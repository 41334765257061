import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class StateService {
  REST_API: string;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}`;
    } else {
      this.REST_API = `${environment.apiurl}`;
    }
  }

  getStateListDropdown(countryId: string) {
    return this.httpClient.get(`${this.REST_API}select-list/state/` + countryId);
  }

  getAllState() {
    return this.httpClient.get(`${this.REST_API}select-list/state`);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }
}
