<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>All Jobs</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a href="index.html">Home</a>
                        </li>
                        <li>
                            <span>/</span>
                        </li>
                        <li>
                            All Jobs
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Filter -->
<div class="job-filter-area pt-100">
    <div class="container" *ngIf="isMobileView == false">
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="e.g UI/UX Design" formControlName="title">
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isJobTypeLoaded" formControlName="job_type">
                            <option value="">Filter By Job Type</option>
                            <option *ngFor="let jobType of jobTypeList" [value]="jobType.id">{{ jobType.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isJobCategoryLoaded" formControlName="job_category">
                            <option value="">Filter By Category</option>
                            <option *ngFor="let jobCategory of jobCategoryList" [value]="jobCategory.id">
                                {{jobCategory.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="country" (change)="getState($event.target.value)">
                            <option value="">Filter By Country</option>
                            <option *ngFor="let countryRows of countryList" [value]="countryRows.id">{{ countryRows.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="state" (change)="getCity($event.target.value)">
                            <option value="">Filter By State</option>
                            <option *ngFor="let item of stateList" [value]="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="city">
                            <option value="">Filter By City</option>
                            <option *ngFor="let item of cityList" [value]="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isJobQualificationLoaded" formControlName="qualification">
                            <option value="">Filter By Qualifications</option>
                            <option *ngFor="let items of jobQualificationList" [value]="items.id.toString()">
                                {{items.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isJobExperienceLoaded" formControlName="experience">
                            <option value="">Filter By Experiences</option>
                            <option *ngFor="let item of jobExperienceList" [value]="item.id">{{item.min_exp}} -
                                {{item.max_exp}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-3 col-lg-2 col-md-2">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Salary" formControlName="salary">
                    </div>
                </div>
                <div class="col-sm-3 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" formControlName="currencyId">
                            <option value="" selected>Currency</option>
                            <option *ngFor="let item of countryList" [value]="item.id">{{ item.currency }}</option>
                        </select>
                    </div>
                </div>
                <div  class="col-sm-8 col-lg-2 col-md-2">
                    <div class="row" id="btn_contain">
                        <div class="col-6">
                            <button type="submit" class="filter_btn"  (click)="searchFilter()">Search</button>
                        </div>
                        <div class="col-6">
                            <button type="clear" class="filter_btn" (click)="resetFilter()">Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End Filter -->

<!-- Mobile Filters -->
<div class="collapse" id="collapseExample">
    <div class="job-filter-area">
        <div class="container">
            <form [formGroup]="searchForm">
                <div class="row">
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="e.g UI/UX Design" formControlName="title">
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isJobTypeLoaded" formControlName="job_type">
                                <option value="">Filter By Job Type</option>
                                <option *ngFor="let jobType of jobTypeList" [value]="jobType.id">{{ jobType.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isJobCategoryLoaded" formControlName="job_category">
                                <option value="">Filter By Category</option>
                                <option *ngFor="let jobCategory of jobCategoryList" [value]="jobCategory.id">
                                    {{jobCategory.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="country" (change)="getState($event.target.value)">
                                <option value="">Filter By Country</option>
                                <option *ngFor="let countryRows of countryList" [value]="countryRows.id">{{ countryRows.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="state" (change)="getCity($event.target.value)">
                                <option value="">Filter By State</option>
                                <option *ngFor="let item of stateList" [value]="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="city">
                                <option value="">Filter By City</option>
                                <option *ngFor="let item of cityList" [value]="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isJobQualificationLoaded" formControlName="qualification">
                                <option value="">Filter By Qualifications</option>
                                <option *ngFor="let items of jobQualificationList" [value]="items.id.toString()">
                                    {{items.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isJobExperienceLoaded" formControlName="experience">
                                <option value="">Filter By Experiences</option>
                                <option *ngFor="let item of jobExperienceList" [value]="item.id">{{item.min_exp}} -
                                    {{item.max_exp}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-3 col-lg-2 col-md-2">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Salary" formControlName="salary">
                        </div>
                    </div>
                    <div class="col-sm-3 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" formControlName="currencyId">
                                <option value="" selected>Currency</option>
                                <option *ngFor="let item of countryList" [value]="item.id">{{ item.currency }}</option>
                            </select>
                        </div>
                    </div>
                    <div  class="col-sm-8 col-lg-2 col-md-2">
                        <div class="row" id="btn_contain">
                            <div class="col-6">
                                <button type="submit" class="filter_btn"  (click)="searchFilter()">Search</button>
                            </div>
                            <div class="col-6">
                                <button type="clear" class="filter_btn" (click)="resetFilter()">Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Mobile Filters -->

<!-- Showing -->
<div class="job-showing-area">
    <div class="container">
        <h4>Showing {{paging?.page_length}} of {{paging?.total}} results</h4>
        <div class="showing">
            <div class="row">
                <div style="width:30%">
                    <div class="left">
                        <form [formGroup]="sortForm">
                            <div class="form-group">
                                <select class="form-control" formControlName="sort" (change)="getAllJobs(1)" style="width:auto">
                                    <option selected value="">Latest</option>
                                    <option value="id:asc">Oldest</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div style="width:70%">
                    <div class="right">
                        <ul>
                            <li>
                                <a (click)="showBoxesFunction()"><i class='bx bx-dots-horizontal'></i></a>
                            </li>
                            <li>
                                <a (click)="showListFunction()"><i class='bx bx-menu'></i></a>
                            </li>
                            <li *ngIf="isMobileView == true">
                                <a data-bs-toggle="collapse" data-bs-target="#collapseExample"><i class='bx bx-filter-alt'></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Showing -->

<!-- Candidate -->
<div class="candidate-area pb-100">
    <div class="container">
        <div class="row" *ngIf="showBoxes">
            <div class="col-lg-6 col-md-12" *ngFor="let data of apiData | paginate : { itemsPerPage: 10, currentPage: page, totalItems: totalItems }; let i = index">

                <div *ngIf="isDashboardSearch == null">
                    <div class="job-item two">
                        <div class="left" id="job_card">
                            <h3>{{data.title}}</h3>
                            <span>{{data.employer_company.company_name}}</span>
                            <ul class="experience">
                                <li>
                                    <img src="assets/icons/experience.png" alt="experience-icon">
                                    Experience:
                                    <span>
                                        {{data.job_experience.min_exp}} - {{data.job_experience.max_exp}} Years
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/icons/qualification.png" alt="Details">
                                    Qualification:
                                    <span>
                                        {{data.job_qualification.name}}
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/icons/salary.png" alt="Details">
                                    Salary:
                                    <span>
                                        {{data.min_salary}} - {{data.max_salary}} LPA
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/icons/job-type.png" alt="Details">
                                    Job Type:
                                    <span>
                                        {{data.job_type.name}}
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/icons/location.png" alt="Details">
                                    Location:
                                    <span>
                                        {{data.city.name}},{{data.state.name}},{{data.country.name}}
                                    </span>
                                </li>
                            </ul>
                            <div class="cmn-link">
                                <a routerLink="/job/{{data.id}}/{{data.jobTitle}}">
                                    <i class="flaticon-right-arrow one"></i> View Job Details 
                                    <i class="flaticon-right-arrow two"></i>
                                </a>
                                <!-- <a *ngIf="profileCompleted > 80 || currentUser?.user_type == 1 || currentUser?.user_type == 3"
                                    routerLink="/job/{{data.id}}/{{data.jobTitle}}"><i class="flaticon-right-arrow one"></i> View
                                    Job Details <i class="flaticon-right-arrow two"></i></a> -->
                                <!-- <a *ngIf="profileCompleted < 80 && currentUser?.user_type == 2" data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"><i class="flaticon-right-arrow one"></i> View Job
                                    Details <i class="flaticon-right-arrow two"></i></a> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="isDashboardSearch == true">
                    <div class="job-item two">
                        <div class="left" id="job_card">
                            <h3 s>{{data.title}}</h3>
                            <span>{{data.employer_company.company_name}}</span>
                            <ul class="experience">
                                <li>
                                    <img src="assets/icons/experience.png" alt="experience-icon">
                                    Experience:
                                    <span>
                                        {{data.job_experience.min_exp}} - {{data.job_experience.max_exp}} Years
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/icons/qualification.png" alt="Details">
                                    Qualification:
                                    <span>
                                        {{data.job_qualification.name}}
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/icons/salary.png" alt="Details">
                                    Salary:
                                    <span>
                                        {{data.min_salary}} - {{data.max_salary}} LPA
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/icons/job-type.png" alt="Details">
                                    Job Type:
                                    <span>
                                        {{data.job_type.name}}
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/icons/location.png" alt="Details">
                                    Location:
                                    <span>
                                        {{data.city.name}},{{data.state.name}},{{data.country.name}}
                                    </span>
                                </li>
                            </ul>
                            <div class="cmn-link">
                                <a routerLink="/job/{{data.id}}/{{data.jobTitle}}">
                                    <i class="flaticon-right-arrow one"></i> View Job Details
                                    <i class="flaticon-right-arrow two"></i>
                                </a>
                                <!-- <a *ngIf="profileCompleted > 80 || currentUser?.user_type == 1 || currentUser?.user_type == 3"
                                    routerLink="/job/{{data.id}}/{{data.jobTitle}}"><i class="flaticon-right-arrow one"></i> View
                                    Job Details <i class="flaticon-right-arrow two"></i></a>
                                <a *ngIf="profileCompleted < 80 && currentUser?.user_type == 2" data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"><i class="flaticon-right-arrow one"></i> View Job
                                    Details <i class="flaticon-right-arrow two"></i></a> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-12 col-md-12" *ngIf="isSearchValueExists == false">
                <div class="employer-item d-flex justify-content-center">
                    <h3>No Job Found!</h3>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="showList">

            <div *ngIf="isDashboardSearch == null">
                <div class="employer-item" *ngFor="let data of apiData | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }; let i = index">
                    <!-- <a *ngIf="profileCompleted > 80 || currentUser?.user_type == 1 || currentUser?.user_type == 3"
                        routerLink="/job/{{data.id}}/{{data.jobTitle}}"> -->
                    <a routerLink="/job/{{data.id}}/{{data.jobTitle}}">
                        <h3>{{ data.title }} | {{data.employer_company.company_name}}</h3>
                        <ul>
                            <!--  style="display: flex;" -->
                            <li class="span-one">Experience: <span>{{data.job_experience.min_exp}} -
                                    {{data.job_experience.max_exp}} Years</span></li>
                            <li class="span-one">Qualification: <span>{{data.job_qualification.name}}</span></li>
                        </ul>
                        <ul>
                            <!--  style="display: flex;" -->
                            <li class="span-one">Salary: <span>{{data.min_salary}} - {{data.max_salary}} LPA</span></li>
                            <li class="span-one">Location: {{data.city.name}},{{data.state.name}},{{data.country.name}}
                            </li>
                        </ul>
                    </a>

                    <!-- <a *ngIf="profileCompleted < 80 && currentUser?.user_type == 2" data-bs-toggle="modal"
                        data-bs-target="#exampleModal">
                        <h3>{{ data.title }} | {{data.employer_company.company_name}}</h3>
                        <ul>
                            <li class="span-one">Experience: <span>{{data.job_experience.min_exp}} -
                                    {{data.job_experience.max_exp}} Years</span></li>
                            <li class="span-one">Qualification: <span>{{data.job_qualification.name}}</span></li>
                        </ul>
                        <ul>
                            <li class="span-one">Salary: <span>{{data.min_salary}} - {{data.max_salary}} LPA</span></li>
                            <li class="span-one">Location: {{data.city.name}},{{data.state.name}},{{data.country.name}}
                            </li>
                        </ul>
                    </a> -->

                    <!-- <a *ngIf="isLoggedIn == false" routerLink="/job/{{data.id}}/{{data.jobTitle}}">
                        <h3>{{ data.title }} | {{data.employer_company.company_name}}</h3>
                        <ul>
                            <li class="span-one">Experience: <span>{{data.job_experience.min_exp}} -
                                    {{data.job_experience.max_exp}} Years</span></li>
                            <li class="span-one">Qualification: <span>{{data.job_qualification.name}}</span></li>
                        </ul>
                        <ul>
                            <li class="span-one">Salary: <span>{{data.min_salary}} - {{data.max_salary}} LPA</span></li>
                            <li class="span-one">Location: {{data.city.name}},{{data.state.name}},{{data.country.name}}
                            </li>
                        </ul>
                    </a> -->

                    <span class="span-two">{{data.job_type.name}}</span>
                </div>
            </div>

            <div *ngIf="isDashboardSearch == true">
                <div class="employer-item"
                    *ngFor="let data of apiData | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }; let i = index">
                    <a *ngIf="profileCompleted > 80 || currentUser?.user_type == 1 || currentUser?.user_type == 3"
                        routerLink="/job/{{data.id}}/{{data.jobTitle}}">
                        <h3>{{ data.title }} | {{data.employer_company.company_name}}</h3>
                        <ul style="display: flex;">
                            <li class="span-one">Experience: <span>{{data.job_experience.min_exp}} -
                                    {{data.job_experience.max_exp}} Years</span></li>
                            <li class="span-one">Qualification: <span>{{data.job_qualification.name}}</span></li>
                        </ul>
                        <ul style="display: flex;">
                            <li class="span-one">Salary: <span>{{data.min_salary}} - {{data.max_salary}} LPA</span></li>
                            <li class="span-one">Location: {{data.city.name}},{{data.state.name}},{{data.country.name}}</li>
                        </ul>
                    </a>
    
                    <!-- <a *ngIf="profileCompleted < 80 && currentUser?.user_type == 2" data-bs-toggle="modal"
                        data-bs-target="#exampleModal">
                        <h3>{{ data.title }} | {{data.employer_company.company_name}}</h3>
                        <ul>
                            <li class="span-one">Experience: <span>{{data.job_experience.min_exp}} -
                                    {{data.job_experience.max_exp}} Years</span></li>
                            <li class="span-one">Qualification: <span>{{data.job_qualification.name}}</span></li>
                        </ul>
                        <ul>
                            <li class="span-one">Salary: <span>{{data.job_salary.min_salary}} -
                                    {{data.job_salary.max_salary}} LPA</span></li>
                            <li class="span-one">Location: {{data.city.name}},{{data.state.name}},{{data.country.name}}</li>
                        </ul>
                    </a> -->
    
                    <!-- <a *ngIf="isLoggedIn == false" routerLink="/job/{{data.id}}/{{data.jobTitle}}">
                        <h3>{{ data.title }} | {{data.employer_company.company_name}}</h3>
                        <ul>
                            <li class="span-one">Experience: <span>{{data.job_experience.min_exp}} -
                                    {{data.job_experience.max_exp}} Years</span></li>
                            <li class="span-one">Qualification: <span>{{data.job_qualification.name}}</span></li>
                        </ul>
                        <ul>
                            <li class="span-one">Salary: <span>{{data.min_salary}} -
                                    {{data.max_salary}} LPA</span></li>
                            <li class="span-one">Location: {{data.city.name}},{{data.state.name}},{{data.country.name}}</li>
                        </ul>
                    </a> -->
    
                    <span class="span-two">{{data.job_type.name}}</span>
                </div>
    
                <div class="employer-item" *ngIf="isDashboardSearch == true && isSearchValueExists == false">
                    <a><h3 style="margin-left: 560px !important; margin-top: 28px;">No Job Found!</h3></a>
                </div>
            </div>

        </div>

        <div class="pagination-area">
            <pagination-controls class="pagi" (pageChange)="getAllJobs(page = $event)" (directionLinks)="true"
                (responsive)="true"></pagination-controls>
        </div>
    </div>
</div>
<!-- End Candidate -->

<!-- Code for Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">To start applying to Jobs, you should complete your Profile, Education, Experience(if any), Skills, <br> Certificates(if any), Language and upload your Resume and required Documents.</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->