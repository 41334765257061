<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Career Advice</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Career Advice</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Blog -->
<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row">

            <div class="col-sm-6 col-lg-4" *ngFor="let item of allBlogs">
                <div class="blog-item">
                    <div class="top">
                        <a style="text-decoration: none;" routerLink="/career-advice/{{item.slug}}"><img src="{{ item.imgPhotoUrl }}" alt="Blog"></a>
                    </div>
                    <span>{{ item.blog_categories.blog_category_name }}</span>
                    <h3><a style="text-decoration: none;" routerLink="/career-advice/{{item.slug}}">{{ item.blog_title }}</a></h3>
                    <div class="cmn-link">
                        <a style="text-decoration: none;" routerLink="/career-advice/{{item.slug}}"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Blog -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->