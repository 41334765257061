<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Dashboard</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Dashboard</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<div class="search-result-list ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-24 col-md-12">
                <h2> Search Result </h2>
                <div class="row">
                    <div class="col-lg-20 col-md-12">
                        <ng-template #no_data>
                            No data found!!!
                        </ng-template>

                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Id</th>
                                        <th scope="col">Id</th>
                                        <th scope="col">Id</th>
                                        <th scope="col">Id</th>
                                        <th scope="col">Id</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Bind Values Here</td>
                                        <td>Bind Values Here</td>
                                        <td>Bind Values Here</td>
                                        <td>Bind Values Here</td>
                                        <td>Bind Values Here</td>
                                        <td>Bind Values Here</td>
                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls class="pagi" (directionLinks)="true" (responsive)="true"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
