// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiurl: "https://api.abroadwork.com/v1/website/",
  apiAdminUrl: "https://api.abroadwork.com/v1/admin/",
  apiBaseUrl: "https://api.abroadwork.com/",
  token: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyMiwiZGVwYXJ0bWVudF9pZCI6MSwibmFtZSI6IlByYW1vZCBLYW5vamlhIiwiZW1haWxpZCI6InByYW1vZEBwcm9saWZpcXVldGVjaC5pbiIsIm1vYmlsZW5vIjo4NjAwNDA5NDM5LCJhcGlfYWNjZXNzIjoiYWRtaW4iLCJpYXQiOjE2ODQyMjIxNjEsImV4cCI6MTY4NDMwODU2MX0.9Mws9wJuOwdxQAMEB-UBYNHUJ0LSZ3mBZXB-0jyDOrU",
  pagination: {
    itemsPerPage: 10
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
