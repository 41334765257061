import { Component, OnInit } from '@angular/core';
import { JobsService } from '../../../service/jobs/jobs.service';
import { environment } from './../../../../environments/environment';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TokenStorageService } from '../../../service/login/token-storage.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './employer-jobs.component.html',
  styleUrls: ['./employer-jobs.component.scss']
})
export class EmployerJobsComponent implements OnInit {
  getId: any;
  EmployerJobs: any = [];
  saveSucess: boolean = false;
  error: boolean = false;
  errorMessage: string = "";
  successMessage: string = "";
  employerJobsEditForm: FormGroup;
  currentUser:any;
  employerId: any;

  //pagination
  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  totalItems: any;

  //for images  
  apiBaseUrl = environment.apiBaseUrl;

  constructor(
    public formBuilder: FormBuilder,
    private jobsService: JobsService,
    private token: TokenStorageService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    if(this.currentUser.admin_id == 0 || this.currentUser.admin_id == null){
      this.employerId = this.currentUser.id;
    } else {
      this.employerId = this.currentUser.admin_id;
    }
    this.employerJobsListData(this.page);
  }

  employerJobsListData(page: any) {
    this.jobsService.GetEmployerJobsListData(page, this.itemsPerPage, this.employerId).subscribe((res: any) => {
      this.EmployerJobs = res.data;
      this.totalItems = res.paging.total;
    });
  }

  deleteJobs(id: any, i: any) {
    if (window.confirm('Do you want to delete?')) {
      this.jobsService.deleteJobs(id).subscribe((res) => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = "Job Deleted Successfully";
        this.employerJobsListData(this.page);
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
    }
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.saveSucess = false;
    }, 5000);
  }

}
