import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployerCompaniesService } from 'src/app/service/employer_companies/employer_companies.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})
export class CompanyDetailComponent {
  getId: string;
  companyData: any;
  imgPhotoUrl: string;
  totalJobs: Number;
  dob: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private employerCompaniesService: EmployerCompaniesService
  ) {
    this.getId = this.activatedRoute.snapshot.paramMap.get('id');
    this.employerCompaniesService.GetCompanyDetails(this.getId).subscribe((res: any) => {
      this.companyData = res.data;
      if (this.companyData.web_user.photo == '' || this.companyData.web_user.photo == null) {
        this.imgPhotoUrl = '../../../../assets/img/default1.png';
      } else {
        this.imgPhotoUrl = `${environment.apiBaseUrl}employer/${this.companyData.web_user.photo}`;
      }
      this.totalJobs = this.companyData.jobs.filter(item => item.submit_type == 1).length;
      this.dob = this.companyData.web_user?.dob ? moment(this.companyData.web_user.dob).format('DD MMMM, YYYY') : "N/A";
    })

  }

}
