<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>All Companies</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a href="index.html">Home</a>
                        </li>
                        <li>
                            <span>/</span>
                        </li>
                        <li>
                            All Companies
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Filter -->
<div class="job-filter-area pt-100">
    <div class="container" *ngIf="isMobileView == false">
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="e.g UI/UX Design" formControlName="title">
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isSectorListLoaded" formControlName="sector">
                            <option value="">Filter By Sector</option>
                            <option *ngFor="let item of sectorList" [value]="item.id.toString()">{{item.sector_name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="country"
                            (change)="getState($event.target.value)">
                            <option value="">Filter By Country</option>
                            <option *ngFor="let countryRows of countryList" [value]="countryRows.id">{{ countryRows.name
                                }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="state"
                            (change)="getCity($event.target.value)">
                            <option value="">Filter By State</option>
                            <option *ngFor="let item of stateList" [value]="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="city">
                            <option value="" disabled selected>Filter By City</option>
                            <option *ngFor="let item of cityList" [value]="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <!-- <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isJobQualificationLoaded" formControlName="qualification">
                            <option value="">Filter By Qualifications</option>
                            <option *ngFor="let items of jobQualificationList" [value]="items.id.toString()">{{items.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isJobExperienceLoaded" formControlName="experience">
                            <option value="">Filter By Experiences</option>
                            <option *ngFor="let item of jobExperienceList" [value]="item.id">{{item.min_exp}} - {{item.max_exp}}</option>
                        </select>
                    </div>
                </div> -->
                <div class="col-sm-8 col-lg-2 col-md-2">
                    <div class="row" id="btn_contain">
                        <div class="col-6">
                            <button type="submit" class="filter_btn" (click)="searchFilter()">Search</button>
                        </div>
                        <div class="col-6">
                            <button type="clear" class="filter_btn" (click)="resetFilter()">Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End Filter -->

<!-- Mobile Filters -->
<div class="collapse" id="collapseExample">
    <div class="job-filter-area">
        <div class="container">
            <form [formGroup]="searchForm">
                <div class="row">
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="e.g UI/UX Design" formControlName="title">
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isSectorListLoaded" formControlName="sector">
                                <option value="">Filter By Sector</option>
                                <option *ngFor="let item of sectorList" [value]="item.id.toString()">{{item.sector_name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="country"
                                (change)="getState($event.target.value)">
                                <option value="">Filter By Country</option>
                                <option *ngFor="let countryRows of countryList" [value]="countryRows.id">{{ countryRows.name
                                    }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="state"
                                (change)="getCity($event.target.value)">
                                <option value="">Filter By State</option>
                                <option *ngFor="let item of stateList" [value]="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="city">
                                <option value="" disabled selected>Filter By City</option>
                                <option *ngFor="let item of cityList" [value]="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <!-- <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isJobQualificationLoaded" formControlName="qualification">
                                <option value="">Filter By Qualifications</option>
                                <option *ngFor="let items of jobQualificationList" [value]="items.id.toString()">{{items.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isJobExperienceLoaded" formControlName="experience">
                                <option value="">Filter By Experiences</option>
                                <option *ngFor="let item of jobExperienceList" [value]="item.id">{{item.min_exp}} - {{item.max_exp}}</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="col-sm-8 col-lg-2 col-md-2">
                        <div class="row" id="btn_contain">
                            <div class="col-6">
                                <button type="submit" class="filter_btn" (click)="searchFilter()">Search</button>
                            </div>
                            <div class="col-6">
                                <button type="clear" class="filter_btn" (click)="resetFilter()">Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Mobile Filters -->

<!-- Showing -->
<div class="job-showing-area">
    <div class="container">
        <div class="showing">
            <div class="row">
                <div style="width:30%">
                    <div class="left">
                        <form [formGroup]="sortForm">
                            <div class="form-group">
                                <select class="form-control" formControlName="sort" (change)="getAllCompanies(1)"
                                    style="width:auto">
                                    <option selected value="">Latest</option>
                                    <option value="id:asc">Oldest</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div style="width:70%">
                    <div class="right">
                        <ul>
                            <li>
                                <a (click)="showBoxesFunction()"><i class='bx bx-dots-horizontal'></i></a>
                            </li>
                            <li>
                                <a (click)="showListFunction()"><i class='bx bx-menu'></i></a>
                            </li>
                            <li *ngIf="isMobileView == true">
                                <a data-bs-toggle="collapse" data-bs-target="#collapseExample"><i class='bx bx-filter-alt'></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Showing -->

<!-- Candidate -->
<div class="candidate-area pb-100">
    <div class="container">
        <div class="row" *ngIf="showBoxes">
            <div class="col-lg-6 col-md-12"
                *ngFor="let data of apiData | paginate : { itemsPerPage: 10, currentPage: page, totalItems: totalItems }; let i = index">
                <a routerLink="/company/{{data.id}}/{{data.companyTitle}}" style="text-decoration: none;">
                    <div class="employer-items two">
                        <div class="left">
                            <img src="{{ data.imgPhotoUrl }}" onerror="this.src='../../../../assets/img/default1.png'" alt="Company Logo">
                            <span>{{data.company_name}}</span>
                            <ul class="experience">
                                <li>
                                    <img src="assets/icons/vacancy.png" alt="Details">Vacancy ({{ data.totalJobs }})
                                </li>
                            </ul>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-12 col-md-12" *ngIf="isSearchValueExists == false">
                <div class="employer-item d-flex justify-content-center">
                    <h3>No Companies Found!!</h3>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="showList">
            <!-- <div class="company-list-item" *ngFor="let data of apiData | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }; let i = index">
                <a routerLink="/company-detail/{{ data.id }}">
                    <img src="{{ data.imgPhotoUrl }}" alt="Company Logo">
                    <h3>{{ data.company_name }}</h3>
                    <ul class="experience">
                        <li>
                            <img src="assets/icons/vacancy.png" alt="Details">Vacancy ({{ data.totalJobs }})
                        </li>
                    </ul>
                </a>
                <span class="span-two">Vacancy ({{ data.totalJobs }})</span>
            </div> -->
            <div class="company-list" *ngFor="let data of apiData | paginate : { itemsPerPage: 10, currentPage: page, totalItems: totalItems }; let i = index">
                <div>
                    <img src="{{data.imgPhotoUrl}}" onerror="this.src='../../../../assets/img/default1.png'" alt="Company Logo">
                </div>

                <div class="company-list-data">
                    <a routerLink="/company/{{ data.id }}/{{data.companyTitle}}">
                        <h3>{{data.company_name}}</h3>
                    </a>
                    <div class="exp-icon">
                        <img class="experience-icon" src="assets/icons/vacancy.png" alt="vacancy-icon" />
                        <p>Vacancy: <span>({{data.totalJobs}})</span></p>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12" *ngIf="isSearchValueExists == false">
                    <div class="employer-item d-flex justify-content-center">
                        <h3>No Companies Found!!</h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="pagination-area">
            <pagination-controls class="pagi" (pageChange)="getAllCompanies(page = $event)" (directionLinks)="true" (responsive)="true"></pagination-controls>
        </div>
    </div>
</div>
<!-- End Candidate -->

<!-- Code for Modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">To Apply for this Job, your Profile must be 80% Completed.</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
      </div>
    </div>
</div> -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->