<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-end">
                    <div class="col-lg-8 col-md-12">
                        <div class="left two">
                            <img src="{{ imgPhotoUrl }}" onerror="this.src='../../../../assets/img/default1.png'" alt="Details" style="width:230px; height: 220px;">
                            <h2 style="left:100px">{{ companyData?.company_name }}</h2>
                            <h3 style="left:100px;color: white;"><i class='bx bx-phone-call'></i> {{ companyData?.mobileno }} | <i class='bx bx-mail-send'></i> {{ companyData?.emailid }}</h3>
                            <!-- <ul>
                                <li><i class='bx bx-box'></i> UX/UI Designer</li> 
                                <li><i class='bx bx-phone-call'></i> <a>{{ companyData?.mobileno }}</a></li>
                            </ul> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Candidate Details -->
<div class="person-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="details-item">
                    <div class="detail-title">
                        <div class="detail-found-date">
                            <div class="left" style="font-size: 60px;">
                                <i class='bx bx-calendar-check'></i>
                            </div>
                            <div>
                                <h4 style="font-size: 25px;color: #221668;">Founded Date</h4>
                                <span>{{ dob }}</span>
                            </div>
                        </div>
                        <div class="detail-job-posted">
                            <div class="left" style="font-size: 60px;">
                                <i class='bx bx-calendar-check'></i>
                            </div>
                            <div>
                                <h4 style="font-size: 25px;color: #221668;">Jobs Posted</h4>
                                <span>{{ totalJobs }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="profile">
                        <!-- <h3>{{ companyData?.company_name }} Profile</h3> -->
                        <p>{{ companyData?.about_company }}</p>
                    </div>

                    <div class="work bottom-item">
                        <h3>Company Mission</h3>
                        <div>
                            <!-- <ul>
                                <li><img src="assets/img/logo2.png" alt="Icon" style="width:20px;">{{ data.organization }}</li>
                                <li><span>{{data.working_from_month}}/{{data.working_from_year}} - {{data.working_till_month}}/{{data.working_till_year}}</span></li>
                            </ul>
                            <h4>{{ data.designation }}</h4> -->
                            <p>{{ companyData?.company_mission }}</p>
                        </div>
                    </div>

                    <div class="work bottom-item">
                        <h3>Company Vision</h3>
                        <div>
                            <!-- <ul>
                                <li><img src="assets/img/logo2.png" alt="Icon" style="width:20px;">{{ data.university.name }}</li>
                            </ul>
                            <h4>{{ data.course_type.name }}</h4> -->
                            <p>{{ companyData?.company_vision }}</p>
                        </div>
                    </div>

                    <!-- <div class="skills">
                        <h3>Skills</h3>

                        <div class="skill-wrap" *ngFor="let data of companyData?.candidate_key_skills">
                            <div class="skill">
                                <h3>{{ data.key_skill.name }}</h3>
                                <div class="skill-bar skill1 animate__slideInLeft animate__animated">
                                    <span class="skill-count1">56%</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-12" *ngIf="(companyData?.employer_name != '' && companyData?.employer_name != null) || (companyData?.employer_emailid != '' && companyData?.employer_emailid != null) || (companyData?.employer_mobileno != '' && companyData?.employer_mobileno != null) || (companyData?.employer_designation != '' && companyData?.employer_designation != null) || (companyData?.employer_department != '' && companyData?.employer_department != null)">
                <div class="widget-area">
                    <div class="information widget-item">
                        <h3>Employer Overview</h3>

                        <ul>
                            <li>
                                <img src="assets/img/logo2.png" alt="Details" style="width: 20px;">
                                <h4>Name:</h4>
                                <span>{{ companyData?.employer_name }}</span>
                            </li>
                            <li>
                                <img src="assets/img/logo2.png" alt="Details" style="width: 20px;">
                                <h4>Email Id:</h4>
                                <span>{{ companyData?.employer_emailid }}</span>
                            </li>
                            <li>
                                <img src="assets/img/logo2.png" alt="Details" style="width: 20px;">
                                <h4>Mobile No:</h4>
                                <span>{{ companyData?.employer_mobileno }}</span>
                            </li>
                            <li>
                                <img src="assets/img/logo2.png" alt="Details" style="width: 20px;">
                                <h4>Designation:</h4>
                                <span>{{ companyData?.employer_designation }}</span>
                            </li>
                            <li>
                                <img src="assets/img/logo2.png" alt="Details" style="width: 20px;">
                                <h4>Department:</h4>
                                <span>{{ companyData?.employer_department }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Candidate Details -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->