<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Resume</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>
                            <a routerLink="/{{redirectURL}}">Dashboard</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Resume</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- resume type -->
<div class="template-list" style="margin-top: 80px;position: relative;" *ngIf="currentUser.user_type == 2">
    <div class="container">
        <div class="flex">
            <div class="button-container">
                <button type="button" routerLink="/resume-layout1/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout1.png" width="130px"></button>
                <button type="button" routerLink="/resume-layout2/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout2.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout3/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout3.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout4/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout4.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout5/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout5.png" width="130px"></button>
            </div>
        </div>
    </div>
</div>

<!-- Resume -->
<div class="person-details-area resume-area ptb-100">
    <div class="default-layout" id="default">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12" id="default-resume-layout" aria-labelledby="v-pills-default">
                    <div class="widget-area">
                        <div class="resume-profile">
                            <img src="{{imgPhotoUrl}}" alt="{{ resumeData.name }}">
                            <h2>{{ resumeData.name }}</h2>
                            <span>{{ resumeData.candidate_experiences[0].designation }}</span>
                        </div>
    
                        <div class="information widget-item">
                            <h3>Overview</h3>
                            <ul>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Salary:</h4>
                                    <span>Rs.{{ resumeData.current_salary }}</span>
                                </li>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Experience:</h4>
                                    <span>{{ resumeData.total_experience }} years</span>
                                </li>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Age:</h4>
                                    <span>{{ resumeData.age }}</span>
                                </li>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Gender:</h4>
                                    <span>{{ resumeData.gender }}</span>
                                </li>
                                <li>
                                    <img src="assets/img/job-details-icon.png" alt="Details">
                                    <h4>Languages:</h4>
                                    <span>Bangla, English, Arabic, Spanish</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-8 col-md-12">
                    <div class="details-item">
                        <div class="profile">
                            <h3>About</h3>
                            <p>{{ resumeData.about_me }}</p>
                        </div>
    
                        <div class="work bottom-item" *ngFor="let expData of resumeData.candidate_experiences; let i = index">
                            <h3 *ngIf="i==0">Work Experience</h3>
                            <ul>
                                <li>
                                    <img src="assets/img/home-three/title-img.png" alt="Icon">
                                    {{expData.organization}}
                                </li>
                                <li><span>{{expData.working_from_month}}/{{expData.working_from_year}} - {{expData.working_till_month}}/{{expData.working_till_year}}</span></li>
                            </ul>
                            <h4>{{expData.designation}}</h4>
                            <p>{{expData.description}}</p>
                        </div>
    
                        <div class="work bottom-item" *ngFor="let eduData of resumeData.candidate_educations; let i = index">
                            <h3 *ngIf="i==0">Education</h3>
                            <ul>
                                <li>
                                    <img src="assets/img/home-three/title-img.png" alt="Icon">
                                    {{eduData.university.name}}
                                </li>
                            </ul>
                            <h4>{{eduData.course.name}} ({{eduData.passing_year}})</h4>
                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form, by injected humour, or randomised words which don't look
                                even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be
                                sure there isn't anything embarrassing hidden in the middle of text.</p>
                        </div>
    
                        <div class="skills" *ngIf="resumeData.candidate_key_skills.length > 0">
                            <h3>Skills</h3>
    
                            <div class="skill-wrap">
                                <div class="skill" *ngFor="let skillsData of resumeData.candidate_key_skills">
                                    <h3>{{skillsData.key_skill.name}}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Resume -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
