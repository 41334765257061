import { TokenStorageService } from './../../../service/login/token-storage.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { JobsService } from '../../../service/jobs/jobs.service';
import { JobTypeService } from '../../../service/job-type/job-type.service';
import { JobCategoryService } from '../../../service/job-category/job-category.service';
import { IndustryTypeService } from '../../../service/industry-type/industry-type.service';
import { JobSalaryService } from '../../../service/job-salary/job-salary.service';
import { JobExperienceService } from '../../../service/job-experience/job-experience.service';
import { JobQualificationService } from '../../../service/job-qualification/job-qualification.service';
import { JobLevelService } from '../../../service/job-level/job-level.service';
import { CountryService } from '../../../service/country/country.service';
import { StateService } from '../../../service/state/state.service';
import { CityService } from '../../../service/city/city.service';
import { KeySkillsService } from '../../../service/key-skills/key-skills.service';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, AbstractControl } from "@angular/forms";
import { EmployerCompaniesService } from 'src/app/service/employer_companies/employer_companies.service';
import { environment } from './../../../../environments/environment'

@Component({
  selector: 'app-post-a-job',
  templateUrl: './post-a-job.component.html',
  styleUrls: ['./post-a-job.component.scss']
})
export class PostAJobComponent implements OnInit {
  saveSucess: boolean = false;
  error: boolean = false;
  errorMessage: string = "";
  successMessage: string = "";
  addJobsForm: FormGroup;

  isCompanyLoaded: boolean = false;
  employerCompaniesList: any;

  isJobTypeLoaded: boolean = false;
  jobTypeList: any;

  isIndustryTypeLoaded: boolean = false;
  industryTypeList: any;

  isJobCategoryLoaded: boolean = false;
  jobCategoryList: any;

  isJobSalaryLoaded: boolean = false;
  jobSalaryList: any;

  isCurrencyLoaded: boolean = false;
  currencyList: any;

  isJobExperienceLoaded: boolean = false;
  jobExperienceList: any;

  isJobQualificationLoaded: boolean = false;
  jobQualificationList: any;

  isJobLevelLoaded: boolean = false;
  jobLevelList: any;

  isCountryLoaded: boolean = false;
  countryList: any;

  stateList: any;
  cityList: any;

  isKeySkillsLoaded: boolean = false;
  keySkillsList: any;

  submitTypeToggle: Number = 0;
  // submitTypeList: any = [
  //   { value: "1", name: "No" },
  //   { value: "2", name: "Yes" },
  // ];
  companyName: any;
  employeeCompanyId: any;

  totalItems: any;
  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  isLoggedIn: boolean;
  currentUser: any;

  questionForm: FormGroup;
  questionsArray: FormArray;

  jobResponsibilityArray: FormArray;
  selectedSkills: Array<string> = [];

  get contactFormGroup() {
    return this.addJobsForm.get('questions') as FormArray;
  }

  get jobResponsibilityFormGroup() {
    return this.addJobsForm.get('job_responsibilities') as FormArray;
  }

  constructor(
    private token: TokenStorageService,
    public formBuilder: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private jobsService: JobsService,
    private jobTypeService: JobTypeService,
    private industryTypeService: IndustryTypeService,
    private jobCategoryService: JobCategoryService,
    private jobSalaryService: JobSalaryService,
    private jobExperienceService: JobExperienceService,
    private jobQualificationService: JobQualificationService,
    private jobLevelService: JobLevelService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private keySkillsService: KeySkillsService,
    private employerCompaniesService: EmployerCompaniesService,
  ) {
    this.addJobsForm = this.formBuilder.group({
      employer_companies_id: [''],
      title: ['', Validators.required],
      job_type_id: ['', Validators.required],
      industry_type_id: ['', Validators.required],
      job_category_id: ['', Validators.required],
      min_salary: ['', Validators.required],
      max_salary: ['', Validators.required],
      currency_id: ['', Validators.required],
      job_experience_id: ['', Validators.required],
      job_qualification_id: ['', Validators.required],
      job_level_id: ['', Validators.required],
      country_id: ['', Validators.required],
      state_id: ['', Validators.required],
      city_id: ['', Validators.required],
      key_skills_ids: ['', Validators.required],
      job_description: ['', Validators.required],
      submit_type: ['', Validators.required],
      status: ['1'],
      // questions: this.formBuilder.array([this.createContact()]),
      questions: this.formBuilder.array([]),
      job_responsibilities: this.formBuilder.array([this.createResponibility()])
    });

    this.questionsArray = this.addJobsForm.get('questions') as FormArray;
    this.jobResponsibilityArray = this.addJobsForm.get('job_responsibilities') as FormArray; 
  }

  get f() {
    return this.addJobsForm.controls;
  }

  ngOnInit(): void {
    if (this.token.getToken()) {
      this.isLoggedIn = true;
      this.currentUser = this.token.getUser();
    }
    const companyId = this.currentUser.admin_id == null ? this.currentUser.id : this.currentUser.admin_id;
    this.employerCompaniesService.GetEmployerCompanies(this.page, this.itemsPerPage, companyId).subscribe((res:any) => {
      if(res.data[0].status == 0) {
        alert("Your Company is In-Active. Activate your Company from Dashboard -> Company Details");
        this.ngZone.run(() => this.router.navigateByUrl('/employer-dashboard'));
      }
      this.companyName = res.data[0].company_name;
      this.employeeCompanyId = res.data[0].id.toString();
    })
    // this.employerCompaniesDropdown();
    this.jobTypeDropdown();
    this.industryTypeDropdown();
    this.jobCategoryDropdown();
    this.jobSalaryDropdown();
    // this.currencyDropdown();
    this.jobExperienceDropdown();
    this.jobQualificationDropdown();
    this.jobLevelDropdown();
    this.countryDropdown();
    this.keySkillsDropdown();

    this.addJobsForm.get('max_salary').valueChanges.subscribe(value => {
        const min = this.addJobsForm.get('min_salary').value

        if (Number(value) < Number(min)) {
          this.addJobsForm.get('max_salary').setErrors({ 'minIsGreaterThanMax': true })
        } else {
          if (Number(value) > Number(min)) {
            this.addJobsForm.get('max_salary').setErrors({ 'minIsGreaterThanMax': false })
            this.addJobsForm.get('max_salary').setErrors(null);
          } else {
            this.addJobsForm.get('max_salary').setErrors({ 'minIsGreaterThanMax': true })
          }
        }
    });

  }

  addJobs(): any {
    const submitType = this.addJobsForm.get('submit_type').value == 1 ? 1 : 0;
    this.addJobsForm.controls['submit_type'].setValue(submitType.toString());
    this.jobsService.AddJobs(this.addJobsForm.value).subscribe(() => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = submitType == 0 ? "Job Saved as Draft" : "Job Posted Successfully";
        this.gotoMyJobs();
        this.ngZone.run(() => this.router.navigateByUrl('/employer-dashboard'))
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
  }

  gotoMyJobs() { this.token.setTest(true); }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.saveSucess = false;
    }, 5000);
  }

  // employerCompaniesDropdown() {
  //   this.jobsService.getEmployerCompaniesDropdown().subscribe((res: any) => {
  //     this.isCompanyLoaded = true;
  //     this.employerCompaniesList = res.data;
  //     this.companyName = res.data[0].company_name;
  //     this.employeeCompanyId = res.data[0].id.toString();
  //   });
  // }

  jobTypeDropdown() {
    this.jobTypeService.getJobTypeDropdown().subscribe((res: any) => {
      this.isJobTypeLoaded = true;
      this.jobTypeList = res.data;
    });
  }

  industryTypeDropdown() {
    this.industryTypeService.getIndustryTypeDropdown().subscribe((res: any) => {
      this.isIndustryTypeLoaded = true;
      this.industryTypeList = res.data;
    });
  }

  jobCategoryDropdown() {
    this.jobCategoryService.getJobCategoryDropdown().subscribe((res: any) => {
      this.isJobCategoryLoaded = true;
      this.jobCategoryList = res.data;
    });
  }

  jobSalaryDropdown() {
    this.jobSalaryService.getJobSalaryDropdown().subscribe((res: any) => {
      this.isJobSalaryLoaded = true;
      this.jobSalaryList = res.data;
    });
  }

  // currencyDropdown() {
  //   this.jobSalaryService.getCurrencyDropdown().subscribe((res: any) => {
  //     this.isCurrencyLoaded = true;
  //     this.currencyList = res.data;
  //   });
  // }

  jobExperienceDropdown() {
    this.jobExperienceService.getJobExperienceDropdown().subscribe((res: any) => {
      this.isJobExperienceLoaded = true;
      this.jobExperienceList = res.data;
    });
  }

  jobQualificationDropdown() {
    this.jobQualificationService.getJobQualificationDropdown().subscribe((res: any) => {
      this.isJobQualificationLoaded = true;
      this.jobQualificationList = res.data;
    });
  }

  jobLevelDropdown() {
    this.jobLevelService.getJobLevelDropdown().subscribe((res: any) => {
      this.isJobLevelLoaded = true;
      this.jobLevelList = res.data;
    });
  }

  countryDropdown() {
    this.countryService.getCountryDropdown().subscribe((res: any) => {
      this.isCountryLoaded = true;
      this.countryList = res.data;
    });
  }

  getState(countryId: string) {
    const toArray = countryId.split(": ");
    const country_id = toArray[1];
    this.stateService.getStateListDropdown(country_id).subscribe((res: any) => {
      this.stateList = res.data;
    });
  }

  getCity(stateId: string) {
    const toArray = stateId.split(": ");
    const state_id = toArray[1];
    this.cityService.getCityListDropdown(state_id).subscribe((res: any) => {
      this.cityList = res.data;
    });
  }

  keySkillsDropdown() {
    this.keySkillsService.getKeySkillsDropdown().subscribe((res: any) => {
      this.isKeySkillsLoaded = true;
      this.keySkillsList = res.data;
    });
  }

  addContact() {
    this.questionsArray.push(this.createContact());
  }

  removeQuestions(i) {
    const emails = this.addJobsForm.get('questions') as FormArray
    if (emails.length >= 1) {
      emails.removeAt(i)
    }
  }

  createContact(): FormGroup {
    return this.formBuilder.group({
      isMandatory: ['', Validators.required],
      question: ['', Validators.required]
    });
  }

  getContactsFormGroup(index): FormGroup {
    const formGroup = this.questionsArray.controls[index] as FormGroup;
    return formGroup;
  }

  createResponibility(): FormGroup {
    return this.formBuilder.group({
      job_responsibility: ['', Validators.required]
    })
  }

  addResponsibility() {
    this.jobResponsibilityArray.push(this.createResponibility());
  }

  removeResponsibility(i) {
    const emails = this.addJobsForm.get('job_responsibilities') as FormArray
    if (emails.length > 1) {
      emails.removeAt(i)
    } else {
      emails.reset()
    }
  }

  getJobResponsibilityFormGroup(index): FormGroup {
    // this.contactList = this.form.get('contacts') as FormArray;
    const formGroup = this.jobResponsibilityArray.controls[index] as FormGroup;
    return formGroup; 
  }

}
