import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobsService } from '../../../service/jobs/jobs.service';
import { environment } from './../../../../environments/environment';
import { TokenStorageService } from '../../../service/login/token-storage.service';
import { Router } from '@angular/router';
import { CrudService } from '../../../service/web_users/web_users.service';
import { CandidateResumeService } from 'src/app/service/candidate-resume/candidate-resume.service';
import { FormGroup, FormBuilder, FormArray, Validators, NgForm } from '@angular/forms';
import { CandidateDocumentService } from '../../../service/candidate-document/candidate-document.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
  formData = new FormData();
  getId: any;
  jobDetails: any;
  applyJobs: any;
  currentUser: any;
  isLoggedIn = false;

  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  totalItems: any;

  //for images  
  apiBaseUrl = environment.apiBaseUrl;
  savedJobs: any;
  favouriteJobData: any = [];
  idArray: boolean = true;
  resume: any;
  isCandidateApplied: boolean = false;
  isCandidateSaved: boolean = false;
  candidateResume: any;
  getResumeId: any;
  candidateResumeForm: FormGroup;
  successMessage: string;
  saveSucess: boolean;
  error: boolean;
  errorMessage: any;
  isResumeUploaded: boolean = false;

  showResumePage: boolean = true;
  showQuestionPage: boolean = false;
  showSummaryPage: boolean = false;
  showDocumentPage: boolean = false;
  answerData:FormData [] = [];
  duplicateAnswerData = [];
  questionAnswer: FormData;

  isResumeSelected: boolean = false;
  isAnswered: boolean = false;
  ans: FormData;

  myForm: FormGroup;
  resumeData: any;
  imgPhotoUrl: string;
  candidateDocument: any;
  documentForm: any;
  doubleOtherDocsExists: boolean = false;
  submitted_passport: any;
  submitted_certificate: any;
  submitted_cover_letter: any;
  submitted_document1: any;
  submitted_document2: any;

  submitted_document = {};
  isPassportChecked: boolean;
  isCertificateChecked: boolean;
  isCoverLetterChecked: boolean;
  isDocument1Checked: boolean;
  isDocument2Checked: boolean;
  showDocumentList: boolean;
  showDocumentUpload: boolean;
  passportPage: boolean;
  certificatePage: boolean;
  coverLetterPage: boolean;
  otherPage: boolean;
  candidateDocumentForm: FormGroup;

  isValidForJobApplication: string;


  constructor(
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private jobsService: JobsService,
    private token: TokenStorageService,
    private ngZone: NgZone,
    private router: Router,
    private crudService: CrudService,
    private candidateResumeService: CandidateResumeService,
    private candidateDocumentService: CandidateDocumentService,
    private cookieService : CookieService
  ) {
    this.getId = this.activatedRoute.snapshot.paramMap.get('id');

    this.jobsService.GetJobsDetail(this.getId).subscribe((res: any) => {
      this.jobDetails = res.data;
      if(this.jobDetails.questions != null) {
        this.jobDetails.questions.forEach((item, index) => {
          if (item.isMandatory == 1) {
            this.myForm.addControl(`answer${index}`, this.formBuilder.control('', Validators.required));
          } else {
            this.myForm.addControl(`answer${index}`, this.formBuilder.control(''))
          }
        });
      }

      if (this.jobDetails.web_user.photo) {
        this.imgPhotoUrl = `${environment.apiBaseUrl}employer/${this.jobDetails.web_user.photo}`;
      } else {
        this.imgPhotoUrl = '../../../../assets/img/default1.png';
      }
    });

    this.jobsService.GetCandidateAppliedJobsListData(this.page, this.itemsPerPage).subscribe((res:any) => {
      if(res.data.length > 0){
        res.data.map((item) => {
          if (item.jobs_id == this.getId) {
            this.isCandidateApplied = true;
          }
        })
      }
    })

    this.jobsService.GetCandidateFavouriteJobsListData(this.page, this.itemsPerPage).subscribe((res:any) => {
      if(res.data.length > 0){
        res.data.map((item) => {
          if(item.jobs_id == this.getId){
            this.isCandidateSaved = true;
          }
        })
      }
    })

    this.candidateResumeForm = this.formBuilder.group({
      file: [''],
      resume: ['']
    });

    this.myForm = this.formBuilder.group({});

    this.documentForm = this.formBuilder.group({
      passport : ['', Validators.required],
      education_certificate: ['', Validators.required],
      cover_letter: ['', Validators.required],
      other_document1: [''],
      other_document2: [''],
    });

    this.candidateDocumentForm = this.formBuilder.group({
      file: [''],
      document_type: [''],
      document: ['']
    });
  }

  ngOnInit(): void {
    if (this.token.getToken()) {
      this.isLoggedIn = true;
      this.currentUser = this.token.getUser();
    }
    // this.profileScore = this.token.getProfileScore();
    this.isValidForJobApplication = this.cookieService.get('isValidForApplication');
    this.getCandidateResumeData();
    this.getCandidateDocumentData();
  }

  getFormControl(index: number) {
    return this.myForm.get(`answer${index}`);
  }

  get f() {
    return this.documentForm.controls;
  }

  setResume(event: any):any {
    this.getResumeId = event.target.value;
    if(this.getResumeId) {
      this.isResumeSelected = true;
    }
  }
  
  applyJob():any {
    this.questionAnswer = this.myForm.value;
    const updatedArray = this.jobDetails.questions.map((item, index) => ({ ...item, ...{ ['answer']: this.questionAnswer[`answer${index}`] } }));
    const paylaod = { 
      resume_id : this.getResumeId,
      answer : updatedArray,
      applied_passport : this.documentForm.get('passport').value || 0,
      applied_certificate: this.documentForm.get('education_certificate').value || 0,
      applied_cover_letter: this.documentForm.get('cover_letter').value || 0,
      applied_other_document1: this.documentForm.get('other_document1').value || 0,
      applied_other_document2: this.documentForm.get('other_document2').value || 0
    };
    this.jobsService.ApplyJobs(this.getId, paylaod).subscribe((res: any) => {
      this.applyJobs = res.data;
      if (res.code == 0) {
        alert(res.message);
        window.location.reload();
      }
    },
    (err) => {
      if (err.status == 409) {
        alert(err.error.message);
      } else {
        alert(err.statusText);
      }
    })
  }

  getCandidateResumeData() {
    this.candidateResumeService.getCandidateResume(this.page, this.itemsPerPage).subscribe((res:any) => {
      this.candidateResume = res.data.data;
    })
  }

  saveJob():any {
    this.jobsService.AddCandidateFavouriteJob(this.getId).subscribe((res:any) => {
      this.savedJobs = res.data;
      if (res.code == 0) {
        alert(res.message);
        window.location.reload();
      }
    },
    (err) => {
      if (err.status == 409) {
        alert(err.error.message);
      } else {
        alert(err.statusText);
      }
    })
  }
  
  uploadResume() {
    this.ngZone.run(() => this.router.navigateByUrl('/candidate-dashboard'))
  }

  deleteSaveJob():any {
    this.jobsService.RemoveCandidateFavouriteJobs(this.getId).subscribe((res:any) => {
      this.isCandidateSaved = false;
      if (res.code == 0) {
        alert(res.message);
        window.location.reload();
      }
    },
    (err) => {
      if (err.status == 409) {
        alert(err.error.message);
      } else {
        alert(err.statusText);
      }
    })
  }

  onResumeUpload(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.candidateResumeForm.patchValue({ resume: file });
      this.isResumeUploaded = true;
    }
  }

  uploadCV() {
    const formData = new FormData();
    formData.append('file', this.candidateResumeForm.get('resume')?.value);
    this.crudService.addCandidateResume(formData).subscribe(res => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Resume Added Successfully';
      window.location.reload();
      },(err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
    });
  }

  gotoQuestionsPage() {
    this.showResumePage = false;
    this.showDocumentPage = false;
    if(this.jobDetails.questions != null){
      this.showQuestionPage = true;
    } else {
      this.showSummaryPage = true;
    }
  }

  gotoDocumentPage() {
    this.showResumePage = false;
    this.showQuestionPage = false;
    this.showSummaryPage = false;
    this.showDocumentPage = true;
    // if(this.jobDetails.questions != null){
    //   this.showQuestionPage = true;
    // } else {
    //   this.showSummaryPage = true;
    // }
  }

  getCandidateDocumentData() {
    this.candidateDocumentService.getCandidateDocument(this.page, this.itemsPerPage).subscribe((res:any) => {
      this.candidateDocument = res.data.data;
      const isMoreThanTwo = res.data.data.filter((item) => item.document_type == 4);
      isMoreThanTwo ? this.doubleOtherDocsExists = true : this.doubleOtherDocsExists = false;
    });
  }

  selectDocument(id, type) {
    if (type == 1) this.documentForm.controls['passport']?.setValue(id);
    if (type == 2) this.documentForm.controls['education_certificate']?.setValue(id);
    if (type == 3) this.documentForm.controls['cover_letter']?.setValue(id);
    if (type == 4) this.documentForm.controls['other_document1']?.setValue(id);
    if (type == 4) this.documentForm.controls['other_document2']?.setValue(id);
  }

  gotoSummaryPage() {
    this.showResumePage = false;
    this.showQuestionPage = false;
    this.showDocumentPage = false;
    this.showSummaryPage = true;
    this.questionAnswer = this.myForm.value;
    this.resumeData = this.candidateResume.filter(item => item.id == this.getResumeId);
    this.submitted_passport = this.candidateDocument.filter(item => item.id == this.documentForm.controls['passport'].value);
    this.submitted_certificate = this.candidateDocument.filter(item => item.id == this.documentForm.controls['education_certificate'].value);
    this.submitted_cover_letter = this.candidateDocument.filter(item => item.id == this.documentForm.controls['cover_letter'].value);
    this.submitted_document1 = this.candidateDocument.filter(item => item.id == this.documentForm.controls['other_document1'].value);
    this.submitted_document2 = this.candidateDocument.filter(item => item.id == this.documentForm.controls['other_document2']?.value || '');
  }

  cancelApplication() {
    this.showResumePage = true;
    this.showQuestionPage = false;
    this.showSummaryPage = false;
    this.isResumeSelected = false;
    this.getResumeId = undefined;
    this.isAnswered = false;
    this.showDocumentPage = false;
  }

  viewPassportUpload(){
    this.showDocumentList = false;
    this.showDocumentUpload = true;
    this.passportPage = true;
    this.certificatePage = false;
    this.coverLetterPage = false;
    this.otherPage = false;
  }

  viewCertificateUpload(){
    this.showDocumentList = false;
    this.showDocumentUpload = true;
    this.passportPage = false;
    this.certificatePage = true;
    this.coverLetterPage = false;
    this.otherPage = false;
  }

  viewCoverLetterUpload(){
    this.showDocumentList = false;
    this.showDocumentUpload = true;
    this.passportPage = false;
    this.certificatePage = false;
    this.coverLetterPage = true;
    this.otherPage = false;
  }

  viewOtherUpload(){
    this.showDocumentList = false;
    this.showDocumentUpload = true;
    this.passportPage = false;
    this.certificatePage = false;
    this.coverLetterPage = false;
    this.otherPage = true;
  }

  onFileUpload(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.candidateDocumentForm.patchValue({ document: file });
    }
  }

  uploadFile(id) {
    const formData = new FormData();
    formData.append('file', this.candidateDocumentForm.get('document')?.value);
    formData.append('document_type', id);
    this.candidateDocumentService.addCandidateDocument(formData).subscribe(res => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Document Added Successfully';
      window.location.reload();
      },(err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
    });
  }

}
