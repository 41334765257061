import { environment } from './../../../../environments/environment';
import { Component, OnInit, NgZone } from '@angular/core';
import { AgencyService } from '../../../service/agency/agency.service';
import { CountryService } from '../../../service/country/country.service';
import { StateService } from '../../../service/state/state.service';
import { CityService } from 'src/app/service/city/city.service';
import { EducationService } from '../../../service/education/education.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { JobCategoryService } from 'src/app/service/job-category/job-category.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-all-candidate',
  templateUrl: './all-candidate.component.html',
  styleUrls: ['./all-candidate.component.scss']
})

export class AllCandidateComponent implements OnInit {
  term: any;
  apiData: any;
  searchCount: boolean;
  pagings: any;
  resumeData: any;
  countryList: any;
  stateList: any;
  isCountryLoaded: boolean = false;
  isStateLoaded: boolean;
  educationList: [];
  ageValue: any;
  expValue: any;
  searchForm: FormGroup;
  showBoxes: boolean = true;
  showList: boolean = false;

  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  totalItems: any;
  totalCandidates : any;
  
  ageDropdown: any = [
    { id: 1, value: "15 - 20" },
    { id: 2, value: "20 - 25" },
    { id: 3, value: "25 - 30" },
    { id: 4, value: "30 - 35" },
    { id: 5, value: "35 - 40" },
  ]
  expDropdown: any = [
    { id: 1, value: "0 - 1" },
    { id: 2, value: "1 - 2" },
    { id: 3, value: "2 - 3" },
    { id: 4, value: "3 - 4" },
    { id: 5, value: "4 - 5" },
  ]
  cityList: any;
  sortForm: FormGroup;
  queryData: any;
  isSearchValueExists: boolean = false;
  isJobCategoryLoaded: boolean;
  jobCategoryList: any;

  isMobileView: boolean = false;
  isDashboardSearch: any;
  searchQuery: any;
  
  constructor(
    public formBuilder: FormBuilder,
    private agencyService: AgencyService,
    private countryService: CountryService,
    private stateService: StateService,
    private educationService: EducationService,
    private router: Router,
    private ngZone: NgZone,
    private cityService: CityService,
    private jobCategoryService: JobCategoryService,
  ) {

    this.sortForm = this.formBuilder.group({ sort:[''] });

    this.searchForm = this.formBuilder.group({
      title: [''],
      age: [''],
      candidate_category: [''],
      country: [''],
      state: [''],
      city:[''],
      education: [''],
      experience: ['']
    })

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.isDashboardSearch = JSON.parse(window.localStorage.getItem("dashboardSearch"));
    this.searchQuery = JSON.parse(window.localStorage.getItem("dashboard-search"));

    if (this.isDashboardSearch == true) {
      
      this.searchForm.patchValue({
        title: [''],
        job_type: [''],
        candidate_category: this.searchQuery?.candidate_category || '',
        country: [''],
        state: [''],
        city: [''],
        qualification: [''],
        experience: [''],
        salary: [''],
        currencyId: ['']
      })
      
      this.agencyService.getAllCandidatesFilters(this.page, this.itemsPerPage, this.searchForm.value, this.sortForm.value).subscribe((res:any) => {
        this.apiData = res.data.data;
        this.pagings = res.data.paging;
        this.totalItems = res.data.paging.total;
        this.apiData.length > 0 ? this.isSearchValueExists = true : this.isSearchValueExists = false;
        this.apiData.map((item) => {
          if (item.photo) {
            item.imgPhotoUrl = `${environment.apiBaseUrl}candidate/profile/${item.photo}`;
          } else {
            item.imgPhotoUrl = '../../../../assets/img/default1.png';
          }
          item.candidateTitle = item.first_name.split(' ').join('-').toLowerCase()+'-'+item.last_name.split(' ').join('-').toLowerCase();
        });
      })

    } else {
      this.getAllCandidatesList(this.page);
    }

  }

  ngOnInit(): void {
    if (window.screen.width <= 768) {
      this.isMobileView = true;
    }
    this.countryDropdown();
    this.educationDropdown();
    this.categoriesDropdown();
  }

  getAllCandidatesList(page:any) {
    this.agencyService.getAllCandidates(page, this.itemsPerPage, this.sortForm.value).subscribe((res:any) => {
      this.apiData = res.data.data;
      this.pagings = res.data.paging;
      this.totalCandidates = res.data.paging.total;
      this.apiData.length > 0 ? this.isSearchValueExists = true : this.isSearchValueExists = false;
      this.apiData.map((item) => {
        if (item.photo) {
          item.imgPhotoUrl = `${environment.apiBaseUrl}candidate/profile/${item.photo}`;
        } else {
          item.imgPhotoUrl = '../../../../assets/img/default1.png';
        }
        item.candidateTitle = item.first_name.split(' ').join('-').toLowerCase()+'-'+item.last_name.split(' ').join('-').toLowerCase();
      });
    })
  }

  onClick(id:any,first_name:any,last_name:any) {
    const firstName = first_name.replace(/[\s\/]+/g, '-').toLowerCase();
    const lastName = last_name.replace(/[\s\/]+/g, '-').toLowerCase();
    const resumeTitle = firstName + '-' + lastName;
    this.ngZone.run(() => this.router.navigateByUrl(`/resume/${id}/${resumeTitle}`));
  }

  countryDropdown () {
    this.countryService.getCountryDropdown().subscribe((res: any) => {
    this.isCountryLoaded = true;
      this.countryList = res.data;
    });
  }

  getState(countryId: string) {
    this.stateService.getStateListDropdown(countryId).subscribe((res: any) => {
      this.stateList = res.data;
    });
  }

  getCity(stateId: string) {
    this.cityService.getCityListDropdown(stateId).subscribe((res: any) => {
      this.cityList = res.data;
    });
  }

  educationDropdown() {
    this.educationService.getEducationDropdown().subscribe((res: any) => {
      this.educationList = res.data;
    });
  }

  categoriesDropdown() {
    this.jobCategoryService.getJobCategoryDropdown().subscribe((res:any) => {
      this.isJobCategoryLoaded = true;
      this.jobCategoryList = res.data;
    })
  }

  searchFilter(): any {
    this.agencyService.getAllCandidatesFilters(this.page, this.itemsPerPage, this.searchForm.value, this.sortForm.value).subscribe((res:any) => {
      this.apiData = res.data.data;
      this.pagings = res.data.paging;
      this.totalCandidates = res.data.paging.total;
      this.apiData.length > 0 ? this.isSearchValueExists = true : this.isSearchValueExists = false;
      this.apiData.map((item) => {
        if (item.photo) {
          item.imgPhotoUrl = `${environment.apiBaseUrl}candidate/profile/${item.photo}`;
        } else {
          item.imgPhotoUrl = '../../../../assets/img/default1.png';
        }
      });
    });
  }

  resetFilter(): any {
    this.searchForm.reset();
    this.router.navigate([], { queryParams: { 'candidate_category': null}, queryParamsHandling: 'merge' });
    this.getAllCandidatesList(this.page);
    this.searchForm.get('title').setValue('');
    this.searchForm.get('age').setValue('');
    this.searchForm.get('candidate_category').setValue('');
    this.searchForm.get('country').setValue('');
    this.searchForm.get('state').setValue('');
    this.searchForm.get('city').setValue('');
    this.searchForm.get('education').setValue('');
    this.searchForm.get('experience').setValue('');
  }

  showListFunction() {
    this.showBoxes = false;
    this.showList = true;
  }

  showBoxesFunction() {
    this.showList = false;
    this.showBoxes = true;
  }

}
