<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Resume</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>
                            <a routerLink="/{{redirectURL}}">Dashboard</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Resume</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- resume type -->
<div class="template-list" style="margin-top: 80px;position: relative;" *ngIf="currentUser.user_type == 2">
    <div class="container">
        <div class="flex">
            <div class="button-container">
                <button type="button" routerLink="/resume/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout1.png" width="130px"></button>
                <button type="button" routerLink="/resume-layout2/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout2.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout3/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout3.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout4/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout4.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout5/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout5.png" width="130px"></button>
            </div>
        </div>
    </div>
</div>

<!-- Resume -->
<div class="person-details-area resume-area ptb-100">
    <div class="default-layout" id="default">
        <div class="container">
            <div class="row">

                <div class="wrapper">
                    <div class="intro">
                        <div class="profile">
                            <div class="photo">
                                <img src="{{ imgPhotoUrl }}">
                            </div>
                            <div class="bio">
                                <h1 class="name">{{ resumeData.name }}</h1>
                            </div>
                        </div>
                        <div class="intro-section about">
                            <h1 class="title">about me</h1>
                            <p class="paragraph">{{ resumeData.about_me }}</p>
                        </div>
                        <div class="intro-section contact">
                            <h1 class="title">Contact</h1>
                            <div class="info-section">
                                <i class="fas fa-phone"></i>
                                <span>{{ resumeData.mobileno }}</span>
                            </div>
                            <div class="info-section">
                                <i class="fas fa-paper-plane"></i>
                                <span>{{ resumeData.emailid }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="detail">
                        <div class="detail-section edu">
                            <div class="detail-title">
                                <div class="title-icon">
                                    <i class="fas fa-user-graduate"></i>
                                </div>
                                <span>Eduation</span>
                            </div>
                            <div class="detail-content">
                                <div class="timeline-block" *ngFor="let edu of resumeData.candidate_educations">
                                    <h1>{{ edu.university.name }}</h1>
                                    <p>{{ edu.course_type.name }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="detail-section pg-skill">
                            <div class="detail-title">
                                <div class="title-icon">
                                    <i class="fas fa-laptop-code"></i>
                                </div>
                                <span>Programming skills</span>
                            </div>
                            <div class="detail-content">
                                <div class="timeline-block" *ngFor="let skills of resumeData.candidate_key_skills">
                                    <h1>{{ skills.key_skill.name }}</h1>
                                </div>
                                <!-- <ul class="pg-list">
                                    <li *ngFor="let skills of resumeData.candidate_key_skills">
                                        <span>{{ skills.key_skill.name }}</span>
                                    </li>
                                </ul> -->
                            </div>
                        </div>
                        <div class="detail-section pg-skill">
                            <div class="detail-title">
                                <div class="title-icon">
                                    <i class="fas fa-laptop-code"></i>
                                </div>
                                <span>Work Experience</span>
                            </div>
                            <div class="detail-content">
                                <div class="timeline-block" *ngFor="let exps of resumeData.candidate_experiences">
                                    <h1>{{ exps.organization }}</h1>
                                    <p>{{ exps.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- End Resume -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->