import { EmployerCompaniesService } from './../../../service/employer_companies/employer_companies.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { JobCategoryService } from '../../../service/job-category/job-category.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TokenStorageService } from '../../../service/login/token-storage.service';
import { Router } from '@angular/router';
import { CountryService } from '../../../service/country/country.service';
import { StateService } from '../../../service/state/state.service';
import { CityService } from '../../../service/city/city.service';
import { BlogService } from 'src/app/service/blog/blog.service';
import { environment } from './../../../../environments/environment';
import { AgencyService } from 'src/app/service/agency/agency.service';

declare var $: any; // Import jQuery

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  isJobCategoryLoaded: boolean = false;
  jobCategoryList: any;
  homeSearchForm: FormGroup;
  searchTerm: any;
  searchResult: any = [];
  trendingKeywords: boolean = true;
  searchCount: boolean = false;
  isLoggedIn: boolean;
  currentUser: any;
  searchForm: FormGroup;
  allCity: any;
  isCountryLoaded: boolean = false;
  isStateLoaded: boolean = false;
  isCityLoaded: boolean = false;
  countryList: any;
  stateList: any;
  cityList: any;

  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  totalItems: any;
  allBlogs: any;
  imgUrl: string;

  title: string = '';
  category: string = '';
  country: string = '';
  state: string = '';
  city: string = '';
  jobData: any;

  constructor(
    public formBuilder: FormBuilder,
    private jobCategoryService: JobCategoryService,
    private token: TokenStorageService,
    private ngZone: NgZone,
    private router: Router,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private blogService: BlogService,
    private agencyService: AgencyService
  ) {
    this.searchForm = this.formBuilder.group({
      title: [''],
      job_category: [''],
      country: [''],
      state: [''],
      city: ['']
    });
  }

  ngOnInit(): void {
    if (this.token.getToken()) {
      this.isLoggedIn = true;
      this.currentUser = this.token.getUser();
    }
    this.jobCategoryDropdown();
    this.getCountry();
    this.getBlogs();
    this.getJobsList();
    window.localStorage.removeItem('dashboardSearch');
    window.localStorage.removeItem('dashboard-search');
  }

  jobCategoryDropdown() {
    this.jobCategoryService.getJobCategoryDropdown().subscribe((res: any) => {
      this.isJobCategoryLoaded = true;
      this.jobCategoryList = res.data;
    });
  }

  searchJobs(): any {
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(this.searchForm.value));

    if (this.searchForm.get('title')?.value){
      const title = this.searchForm.get('title')?.value;
      const title_ = title.replace(/[\s\/]+/g, '-').toLowerCase();
      this.title = title_;
    }

    if (this.searchForm.get('job_category')?.value) {
      const category = this.jobCategoryList?.filter((item:any) => item.id == this.searchForm.get('job_category')?.value);
      const category_ = category[0]?.name?.replace(/[\s\/]+/g, '-').toLowerCase();
      this.category = category_;
    }
    if (this.searchForm.get('country')?.value) {
      const country = this.countryList?.filter((item:any) => item.id == this.searchForm.get('country')?.value);
      const country_ = country[0]?.name?.replace(/[\s\/]+/g, '-').toLowerCase();
      this.country = country_;
    }
    if (this.searchForm.get('state')?.value) {
      const state = this.stateList?.filter((item:any) => item.id == this.searchForm.get('state')?.value);
      const state_ = state[0]?.name?.replace(/[\s\/]+/g, '-').toLowerCase();
      this.state = state_;
    }
    if (this.searchForm.get('city')?.value) {
      const city = this.cityList?.filter((item:any) => item.id == this.searchForm.get('city')?.value);
      const city_ = city [0]?.name?.replace(/[\s\/]+/g, '-').toLowerCase();
      this.city = city_;
    }

    let url = '/search';

    if (this.title) url += `/${this.title}`;
    if (this.category) url += `/${this.category}`;
    if (this.country) url += `/${this.country}`;
    if (this.state) url += `/${this.state}`;
    if (this.city) url += `/${this.city}`;

    this.ngZone.run(() => this.router.navigateByUrl(url));
  }

  showTrendingKeywords(): any {
    this.searchCount = false;
  }

  getCountry() {
    this.countryService.getCountryDropdown().subscribe((res: any) => {
      this.isCountryLoaded = true;
      this.countryList = res.data;
    });
  }

  getState(countryId: string) {
    this.stateService.getStateListDropdown(countryId).subscribe((res: any) => {
      this.isStateLoaded = true;
      this.stateList = res.data;
    });
  }

  getCity(stateId: string) {
    this.cityService.getCityListDropdown(stateId).subscribe((res: any) => {
      this.isCityLoaded = true;
      this.cityList = res.data;
    });
  }

  gotoQatar() {
    const payload={ country:179 };
    const title = "qatar";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`));
  }

  gotoUAE() {
    const payload={ country:233 };
    const title = "uae";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`))
  }

  gotoPhilippines() {
    const payload={ country:174 };
    const title = "philippines";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`))
  }

  gotoKuwait() {
    const payload={ country:117 };
    const title = "kuwait";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`))
  }

  gotoOman() {
    const payload={ country:166 };
    const title = "oman";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`))
  }

  gotoKSA() {
    const payload={ country:194 };
    const title = "ksa";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`))
  }

  gotoBahrain() {
    const payload={ country:18 };
    const title = "bahrain";
    window.localStorage.setItem("dashboardSearch", "true")
    window.localStorage.setItem("dashboard-search", JSON.stringify(payload));
    this.ngZone.run(() => this.router.navigateByUrl(`/jobs/${title}`))
  }

  getBlogs() {
    this.blogService.getLatestBlog().subscribe((res: any) => {
      this.allBlogs = res.data.data;
      this.allBlogs.map((item:any) => {
        if (item.blog_image) {
          item.imgPhotoUrl = `${environment.apiBaseUrl}admin/blog/img/${item.blog_image}`;
        } else {
          item.imgPhotoUrl = '../../../../assets/img/default1.png';
        }
        item.blogTitle = item.blog_title.replace(/[\s\/]+/g, '-').toLowerCase();
      })
    })
  }

  getJobsList() {
    this.agencyService.getAllJobs(1,6).subscribe((res:any) => {
      this.jobData = res.data.data;
      this.jobData.map((item:any) => {
        if (item.web_user.photo) {
          item.imgPhotoUrl = `${environment.apiBaseUrl}employer/${item.web_user.photo}`;
        } else {
          item.imgPhotoUrl = '../../../../assets/img/default1.png';
        }
        item.jobTitle = item.title.replace(/[\s\/]+/g, '-').toLowerCase();
      })
    })
  }

}