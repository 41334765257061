import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CandidateDocumentService {

  REST_API: string;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}candidate/`;
    } else {
      this.REST_API = `${environment.apiurl}candidate/`;
    }
  }

  addCandidateDocument(data: any): Observable<any> {
    let API_URL = `${this.REST_API}upload-document`;
    return this.httpClient.post(API_URL, data).pipe(catchError(this.handleError))
  }

  getCandidateDocument(page: any, itemsPerPage: any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}all-document`, { params: queryParams }).pipe(catchError(this.handleError));
  }

  deleteCandidateDocument(id: any): Observable<any> {
    let API_URL = `${this.REST_API}update-document/${id}`;
    return this.httpClient.get(API_URL).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }

}
