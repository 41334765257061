<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Career Advice Details</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Career Advice Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Blog Details -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="details-item">
                    <div class="details-img">
                        <img src="{{ imgPhotoUrl }}" alt="Blog Details" loading="eager">
                    </div>
                    <h1>{{ blogDetail?.blog_title }}</h1>
                    <div [innerHTML]="blogDetail?.blog"></div>

                    <div class="details-tag" *ngIf="blogDetail?.blog_tags?.length > 0">
                        <div class="row">
                            <div class="col-lg-7">
                                <div class="left">
                                    <ul>
                                        <li>
                                            <span>Tags:</span>
                                        </li>
                                        <li *ngFor="let item of blogDetail.blog_tags; let isLast=last">
                                            <a>{{ item.tag.name }}{{isLast ? '' : ', '}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- <div class="col-lg-5">
                                <div class="right">
                                    <ul>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class='bx bxl-facebook'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class='bx bxl-twitter'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class='bx bxl-instagram'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class='bx bxl-pinterest-alt'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class='bx bxl-youtube'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="details-date">
                        <div class="row">
                            <div class="col-sm-6 col-lg-6" *ngIf="previousBlog != undefined || previousBlog != null">
                                <div class="date-inner">
                                    <a class="left-arrow" href="/career-advice/{{previousBlog?.slug}}">
                                        <i class='bx bx-left-arrow-alt'></i>
                                    </a>
                                    <div class="left-content">
                                        <span>{{ previousBlogDate }}</span>
                                        <h4>{{ previousBlog?.blog_title }}</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6" *ngIf="nextBlog != undefined || nextBlog != null">
                                <div class="date-inner">
                                    <a class="right-arrow" href="/career-advice/{{nextBlog?.slug}}">
                                        <i class='bx bx-right-arrow-alt'></i>
                                    </a>
                                    <div class="right-content">
                                        <span>{{ nextBlogDate }}</span>
                                        <h4>{{ nextBlog?.blog_title }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="details-comment">
                        <h3>Comment <span>({{blogComments?.length}}) </span></h3>
                        <ul>
                            <li *ngFor="let item of blogComments">
                                <h4>{{ item.name }}</h4>
                                <span>{{ item.created_at }}</span>
                                <p>{{ item.comment }}</p>
                                <a data-bs-toggle="modal" data-bs-target="#exampleModal" style="text-decoration: none;" *ngIf="item.parent_id == null" (click)="getReplyToId(item.id)">Reply</a>
                                <ul *ngIf="item.replies?.length > 0">
                                    <li *ngFor="let items of item.replies" class="sub-comment">
                                        <h4>{{ items.name }}</h4>
                                        <span>{{ items.created_at }}</span>
                                        <p>{{ items.comment }}</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div class="details-form">
                        <h3>Leave A Comment</h3>
                        <form [formGroup]="commentForm" (ngSubmit)="addComment()">
                            <div class="form-group form-check" *ngIf="isLoggedIn == false">
                                <input type="checkbox" class="form-check-input" id="exampleCheck59" formControlName="is_saved">
                                <label class="form-check-label" for="exampleCheck59">Save my name, email, and website in
                                    this browser for the next time I comment.</label>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" class="form-control" placeholder="Name*" formControlName="name">
                                        <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                                            <div *ngIf="f.name.errors?.required">
                                                Name is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class='bx bx-mail-send'></i>
                                        <input type="email" class="form-control" placeholder="Email*" formControlName="email_id">
                                        <div *ngIf="f.email_id.invalid && (f.email_id.dirty || f.email_id.touched)"
                                            class="alert alert-danger">
                                            <div *ngIf="f.email_id.errors?.required">
                                                Email Id is required.
                                            </div>
                                            <div *ngIf="f.email_id.errors?.email">
                                                Email must be a valid email address
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <i class='bx bx-mail-send'></i>
                                        <textarea id="your-message" rows="10" class="form-control" placeholder="Write message" formControlName="comment"></textarea>
                                        <div *ngIf="f.comment.invalid && (f.comment.dirty || f.comment.touched)" class="alert alert-danger">
                                            <div *ngIf="f.comment.errors?.minlength || f.comment.errors?.required">
                                                Comment cannot be blank.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <button type="submit" class="btn" [disabled]="!commentForm.valid">Post A Comment</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="widget-area">
                    <div class="cat widget-item">
                        <h3>Categories</h3>

                        <ul *ngFor="let item of count">
                            <li><a style="text-decoration: none;" routerLink="/career-advice">{{ item.categoryName }} <span>{{ item.blogCount }}</span></a></li>
                        </ul>
                    </div>

                    <div class="recent widget-item">
                        <h3>Recent Posts</h3>

                        <div class="recent-inner" *ngFor="let item of allBlog">
                            <a href="/career-advice/{{ item?.slug }}">
                                <ul>
                                    <li><img src="{{ item.recentBlogUrl }}" alt="Details"></li>
                                    <li>
                                        <span>{{ item.blogDate }}</span>
                                        <span class="recent-blog-title">{{ item.blog_title }}</span>
                                    </li>
                                </ul>
                            </a>
                        </div>
                    </div>

                    <div class="tags widget-item" *ngIf="blogDetail?.blog_tags?.length > 0">
                        <h3>Tags</h3>

                        <ul>
                            <li *ngFor="let item of blogDetail.blog_tags; let isLast=last"><a>{{ item.tag.name }}{{isLast ? '' : ', '}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Details -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
          <div class="row">
              <div class="col-lg-12 col-md-12">
                  <div class="popup-detail-form">
                    <div class="col-lg-12 col-md-12 d-flex justify-content-between">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Leave a Comment</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form [formGroup]="commentForm" (ngSubmit)="addComment()">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <i class='bx bx-user'></i>
                                    <input type="text" class="form-control" placeholder="Name*" formControlName="name">
                                    <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                                        <div *ngIf="f.name.errors?.required">
                                            Name is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <i class='bx bx-mail-send'></i>
                                    <input type="email" class="form-control" placeholder="Email*"
                                        formControlName="email_id">
                                    <div *ngIf="f.email_id.invalid && (f.email_id.dirty || f.email_id.touched)"
                                        class="alert alert-danger">
                                        <div *ngIf="f.email_id.errors?.required">
                                            Email Id is required.
                                        </div>
                                        <div *ngIf="f.email_id.errors?.email">
                                            Email must be a valid email address
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <i class='bx bx-mail-send'></i>
                                    <textarea id="your-message" rows="10" class="form-control" placeholder="Write message" formControlName="comment"></textarea>
                                    <div *ngIf="f.comment.invalid && (f.comment.dirty || f.comment.touched)" class="alert alert-danger">
                                        <div *ngIf="f.comment.errors?.minlength || f.comment.errors?.required">
                                            Comment cannot be blank.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="modal-footer d-flex justify-content-around" style="margin-bottom: -30px;">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" [disabled]="!commentForm.valid">Post a Comment</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>


<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->