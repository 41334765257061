import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TokenStorageService } from '../../../service/login/token-storage.service';
import { EmployerCompaniesService } from '../../../service/employer_companies/employer_companies.service';
import { Router } from '@angular/router';
import { CountryService } from '../../../service/country/country.service';
import { StateService } from 'src/app/service/state/state.service';
import { CityService } from 'src/app/service/city/city.service';

@Component({
  selector: 'app-company-registration-form',
  templateUrl: './company-registration-form.component.html',
  styleUrls: ['./company-registration-form.component.scss']
})
export class CompanyRegistrationFormComponent implements OnInit{
  employerCompaniesForm: FormGroup
  saveSucess: boolean;
  error: boolean;
  successMessage: string;
  errorMessage: any;
  submitTypeToggle: Number = 0;
  isLoggedIn: boolean;
  currentUser: any;
  companyName: any;
  isCountryLoaded: boolean;
  countryList: any;
  stateList: any;
  cityList: any;

  constructor( 
    public formBuilder: FormBuilder,
    private token: TokenStorageService,
    private ngZone: NgZone,
    private employerCompaniesService: EmployerCompaniesService,
    private router: Router,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService
    ) 
    {
      if (this.token.getToken()) {
        this.isLoggedIn = true;
        this.currentUser = this.token.getUser();
      }

      this.employerCompaniesForm = this.formBuilder.group({
        company_name: ['', Validators.required],
        emailid: ['', [Validators.required, Validators.email]],
        dial_code: ['', Validators.required],
        mobileno: ['', Validators.required],
        website_url: ['', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
        company_dial_code: ['', Validators.required],
        company_contactno: ['', 
        [
          Validators.required,
          Validators.pattern("/^-?(0|[1-9]\d*)?$/"),
          Validators.pattern(/$/)
        ]],
        about_company: [''],
        company_mission: [''],
        company_vision: [''],
        country_id: ['', Validators.required],
        state_id: ['', Validators.required],
        city_id: ['', Validators.required],
        status: ['', Validators.required],
        employer_name: [''],
        employer_emailid: ['', Validators.email],
        employer_dial_code: [''],
        employer_mobileno: ['',
        [
          Validators.pattern("/^-?(0|[1-9]\d*)?$/"),
          Validators.pattern(/$/)
        ]],
        employer_designation: [''],
        employer_department: [''],
      });

      this.getState(`${this.currentUser.country_id?.toString()}: ${this.currentUser.country_id?.toString()}`)
      this.getCity(`${this.currentUser.state_id?.toString()}: ${this.currentUser.state_id?.toString()}`);

      this.employerCompaniesForm.setValue({
        company_name: this.currentUser.first_name + ' ' + this.currentUser.last_name,
        emailid: this.currentUser.emailid || '',
        dial_code: this.currentUser.dial_code || '',
        mobileno: this.currentUser.mobileno || '',
        website_url: this.currentUser.website_url || '',
        company_dial_code: this.currentUser.company_dial_code || '',
        company_contactno: this.currentUser.company_contactno || '',
        about_company: this.currentUser.about_company || '',
        company_mission: this.currentUser.company_mission || '',
        company_vision: this.currentUser.company_vision || '',
        country_id: this.currentUser?.country_id || '',
        state_id: this.currentUser?.state_id || '',
        city_id: this.currentUser?.city_id || '',
        status: this.currentUser.status || '',
        employer_name: this.currentUser.employer_name || '',
        employer_emailid: this.currentUser.employer_emailid || '',
        employer_dial_code: this.currentUser.employer_dial_code || '',
        employer_mobileno: this.currentUser.employer_mobileno || '',
        employer_designation: this.currentUser.employer_designation || '',
        employer_department: this.currentUser.employer_department || '',
      });

      this.countryService.getCountryDropdown().subscribe((res: any) => {
        this.isCountryLoaded = true;
        this.countryList = res.data;
      });
  }

  get fComp() {
    return this.employerCompaniesForm.controls;
  }

  ngOnInit(): void {
    this.employerCompaniesForm.get('mobileno').valueChanges.subscribe(value => {
      const dialCode = this.employerCompaniesForm.get('dial_code')?.value;
      if (this.isCountryLoaded == true) {
        const numberLength = this.countryList.filter((item:any) => { return item.id == dialCode });
        const numberLength_ = JSON.parse(numberLength[0].number_length);
        const validPattern = /^[0-9]*$/.test(value);
        if(value.length == 0){
          this.employerCompaniesForm.get('mobileno').setErrors({ required: true, 'invalidMobileNumber': false, pattern: false });
        } else if(!validPattern){
          this.employerCompaniesForm.get('mobileno').setErrors({ 'invalidMobileNumber': false, pattern: true, required: false });
        } else if (!numberLength_.includes(value.length)) {
            this.employerCompaniesForm.get('mobileno').setErrors({ 'invalidMobileNumber': true, pattern: false, required: false })
        } else {
          this.employerCompaniesForm.get('mobileno').setErrors(null);
        }
      }
    });
  }

  setMobileNumberValidation(id:any) {
    this.employerCompaniesForm.get('company_contactno').valueChanges.subscribe(value => {
      if (this.isCountryLoaded == true) {
        const numberLength = this.countryList.filter((item:any) => { return item.id == id });
        const numberLength_ = JSON.parse(numberLength[0].number_length);
        const validPattern = /^[0-9]*$/.test(value);
        if (value.length == 0) {
          this.employerCompaniesForm.get('company_contactno').setErrors({ required: true, 'invalidCompanyMobileNumber': false, pattern: false });
        } else if(!validPattern){
          this.employerCompaniesForm.get('company_contactno').setErrors({ 'invalidCompanyMobileNumber': false, pattern: true, required: false });
        } else if (!numberLength_.includes(value.length)) {
          this.employerCompaniesForm.get('company_contactno').setErrors({ 'invalidCompanyMobileNumber':true, pattern: false, required: false })
        } else {
          this.employerCompaniesForm.get('company_contactno').setErrors(null);
        }
      }
    });
  }

  setEmpMobileNumberValidation(id:any) {
    this.employerCompaniesForm.get('employer_mobileno').valueChanges.subscribe(value => {
      if (this.isCountryLoaded == true) {
        const numberLength = this.countryList.filter((item:any) => { return item.id == id });
        const numberLength_ = JSON.parse(numberLength[0].number_length);
        const validPattern = /^[0-9]*$/.test(value);
        if (value.length == 0) {
          this.employerCompaniesForm.get('company_contactno').setErrors({ required: true, 'invalidEmpMobileNumber': false, pattern: false });
        } else if(!validPattern){
          this.employerCompaniesForm.get('employer_mobileno').setErrors({ 'invalidEmpMobileNumber': false, pattern: true, required: false });
        } else if (!numberLength_.includes(value.length)) {
          this.employerCompaniesForm.get('employer_mobileno').setErrors({ 'invalidEmpMobileNumber':true, pattern: false, required: false })
        } else {
          this.employerCompaniesForm.get('employer_mobileno').setErrors(null);
        }
      }
    });
  }

  employerCompaniesSubmit(): any {
    const activeStatus = this.employerCompaniesForm.get('status').value == 1 ? 1 : 0;
    this.employerCompaniesForm.controls['status'].setValue(activeStatus.toString());
    this.employerCompaniesService.AddEmployerCompanies(this.employerCompaniesForm.value)
      .subscribe(() => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = "Company Added Successfully";
        this.employerCompaniesForm.reset();
        this.ngZone.run(() => this.router.navigateByUrl('/employer-dashboard'))
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
  }

  countryDropdown() {
    this.countryService.getCountryDropdown().subscribe((res: any) => {
      this.isCountryLoaded = true;
      this.countryList = res.data;
    });
  }

  getState(countryId: string) {
    const toArray = countryId.split(": ");
    const country_id = toArray[1];
    this.stateService.getStateListDropdown(country_id).subscribe((res: any) => {
      this.stateList = res.data;
    });
  }

  getCity(stateId: string) {
    const toArray = stateId.split(": ");
    const state_id = toArray[1];
    this.cityService.getCityListDropdown(state_id).subscribe((res: any) => {
      this.cityList = res.data;
    });
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.saveSucess = false;
    }, 5000);
  }

  logout(): void {
    this.token.signOut();
    this.ngZone.run(() => this.router.navigateByUrl('/login'))
  }

}
