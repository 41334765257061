<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Dashboard</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Dashboard</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Dashboard -->
<div class="dashboard-area ptb-100">
    <div class="container">
        <div *ngIf="saveSucess" class="alert alert-success">
            <strong [class]="FadeOutSuccessMsg()">{{ successMessage }}</strong>
        </div>
        <div *ngIf="error" class="alert alert-danger">
            {{ errorMessage }}
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-12">

                <div class="profile-item">
                    <div class="image-container">
                        <img src="{{ imgPhotoUrl }}" onerror="this.src='../../../../assets/img/default1.png'" alt="Profile" class="actual-image">
                    </div>
                </div>

                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
    
                    <a [ngClass]="isMyJobs == false || isMyJobs == undefined ? 'nav-link active' : 'nav-link'"
                        id="my-dashboard-tab" data-bs-toggle="pill" href="#my-dashboard" role="tab"
                        aria-controls="my-dashboard" [attr.aria-selected]="isMyJobs ? 'false' : 'true'"><i
                            class='bx bx-user'></i>Dashboard</a>
    
                    <a class="nav-link" id="v-pills-home-tab" data-bs-toggle="pill" href="#v-pills-home" role="tab"
                        aria-controls="v-pills-home" aria-selected="false"><i class='bx bx-user'></i> My Profile</a>
    
                    <a *ngIf="currentUser?.admin_id == 0 || currentUser?.admin_id == null || currentUser?.permissions[0]?.show_company == 1"
                        class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" href="#v-pills-messages" role="tab"
                        aria-controls="v-pills-messages" aria-selected="false">
                        <div class="profile-list"> <i class='bx bxs-inbox'></i> Company Details </div>
                    </a>
    
                    <a *ngIf="currentUser?.admin_id == 0 || currentUser?.admin_id == null || currentUser?.permissions[0]?.show_job == 1"
                        [ngClass]="isMyJobs == true ? 'nav-link active' : 'nav-link'" id="v-pills-job-tab"
                        data-bs-toggle="pill" href="#v-pills-jobs" role="tab" aria-controls="v-pills-my-jobs"
                        [attr.aria-selected]="isMyJobs ? 'true' : 'false'">
                        <div class="profile-list"> <i class='bx bxs-inbox'></i> My Jobs </div>
                    </a>
    
                    <a *ngIf="currentUser?.admin_id == 0 || currentUser?.admin_id == null" class="nav-link"
                        id="my-users-tab" data-bs-toggle="pill" href="#my-users" role="tab" aria-controls="my-users"
                        aria-selected="false">
                        <div class="profile-list"><i class='bx bxs-inbox'></i> My Users </div>
                    </a>
    
                    <a class="nav-link" id="my-password-tab" data-bs-toggle="pill" href="#my-password" role="tab"
                        aria-controls="my-password" aria-selected="false">
                        <div class="profile-list"><i class='bx bxs-inbox'></i> Change Password </div>
                    </a>
    
                    <a style="cursor:pointer;" (click)="logout()">
                        <div class="profile-list"><i class='bx bx-log-out'></i> Logout</div>
                    </a>
                </div>

            </div>

            <div class="col-lg-9 col-md-12">
                <div class="tab-content" id="v-pills-tabContent">
    
                    <!-- Dashboard -->
                    <div [ngClass]="isMyJobs == false || isMyJobs == undefined ? 'tab-pane fade show active' : 'tab-pane fade'"
                        id="my-dashboard" role="tabpanel" aria-labelledby="my-dashboard-tab">
                        <div class="profile-content">
                            <div class="profile-content-inner">
    
                                <h2>Dashboard</h2>
    
                                <div *ngIf="isPermission == false && currentUser.is_sub_user == 1">
                                    <div class="card d-flex justify-content-center" style="margin-bottom: 20px">
                                        <div class="card-body">
                                            <span style="color:red">
                                                Your Account Does Not Have Appropriate Permissions. Kindly Contact Your
                                                Admin For Permissions.
                                            </span>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="dashboard-row">
    
                                    <!-- <div class="card" style="width: 15rem;">
                                            <div class="card-body">
                                                <h5 class="card-title" style="font-size: 20px;">Profile Completed</h5>
                                                <circle-progress 
                                                    [percent]="profileCompleted" 
                                                    [radius]= "80"
                                                    [unitsFontSize]= "27"
                                                    [unitsFontWeight]= "600"
                                                    [outerStrokeColor]="'#fe4a55'" 
                                                    [outerStrokeWidth]= "8"
                                                    [titleFontSize]= "27"
                                                    [titleFontWeight]= "600"
                                                    [subtitleFontSize]= "15"
                                                    [subtitleFontWeight]= "400"
                                                    [showInnerStroke]="false"
                                                    [startFromZero]= false
                                                    [animation]="true" 
                                                    [animationDuration]="1000"
                                                >
                                                </circle-progress>
                                            </div>
                                        </div> -->
    
                                    <div class="card dash-card">
                                        <div class="card-body" style="height: 230px;">
                                            <h5 class="card-title" style="font-size: 20px;">Total Jobs</h5>
                                            <span style="top: 80px; font-size: 40px; position: relative;"> {{ totalJobItems
                                                }} </span>
                                        </div>
                                    </div>
    
                                    <div class="card dash-card">
                                        <div class="card-body" style="height: 230px;">
                                            <h5 class="card-title" style="font-size: 20px;">Total Active Jobs</h5>
                                            <span style="top: 80px; font-size: 40px; position: relative;"> {{
                                                totalPublishedJobs }} </span>
                                        </div>
                                    </div>
    
                                    <div class="card dash-card">
                                        <div class="card-body" style="height: 230px;">
                                            <h5 class="card-title" style="font-size: 20px;">Total In-Active Jobs</h5>
                                            <span style="top: 80px; font-size: 40px; position: relative;"> {{ totalDraftJobs
                                                }} </span>
                                        </div>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!-- Profile -->
                    <div class="tab-pane fade" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
    
                        <div class="profile-content" *ngIf="showProfile">
                            <form [formGroup]="employerProfileForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
                                <div class="profile-content-inner">
                                    <h2>Basic Info</h2>
    
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>First Name: <span class="text-danger">*</span></label>
                                                <input class="form-control" type="text" formControlName="first_name"
                                                    placeholder="First Name">
                                                <div *ngIf="f.first_name.invalid && (f.first_name.dirty || f.first_name.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.first_name.errors?.required">
                                                        First Name is required.
                                                    </div>
                                                    <div *ngIf="f.first_name.errors?.pattern">
                                                        Only Alphabets are Allowed.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Last Name: <span class="text-danger">*</span></label>
                                                <input class="form-control" type="text" formControlName="last_name"
                                                    placeholder="Last Name">
                                                <div *ngIf="f.last_name.invalid && (f.last_name.dirty || f.last_name.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.last_name.errors?.required">
                                                        Last Name is required.
                                                    </div>
                                                    <div *ngIf="f.last_name.errors?.pattern">
                                                        Only Alphabets are Allowed.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Organization Name:</label>
                                                <input class="form-control" type="text" formControlName="organization_name"
                                                    placeholder="Enter Organization Name">
                                            </div>
                                        </div>
    
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Email Id: <span class="text-danger">*</span></label>
                                                <input class="form-control" readonly type="email" formControlName="emailid"
                                                    placeholder="Email Id">
                                                <div *ngIf="f.emailid.invalid && (f.emailid.dirty || f.emailid.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.emailid.errors?.required">
                                                        Email Id is required.
                                                    </div>
                                                    <div *ngIf="f.emailid.errors?.email">
                                                        Email must be a valid email address
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-lg-2 col-md-12">
                                            <div class="form-group">
                                                <label>Dial Code: <span class="text-danger">*</span></label>
                                                <select class="form-control" *ngIf="isCountryLoaded"
                                                    formControlName="dial_code">
                                                    <option value="">Select Code</option>
                                                    <option *ngFor="let item of dialCodeList" [value]="item.id.toString()">
                                                        {{ item.name }}({{ item.dial_code }})
                                                    </option>
                                                </select>
                                                <div *ngIf="f.dial_code.invalid && (f.dial_code.dirty || f.dial_code.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.dial_code.errors?.required">
                                                        This is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-lg-4 col-md-12">
                                            <div class="form-group">
                                                <label>Mobile No.: <span class="text-danger">*</span></label>
                                                <input class="form-control" type="text" formControlName="mobileno"
                                                    placeholder="Mobile No.">
                                                <div *ngIf="f.mobileno.invalid && (f.mobileno.dirty || f.mobileno.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.mobileno.errors?.required">
                                                        Mobile No is required.
                                                    </div>
                                                    <div *ngIf="f.mobileno.errors?.pattern">
                                                        Only Numbers are Allowed.
                                                    </div>
                                                    <div
                                                        *ngIf="this.employerProfileForm.get('mobileno').errors?.invalidMobileNumber">
                                                        Enter a valid mobile number.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Profile Photo:</label>
                                                <input class="form-control" type="file" (change)="fileChangeEvent($event)">
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12" *ngIf="showEditImage">
                                            <div class="form-group cropper-wrapper">
                                                <image-cropper [imageChangedEvent]="imageChangedEvent"
                                                    [maintainAspectRatio]="true" [aspectRatio]="1/1"
                                                    [resizeToWidth]="128" format="png"
                                                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                                    (cropperReady)="cropperReady()"
                                                    (loadImageFailed)="loadImageFailed()">
                                                </image-cropper>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
    
                                <button type="submit" class="btn dashboard-btn"
                                    [disabled]="!employerProfileForm.valid">Update</button>
                            </form>
                        </div>
    
                    </div>
    
                    <!-- Company details -->
                    <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
    
                        <div class="profile-content" *ngIf="showEmployerCompaniesList">
                            <div class="profile-content-inner">
                                <h2>Company Detail</h2>
    
                                <div class="row">
                                    <!-- <div class="col-lg-4 col-md-4">
                                            <button class="btn btn-sm btn-primary" style="right: -570px; position: relative; top: -15px;" (click)="addEmployerCompanies()" *ngIf="EmployerCompanies.length == 0">Add New</button>
                                        </div> -->
                                    <div class="col-lg-12 col-md-12">
                                        <ng-template #no_data>
                                            No Company Added Yet!!!
                                        </ng-template>
    
                                        <div class="table-responsive" *ngIf="EmployerCompanies.length > 0;else no_data">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Company</th>
                                                        <th scope="col">Contact Details</th>
                                                        <th scope="col">Website Url</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col"
                                                            *ngIf="currentUser?.admin_id == 0 || currentUser?.admin_id == null || currentUser?.permissions[0]?.show_edit_company == 1 || userData.permissions[0]?.show_delete_company == 1">
                                                            Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let rows of EmployerCompanies | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }; let i = index">
                                                        <td>{{ rows.company_name }}</td>
                                                        <td>
                                                            {{ rows.emailid }}<br />
                                                            {{ rows.mobileno }}<br />
                                                            {{ rows.company_contactno }}
                                                        </td>
                                                        <td>{{ rows.website_url }}</td>
                                                        <td>{{ rows.status==1?'Active':'In-Active' }}
                                                        </td>
                                                        <td class="text-center">
                                                            <!-- routerLink="/company-edit-form/{{rows.id}}" -->
                                                            <button class="btn btn-sm btn-primary"
                                                                *ngIf="currentUser?.admin_id == 0 || currentUser?.admin_id == null || currentUser?.permissions[0]?.show_edit_company == 1"
                                                                (click)="navigateToEditCompany()">Edit</button>
                                                            <button class="btn btn-sm btn-danger"
                                                                *ngIf="currentUser?.admin_id == 0 || currentUser?.admin_id == null || currentUser?.permissions[0]?.show_delete_company == 1"
                                                                (click)="deleteEmployerCompanies(rows.web_users_id, i)">Delete</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <pagination-controls class="pagi"
                                                (pageChange)="employerCompaniesListData((page = $event))"
                                                (directionLinks)="true" (responsive)="true"></pagination-controls>
    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
    
                    <!-- Jobs -->
                    <div [ngClass]="isMyJobs == true ? 'tab-pane fade show active' : 'tab-pane fade'" id="v-pills-jobs"
                        role="tabpanel" aria-labelledby="v-pills-job-tab">
    
                        <div class="profile-content" *ngIf="showJobList">
                            <div class="profile-content-inner">
                                <h2>My Jobs</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4"
                                        *ngIf="currentUser?.admin_id == 0 || currentUser?.admin_id == null || currentUser?.permissions[0]?.show_add_job == 1">
                                        <button class="btn btn-sm btn-primary add-view-btn" routerLink="/post-a-job">Add
                                            Job</button>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <ng-template #no_data>
                                            No Job Posted Yet!!!
                                        </ng-template>
                                        <div class="table-responsive" *ngIf="EmployerJobs.length > 0;else no_data">
    
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Location</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col"
                                                            *ngIf="currentUser?.admin_id == 0 || currentUser?.admin_id == null || currentUser?.permissions[0]?.show_edit_job == 1 || currentUser?.permissions[0]?.show_delete_job == 1">
                                                            Actions</th>
                                                        <th scope="col"
                                                            *ngIf="currentUser?.admin_id == 0 || currentUser?.admin_id == null || currentUser?.permissions[0]?.show_candidate == 1">
                                                            Candidates Applied</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let rows of EmployerJobs | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }; let i = index"
                                                        style="cursor: pointer">
                                                        <td (click)="navigateToJobDetails(rows.id)">{{ rows.title }}</td>
    
                                                        <td (click)="navigateToJobDetails(rows.id)">
                                                            {{ rows.city.name }}<br />
                                                            {{ rows.state.name }}<br />
                                                            {{ rows.country.name }}
                                                        </td>
    
                                                        <td (click)="navigateToJobDetails(rows.id)">{{ rows.job_description
                                                            }}</td>
    
                                                        <td class="text-center">
                                                            <span class="btn btn-outline-dark rounded mr-2"
                                                                *ngIf="rows.submit_type==0">Not Published</span>
                                                            <span class="btn btn-outline-dark rounded mr-2"
                                                                *ngIf="rows.submit_type==1">Published</span>
                                                        </td>
    
                                                        <!-- <td class="text-center">
                                                                <button class="btn btn-outline-dark rounded mr-2" (click)="navigateToJobDetails(rows.id)" *ngIf="rows.submit_type==1 && (currentUser?.admin_id == 0 || currentUser?.permissions[0]?.show_job_detail == 1)"><i class="bx bx-show"></i></button>
                                                                <button class="btn btn-outline-dark rounded mr-2" *ngIf="rows.submit_type==0 && (currentUser?.admin_id == 0 || currentUser?.permissions[0]?.show_job_detail == 1)"><i class="bx bx-hide"></i></button>
                                                            </td> -->
    
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-primary"
                                                                routerLink="/edit-job/{{ rows.id }}"
                                                                *ngIf="rows.submit_type == 0 && (currentUser?.admin_id == 0 || currentUser?.admin_id == null || currentUser?.permissions[0]?.show_edit_job == 1)">Edit</button>
                                                            <button class="btn btn-sm btn-danger"
                                                                *ngIf="currentUser?.admin_id == 0 || currentUser?.admin_id == null || currentUser?.permissions[0]?.show_delete_job == 1"
                                                                (click)="deleteJobs(rows.id, i)">Delete</button>
                                                        </td>
    
                                                        <td class="text-center"
                                                            *ngIf="currentUser?.admin_id == 0 || currentUser?.admin_id == null || currentUser?.permissions[0]?.show_candidate == 1">
                                                            <button class="btn btn-sm btn-primary"
                                                                *ngIf="rows.candidate_applied_jobs.length > 0"
                                                                routerLink="/applied-candidates/{{rows.id}}">View
                                                                List</button>
                                                            <button class="btn btn-sm btn-primary"
                                                                *ngIf="rows.candidate_applied_jobs.length <= 0">N/A</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
    
                                            <!-- <div class="employer-item" *ngFor="let rows of EmployerJobs
                                                    | paginate : {
                                                    itemsPerPage: itemsPerPage,
                                                    currentPage: page,
                                                    totalItems: totalItems
                                                    };
                                                    let i = index">
                                                    <a routerLink="/job-details/{{ rows.id }}">
                                                        <img src="{{ apiBaseUrl }}employer/{{ rows.web_user.photo }}" width="70" alt="Employer">
                                                        <h3>{{ rows.title }}</h3>
                                                        <ul>
                                                            <li><i class="flaticon-send"></i> {{ rows.city.name }}, {{ rows.state.name }},
                                                                {{ rows.country.name }}</li>
                                                            <li>{{ rows.createdAt | dateAgo }}</li>
                                                        </ul>
                                                        <p>{{ rows.job_description }}</p>
                                                    </a>
                                
                                                    <span *ngIf="rows.status==0 && rows.submit_type==2" class="btn btn-sm btn-outline-warning rounded mr-2">Pending</span>
                                                    <span *ngIf="rows.status==1 && rows.submit_type==2" class="btn btn-sm btn-outline-success rounded mr-2">Approved</span>
                                                    <span *ngIf="rows.status==2 && rows.submit_type==2" class="btn btn-sm btn-outline-danger rounded mr-2">Rejected</span>
                                                    &nbsp;
                                                    <span *ngIf="rows.submit_type==0" class="btn btn-sm btn-outline-danger rounded mr-2">Not
                                                        Published</span>
                                                    <span *ngIf="rows.submit_type==1"
                                                        class="btn btn-sm btn-outline-success rounded mr-2">Published</span>
                                                    <span *ngIf="rows.candidate_applied_jobs.length > 0 && rows.submit_type==1"
                                                        class="btn btn-sm btn-outline-success rounded mr-2" (click)="viewAppliedCandidates()">{{ rows.candidate_applied_jobs.length }} Candidates Applied</span>
                                
                                                    <span class="span-two">
                                                        <span routerLink="/edit-job/{{ rows.id }}" *ngIf="rows.submit_type==0">Edit | </span>
                                                        <span (click)="deleteJobs(rows.id, i)">Delete</span>
                                                    </span>
                                
                                                </div> -->
    
                                            <div class="pagination-area">
                                                <pagination-controls class="pagi"
                                                    (pageChange)="employerJobsListData((page = $event))"
                                                    (directionLinks)="true" (responsive)="true"></pagination-controls>
                                            </div>
                                            <!--<div class="pagination-area">
                                                    <ul>
                                                        <li><a routerLink="/jobs">Prev</a></li>
                                                        <li><a routerLink="/jobs">1</a></li>
                                                        <li><a routerLink="/jobs">2</a></li>
                                                        <li><a routerLink="/jobs">3</a></li>
                                                        <li><a routerLink="/jobs">Next</a></li>
                                                    </ul>
                                                </div>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="profile-content" *ngIf="showCandidiateList">
                            <div class="profile-content-inner">
                                <h2>Applied Candidated</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn" (click)="viewJobList()">Job
                                            List</button>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
    
                                        <div class="table-responsive" *ngIf="EmployerJobs.length > 0;else no_data">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Contact Details</th>
                                                        <th scope="col">Total Experience</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody
                                                    *ngFor="let rows of EmployerJobs | paginate : { itemsPerPage: itemsPerPage,currentPage: page,totalItems: totalItems }; let i = index">
                                                    <tr *ngFor="let row of rows.candidate_applied_jobs">
                                                        <td>{{ row.web_user.name }}</td>
                                                        <td>
                                                            {{ row.web_user.mobileno }}<br />
                                                            {{ row.web_user.emailid }}
                                                        </td>
                                                        <td>{{
                                                            row.web_user.total_experience?row.web_user.total_experience:'-'
                                                            }}</td>
                                                        <td>{{ row.web_user.status==1?'Active':'In-Active' }}
                                                        </td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-primary"
                                                                routerLink="/profile/{{row.web_user.id}}">View
                                                                Profile</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <pagination-controls class="pagi"
                                                (pageChange)="employerCompaniesListData((page = $event))"
                                                (directionLinks)="true" (responsive)="true"></pagination-controls>
    
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
    
                    <!-- Users -->
                    <div class="tab-pane fade" id="my-users" role="tabpanel" aria-labelledby="my-users-tab">
    
                        <div class="profile-content" *ngIf="showUsersList">
                            <div class="profile-content-inner">
                                <h2>My Users</h2>
    
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn" (click)="addUser()">Add New
                                            User</button>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <ng-template #no_data>
                                            No data found!!!
                                        </ng-template>
                                        <div class="table-responsive" *ngIf="newUserData != 0;else no_data">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Email Id</th>
                                                        <th scope="col">Mobile No</th>
                                                        <th scope="col">Permissions</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let rows of newUserData | paginate : { itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems }; let i = index">
                                                        <td>{{ rows.first_name }} {{ rows.last_name }}</td>
                                                        <td>{{ rows.emailid }}</td>
                                                        <td>{{ rows.mobileno }}</td>
                                                        <td>
                                                            <button type="button" class="btn btn-primary"
                                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                (click)="getIds(rows.id, rows.admin_id); getPermission()">Give
                                                                permissions</button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-primary"
                                                                (click)="editUser(rows.id)">Edit</button>
                                                            <button class="btn btn-sm btn-danger"
                                                                (click)="deleteUser(rows.id, i)">Delete</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <pagination-controls class="pagi" (pageChange)="getUserList(page = $event)"
                                                (directionLinks)="true" (responsive)="true"></pagination-controls>
    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="profile-content" *ngIf="showUsersAdd">
                            <div class="profile-content-inner">
                                <h2>Add User</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn" (click)="viewUserList()">View
                                            All</button>
                                    </div>
                                    <div class="col-lg-12 col-md-4">
                                        <form class="form-inline" [formGroup]="addUserForm" (ngSubmit)="submitUser()">
    
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>First Name: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" formControlName="first_name"
                                                            placeholder="Enter First Name">
                                                        <div *ngIf="fUser.first_name.invalid && (fUser.first_name.dirty || fUser.first_name.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fUser.first_name.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fUser.first_name.errors?.pattern">
                                                                Only alphabets are allowed.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Last Name: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" formControlName="last_name"
                                                            placeholder="Enter Last Name">
                                                        <div *ngIf="fUser.last_name.invalid && (fUser.last_name.dirty || fUser.last_name.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fUser.last_name.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fUser.last_name.errors?.pattern">
                                                                Only alphabets are allowed.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Username: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" formControlName="username"
                                                            placeholder="Enter Username">
                                                        <div *ngIf="fUser.username.invalid && (fUser.username.dirty || fUser.username.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fUser.username.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Email Id: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="email" formControlName="emailid"
                                                            placeholder="Enter Email Id">
                                                        <div *ngIf="fUser.emailid.invalid && (fUser.emailid.dirty || fUser.emailid.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fUser.emailid.errors?.required">
                                                                Email Id is required.
                                                            </div>
                                                            <div *ngIf="fUser.emailid.errors?.email">
                                                                Email must be a valid email address
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group total-working-div">
                                                        <label>Password: <span class="text-danger">*</span></label>
                                                        <input class="form-control" [type]="show ? 'text' : 'password'"
                                                            formControlName="password" placeholder="Enter Password">
                                                        <button class="adduser-pass" type="button" (click)="showpassword()">
                                                            <i class="bx bx-lock" aria-hidden="true" *ngIf="!show"></i>
                                                            <i class="bx bx-lock-open" aria-hidden="true" *ngIf="show"></i>
                                                        </button>
                                                        <div *ngIf="fUser.password.invalid && (fUser.password.dirty || fUser.password.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fUser.password.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fUser.password.errors?.pattern">
                                                                Password Must Contain Atleast 8 Characters, 1 Uppercase
                                                                Letter, 1 Lowercase Letter, 1 Numeric & 1 Special Character.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="col-lg-2 col-md-12">
                                                    <div class="form-group">
                                                        <label>Dial Code: <span class="text-danger">*</span></label>
                                                        <select class="form-control" *ngIf="isCountryLoaded"
                                                            formControlName="dial_code"
                                                            (change)="setMobileNumberValidation($event.target.value)">
                                                            <option value="" selected>Select Code</option>
                                                            <option *ngFor="let item of dialCodeList"
                                                                [value]="item.id.toString()">{{ item.name }}({{
                                                                item.dial_code }})
                                                            </option>
                                                        </select>
                                                        <div *ngIf="fUser.dial_code.invalid && (fUser.dial_code.dirty || fUser.dial_code.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fUser.dial_code.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="col-lg-4 col-md-12">
                                                    <div class="form-group">
                                                        <label>Mobile Number: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" formControlName="mobileno"
                                                            placeholder="Enter Mobile Number">
                                                        <div *ngIf="fUser.mobileno.invalid && (fUser.mobileno.dirty || fUser.mobileno.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="fUser.mobileno.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="fUser.mobileno.errors?.pattern">
                                                                Only numbers are allowed.
                                                            </div>
                                                            <div
                                                                *ngIf="this.addUserForm.get('mobileno').errors?.invalidAddUserMobileNumber">
                                                                Enter a Valid Mobile Number.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="form-group">
                                                    <label>Organization Name:</label>
                                                    <input class="form-control" type="text"
                                                        formControlName="organization_name"
                                                        placeholder="Enter Organization Name">
                                                </div>
                                            </div>
    
                                            <button type="submit" class="btn dashboard-btn"
                                                [disabled]="!addUserForm.valid">Save</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="profile-content" *ngIf="showUsersEdit">
                            <div class="profile-content-inner">
                                <h2>Edit User</h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <button class="btn btn-sm btn-primary add-view-btn" (click)="viewUserList()">View
                                            All</button>
                                    </div>
                                    <div class="col-lg-12 col-md-4">
                                        <form [formGroup]="editUserForm" (ngSubmit)="updateUser()">
    
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>First Name: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" formControlName="first_name"
                                                            placeholder="Enter First Name">
                                                        <div *ngIf="eUser.first_name.invalid && (eUser.first_name.dirty || eUser.first_name.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="eUser.first_name.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="eUser.first_name.errors?.pattern">
                                                                Only alphabets are allowed.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Last Name: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" formControlName="last_name"
                                                            placeholder="Enter Last Name">
                                                        <div *ngIf="eUser.last_name.invalid && (eUser.last_name.dirty || eUser.last_name.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="eUser.last_name.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="eUser.last_name.errors?.pattern">
                                                                Only alphabets are allowed.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Username: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" formControlName="username"
                                                            placeholder="Enter Username">
                                                        <!-- <ng-select formControlName="university_id" bindLabel="name"
                                                                bindValue="id" [items]="university" [multiple]="false"
                                                                [closeOnSelect]="true" [searchable]="true"
                                                                [hideSelected]="false" placeholder="Select University"
                                                                [ngModel]="selectedUniversity" addTagText="Add New"
                                                                [addTag]="addNewUniversity">
                                                            </ng-select> -->
                                                        <div *ngIf="eUser.username.invalid && (eUser.username.dirty || eUser.username.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="eUser.username.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Email Id: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="email" formControlName="emailid"
                                                            placeholder="Enter Email Id">
                                                        <div *ngIf="eUser.emailid.invalid && (eUser.emailid.dirty || eUser.emailid.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="eUser.emailid.errors?.required">
                                                                Email Id is required.
                                                            </div>
                                                            <div *ngIf="eUser.emailid.errors?.email">
                                                                Email must be a valid email address
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="col-lg-2 col-md-12">
                                                    <div class="form-group">
                                                        <label>Dial Code: <span class="text-danger">*</span></label>
                                                        <select class="form-control" *ngIf="isCountryLoaded"
                                                            formControlName="dial_code">
                                                            <option value="">Select Code</option>
                                                            <option *ngFor="let item of dialCodeList"
                                                                [value]="item.id.toString()">{{ item.name }}({{
                                                                item.dial_code }})
                                                            </option>
                                                        </select>
                                                        <div *ngIf="eUser.dial_code.invalid && (eUser.dial_code.dirty || eUser.dial_code.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="eUser.dial_code.errors?.required">
                                                                This is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="col-lg-4 col-md-12">
                                                    <div class="form-group">
                                                        <label>Mobile Number: <span class="text-danger">*</span></label>
                                                        <input class="form-control" type="text" formControlName="mobileno"
                                                            placeholder="Enter Mobile Number">
                                                        <div *ngIf="eUser.mobileno.invalid && (eUser.mobileno.dirty || eUser.mobileno.touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="eUser.mobileno.errors?.required">
                                                                This is required.
                                                            </div>
                                                            <div *ngIf="eUser.mobileno.errors?.pattern">
                                                                Only Numbers are allowed.
                                                            </div>
                                                            <div
                                                                *ngIf="this.editUserForm.get('mobileno').errors?.invalidEditUserMobileNumber">
                                                                Enter a valid mobile number.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="form-group">
                                                    <label>Organization Name:</label>
                                                    <input class="form-control" type="text"
                                                        formControlName="organization_name"
                                                        placeholder="Enter Organization Name">
                                                </div>
                                            </div>
    
                                            <button type="submit" class="btn dashboard-btn"
                                                [disabled]="!editUserForm.valid">Update</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
    
                    <!-- Change Password -->
                    <div class="tab-pane fade" id="my-password" role="tabpanel" aria-labelledby="my-password-tab">
    
                        <div class="profile-content">
                            <form [formGroup]="employerChangePasswordForm" (ngSubmit)="updateEmployerPassword()">
                                <div class="profile-content-inner">
                                    <h2>Change Password</h2>
    
                                    <div class="row">

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group total-working-div">
                                                <label>Old Password: <span class="text-danger">*</span></label>
                                                <input class="form-control" [type]="old_show ? 'text' : 'password'"
                                                    formControlName="old_password" placeholder="Old Password">
                                                <button class="new-password-btn" type="button" (click)="showOldPassword()">
                                                    <i class="bx bx-lock" aria-hidden="true" *ngIf="!old_show"></i>
                                                    <i class="bx bx-lock-open" aria-hidden="true" *ngIf="old_show"></i>
                                                </button>
                                                <div *ngIf="fPass.old_password.invalid && (fPass.old_password.dirty || fPass.old_password.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="fPass.old_password.errors?.required">
                                                        Password is required.
                                                    </div>
                                                    <div *ngIf="fPass.old_password.errors?.patternn || fPass.old_password.errors?.minlength">
                                                        Password Must Contain Atleast 8 Characters, 1 Uppercase Letter, 1
                                                        Lowercase Letter, 1 Numeric & 1 Special Character.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group total-working-div">
                                                <label>New Password: <span class="text-danger">*</span></label>
                                                <input class="form-control" [type]="show ? 'text' : 'password'"
                                                    formControlName="password" placeholder="New Password">
                                                <button class="new-password-btn" type="button" (click)="showpassword()">
                                                    <i class="bx bx-lock" aria-hidden="true" *ngIf="!show"></i>
                                                    <i class="bx bx-lock-open" aria-hidden="true" *ngIf="show"></i>
                                                </button>
                                                <div *ngIf="fPass.password.invalid && (fPass.password.dirty || fPass.password.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="fPass.password.errors?.required">
                                                        Password is required.
                                                    </div>
                                                    <div *ngIf="fPass.password.errors?.patternn || fPass.password.errors?.minlength">
                                                        Password Must Contain Atleast 8 Characters, 1 Uppercase Letter, 1
                                                        Lowercase Letter, 1 Numeric & 1 Special Character.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group total-working-div">
                                                <label>Confirm New Password: <span class="text-danger">*</span></label>
                                                <input class="form-control" [type]="confirm_show ? 'text' : 'password'"
                                                    formControlName="confirm_password" placeholder="Confirm New Password">
                                                <button class="new-password-btn" type="button"
                                                    (click)="showConfirmPassword()">
                                                    <i class="bx bx-lock" aria-hidden="true" *ngIf="!confirm_show"></i>
                                                    <i class="bx bx-lock-open" aria-hidden="true" *ngIf="confirm_show"></i>
                                                </button>
                                                <div *ngIf="fPass.confirm_password.invalid && (fPass.confirm_password.dirty || fPass.confirm_password.touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="fPass.confirm_password.errors?.required">
                                                        Confirm Password is required.
                                                    </div>
                                                    <div *ngIf="fPass.confirm_password.errors?.patternn || fPass.confirm_password.errors?.minlength">
                                                        Confirm Password Must Contain Atleast 8 Characters, 1 Uppercase
                                                        Letter, 1 Lowercase Letter, 1 Numeric & 1 Special Character.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                                <button type="submit" class="btn dashboard-btn"
                                    [disabled]="!employerChangePasswordForm.valid">
                                    Change Password
                                </button>
                            </form>
                        </div>
    
                    </div>
    
                </div>
            </div>

        </div>

    </div>
</div>
<!-- End Dashboard -->

<!-- modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Set Permission</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form [formGroup]="permissionForm" (ngSubmit)="submitPermission()">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-5 col-md-12 col-lg-12">
                            <div class="company-checklist">
                                <h3>Company</h3>
                                <div class="checklist">
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" for="asset">List</label>
                                        <input class="form-check-input" type="checkbox" formControlName="show_company" value="1" (change)="CompanyCheckBox($event)" [checked]="isCompanyChecked" id="asset">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" for="inlineCheckbox2">Edit</label>
                                        <input class="form-check-input" type="checkbox" formControlName="show_edit_company" value="1"
                                        name="asset" (change)="EditCompany($event)" [checked]="isCompany">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" for="inlineCheckbox2">Delete</label>
                                        <input class="form-check-input" type="checkbox" formControlName="show_delete_company" value="1"
                                        name="asset" (change)="DeleteCompany($event)" [checked]="isCompany">
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="company-checklist">
                                <h3>Job</h3>
                                <div class="checklist">
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" for="jobs">List</label>
                                        <input class="form-check-input" type="checkbox" formControlName="show_job" value="1"
                                        (change)="JobCheckBox($event)" [checked]="isJobChecked" id="jobs">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" for="inlineCheckbox2">Add</label>
                                        <input class="form-check-input" type="checkbox" formControlName="show_add_job" value="1"
                                        (change)="AddJob($event)" [checked]="isJob">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" for="inlineCheckbox2">Edit</label>
                                        <input class="form-check-input" type="checkbox" formControlName="show_edit_job" value="1"
                                        (change)="EditJob($event)" [checked]="isJob">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" for="inlineCheckbox2">Delete</label>
                                        <input class="form-check-input" type="checkbox" formControlName="show_delete_job" value="1"
                                        (change)="DeleteJob($event)" [checked]="isJob">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" for="inlineCheckbox2">Applied Candidates</label>
                                        <input class="form-check-input" type="checkbox" formControlName="show_candidate" value="1"
                                        (change)="ShowCandidate($event)" [checked]="isJob">
                                    </div>
                                </div>
                            </div>

                            <!-- <table class="table table-stripped table-bordered table-dard bg-light table-hover">
                                <tr>
                                    <th scope="col" class="text-left">Company</th>
                                    <td class="text-left"><input type="checkbox" formControlName="show_company"
                                            value="1" (change)="CompanyCheckBox($event)" [checked]="isCompanyChecked"
                                            id="asset" /><span class="add_check">Company</span></td>
                                    <td><input type="checkbox" formControlName="show_edit_company" value="1"
                                            name="asset" (change)="EditCompany($event)" [checked]="isCompany" /><span
                                            class="add_check"> Edit</span></td>
                                    <td><input type="checkbox" formControlName="show_delete_company" value="1"
                                            name="asset" (change)="DeleteCompany($event)" [checked]="isCompany" /><span
                                            class="add_check" style="color: red;"> Delete</span></td>
                                </tr>

                                <tr>
                                    <th scope="col" class="text-left">Jobs</th>
                                    <td class="text-left"><input type="checkbox" formControlName="show_job" value="1"
                                            (change)="JobCheckBox($event)" [checked]="isJobChecked" /><span
                                            class="add_check">Jobs</span></td>
                                    <td><input type="checkbox" formControlName="show_add_job" value="1"
                                            (change)="AddJob($event)" [checked]="isJob" /><span class="add_check">
                                            Add</span></td>
                                    <td><input type="checkbox" formControlName="show_edit_job" value="1"
                                            (change)="EditJob($event)" [checked]="isJob" /><span class="add_check">
                                            Edit</span></td>
                                    <td><input type="checkbox" formControlName="show_candidate" value="1"
                                            (change)="ShowCandidate($event)" [checked]="isJob" /><span
                                            class="add_check"> Applied Candidate</span></td>
                                    <td><input type="checkbox" formControlName="show_delete_job" value="1"
                                            (change)="DeleteJob($event)" [checked]="isJob" /><span class="add_check"
                                            style="color: red;"> Delete</span></td>
                                </tr>
                            </table> -->



                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Save permissions</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->