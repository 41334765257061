<!-- Register -->
<div class="user-form-area">
    <div class="container-fluid p-0">

        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User">
                </div>
            </div>

            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>Register As</h2>
                        <div *ngIf="saveSucess" class="alert alert-success">
                            <strong [class]="FadeOutSuccessMsg()">{{ successMessage }}</strong>
                        </div>
                        <div *ngIf="error" class="alert alert-danger">
                            {{ errorMessage }}
                        </div>

                        <form class="form-inline" [formGroup]="webUsersForm" (ngSubmit)="onSubmit()">
                            <!-- <div class="form-group"> -->
                            <div>
                                <!-- <label class="register-label1">Register as:</label>&nbsp;&nbsp; -->
                                <input class="register-input" type="radio" formControlName="user_type" id="employerRadio" value="1">
                                <label class="register-label" for="employerRadio">Company</label>
                                &nbsp;
                                <input class="register-input" type="radio" formControlName="user_type" id="candidateRadio" value="2" checked="isChecked">&nbsp;
                                <label class="register-label" for="candidateRadio" checked="isChecked">Candidate</label>
                                &nbsp;
                                <input class="register-input" type="radio" formControlName="user_type" id="agencyRadio" value="3">&nbsp;
                                <label class="register-label" for="agencyRadio">HR Agency</label>

                                <div *ngIf="f.user_type.invalid && (f.user_type.dirty || f.user_type.touched)" class="alert alert-danger">
                                    <div *ngIf="f.user_type.errors?.required">
                                        Type is required.
                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-lg-6 col-md-12" *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 2 || webUsersForm.value.user_type == 3">
                                    <div class="form-group">
                                        <label>First Name: <span class="text-danger">*</span></label>
                                        <input class="form-control" type="text" formControlName="first_name" placeholder="Enter First Name">
                                        <!-- <input *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 3" class="form-control" type="text" formControlName="name" placeholder="Enter First Name"> -->
                                        <div *ngIf="f.first_name.invalid && (f.first_name.dirty || f.first_name.touched)"
                                            class="alert alert-danger">
                                            <div *ngIf="f.first_name.errors?.required">
                                                First Name is required.
                                            </div>
                                            <div *ngIf="f.first_name.errors?.pattern">
                                                Only Alphabets are Allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12" *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 2 || webUsersForm.value.user_type == 3">
                                    <div class="form-group">
                                        <label>Last Name: <span class="text-danger">*</span></label>
                                        <input class="form-control" type="text" formControlName="last_name" placeholder="Enter Last Name">
                                        <!-- <input *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 3" class="form-control" type="text" formControlName="name" placeholder="Enter Last Name"> -->
                                        <div *ngIf="f.last_name.invalid && (f.last_name.dirty || f.last_name.touched)"
                                            class="alert alert-danger">
                                            <div *ngIf="f.last_name.errors?.required">
                                                Last Name is required.
                                            </div>
                                            <div *ngIf="f.last_name.errors?.pattern">
                                                Only Alphabets are Allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12" *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 2 || webUsersForm.value.user_type == 3">
                                    <div class="form-group">
                                        <label>Username: <span class="text-danger">*</span></label>
                                        <input class="form-control" type="text" formControlName="username" placeholder="Enter Username">
                                        <!-- <input *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 3" class="form-control" type="text" formControlName="name" placeholder="Enter Username"> -->
                                        <div *ngIf="f.username.invalid && (f.username.dirty || f.username.touched)"
                                            class="alert alert-danger">
                                            <div *ngIf="f.username.errors?.required">
                                                Username is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12" *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 2 || webUsersForm.value.user_type == 3">
                                    <div class="form-group">
                                        <label>Email Id: <span class="text-danger">*</span></label>
                                        <input class="form-control" type="email" formControlName="emailid" placeholder="Enter Email Id">
                                            <!-- <input *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 3" class="form-control" type="email" formControlName="emailid"
                                                placeholder="Organization Email Id"> -->
                                        <div *ngIf="f.emailid.invalid && (f.emailid.dirty || f.emailid.touched)"
                                            class="alert alert-danger">
                                            <div *ngIf="f.emailid.errors?.required">
                                                Email Id is required.
                                            </div>
                                            <div *ngIf="f.emailid.errors?.email">
                                                Email must be a valid email address
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12" *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 2 || webUsersForm.value.user_type == 3">
                                    <div class="form-group regPass-div">
                                        <label>Password: <span class="text-danger">*</span></label>
                                        <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" placeholder="Enter Password">
                                        <button type="button" class="new-pass-btn" (click)="showpassword()">
                                            <i class="bx bx-lock" aria-hidden="true" *ngIf="!show"></i>
                                            <i class="bx bx-lock-open" aria-hidden="true" *ngIf="show"></i>
                                        </button>
                                        <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert alert-danger">
                                            <div *ngIf="f.password.errors?.required">
                                                Password is Required.
                                            </div>
                                            <div *ngIf="f.password.errors?.pattern">
                                                Password Must Contain Atleast 8 Characters, 1 Uppercase Letter, 1 Lowercase Letter, 1 Numeric & 1 Special Character.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12" *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 2 || webUsersForm.value.user_type == 3">
                                    <div class="form-group regPass-div">
                                        <label>Confirm Password: <span class="text-danger">*</span></label>
                                        <input class="form-control" [type]="confirm_show ? 'text' : 'password'" formControlName="confirm_password" placeholder="Enter Confirm Password">
                                        <button type="button" class="confirm-pass-btn" (click)="showConfirmPassword()">
                                            <i class="bx bx-lock" aria-hidden="true" *ngIf="!confirm_show"></i>
                                            <i class="bx bx-lock-open" aria-hidden="true" *ngIf="confirm_show"></i>
                                        </button>
                                        <div *ngIf="f.confirm_password.invalid && (f.confirm_password.dirty || f.confirm_password.touched)" class="alert alert-danger">
                                            <div *ngIf="f.confirm_password.errors?.required">
                                                Confirm Password is Required.
                                            </div>
                                            <div *ngIf="f.confirm_password.errors?.pattern">
                                                Confirm Password Must Contain Atleast 8 Characters, 1 Uppercase Letter, 1 Lowercase Letter, 1 Numeric & 1 Special Character.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-5 col-md-12">
                                    <div class="form-group" *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 2 || webUsersForm.value.user_type == 3">
                                        <label>Dial Code: <span class="text-danger">*</span></label>
                                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="dial_code" placeholder="Select Dial Code">
                                            <option value="">Select Dial Code</option>
                                            <option *ngFor="let item of countryList" [value]="item.id">{{ item.name }}({{ item.dial_code }})
                                            </option>
                                        </select>
                                        <div *ngIf="f.dial_code.invalid && (f.dial_code.dirty || f.dial_code.touched)"
                                            class="alert alert-danger">
                                            <div *ngIf="f.dial_code.errors?.required">
                                                Dial Code is Required.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-7 col-md-12">
                                    <div class="form-group" *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 2 || webUsersForm.value.user_type == 3">
                                        <label>Mobile Number: <span class="text-danger">*</span></label>
                                        <input class="form-control" type="text" formControlName="mobileno" placeholder="Enter Mobile Number">
                                        <!-- <input *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 3" class="form-control" type="text" formControlName="mobileno"
                                            placeholder="Organization Mobile No."> -->
                                        <div *ngIf="f.mobileno.invalid && (f.mobileno.dirty || f.mobileno.touched)"
                                            class="alert alert-danger">
                                            <div *ngIf="f.mobileno.errors?.required">
                                                Mobile Number is Required.
                                            </div>
                                            <div *ngIf="f.mobileno.errors?.pattern">
                                                Only Numbers are Allowed.
                                            </div>
                                            <div *ngIf="this.webUsersForm.get('mobileno').errors?.invalidMobileNumber">
                                                Enter a Valid Mobile Number.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 3">
                                    <div class="form-group">
                                        <label>Organization Name:</label>
                                        <input class="form-control" type="text" formControlName="organization_name" placeholder="Enter Organization Name">
                                        <div *ngIf="f.organization_name.invalid && (f.organization_name.dirty || f.organization_name.touched)" class="alert alert-danger">
                                            <div *ngIf="f.organization_name.errors?.required">
                                                Organization Name is Required.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 2 || webUsersForm.value.user_type == 3">
                                    <label>Sector:</label>
                                    <select class="form-control" *ngIf="isJobSectorDropdownLoaded" formControlName="job_sector_id" placeholder="Select Sector">
                                        <option value="">Select Sector</option>
                                        <option *ngFor="let item of jobSectorDropdown" [value]="item.id">{{ item.sector_name }}
                                        </option>
                                    </select>
                                </div>
                                
                                <!-- *ngIf="webUsersForm.value.user_type === '2'"
                                    *ngIf="webUsersForm.value.user_type === '2'"
                                    *ngIf="webUsersForm.value.user_type === '2'"
                                    *ngIf="webUsersForm.value.user_type === '2'" -->

                                <!-- <div class="form-group">
                                    <input class="form-control" type="date" formControlName="dob"
                                        placeholder="Date Of Birth" [max]="getToday()"> 
                                    <div *ngIf="f.dob.invalid && (f.dob.dirty || f.dob.touched)"
                                        class="alert alert-danger">
                                        <div *ngIf="f.dob.errors?.required">
                                            Date of Birth is required.
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <select class="form-control" formControlName="country_id" id="country_id" *ngIf="isCountryLoaded"
                                        (change)="getState($event.target.value)" placeholder="Country">
                                        <option value="">Select Your Country</option>
                                        <option *ngFor="let countryRows of countryList" [ngValue]="countryRows.id.toString()">
                                            {{ countryRows.name }}
                                        </option>
                                    </select>
                                    <div *ngIf="f.country_id.invalid && (f.country_id.dirty || f.country_id.touched)" class="alert alert-danger">
                                        <div *ngIf="f.country_id.errors?.required">
                                            Select Country                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <select class="form-control" formControlName="state_id" id="state_id" *ngIf="isCountryLoaded"
                                        (change)="getCity($event.target.value)">
                                        <option value="">Select Your State</option>
                                        <option *ngFor="let stateRows of stateList" [ngValue]="stateRows.id.toString()">
                                            {{ stateRows.name }}
                                        </option>
                                    </select>
                                    <div *ngIf="f.state_id.invalid && (f.state_id.dirty || f.state_id.touched)" class="alert alert-danger">
                                        <div *ngIf="f.state_id.errors?.required">
                                            Select State
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <select class="form-control" formControlName="city_id" id="city_id" *ngIf="isCountryLoaded">
                                        <option value="">Select Your City</option>
                                        <option *ngFor="let cityRows of cityList" [ngValue]="cityRows.id.toString()">
                                            {{ cityRows.name }}
                                        </option>
                                    </select>
                                    <div *ngIf="f.city_id.invalid && (f.city_id.dirty || f.city_id.touched)" class="alert alert-danger">
                                        <div *ngIf="f.city_id.errors?.required">
                                            Select City
                                        </div>
                                    </div>
                                </div> -->

                            </div>

                            <div style="margin-bottom: 15px;" *ngIf="webUsersForm.value.user_type == 1 || webUsersForm.value.user_type == 2 || webUsersForm.value.user_type == 3">
                                <input name="terms&condition" type="checkbox" (change)="tickCheckbox()"/> 
                                <label>By clicking checkbox, you agree to our <a target="_blank" href="/terms-conditions"> Terms and Conditions </a> and <a target="_blank" href="/privacy-policy"> Privacy Policy </a></label>
                            </div>


                            <button type="submit" class="btn" [disabled]="!webUsersForm.valid || !checkboxFlag">Register Here</button>

                            <p class="p-login">Already have a Account? <span class="span-class" (click)="gotoLogin()">Login Here</span></p>
                        </form>
                    </div>

                    <div class="end">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Register -->
