import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JobsService } from '../../../service/jobs/jobs.service';
import { JobTypeService } from '../../../service/job-type/job-type.service';
import { JobCategoryService } from '../../../service/job-category/job-category.service';
import { IndustryTypeService } from '../../../service/industry-type/industry-type.service';
import { JobSalaryService } from '../../../service/job-salary/job-salary.service';
import { JobExperienceService } from '../../../service/job-experience/job-experience.service';
import { JobQualificationService } from '../../../service/job-qualification/job-qualification.service';
import { JobLevelService } from '../../../service/job-level/job-level.service';
import { CountryService } from '../../../service/country/country.service';
import { StateService } from '../../../service/state/state.service';
import { CityService } from '../../../service/city/city.service';
import { KeySkillsService } from '../../../service/key-skills/key-skills.service';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from "@angular/forms";
import { TokenStorageService } from 'src/app/service/login/token-storage.service';
import { EmployerCompaniesService } from 'src/app/service/employer_companies/employer_companies.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-post-a-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.scss']
})
export class EditJobComponent implements OnInit {
  getId: any;
  saveSucess: boolean = false;
  error: boolean = false;
  errorMessage: string = "";
  successMessage: string = "";
  editJobsForm: FormGroup;

  isCompanyLoaded: boolean = false;
  employerCompaniesList: any;

  isJobTypeLoaded: boolean = false;
  jobTypeList: any;

  isIndustryTypeLoaded: boolean = false;
  industryTypeList: any;

  isJobCategoryLoaded: boolean = false;
  jobCategoryList: any;

  isJobSalaryLoaded: boolean = false;
  jobSalaryList: any;

  isCurrencyLoaded: boolean = false;
  currencyList: any;

  isJobExperienceLoaded: boolean = false;
  jobExperienceList: any;

  isJobQualificationLoaded: boolean = false;
  jobQualificationList: any;

  isJobLevelLoaded: boolean = false;
  jobLevelList: any;

  isCountryLoaded: boolean = false;
  countryList: any;

  stateList: any;
  cityList: any;

  isKeySkillsLoaded: boolean = false;
  keySkillsList: any;

  submitTypeToggle: Number = 0;
  companyName: any;
  employeeCompanyId: any;

  questionForm: FormGroup;
  questionsArray: FormArray;

  jobResponsibilityArray: FormArray;
  selectedSkills: Array<string> = [];
  selectedSkillId: any;

  totalItems: any;
  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  isLoggedIn: boolean;
  currentUser: any;

  get contactFormGroup() {
    return this.editJobsForm.get('questions') as FormArray;
  }

  get jobResponsibilityFormGroup() {
    return this.editJobsForm.get('job_responsibilities') as FormArray;
  }

  constructor(
    private token: TokenStorageService,
    public formBuilder: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private jobsService: JobsService,
    private jobTypeService: JobTypeService,
    private industryTypeService: IndustryTypeService,
    private jobCategoryService: JobCategoryService,
    private jobSalaryService: JobSalaryService,
    private jobExperienceService: JobExperienceService,
    private jobQualificationService: JobQualificationService,
    private jobLevelService: JobLevelService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private keySkillsService: KeySkillsService,
    private employerCompaniesService: EmployerCompaniesService,
  ) {
    this.getId = this.activatedRoute.snapshot.paramMap.get('id');

    this.jobsService.GetJobsDetail(this.getId).subscribe((res: any) => {
      this.getState(`${res.data.country_id.toString()}: ${res.data.country_id.toString()}`);
      this.getCity(`${res.data.state_id.toString()}: ${res.data.state_id.toString()}`);
      this.editJobsForm.patchValue({
        employer_companies_id: res.data.employer_companies_id.toString(),
        title: res.data.title,
        job_type_id: res.data.job_type_id.toString(),
        industry_type_id: res.data.industry_type_id.toString(),
        job_category_id: res.data.job_category_id.toString(),
        min_salary: res.data.min_salary.toString(),
        max_salary: res.data.max_salary.toString(),
        currency_id: res.data.currency.id.toString() || '',
        job_experience_id: res.data.job_experience_id.toString(),
        job_qualification_id: res.data.job_qualification_id.toString(),
        job_level_id: res.data.job_level_id.toString(),
        country_id: res.data.country_id.toString(),
        state_id: res.data.state_id.toString(),
        city_id: res.data.city_id.toString(),
        status: res.data.status.toString(),
        job_description: res.data.job_description,
        submit_type: res.data.submit_type,
        key_skills_ids: res.data.jobs_key_skills.slice(0, 2).map(a => a.key_skills_id.toString()),
        questions: res.data.questions.map(async(item:any) => { this.questionGroup_.push( await this.loadQuestions(item.question, item.isMandatory)) }),
        job_responsibilities: res.data.job_responsibilities.map(async(item:any) => { this.jobResponsibilityGroup_.push( await this.loadJobResponsibility(item.job_responsibility)) })
      });

      this.selectedSkills = res.data.jobs_key_skills.map(a => a.key_skill.name.toString());
      this.selectedSkillId = res.data.jobs_key_skills.map(a => a.key_skill.id);

      // this.editJobsForm.setControl('questions', this.setExistingQuestions(res.data.questions));
      // this.editJobsForm.setControl('job_responsibilities', this.setExistingJobResponsibilities(res.data.job_responsibilities));
    });


    this.editJobsForm = this.formBuilder.group({
      employer_companies_id: [''],
      title: [''],
      job_type_id: [''],
      industry_type_id: [''],
      job_category_id: [''],
      // job_salary_id: [''],
      min_salary: ['', Validators.required],
      max_salary: ['', Validators.required],
      currency_id: [''],
      job_experience_id: [''],
      job_qualification_id: [''],
      job_level_id: [''],
      country_id: [''],
      state_id: [''],
      city_id: [''],
      status: [''],
      job_description: [''],
      submit_type: [''],
      key_skills_ids: [''],
      questions: this.formBuilder.array([]),
      job_responsibilities: this.formBuilder.array([])
    })

    this.questionsArray = this.editJobsForm.get('questions') as FormArray;
    this.jobResponsibilityArray = this.editJobsForm.get('job_responsibilities') as FormArray; 
  }

  get f() {
    return this.editJobsForm.controls;
  }

  async loadQuestions(question:any, isMandatory:any) {
    return await this.formBuilder.group({
      isMandatory: [isMandatory],
      question: [question]
    })
  }

  async loadJobResponsibility(jobRes:any) {
    return await this.formBuilder.group({
      job_responsibility:[jobRes]
    })
  }

  // setExistingQuestions(questionSet: any) : FormArray {
  //   const formArray_ = new FormArray([]);
  //   questionSet.forEach(item => {
  //     formArray_.push(this.formBuilder.group({
  //       isMandatory: item.isMandatory,
  //       question: item.question
  //     }));
  //   })
  //   return formArray_;
  // }

  // setExistingJobResponsibilities(jobResponsibilitySet: any) : FormArray {
  //   const formArray_ = new FormArray([]);
  //   jobResponsibilitySet.forEach(item => {
  //     formArray_.push(this.formBuilder.group({
  //       job_responsibility: item.job_responsibility
  //     }));
  //   });
  //   return formArray_;
  // }

  ngOnInit(): void {
    if (this.token.getToken()) {
      this.isLoggedIn = true;
      this.currentUser = this.token.getUser();
    }
    const companyId = this.currentUser.admin_id == null ? this.currentUser.id : this.currentUser.admin_id;
    this.employerCompaniesService.GetEmployerCompanies(this.page, this.itemsPerPage, companyId).subscribe((res:any) => {
      if(res.data[0].status == 0) {
        alert("Your Company is In-Active. Activate your Company from Dashboard -> Company Details");
        this.ngZone.run(() => this.router.navigateByUrl('/employer-dashboard'));
      }
      this.companyName = res.data[0].company_name;
      this.employeeCompanyId = res.data[0].id.toString();
    })

    // this.employerCompaniesDropdown();
    this.jobTypeDropdown();
    this.industryTypeDropdown();
    this.jobCategoryDropdown();
    this.jobSalaryDropdown();
    // this.currencyDropdown();
    this.jobExperienceDropdown();
    this.jobQualificationDropdown();
    this.jobLevelDropdown();
    this.countryDropdown();
    this.keySkillsDropdown();

    this.editJobsForm.get('max_salary').valueChanges.subscribe(value => {
      const min = this.editJobsForm.get('min_salary').value

      if (Number(value) < Number(min)) {
        this.editJobsForm.get('max_salary').setErrors({ 'minIsGreaterThanMax': true })
      } else {
        if (Number(value) > Number(min)) {
          this.editJobsForm.get('max_salary').setErrors({ 'minIsGreaterThanMax': false })
          this.editJobsForm.get('max_salary').setErrors(null);
        } else {
          this.editJobsForm.get('max_salary').setErrors({ 'minIsGreaterThanMax': true })
        }
      }
    });
  }

  editJobs(): any {
    const submitType = this.editJobsForm.get('submit_type').value == 1 ? 1 : 0;
    this.editJobsForm.controls['submit_type'].setValue(submitType.toString());
    this.jobsService.updateJob(this.getId, this.editJobsForm.value)
      .subscribe(() => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = "Job Updated Successfully";
        this.ngZone.run(() => this.router.navigateByUrl('/employer-dashboard'))
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.saveSucess = false;
    }, 5000);
  }

  employerCompaniesDropdown() {
    this.jobsService.getEmployerCompaniesDropdown().subscribe((res: any) => {
      this.isCompanyLoaded = true;
      this.employerCompaniesList = res.data;
    });
  }

  jobTypeDropdown() {
    this.jobTypeService.getJobTypeDropdown().subscribe((res: any) => {
      this.isJobTypeLoaded = true;
      this.jobTypeList = res.data;
    });
  }

  industryTypeDropdown() {
    this.industryTypeService.getIndustryTypeDropdown().subscribe((res: any) => {
      this.isIndustryTypeLoaded = true;
      this.industryTypeList = res.data;
    });
  }

  jobCategoryDropdown() {
    this.jobCategoryService.getJobCategoryDropdown().subscribe((res: any) => {
      this.isJobCategoryLoaded = true;
      this.jobCategoryList = res.data;
    });
  }

  jobSalaryDropdown() {
    this.jobSalaryService.getJobSalaryDropdown().subscribe((res: any) => {
      this.isJobSalaryLoaded = true;
      this.jobSalaryList = res.data;
    });
  }

  // currencyDropdown() {
  //   this.jobSalaryService.getCurrencyDropdown().subscribe((res: any) => {
  //     this.isCurrencyLoaded = true;
  //     this.currencyList = res.data;
  //   });
  // }

  jobExperienceDropdown() {
    this.jobExperienceService.getJobExperienceDropdown().subscribe((res: any) => {
      this.isJobExperienceLoaded = true;
      this.jobExperienceList = res.data;
    });
  }

  jobQualificationDropdown() {
    this.jobQualificationService.getJobQualificationDropdown().subscribe((res: any) => {
      this.isJobQualificationLoaded = true;
      this.jobQualificationList = res.data;
    });
  }

  jobLevelDropdown() {
    this.jobLevelService.getJobLevelDropdown().subscribe((res: any) => {
      this.isJobLevelLoaded = true;
      this.jobLevelList = res.data;
    });
  }

  countryDropdown() {
    this.countryService.getCountryDropdown().subscribe((res: any) => {
      this.isCountryLoaded = true;
      this.countryList = res.data;
    });
  }

  getState(countryId: string) {
    const toArray = countryId.split(": ");
    const country_id = toArray[1];
    this.stateService.getStateListDropdown(country_id).subscribe((res: any) => {
      this.stateList = res.data;
    });
  }

  getCity(stateId: string) {
    const toArray = stateId.split(": ");
    const state_id = toArray[1];
    this.cityService.getCityListDropdown(state_id).subscribe((res: any) => {
      this.cityList = res.data;
    });
  }

  keySkillsDropdown() {
    this.keySkillsService.getKeySkillsDropdown().subscribe((res: any) => {
      this.isKeySkillsLoaded = true;
      this.keySkillsList = res.data;
    });
  }

  addContact() {
    this.questionsArray.push(this.createContact());
  }

  removeQuestions(i) {
    const emails = this.editJobsForm.get('questions') as FormArray
    if (emails.length > 1) {
      emails.removeAt(i)
    } else {
      emails.reset()
    }
  }

  createContact(): FormGroup {
    return this.formBuilder.group({
      isMandatory: [''],
      question: ['']
    });
  }

  getContactsFormGroup(index): FormGroup {
    const formGroup = this.questionsArray.controls[index] as FormGroup;
    return formGroup;
  }

  get questionGroup_() {
    return (<FormArray>this.editJobsForm.get('questions'));
  }

  createResponibility(): FormGroup {
    return this.formBuilder.group({
      job_responsibility: ['']
    })
  }

  addResponsibility() {
    this.jobResponsibilityArray.push(this.createResponibility());
  }

  removeResponsibility(i) {
    const emails = this.editJobsForm.get('job_responsibilities') as FormArray
    if (emails.length > 1) {
      emails.removeAt(i)
    } else {
      emails.reset()
    }
  }

  getJobResponsibilityFormGroup(index): FormGroup {
    // this.contactList = this.form.get('contacts') as FormArray;
    const formGroup = this.jobResponsibilityArray.controls[index] as FormGroup;
    return formGroup;
  }

  get jobResponsibilityGroup_() {
    return (<FormArray>this.editJobsForm.get('job_responsibilities'));
  }

}
