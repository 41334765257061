import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ContactService } from 'src/app/service/contact/contact.service';
import { CountryService } from 'src/app/service/country/country.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactUsForm : FormGroup;
  saveSucess: boolean;
  error: boolean;
  successMessage: string;
  errorMessage: any;
  dialCodeList: any;

  constructor(
    public fb: FormBuilder,    
    private router: Router,
    private ngZone: NgZone,
    private contactService : ContactService,
    private countryService: CountryService
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]*$')]],
      emailId: ['', [Validators.required, Validators.email]],
      dial_code: ['', Validators.required],
      mobileNumber: ['',
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.pattern(/$/),
          Validators.minLength(5)
        ]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.countryDropdown();
    window.localStorage.removeItem('dashboardSearch');
    window.localStorage.removeItem('dashboard-search');
  }

  countryDropdown() {
    this.countryService.getCountryDropdown().subscribe((res: any) => {
      this.dialCodeList = res.data;
    });
  }

  get f() {
    return this.contactUsForm.controls;
  }

  onSubmit() {
    this.contactService.sendMail(this.contactUsForm.value).subscribe((res:any) => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Contacted Successfully!!';
      this.contactUsForm.reset();
    },(err) => {
      console.log(err);
      this.error = true;
      if (err.status == 150) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.saveSucess = false;
    }, 5000);
  }

}
