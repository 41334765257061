import { Component } from '@angular/core';

@Component({
  selector: 'app-add-candidate-details',
  templateUrl: './add-candidate-details.component.html',
  styleUrls: ['./add-candidate-details.component.scss']
})
export class AddCandidateDetailsComponent {

}
