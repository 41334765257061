import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CandidateEducation } from '../candidate_education/CandidateEducation';

@Injectable({
  providedIn: 'root'
})
export class CandidateExperienceService {
  REST_API: string;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}candidate/experience`;
    } else {
      this.REST_API = `${environment.apiurl}candidate/experience`;
    }
  }

  GetCandidateExperience(page: any, itemsPerPage: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}`, { params: queryParams });
  }

  GetCandidateExperienceDetail(id:any): Observable<any> {
    let API_URL = `${this.REST_API}/${id}`;
    return this.httpClient.get(API_URL)
      .pipe(map((res: any) => {
        return res || {}
      }),
        catchError(this.handleError)
      )
  }

  AddCandidateExperience(data: CandidateEducation): Observable<any> {
    let API_URL = `${this.REST_API}`;
    return this.httpClient.post(API_URL, data).pipe(catchError(this.handleError))
  }

  UpdateCandidateExperience(id: any, data: any): Observable<any> {
    let API_URL = `${this.REST_API}/${id}`;
    return this.httpClient.put(API_URL, data).pipe(catchError(this.handleError))
  }

  DeleteCandidateExperience(id: any): Observable<any> {
    let API_URL = `${this.REST_API}/${id}`;
    return this.httpClient.delete(API_URL).pipe(catchError(this.handleError))
  }

  // Error
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }

}
