<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Edit Your Company</h2>
                    <ul>
                        <!-- <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li> -->
                        <!-- <li><span>/</span></li>
                        <li>
                            <a routerLink="/employer-dashboard">Dashboard</a>
                        </li>
                        <li><span>/</span></li> -->
                        <!-- <li>Add a Company</li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Add Company Form Start -->
<div class="post-job-area ptb-100">
    <div class="container">
        <div *ngIf="saveSucess" class="alert alert-success">
            <strong [class]="FadeOutSuccessMsg()">{{ successMessage }}</strong>
        </div>
        <div *ngIf="error" class="alert alert-danger">
            {{ errorMessage }}
        </div>
        <form [formGroup]="employerCompanyEditForm" (ngSubmit)="employerCompaniesUpdate()">
            <div class="post-item">

                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Company Name: <span class="text-danger">*</span></label>
                            <input class="form-control" type="text" formControlName="company_name"
                                placeholder="Company Name" [(ngModel)]="formData[0].company_name">
                            <div *ngIf="fComp.company_name.invalid && (fComp.company_name.dirty || fComp.company_name.touched)"
                                class="alert alert-danger">
                                <div *ngIf="fComp.company_name.errors?.required">
                                    This is required.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Email Id: <span class="text-danger">*</span></label>
                            <input class="form-control" type="email" formControlName="emailid" placeholder="Email Id" [(ngModel)]="formData[0].emailid">
                            <div *ngIf="fComp.emailid.invalid && (fComp.emailid.dirty || fComp.emailid.touched)" class="alert alert-danger">
                                <div *ngIf="fComp.emailid.errors?.required">
                                    This is required.
                                </div>
                                <div *ngIf="fComp.emailid.errors?.email">
                                    Email must be a valid email address
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-12">
                        <div class="form-group">
                            <label>Dial Code: <span class="text-danger">*</span></label>
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="dial_code" [ngModel]="formData[0].dial_code">
                                <option value="" selected>Select Dial Code</option>
                                <option *ngFor="let item of countryList" [value]="item.id">{{ item.name }}({{ item.dial_code }})
                                </option>
                            </select>
                            <div *ngIf="fComp.dial_code.invalid && (fComp.dial_code.dirty || fComp.dial_code.touched)" class="alert alert-danger">
                                <div *ngIf="fComp.dial_code.errors?.required">
                                    This is required.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Mobile No.: <span class="text-danger">*</span></label>
                            <input class="form-control" type="text" [formControlName]="'mobileno'" placeholder="Mobile No." [(ngModel)]="formData[0].mobileno">
                            <div *ngIf="fComp.mobileno.invalid && (fComp.mobileno.dirty || fComp.mobileno.touched)" class="alert alert-danger">
                                <div *ngIf="fComp.mobileno.errors?.required">
                                    This is required.
                                </div>
                                <div *ngIf="fComp.mobileno.errors?.pattern">
                                    Only Numbers are Allowed.
                                </div>
                                <div *ngIf="this.employerCompanyEditForm.get('mobileno').errors?.invalidMobileNumber">
                                    Enter a Valid Mobile Number.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Website Url:</label>
                            <input class="form-control" type="text" formControlName="website_url"
                                placeholder="Website Url" [(ngModel)]="formData[0].website_url">
                            <div *ngIf="fComp.website_url.invalid && (fComp.website_url.dirty || fComp.website_url.touched)"
                                class="alert alert-danger">
                                <div *ngIf="fComp.website_url.errors?.required">
                                    This is required.
                                </div>
                                <div *ngIf="fComp.website_url.errors?.pattern">
                                    Please enter valid url e.g. https://example.com
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-12">
                        <div class="form-group">
                            <label>Dial Code: <span class="text-danger">*</span></label>
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="company_dial_code">
                                <option value="" selected>Select Dial Code</option>
                                <option *ngFor="let item of countryList" [value]="item.id">{{ item.name }}({{ item.dial_code }})
                                </option>
                            </select>
                            <div *ngIf="fComp.company_dial_code.invalid && (fComp.company_dial_code.dirty || fComp.company_dial_code.touched)" class="alert alert-danger">
                                <div *ngIf="fComp.company_dial_code.errors?.required">
                                    This is required.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Company Contact No.: <span class="text-danger">*</span></label>
                            <input class="form-control" type="text" formControlName="company_contactno"
                                placeholder="Company Contact No." [(ngModel)]="formData[0].company_contactno">
                            <div *ngIf="fComp.company_contactno.invalid && (fComp.company_contactno.dirty || fComp.company_contactno.touched)"
                                class="alert alert-danger">
                                <div *ngIf="fComp.company_contactno.errors?.required">
                                    This is required.
                                </div>
                                <div *ngIf="fComp.company_contactno.errors?.pattern">
                                    Only Numbers are Allowed.
                                </div>
                                <div *ngIf="this.employerCompanyEditForm.get('company_contactno').errors?.invalidCompanyContactMobileNumber">
                                    Enter a Valid Mobile Number.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12 publish-job">
                        <div class="form-group">
                            <label>Is Your Company Active?</label>
                        </div>
                        <div class="form-check form-switch" >
                            <input
                                mdbCheckbox
                                checked="status"
                                [(ngModel)]="formData[0].status"
                                class="form-check form-switch form-check-input"
                                [formControlName]="'status'" id="status"
                                type="checkbox"
                            />
                        </div>
                        <div *ngIf="fComp.status.invalid && (fComp.status.dirty || fComp.status.touched)" class="alert alert-danger">
                            <div *ngIf="fComp.status.errors?.required">
                                This is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>About Company:</label>
                            <textarea class="form-control" formControlName="about_company" placeholder="About Company" [(ngModel)]="formData[0].about_company"
                                rows="10"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Company Mission:</label>
                            <textarea class="form-control" formControlName="company_mission" [(ngModel)]="formData[0].company_mission"
                                placeholder="Company Mission" rows="10"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Company Vision:</label>
                            <textarea class="form-control" formControlName="company_vision" placeholder="Company Vision" [(ngModel)]="formData[0].company_vision"
                                rows="10"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Country: <span class="text-danger">*</span></label>
                            <select class="form-control" formControlName="country_id" id="country_id" *ngIf="isCountryLoaded" (change)="getState($event.target.value)">
                                <option value="">Select Country</option>
                                <option *ngFor="let countryRows of countryList" [ngValue]="countryRows.id.toString()"> {{ countryRows.name }}</option>
                            </select>
                            <div *ngIf="fComp.country_id.invalid && (fComp.country_id.dirty || fComp.country_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="fComp.country_id.errors?.required">
                                    Select Country
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>State: <span class="text-danger">*</span></label>
                            <select class="form-control" formControlName="state_id" id="state_id"
                                *ngIf="isCountryLoaded" (change)="getCity($event.target.value)">
                                <option value="">Select State</option>
                                <option *ngFor="let stateRows of stateList"
                                    [ngValue]="stateRows.id.toString()">
                                    {{ stateRows.name }}
                                </option>
                            </select>
                            <div *ngIf="fComp.state_id.invalid && (fComp.state_id.dirty || fComp.state_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="fComp.state_id.errors?.required">
                                    Select State
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>City: <span class="text-danger">*</span></label>
                            <select class="form-control" formControlName="city_id" id="city_id"
                                *ngIf="isCountryLoaded">
                                <option value="">Select City</option>
                                <option *ngFor="let cityRows of cityList"
                                    [ngValue]="cityRows.id.toString()">
                                    {{ cityRows.name }}
                                </option>
                            </select>
                            <div *ngIf="fComp.city_id.invalid && (fComp.city_id.dirty || fComp.city_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="fComp.city_id.errors?.required">
                                    Select City
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12"> <hr> </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Employer Name:</label>
                            <input class="form-control" type="text" formControlName="employer_name" [(ngModel)]="formData[0].employer_name"
                                placeholder="Employer Name">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Employer Email Id:</label>
                            <input class="form-control" type="text" [formControlName]="'employer_emailid'" [(ngModel)]="formData[0].employer_emailid" placeholder="Employer Email Id">
                            <div *ngIf="fComp.employer_emailid.invalid && (fComp.employer_emailid.dirty || fComp.employer_emailid.touched)" class="alert alert-danger">
                                <div *ngIf="fComp.employer_emailid.errors?.email">
                                    Email must be a valid email address
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-12">
                        <div class="form-group">
                            <label>Dial Code:</label>
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="employer_dial_code">
                                <option value="" selected>Select Dial Code</option>
                                <option *ngFor="let item of countryList" [value]="item.id">{{ item.name }}({{ item.dial_code }})
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Employer Mobile Number:</label>
                            <input class="form-control" type="text" [formControlName]="'employer_mobileno'" [(ngModel)]="formData[0].employer_mobileno" placeholder="Employer Mobile Number">
                            <div *ngIf="fComp.employer_mobileno.invalid && (fComp.employer_mobileno.dirty || fComp.employer_mobileno.touched)" class="alert alert-danger">
                                <div *ngIf="fComp.employer_mobileno.errors?.pattern">
                                    Only Numbers are Allowed.
                                </div>
                                <div *ngIf="this.employerCompanyEditForm.get('employer_mobileno').errors?.invalidEmpMobileNumber">
                                    Enter a Valid Mobile Number.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Employer Designation:</label>
                            <input class="form-control" type="text" [formControlName]="'employer_designation'" [(ngModel)]="formData[0].employer_designation"
                                placeholder="Employer Designation">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Employer Department:</label>
                            <input class="form-control" type="text" [formControlName]="'employer_department'" [(ngModel)]="formData[0].employer_department"
                                placeholder="Employer Department">
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn dashboard-btn" [disabled]="!employerCompanyEditForm.valid">Save</button>
            </div>
        </form>
    </div>
</div>
<!-- Add Company Form End -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->