<!-- Navbar -->
<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/main_logo2.png" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/main_logo2.png" alt="Logo" style="width: 190px;height: 50px;"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item" *ngIf="currentUser?.user_type == 2 || isLoggedIn == false">
                            <a routerLink="/jobs" class="nav-link">Jobs</a>
                        </li>

                        <li class="nav-item" *ngIf="(currentUser?.user_type == 1 && currentUser?.is_sub_user == null) || (currentUser?.user_type == 1 && currentUser?.is_sub_user == 1 && currentUser?.permissions[0]?.show_job == 1)">
                            <a routerLink="/employer-dashboard" (click)="gotoMyJobs()" class="nav-link">My Jobs</a>
                        </li>

                        <li class="nav-item" *ngIf="currentUser?.user_type == 3">
                            <a routerLink="/candidates" class="nav-link">Candidates</a>
                        </li>

                        <li class="nav-item" *ngIf="currentUser?.user_type == 3 || currentUser?.user_type == 2 || isLoggedIn == false">
                            <a routerLink="/companies" class="nav-link">Companies</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/career-advice" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Career Advice</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>
                    </ul>

                    <div class="side-nav">
                        <a class="login-left" routerLink="/register" *ngIf="!isLoggedIn"><i class="flaticon-enter"></i> Register</a>
                        <a class="login-left" routerLink="/login" *ngIf="!isLoggedIn"><i class="flaticon-enter"></i> Login</a>
                        <a class="login-left" routerLink="/employer-dashboard" *ngIf="isLoggedIn && currentUser.user_type==1"><i class="bx bxs-user"></i>My Account</a>
                        <a class="login-left" routerLink="/candidate-dashboard" *ngIf="isLoggedIn && currentUser.user_type==2"><i class="bx bxs-user"></i>My Account</a>
                        <a class="login-left" routerLink="/employer-dashboard" *ngIf="isLoggedIn && currentUser.user_type==3"><i class="bx bxs-user"></i>My Account</a>
                        <a class="job-right" routerLink="/post-a-job" *ngIf="(isLoggedIn && currentUser?.is_sub_user==null && currentUser?.user_type==1) || (isLoggedIn && currentUser?.is_sub_user==null && currentUser?.user_type==3) || (isLoggedIn && currentUser?.is_sub_user==1 && currentUser?.permissions[0]?.show_add_job == 1)">Post A Job <i class='bx bx-plus'></i></a>
                    
                        <select>
                            <option *ngIf="(domain == 'abroadwork.id') || (domain == 'localhost')" value="id">Indonesian</option>
                            <option *ngIf="(domain == 'abroadwork.my') || (domain == 'localhost')" value="ms">Malay</option>
                            <option *ngIf="(domain == 'abroadwork.id') || (domain == 'abroadwork.my') || (domain == 'localhost')" value="en">English</option>
                        </select>
                        <!-- <a class="job-right" *ngIf="(domain == 'abroadwork.id') || (domain == 'localhost')" (click)="changeLanguageToID()"> Indonesian </a>
                        
                        <a class="job-right" *ngIf="(domain == 'abroadwork.my') || (domain == 'localhost')" (click)="changeLanguageToMalay()"> Malay </a>
                        
                        <a class="job-right" *ngIf="(domain == 'abroadwork.id') || (domain == 'abroadwork.my') || (domain == 'localhost')" (click)="changeLanguageToEnglish()"> English </a> -->

                    </div>
                    
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar -->
