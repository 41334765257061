import { Injectable } from '@angular/core';
import { WebUsers } from './WebUsers';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CrudService {
  REST_API: string;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}register`;
    } else {
      this.REST_API = `${environment.apiurl}register`;
    }
  }

  AddWebUsers(data: WebUsers): Observable<any> {
    let API_URL = `${this.REST_API}`;
    return this.httpClient.post(API_URL, data).pipe(catchError(this.handleError))
  }

  GetEmployerProfileDetail(): Observable<any> {
    let API_URL = `${environment.apiurl}employer/profile`;
    return this.httpClient.get(API_URL)
      .pipe(map((res: any) => {
        return res || {}
      }),
        catchError(this.handleError)
      )
  }

  updateEmployerProfile(data: any): Observable<any> {
    let API_URL = `${environment.apiurl}employer/update`;
    return this.httpClient.put(API_URL, data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateEmployerPassword(data: any): Observable<any> {
    let API_URL = `${environment.apiurl}employer/change-password`;
    return this.httpClient.put(API_URL, data)
      .pipe(
        catchError(this.handleError)
      )
  }

  GetCandidateProfileDetail(): Observable<any> {
    let API_URL = `${environment.apiurl}candidate/profile`;
    return this.httpClient.get(API_URL)
      .pipe(map((res: any) => {
        return res || {}
      }),
        catchError(this.handleError)
      )
  }

  updateCandidateProfile(data: any): Observable<any> {
    let API_URL = `${environment.apiurl}candidate/update`;
    return this.httpClient.put(API_URL, data)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateCandidatePassword(data: any): Observable<any> {
    let API_URL = `${environment.apiurl}candidate/change-password`;
    return this.httpClient.put(API_URL, data)
      .pipe(
        catchError(this.handleError)
      )
  }

  GetCandidateResumeDetail(id: any): Observable<any> {
    let API_URL = `${environment.apiurl}candidate/resume/${id}`;
    return this.httpClient.get(API_URL)
      .pipe(map((res: any) => {
        return res || {}
      }),
        catchError(this.handleError)
      )
  }

  addCandidateResume(data: any): Observable<any> {
    let API_URL = `${environment.apiurl}candidate/upload-resume`;
    return this.httpClient.post(API_URL, data).pipe(catchError(this.handleError))
  }

  // Error
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }

  GetCandidateProfileDetailId(id: any): Observable<any> {
    let API_URL = `${environment.apiurl}candidate/profile/${id}`;
    return this.httpClient.get(API_URL).pipe( map( (res: any) => { return res || {} } ), catchError(this.handleError) )
  }

  ResendOTP(id:any): Observable<any> {
    let API_URL = `${environment.apiurl}resend-otp/${id}`;
    return this.httpClient.get(API_URL).pipe( map( (res: any) => { return res || {} } ), catchError(this.handleError) ) 
  }

  getCurrency(): Observable<any> {
    return this.httpClient.get(`${environment.apiurl}select-list/currency`);
  }

  getJobSector(): Observable<any> {
    return this.httpClient.get(`${environment.apiurl}select-list/job-sectors`);
  } 

  validateEmail(data: any, id:any): Observable<any> {
    let API_URL = `${environment.apiurl}candidate/validate/${id}`
    return this.httpClient.put(API_URL, data).pipe( map( (res: any) => { return res || {} } ), catchError(this.handleError) );
  }

}
