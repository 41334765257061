import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/service/blog/blog.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

    //pagination
    page: number = 1;
    itemsPerPage = environment.pagination.itemsPerPage;
    totalItems: any;
    allBlogs: any;
  blogTotalItem: any;
  imgPhotoUrl: string;

  constructor(
    private blogService: BlogService
  ) { }

  ngOnInit(): void {
    this.getAllBlogs(this.page);
    window.localStorage.removeItem('dashboardSearch');
    window.localStorage.removeItem('dashboard-search');
  }

  getAllBlogs(page: any) {
    this.blogService.getAllBlogs(page, this.itemsPerPage).subscribe((res:any) => {
      this.allBlogs = res.data.data;
      this.blogTotalItem = res.data.paging.total;

      this.allBlogs.map((item) => {
        if(!item.blog_image) {
          item.imgPhotoUrl = '../../../../assets/img/default1.png';
        } else {
          item.imgPhotoUrl = `${environment.apiBaseUrl}admin/blog/img/${item.blog_image}`;
        }
      })

    })
  }

}
