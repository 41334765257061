import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CourseTypeService {
  REST_API: string;
  ADMIN_BASE_API: string;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}`;
      this.ADMIN_BASE_API = `${environment.apiAdminUrl}`
    } else {
      this.REST_API = `${environment.apiurl}`;
      this.ADMIN_BASE_API = `${environment.apiAdminUrl}`
    }
  }

  getCourseTypeDropdown() {
    return this.httpClient.get(`${this.REST_API}select-list/course-type`);
  }

  getCourseTypeById(id:any) {
    let API_URL = `${this.ADMIN_BASE_API}masters/course/${id}`;
    return this.httpClient.get(API_URL)
      .pipe(map((res: any) => {
        return res || {}
      }),
        catchError(this.handleError)
      )
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }
}
