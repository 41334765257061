<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Resume</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>
                            <a routerLink="/{{redirectURL}}">Dashboard</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Resume</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- resume type -->
<div class="template-list" style="margin-top: 80px;position: relative;" *ngIf="currentUser.user_type == 2">
    <div class="container">
        <div class="flex">
            <div class="button-container">
                <button type="button" routerLink="/resume/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout1.png" width="130px"></button>
                <button type="button" routerLink="/resume-layout2/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout2.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout3/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout3.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout4/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout4.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout5/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout5.png" width="130px"></button>
            </div>
        </div>
    </div>
</div>

<!-- Resume -->
<div class="person-details-area resume-area ptb-100">
    <div class="default-layout" id="default">
        <div class="container">
            <div class="row">
                
                <div class="containerss">
                    <div class="left_Side">
                        <div class="profileText">
                            <div class="imgBx">
                                <img class="photo" src="{{ imgPhotoUrl }}">
                            </div>
                            <br>
                            <h2>{{ resumeData.name }}</h2>
                        </div>
                
                        <div class="contactInfo">
                            <h3 class="title">Contact Info</h3>
                            <ul>
                                <li>
                                    <span class="icon"><i class="fa fa-phone" aria-hidden="true"></i></span>
                                    <span class="text">{{ resumeData.mobileno }}</span>
                                </li>
                                <li>
                                    <span class="icon"><i class="fa fa-envelope-o" aria-hidden="true"></i></span>
                                    <span class="text">{{ resumeData.emailid }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="contactInfo education">
                            <h3 class="title">Education</h3>
                            <ul>
                                <li *ngFor="let edu of resumeData.candidate_educations">
                                    <h5>{{ edu.university.name }}</h5>
                                    <h4>{{ edu.course_type.name }}</h4>
                                </li>
                            </ul>
                        </div>
                        <div class="contactInfo language">
                            <h3 class="title">Languages</h3>
                            <ul>
                                <li>
                                    <span class="text">English</span>
                                    <span class="percent">
                                        <div class="englishw50"></div>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="right_Side">
                        <div class="about">
                            <h2 class="title2">Profile</h2>
                            <p>{{ resumeData.about_me }}</p>
                        </div>
                        <div class="about">
                            <h2 class="title2">Experience</h2>
                            <div class="box" *ngFor="let exps of resumeData.candidate_experiences">
                                <div class="year_company">
                                    <h5>{{ exps.working_from_month }}/{{ exps.working_from_year }} - {{ exps.working_till_month }}/{{ exps.working_till_year }}</h5>
                                    <h5>{{ exps.organization }}</h5>
                                </div>
                                <div class="text">
                                    <h4>{{ exps.designation }}</h4>
                                    <p>{{ exps.description }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="about skills">
                            <h2 class="title2">Professionals skills</h2>
                            <div class="box" *ngFor="let skills of resumeData.candidate_key_skills">
                                <h4>{{ skills.key_skill.name }}</h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- End Resume -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
