<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Resume</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>
                            <a routerLink="/{{redirectURL}}">Dashboard</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Resume</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- resume type -->
<div class="template-list" style="margin-top: 80px;position: relative;" *ngIf="currentUser.user_type == 2">
    <div class="container">
        <div class="flex">
            <div class="button-container">
                <button type="button" routerLink="/resume/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout1.png" width="130px"></button>
                <button type="button" routerLink="/resume-layout2/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout2.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout3/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout3.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout4/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout4.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout5/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout5.png" width="130px"></button>
            </div>
        </div>
    </div>
</div>

<!-- Resume -->
<div class="person-details-area resume-area ptb-100">
    <div class="default-layout" id="default">
        <div class="container">
            <div class="row">

                <div class="resume">
                    <div class="resume_left">
                        <div class="resume_profile">
                            <img src="{{ imgPhotoUrl }}" alt="profile_pic">
                        </div>
                        <div class="resume_content">

                            <div class="resume_item resume_info">
                                <div class="title">
                                    <p class="bold">{{ resumeData.name }}</p>
                                </div>
                                <ul>
                                    <li>
                                        <!-- <div class="icon">
                                            <i class="fas fa-mobile-alt"></i>
                                        </div> -->
                                        <div class="data">
                                            {{ resumeData.mobileno }}
                                        </div>
                                    </li>
                                    <li>
                                        <!-- <div class="icon">
                                            <i class="fas fa-envelope"></i>
                                        </div> -->
                                        <div class="data">
                                            {{ resumeData.emailid }}
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="resume_item resume_skills">
                                <div class="title">
                                    <p class="bold">Skill's</p>
                                </div>
                                <ul>
                                    <li *ngFor="let skills of resumeData.candidate_key_skills">
                                        <div class="skill_name">
                                            {{ skills.key_skill.name }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="resume_right">

                        <div class="resume_item resume_about">
                            <div class="title">
                                <p class="bold">About Me</p>
                            </div>
                            <p>{{ resumeData.about_me }}</p>
                        </div>

                        <div class="resume_item resume_work">
                            <div class="title">
                                <p class="bold">Work Experience</p>
                            </div>
                            <ul>
                                <li *ngFor="let exps of resumeData.candidate_experiences">
                                    <div class="date">{{ exps.working_from_month }}/{{ exps.working_from_year }} - {{ exps.working_till_month }}/{{ exps.working_till_year }}</div>
                                    <div class="info">
                                        <p class="semi-bold">{{ exps.organization }}</p>
                                        <p>{{ exps.description }}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="resume_item resume_education">
                            <div class="title">
                                <p class="bold">Education</p>
                            </div>
                            <ul>
                                <li *ngFor="let edu of resumeData.candidate_educations">
                                    <div class="info">
                                        <p class="semi-bold">{{ edu.university.name }}</p>
                                        <p>{{ edu.course_type.name }}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- End Resume -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->