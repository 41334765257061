import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  REST_API: string;
  REST_ADMIN_API: string;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}candidate/`;
      this.REST_ADMIN_API = `${environment.apiAdminUrl}`;
    } else {
      this.REST_API = `${environment.apiurl}candidate/`;
      this.REST_ADMIN_API = `${environment.apiAdminUrl}`;
    }
  }

  getLanguage() {
    return this.httpClient.get(`${this.REST_ADMIN_API}masters/languages`);
  }

  addLanguage(data: any) {
    let API_URL = `${this.REST_ADMIN_API}masters/languages`;
    return this.httpClient.post(API_URL, data).pipe(catchError(this.handleError));
  }

  addCandidateLanguage(data:any): Observable<any> {
    let API_URL = `${this.REST_API}add-candidate-language`;
    return this.httpClient.post(API_URL, data).pipe(catchError(this.handleError))
  }

  deleteCandidateLanguage(id:any): Observable<any> {
    let API_URL = `${this.REST_API}candidate-language/${id}`;
    return this.httpClient.delete(API_URL).pipe(catchError(this.handleError))
  }

  getCandidateLanguage(page, itemsPerPage): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}get-candidate-language`, { params: queryParams });
  }

  languageDropdown(): Observable<any> {
    return this.httpClient.get(`${this.REST_ADMIN_API}masters/languages-list`);
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }
}
