<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Terms & Conditions</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Terms & Conditions</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Terms & Conditions -->
<div class="rules-area ptb-100">
    <div class="container">
        <div class="rules-item">
            <p>
                Welcome to AbroadWork.com, a leading job portal connecting job seekers, employers, and HR 
                agencies! These Terms and Conditions govern your use of our website, located at 
                www.abroadwork.com (the "Site"), and the services provided through the Site (the "Services"). By 
                accessing or using the Site or the Services, you agree to be bound by these Terms and Conditions. If 
                you do not agree to these Terms and Conditions, please do not use the Site or the Services.
            </p>

            <h3>1 - Acceptance of Terms</h3>
            <p>By accessing or using the Site or the Services, you agree to be bound by these Terms and 
                Conditions, as well as any additional terms and conditions that may apply to specific sections 
                of the Site or to particular Services.
            </p>

            <h3>2 - User Accounts</h3>
            <ol type="a">
                <li><p>Job Seekers: Job seekers must create an account to access certain features of the 
                    Site, such as searching for job listings, uploading resumes, and applying for jobs. By 
                    creating an account, you agree to provide accurate and complete information and to 
                    keep your account password secure.
                </p></li>
                <li><p>
                    Employers and HR Agencies: Employers and HR agencies must create an account to 
                    post job listings, search for candidates, and access other features of the Site. By 
                    creating an account, you agree to provide accurate and complete information about 
                    your company and to comply with all applicable laws and regulations regarding job 
                    postings and hiring practices.
                </p></li>
            </ol>

            <h3>3 - Use of the Site and Services</h3>
            <p>
                You agree to use the Site and the Services only for lawful purposes and in accordance with 
                these Terms and Conditions. You are responsible for maintaining the confidentiality of your 
                account information and for all activities that occur under your account. 
                You agree not to use the Site or the Services to: <br>
                Violate any applicable law or regulation. Infringe the rights of any third party. Transmit any 
                viruses, worms, or other harmful code. Interfere with the operation of the Site or the 
                Services. We reserve the right to terminate or suspend your access to the Site or the Services 
                at any time, without notice, for any reason
            </p>

            <h3>4 - Job Listings and Applications</h3>
            <ol type="a">
                <li><p>Employers and HR agencies are solely responsible for the accuracy and legality of the job listings they post on the Site.</p></li>
                <li><p>Job seekers may apply for job listings through the Site, but we do not guarantee that you will be contacted by the employer or HR agency or that your application will be successful.</p></li>
                <li><p>We reserve the right to remove any job listing or application that we believe violates these Terms and Conditions or is otherwise inappropriate.</p></li>
            </ol>

            <h3>5 - Intellectual Property</h3>
            <ol type="a">
                <li><p>
                    The Site and the Services, including all content, features, and functionality, are 
                    owned by AbroadWork.com and are protected by copyright, trademark, and other 
                    intellectual property laws.
                </p></li>
                <li><p>
                    You may not reproduce, distribute, modify, or create derivative works of any part of the Site or the Services without our prior written consent.
                </p></li>
            </ol>

            <h3>6 - Third-Party Links</h3>
            <p>
                The Site may contain links to third-party websites or resources. We are not responsible for 
                the content, accuracy, or availability of such websites or resources, and we do not endorse 
                any products or services advertised or offered by third parties.
            </p>

            <h3>7 - Disclaimer of Warranties</h3>
            <p>
                The Site and the Services are provided on an "as is" and "as available" basis, without any 
                warranties of any kind, either express or implied. We do not warrant that the Site or the 
                Services will be uninterrupted or error-free, that defects will be corrected, or that the Site or 
                the Services are free of viruses or other harmful components.
            </p>

            <h3>8 - Limitation of Liability</h3>
            <p>
                In no event shall AbroadWork.com, its affiliates, or their respective officers, directors, 
                employees, or agents be liable for any indirect, incidental, special, consequential, or punitive 
                damages, arising out of or in connection with your use of the Site or the Services.
            </p>

            <h3>9 - Indemnification</h3>
            <p>
                You agree to indemnify and hold AbroadWork.com, its affiliates, and their respective officers, 
                directors, employees, and agents, harmless from and against any claims, liabilities, damages, 
                losses, and expenses, including reasonable attorneys' fees, arising out of or in any way 
                connected with your use of the Site or the Services or your violation of these Terms and 
                Conditions.
            </p>

            <h3>10 -  Changes to Terms and Conditions</h3>
            <p>
                We reserve the right to modify these Terms and Conditions at any time, without prior notice. 
                Any changes will be effective immediately upon posting on the Site. Your continued use of 
                the Site or the Services after any such changes constitutes your acceptance of the revised 
                Terms and Conditions.
            </p>

            <h3>11 - Governing Law</h3>
            <p>
                These Terms and Conditions shall be governed by and construed in accordance with the laws 
                of Philippines, without regard to its conflict of law principles.
            </p>

            <h3>12 - Contact Us</h3>
            <p>
                If you have any questions about these Terms and Conditions, please contact us. 
            </p>

            <p>By using the Site or the Services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>

        </div>
    </div>
</div>
<!-- End Terms & Conditions -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->