import { CityService } from './../../../service/city/city.service';
import { TokenStorageService } from '../../../service/login/token-storage.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployerCompaniesService } from '../../../service/employer_companies/employer_companies.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AgencyService } from '../../../service/agency/agency.service';
import { CountryService } from '../../../service/country/country.service';
import { StateService } from '../../../service/state/state.service';
import { EducationService } from '../../../service/education/education.service';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/service/web_users/web_users.service';
import { JobQualificationService } from 'src/app/service/job-qualification/job-qualification.service';


@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss'],
})
export class CandidatesComponent implements OnInit {
  getId: any;
  apiData: any;
  imgPhotoUrl: any;
  totalRows: any;
  totalAppliedItems: any;
  candidiateStates: any;
  isCandidateStatesLoaded: any;
  updateCandidateState: FormGroup;
  getWebUserId: any;
  saveSucess: boolean;
  successMessage: string;
  error: boolean;
  errorMessage: any;
  currentUser: any;
  searchForm: FormGroup;
  countryList: any;
  stateList: any;
  isCountryLoaded: boolean = false;
  isStateLoaded: boolean;
  paging: any;
  showBoxes: boolean = true;
  showList: boolean = false;
  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  sortForm: FormGroup;

  ageDropdown: any = [
    { id: 1, value: "15 - 20" },
    { id: 2, value: "20 - 25" },
    { id: 3, value: "25 - 30" },
    { id: 4, value: "30 - 35" },
    { id: 5, value: "35 - 40" },
  ]
  expDropdown: any = [
    { id: 1, value: "0 - 1" },
    { id: 2, value: "1 - 2" },
    { id: 3, value: "2 - 3" },
    { id: 4, value: "3 - 4" },
    { id: 5, value: "4 - 5" },
  ]
  qualificationList: any;
  candidateData: any;
  selected_state: any;
  candidateFilteredData: any;
  cityList: any;
  isQualificationLoaded: boolean = false;
  isSearchValueExists: boolean = false;

  isMobileView: boolean = false;

  constructor(
    private token: TokenStorageService,
    private activatedRoute: ActivatedRoute,
    private employerCompaniesService: EmployerCompaniesService,
    public formBuilder: FormBuilder,
    private agencyService: AgencyService,
    private countryService: CountryService,
    private stateService: StateService,
    private educationService: EducationService,
    private crudService: CrudService,
    private ngZone: NgZone,
    private router: Router,
    private cityService: CityService,
    private jobQualificationService: JobQualificationService
  ) {

    this.searchForm = this.formBuilder.group({
      term: [''],
      country: [''],
      state: [''],
      city: [''],
      qualification: [''],
      experience: ['']
    })
    this.currentUser = this.token.getUser();

    this.getId = this.activatedRoute.snapshot.paramMap.get('id');

    this.sortForm = this.formBuilder.group({ sort:[''] });

    this.updateCandidateState = this.formBuilder.group({
      state: [''],
    });
  }

  ngOnInit(): void {
    if (window.screen.width <= 768) {
      this.isMobileView = true;
    }
    this.getListOfAppliedCandidates(this.page);
    this.getCandidateSelectedState();
    this.countryDropdown();
    this.qualificationDropdown();
  }

  getListOfAppliedCandidates(page) {
    this.employerCompaniesService.GetCandidateListofAppliedJob(this.getId, page, this.itemsPerPage, this.searchForm.value, this.sortForm.value).subscribe((res: any) => {
      this.apiData = res.data;
      this.paging = res.paging;
      this.totalAppliedItems = res.paging.total;
      this.apiData.length > 0 ? this.isSearchValueExists = true : this.isSearchValueExists = false;
      this.apiData.map((item) => {
        if (!item.web_user.photo) {
          item.imgPhotoUrl = '../../../../assets/img/default1.png';
        } else {
          item.imgPhotoUrl = `${environment.apiBaseUrl}candidate/profile/${item.web_user.photo}`;
        }
      });
    });
  }

  getCandidateSelectedState() {
    this.employerCompaniesService.GetCandidateSelectedState().subscribe((res) => {
        this.isCandidateStatesLoaded = true;
        this.candidiateStates = res.data;
      });
  }

  getIds(webUserId: any): any {
    this.getWebUserId = webUserId;
  }

  updateState(event: any): any {
    this.selected_state = event.target.value;
    // this.save();
  }

  save(){
    let payload = { state: this.selected_state}
    this.employerCompaniesService.updateCandidateState(this.getWebUserId, this.getId, payload).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Candidate State Updated Successfully';
      window.location.reload();
    },
      (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      }
    );
  }

  countryDropdown () {
    this.countryService.getCountryDropdown().subscribe((res: any) => {
    this.isCountryLoaded = true;
      this.countryList = res.data;
    });
  }

  getState(countryId: string) {
    this.stateService.getStateListDropdown(countryId).subscribe((res: any) => {
      this.stateList = res.data;
    });
  }

  getCity(stateId: string) {
    this.cityService.getCityListDropdown(stateId).subscribe((res:any) => {
      this.cityList = res.data;
    })
  }

  qualificationDropdown() {
    this.jobQualificationService.getJobQualificationDropdown().subscribe((res:any) => {
      this.isQualificationLoaded = true;
      this.qualificationList = res.data;
    })
  }

  searchFilter(): any {
    this.employerCompaniesService.GetCandidateListofAppliedJob(this.getId, this.page, this.itemsPerPage, this.searchForm.value, this.sortForm.value).subscribe((res:any) => {
      this.apiData = res.data;
      this.paging = res.paging;
      this.totalAppliedItems = res.paging.total;
      this.apiData.length > 0 ? this.isSearchValueExists = true : this.isSearchValueExists = false;
      this.apiData.map((item) => {
        if (!item.web_user.photo) {
          item.imgPhotoUrl = '../../../../assets/img/default1.png';
        } else {
          item.imgPhotoUrl = `${environment.apiBaseUrl}candidate/profile/${item.web_user.photo}`;
        }
      });
    });
  }

  resetFilter(): any {
    window.location.reload();
  }

  showListFunction() {
    this.showBoxes = false;
    this.showList = true;
  }

  showBoxesFunction() {
    this.showList = false;
    this.showBoxes = true;
  }

  viewResume(id:any, first_name:any, last_name:any) {
    this.employerCompaniesService.GetCandidateListofAppliedJob(this.getId, this.page, this.itemsPerPage, this.searchForm.value, this.sortForm.value).subscribe((res:any) => {
      res.data.map((item) => {
        if (item.candidate_resume != null) {
          window.open(`${environment.apiBaseUrl}candidate/resume/${item.candidate_resume.resume_name}`, "_blank");
        } else {
          const firstName = first_name.replace(/[\s\/]+/g, '-').toLowerCase();
          const lastName = last_name.replace(/[\s\/]+/g, '-').toLowerCase();
          const resumeTitle = firstName + '-' + lastName;
          this.ngZone.run(() => this.router.navigateByUrl(`/resume/${id}/${resumeTitle}`));
        }
      })
    })
  }

  viewPassport(documentName) {
    window.open(`${environment.apiBaseUrl}candidate/documents/${documentName}`, "_blank");
  }

  viewCertificate(documentName) {
    window.open(`${environment.apiBaseUrl}candidate/documents/${documentName}`, "_blank");
  }

  viewCoverLetter(documentName) {
    window.open(`${environment.apiBaseUrl}candidate/documents/${documentName}`, "_blank");
  }

  viewDocument1(documentName) {
    window.open(`${environment.apiBaseUrl}candidate/documents/${documentName}`, "_blank");
  }

  viewDocument2(documentName) {
    window.open(`${environment.apiBaseUrl}candidate/documents/${documentName}`, "_blank");
  }

  getCandidateDetail() {
    this.employerCompaniesService.GetCandidateListofAppliedJob(this.getId, this.page, this.itemsPerPage, this.searchForm.value, this.sortForm.value).subscribe((res: any) => {
      this.candidateFilteredData = res.data.filter(item => item.web_users_id == this.getWebUserId);
      this.candidateFilteredData.map((item) => {
        if (!item.web_user.photo) {
          item.imgPhotoUrl = '../../../../assets/img/default1.png';
        } else {
          item.imgPhotoUrl = `${environment.apiBaseUrl}candidate/profile/${item.web_user.photo}`;
        }
      });
    });
  }

}
