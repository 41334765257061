<!-- Footer -->
<footer class="footer-area three pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-5">
                <div class="footer-item three">
                    <div class="footer-logo">
                        <a class="logo" routerLink="/" (click)="reloadPage()"><img src="assets/img/main_logo2.png" alt="Logo" style="width: 220px;"></a>
                        <ul>
                            <li><span>Address: </span>UNIT 17A8 VICTORIA STATION 1 793 <br>
                                EDSA SACRED HEART, QUEZON CITY, SECOND DISTRICT, <br>
                                NATIONAL CAPITAL REGION (NCR), Philippines</li>
                                <li><span>Message: </span><a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@abroadwork.com" style="text-decoration: none;">info@abroadwork.com</a></li>
                            <li><span>Phone: </span><a href="tel:2151234567" style="text-decoration: none;">+63 919 854 0111</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Company -->
            <div class="col-sm-6 col-lg-2" *ngIf="(currentUser?.user_type == 1 && currentUser?.is_sub_user == null) || (currentUser?.is_sub_user != null && currentUser?.permissions[0]?.show_add_job == 1) || (currentUser?.is_sub_user != null && currentUser?.permissions[0]?.show_job == 1)">
                <div class="footer-item three">
                    <div class="footer-service">
                        <h3>Important Links</h3>

                        <ul>
                            <li *ngIf="(currentUser?.user_type == 1 && currentUser?.is_sub_user == null) || (currentUser?.is_sub_user != null && currentUser?.permissions[0]?.show_add_job == 1)"><a [routerLink]="['/post-a-job']" style="text-decoration: none;">Post Job</a></li>
                            <li *ngIf="(currentUser?.user_type == 1 && currentUser?.is_sub_user == null) || (currentUser?.is_sub_user != null && currentUser?.permissions[0]?.show_job == 1)"><a [routerLink]="['/employer-dashboard']" (click)="gotoMyJobs()" style="text-decoration: none;">My Jobs</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Candidate -->
            <div class="col-sm-6 col-lg-2" *ngIf="isLoggedIn == false || currentUser?.user_type == 2">
                <div class="footer-item three">
                    <div class="footer-service">
                        <h3>Jobs Categories</h3>

                        <ul>
                            <li><a (click)="gotoAccountant()" style="text-decoration: none; cursor: pointer;">Accountant</a></li>
                            <li><a (click)="gotoMarketingManager()" style="text-decoration: none; cursor: pointer;">Marketing Manager</a></li>
                            <li><a (click)="gotoConsultant()" style="text-decoration: none; cursor: pointer;">Consultant</a></li>
                            <li><a (click)="gotoSoftwareDev()" style="text-decoration: none; cursor: pointer;">Web & Software Dev</a></li>
                            <li><a (click)="gotoTechnician()" style="text-decoration: none; cursor: pointer;">Technician</a></li>
                            <li><a (click)="gotoPlumber()" style="text-decoration: none; cursor: pointer;">Plumber</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- HR -->
            <div class="col-sm-6 col-lg-2" *ngIf="currentUser?.user_type == 3">
                <div class="footer-item three">
                    <div class="footer-service">
                        <h3>Candidate Categories</h3>

                        <ul>
                            <!-- <li><a [routerLink]="['/candidates']" [queryParams]="{candidate_category: '13'}" style="text-decoration: none;">Accountant</a></li>
                            <li><a [routerLink]="['/candidates']" [queryParams]="{candidate_category: '25'}" style="text-decoration: none;">Marketing Manager</a></li>
                            <li><a [routerLink]="['/candidates']" [queryParams]="{candidate_category: '22'}" style="text-decoration: none;">Consultant</a></li>
                            <li><a [routerLink]="['/candidates']" [queryParams]="{candidate_category: '16'}" style="text-decoration: none;">Web & Software Dev</a></li>
                            <li><a [routerLink]="['/candidates']" [queryParams]="{candidate_category: '6'}" style="text-decoration: none;">Technician</a></li>
                            <li><a [routerLink]="['/candidates']" [queryParams]="{candidate_category: '5'}" style="text-decoration: none;">Plumber</a></li> -->
                            <li><a (click)="gotoCandidateAccountant()" style="text-decoration: none; cursor: pointer;">Accountant</a></li>
                            <li><a (click)="gotoCandidateMarketingManager()" style="text-decoration: none; cursor: pointer;">Marketing Manager</a></li>
                            <li><a (click)="gotoCandidateConsultant()" style="text-decoration: none; cursor: pointer;">Consultant</a></li>
                            <li><a (click)="gotoCandidateSoftwareDev()" style="text-decoration: none; cursor: pointer;">Web & Software Dev</a></li>
                            <li><a (click)="gotoCandidateTechnician()" style="text-decoration: none; cursor: pointer;">Technician</a></li>
                            <li><a (click)="gotoCandidatePlumber()" style="text-decoration: none; cursor: pointer;">Plumber</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="footer-item three">
                    <div class="footer-service">
                        <h3>Useful Links</h3>

                        <ul>
                            <li><a routerLink="/privacy-policy" style="text-decoration: none;">Privacy Policy</a></li>
                            <li><a routerLink="/terms-conditions" style="text-decoration: none;">Terms & Conditions</a></li>
                            <li><a routerLink="/jobs" style="text-decoration: none;">Jobs</a></li>
                            <li><a routerLink="/companies" style="text-decoration: none;">Companies</a></li>
                            <li><a routerLink="/career-advice" style="text-decoration: none;">Career Advice</a></li>
                            <li><a routerLink="/contact" style="text-decoration: none;">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item three">
                    <div class="footer-service">
                        <h3>Our Partner Groups</h3>
                        <ul>
                            <li><a>
                                <img src="assets/img/home-one/Mahad_Jobs_Logo.png" style="top: -10px; height: 60px; left: 50px; position: relative;" />
                            </a></li>
                            <li><a>
                                <img src="assets/img/home-one/Artboard.png" style="top: -10px; height: 60px; left: 50px; position: relative;" />
                            </a></li>
                            <li><a>
                                <img src="assets/img/home-one/Frame.png" style="top: -10px; height: 60px; left: 50px; position: relative;" />
                            </a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-6 col-lg-3">
                <div class="footer-item three">
                    <div class="footer-newsletter">
                        <h3>Newsletter</h3>
                        <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed</p>
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Your email*" name="EMAIL" required autocomplete="off">
                            <button class="btn" type="submit"><i class="flaticon-send"></i></button>
                        </form>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</footer>
<!-- End Footer -->

<!-- Copyright -->
<div class="copyright-area three">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin-square'></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <p>© Abroadwork is Proudly Owned by <a href="https://mahadgroup.com/" target="_blank" style="text-decoration: none;">Mahad Groups</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Copyright -->