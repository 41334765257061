
import { JobExperienceService } from './../../../service/job-experience/job-experience.service';
import { JobQualificationService } from './../../../service/job-qualification/job-qualification.service';
import { Component, OnInit } from '@angular/core';
import { AgencyService } from '../../../service/agency/agency.service';
import { CountryService } from '../../../service/country/country.service';
import { StateService } from '../../../service/state/state.service';
import { CityService } from 'src/app/service/city/city.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { JobTypeService } from 'src/app/service/job-type/job-type.service';
import { JobCategoryService } from 'src/app/service/job-category/job-category.service';
import { environment } from './../../../../environments/environment';
import { TokenStorageService } from 'src/app/service/login/token-storage.service';
import { EmployerCompaniesService } from 'src/app/service/employer_companies/employer_companies.service';
import { CrudService } from 'src/app/service/web_users/web_users.service';

@Component({
  selector: 'app-all-companies',
  templateUrl: './all-companies.component.html',
  styleUrls: ['./all-companies.component.scss']
})
export class AllCompaniesComponent {
  profileCompleted: string;
  currentUser: any;
  searchForm: any;

  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  totalItems: any;
  isCountryLoaded: boolean;
  countryList: any;
  stateList: any;
  isJobQualificationLoaded: boolean;
  jobQualificationList: any;
  isJobTypeLoaded: boolean;
  jobTypeList: any;
  isJobCategoryLoaded: boolean;
  jobCategoryList: any;
  isJobExperienceLoaded: boolean;
  jobExperienceList: any;
  apiData: any;
  paging: any;
  showBoxes: boolean = true;
  showList: boolean = false;
  imgPhotoUrl: string;
  cityList: any;
  sortForm: FormGroup;
  isSearchValueExists: boolean = false;
  sectorList: any;
  isSectorListLoaded: boolean = false;

  isMobileView: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private agencyService: AgencyService,
    private countryService: CountryService,
    private stateService: StateService,
    private jobTypeService: JobTypeService,
    private jobCategoryService: JobCategoryService,
    private jobQualificationService: JobQualificationService,
    private jobExperienceService: JobExperienceService,
    private token: TokenStorageService,
    private employerCompaniesService: EmployerCompaniesService,
    private cityService: CityService,
    private crudService: CrudService
    ) {

      this.profileCompleted = this.token.getProfileScore();
      this.currentUser = this.token.getUser();

      this.sortForm = this.formBuilder.group({ sort:[''] });

      this.searchForm = this.formBuilder.group({
        title: [''],
        // job_type: [''],
        sector: [''],
        country: [''],
        state: [''],
        city: [''],
        // qualification: [''],
        // experience: ['']
      })

  }

  ngOnInit(): void {
    if (window.screen.width <= 768) {
      this.isMobileView = true;
    }
    this.getAllCompanies(this.page);
    this.countryDropdown();
    this.qualificationDropdown();
    this.jobTypeDropdown();
    this.jobCategoryDropdown();
    this.jobExperienceDropdown();
    this.sectorDropdown();
    window.localStorage.removeItem('dashboardSearch');
    window.localStorage.removeItem('dashboard-search');
  }

  getAllCompanies(page:any) {
    this.employerCompaniesService.GetEmployerCompaniesFilter(page, this.itemsPerPage, this.searchForm.value, this.sortForm.value).subscribe((res: any) => {
      this.apiData = res.data;
      this.paging = res.paging;
      this.totalItems = res.paging.total;
      this.apiData.length > 0 ? this.isSearchValueExists = true : this.isSearchValueExists = false;
      this.apiData.map((item) => {
        if(!item.web_user.photo) {
          item.imgPhotoUrl = '../../../../assets/img/default1.png';
        } else {
          item.imgPhotoUrl = `${environment.apiBaseUrl}employer/${item.web_user.photo}`;
        }
        item.totalJobs = item.jobs.filter(item => item.submit_type == 1).length;
        item.companyTitle = item.company_name.replace(/[\s\/]+/g, '-').toLowerCase();
      })
    })
  }

  countryDropdown () {
    this.countryService.getCountryDropdown().subscribe((res: any) => {
      this.isCountryLoaded = true;
      this.countryList = res.data;
    });
  }

  getState(countryId: string) {
    this.stateService.getStateListDropdown(countryId).subscribe((res: any) => {
      this.stateList = res.data;
    });
  }

  getCity(stateId: string) {
    this.cityService.getCityListDropdown(stateId).subscribe((res: any) => {
      this.cityList = res.data;
    });
  }

  qualificationDropdown() {
    this.jobQualificationService.getJobQualificationDropdown().subscribe((res: any) => {
      this.isJobQualificationLoaded = true;
      this.jobQualificationList = res.data;
    });
  }

  jobTypeDropdown() {
    this.jobTypeService.getJobTypeDropdown().subscribe((res: any) => {
      this.isJobTypeLoaded = true;
      this.jobTypeList = res.data;
    });
  }

  jobCategoryDropdown() {
    this.jobCategoryService.getJobCategoryDropdown().subscribe((res: any) => {
      this.isJobCategoryLoaded = true;
      this.jobCategoryList = res.data;
    });
  }

  jobExperienceDropdown() {
    this.jobExperienceService.getJobExperienceDropdown().subscribe((res: any) => {
      this.isJobExperienceLoaded = true;
      this.jobExperienceList = res.data;
    })
  }

  sectorDropdown() {
    this.crudService.getJobSector().subscribe((res:any) => {
      this.isSectorListLoaded = true;
      this.sectorList = res.data;
    })
  }

  searchFilter(): any {
    this.employerCompaniesService.GetEmployerCompaniesFilter(this.page, this.itemsPerPage, this.searchForm.value, this.sortForm.value).subscribe((res: any) => {
      this.apiData = res.data;
      this.paging = res.paging;
      this.totalItems = res.paging.total;
      this.apiData.length > 0 ? this.isSearchValueExists = true : this.isSearchValueExists = false;
      this.apiData.map((item) => {
        if(!item.web_user.photo) {
          item.imgPhotoUrl = '../../../../assets/img/default1.png';
        } else {
          item.imgPhotoUrl = `${environment.apiBaseUrl}employer/${item.web_user.photo}`;
        }
        item.totalJobs = item.jobs.filter(item => item.submit_type == 1).length;
      })
    })
  }

  resetFilter(): any {
    window.location.reload();
  }

  showListFunction() {
    this.showBoxes = false;
    this.showList = true;
  }

  showBoxesFunction() {
    this.showList = false;
    this.showBoxes = true;
  }

}
