<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Contact</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Info -->
<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="info-item">
                    <i class='bx bx-world'></i>
                    <h3>Qatar</h3>
                    <p>Mahad Manpower Co WLL
                        Office No 32
                        2nd Floor
                        Building no 50
                        Street no 185 (Al Aziziya Street)
                        Zone no 55
                        Al Aziziya, Al Rayyan, Qatar
                    </p>
                    <ul>
                        <li><span>Mobile:</span> <a href="tel:+97444423199">+974 4442 3199 </a></li>
                        <li><span>Email:</span> <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@mahadmanpower.com">info@mahadmanpower.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="info-item">
                    <i class='bx bx-world'></i>
                    <h3>Philippines</h3>
                    <p>UNIT 17A8 VICTORIA STATION 1 793 EDSA SACRED HEART , QUEZON CITY, SECOND DISTRICT, NATIONAL CAPITAL REGION (NCR), Philippines</p>
                    <ul>
                        <li><span>Mobile:</span> <a href="tel:+639198540111">+639198540111</a></li>
                        <li><span>Email:</span> <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@mahadrecruitment.ph">info@mahadrecruitment.ph</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="info-item">
                    <i class='bx bx-world'></i>
                    <h3>India</h3>
                    <p>2/3 Prema Niwas
                        NES Road, Battipada
                        Bhandup West
                        Mumbai, Maharashtra, India
                    </p>
                    <ul>
                        <li><span>Mobile:</span> <a href="tel:+912225942786 ">+912225942786</a></li>
                        <li><span>Email:</span> <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@mahadmanpower.in">info@mahadmanpower.in</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Info -->

<!-- Contact -->
<div class="contact-form-area pb-100">
    <div class="container">
        <div class="form-item">
            <h2>Contact Us</h2>

            <div *ngIf="saveSucess" class="alert alert-success">
                <strong [class]="FadeOutSuccessMsg()">{{ successMessage }}</strong>
            </div>
            <div *ngIf="error" class="alert alert-danger">
                {{ errorMessage }}
            </div>

            <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
                <div class="post-item">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="name" placeholder="Name" id="name" class="form-control" required formControlName="name">
                                <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                                    <div *ngIf="f.name.errors?.required">
                                      This is required.
                                    </div>
                                    <div *ngIf="f.name.errors?.pattern">
                                        Only Alhpabets are Allowed.
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" name="email" placeholder="Email Id" id="email" class="form-control" required formControlName="emailId">
                                <div *ngIf="f.emailId.invalid && (f.emailId.dirty || f.emailId.touched)" class="alert alert-danger">
                                    <div *ngIf="f.emailId.errors?.required">
                                      This is required.
                                    </div>
                                    <div *ngIf="f.emailId.errors?.email">
                                        Email must be a valid email address
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Dial Code</label>
                                <select class="form-control" formControlName="dial_code" placeholder="Select Dial Code">
                                    <option value="">Dial Code</option>
                                    <option *ngFor="let item of dialCodeList" [value]="item.id">{{ item.name }}({{ item.dial_code }})
                                    </option>
                                </select>
                                <div *ngIf="f.dial_code.invalid && (f.dial_code.dirty || f.dial_code.touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="f.dial_code.errors?.required">
                                        Dial Code is required.
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label>Phone Number</label>
                                <input type="text" name="phone_number" placeholder="Phone Number" id="phone_number" required class="form-control" formControlName="mobileNumber">
                                <div *ngIf="f.mobileNumber.invalid && (f.mobileNumber.dirty || f.mobileNumber.touched)" class="alert alert-danger">
                                    <div *ngIf="f.mobileNumber.errors?.required">
                                      This is required.
                                    </div>
                                    <div *ngIf="f.mobileNumber.errors?.pattern">
                                        Only Numbers are Allowed.
                                    </div>
                                    <div *ngIf="f.mobileNumber.errors?.minlength">
                                        Enter a Valid Phone Number.
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-sm-12 col-lg-12">
                            <div class="form-group">
                                <label>Subject</label>
                                <input type="text" name="msg_subject" placeholder="Subject" id="msg_subject" class="form-control" required formControlName="subject">
                                <div *ngIf="f.subject.invalid && (f.subject.dirty || f.subject.touched)" class="alert alert-danger">
                                    <div *ngIf="f.subject.errors?.required">
                                      This is required.
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-md-12 col-lg-12">
                            <div class="form-group">
                                <label>Message</label>
                                <textarea name="message" class="form-control" placeholder="Message" id="message" cols="30" rows="8" required formControlName="message"></textarea>
                                <div *ngIf="f.message.invalid && (f.message.dirty || f.message.touched)" class="alert alert-danger">
                                    <div *ngIf="f.message.errors?.required">
                                      This is required.
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <!-- <div class="col-md-12 col-lg-12"> -->
                            <button type="submit" class="btn" [disabled]="!contactUsForm.valid">Send Message</button>
                        <!-- </div> -->
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Contact -->

<!-- Map -->
<div class="map-area">
    <div class="container-fluid p-0">
        <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59843174.53779285!2d62.17507173408571!3d23.728204508550363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3663f18a24cbe857%3A0xa9416bfcd3a0f459!2sAsia!5e0!3m2!1sen!2sbd!4v1599227927146!5m2!1sen!2sbd"></iframe>
    </div>
</div>
<!-- End Map -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->