import { EmployerCompanies } from './../../../service/employer_companies/EmployerCompanies';
import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../service/login/login.service';
import { TokenStorageService } from '../../../service/login/token-storage.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { EmployerCompaniesService } from 'src/app/service/employer_companies/employer_companies.service';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLoggedIn = false;
  saveSucess: boolean = false;
  error: boolean = false;
  errorMessage: string = "";
  successMessage: string = "";
  loginForm: FormGroup;
  currentUser: any;
  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  EmployerCompanies: any;
  totalItems: any;
  show: boolean = false;
  registerEmailId: string;
  employerId: any;

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private employerCompaniesService: EmployerCompaniesService
  ) {
    this.loginForm = this.formBuilder.group({
      emailid: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    })
  }

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.currentUser = this.tokenStorage.getUser();
      if(this.currentUser.admin_id == 0 || this.currentUser.admin_id == null){
        this.employerId = this.currentUser.id;
      } else {
        this.employerId = this.currentUser.admin_id;
      }
    }
    this.employerCompaniesService.GetEmployerCompanies(this.page, this.itemsPerPage, this.employerId).subscribe((res:any) => {
      this.EmployerCompanies = res.data;
      this.totalItems = res.paging.total;
      if (this.currentUser.user_type == 1 && this.EmployerCompanies.length <= 0){
        this.ngZone.run(() => this.router.navigateByUrl('/company-registration-form'))
      } else if (this.currentUser.user_type == 1 && this.EmployerCompanies.length > 0) {
        this.ngZone.run(() => this.router.navigateByUrl('/employer-dashboard'))
      } else if (this.currentUser.user_type == 2) {
        this.ngZone.run(() => this.router.navigateByUrl('/candidate-dashboard'))
      } else if (this.currentUser.user_type == 3 && this.EmployerCompanies.length <= 0) {
        this.ngZone.run(() => this.router.navigateByUrl('/company-registration-form'))
      } else if (this.currentUser.user_type == 3 && this.EmployerCompanies.length > 0) {
        this.ngZone.run(() => this.router.navigateByUrl('/employer-dashboard')) 
      }
    })
  }

  onSubmit(): any {
    this.authService.checkLogin(this.loginForm.value).subscribe((res: any) => {
        if (res.data.is_valid == 0 || res.data.is_valid == null) {
          this.error = true;
          this.errorMessage = "Email is not Verified. Redirecting to Email Verification..."
          setTimeout(() => {
            this.router.navigateByUrl("/otp-validation/" + res.data.id)
          },2000);
        } else {
          this.tokenStorage.saveToken(res.token);
          this.tokenStorage.saveUser(res.data);
          this.isLoggedIn = true;
          this.saveSucess = true;
          this.error = false;
          this.successMessage = "Login Successfully";
          setTimeout(() => {
            this.reloadPage();
          },1000);
        }
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else if (err.status == 400) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
  }

  reloadPage(): void {
    window.location.reload();
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.saveSucess = false;
    }, 5000);
  }

  gotoRegister() {
    this.ngZone.run(() => this.router.navigateByUrl('/register')) 
  }

  showpassword() {
    this.show = !this.show;
  }

  forgotPassword() {
    let data = { emailid: this.registerEmailId };
    this.authService.forgotPassword(data).subscribe((res:any) => {
      this.saveSucess = true;
        this.error = false;
        this.successMessage = "Password has been Sent on Your Registered Email Id.";
        this.reloadPage();
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else if (err.status == 400) {
          this.errorMessage = err.error.message;
        } else {
          // this.errorMessage = err.statusText;
          this.errorMessage = "Kindly enter registered Email Id."
        }
    })
  }

}
