import { Component, OnInit, Input } from '@angular/core';
import { environment } from './../../../../environments/environment';


@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  @Input() childSearchResult;
  apiBaseUrl = environment.apiBaseUrl;

  ngOnInit(): void {
    console.log("searchResult",this.childSearchResult);
  }
  
}
