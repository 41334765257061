import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { CandidatesDetailsComponent } from './components/pages/candidates-details/candidates-details.component';
import { CandidatesComponent } from './components/pages/applied-candidates/candidates.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { EmployerDashboardComponent } from './components/pages/dashboard/employer-dashboard.component';
import { EmployerJobsComponent } from './components/pages/employer-jobs/employer-jobs.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { CandidateDashboardComponent } from './components/pages/dashboard/candidate-dashboard.component';
import { EmployersDetailsComponent } from './components/pages/employers-details/employers-details.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { FavouriteJobsComponent } from './components/pages/favourite-jobs/favourite-jobs.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { JobDetailsComponent } from './components/pages/job-details/job-details.component';
import { JobsComponent } from './components/pages/jobs/jobs.component';
import { LoginComponent } from './components/pages/login/login.component';
import { PostAJobComponent } from './components/pages/post-a-job/post-a-job.component';
import { EditJobComponent } from './components/pages/edit-job/edit-job.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ResumeDetailsComponent } from './components/pages/resume-details/resume-details.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { AuthGuard } from './components/pages/login/auth.guard';
import { SearchResultComponent } from './components/pages/search-result/search-result.component';
import { CompanyRegistrationFormComponent } from './components/pages/company-registration-form/company-registration-form.component';
import { CompanyEditFormComponent } from './components/pages/company-edit-form/company-edit-form.component';
import { ResumeLayout1Component } from './components/pages/resume-layout1/resume-layout1.component';
import { ResumeLayout2Component } from './components/pages/resume-layout2/resume-layout2.component';
import { ResumeLayout3Component } from './components/pages/resume-layout3/resume-layout3.component';
import { ResumeLayout4Component } from './components/pages/resume-layout4/resume-layout4.component';
import { ResumeLayout5Component } from './components/pages/resume-layout5/resume-layout5.component';
import { AllCandidateComponent } from './components/pages/all-candidate/all-candidate.component';
import { AllJobsComponent } from './components/pages/all-jobs/all-jobs.component';
import { OtpValidationComponent } from './components/pages/otp-validation/otp-validation.component';
import { AllCompaniesComponent } from './components/pages/all-companies/all-companies.component';
import { CompanyDetailComponent } from './components/pages/company-detail/company-detail.component';


const routes: Routes = [
    { path: '', component: HomeOneComponent },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'employers', component: EmployersComponent },
    { path: 'employer-details', component: EmployersDetailsComponent },
    { path: 'employer-dashboard', component: EmployerDashboardComponent, canActivate: [AuthGuard] },
    { path: 'employer-jobs', component: EmployerJobsComponent, canActivate: [AuthGuard] },
    { path: 'edit-job/:id', component: EditJobComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'candidate-dashboard', component: CandidateDashboardComponent, canActivate: [AuthGuard] },
    { path: 'resume/:id/:title', component: ResumeDetailsComponent },
    { path: 'testimonials', component: TestimonialsComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'terms-conditions', component: TermsConditionsComponent },
    { path: 'about', component: AboutComponent },
    { path: 'favourite-jobs', component: FavouriteJobsComponent },
    { path: 'post-a-job', component: PostAJobComponent },

    { path: 'career-advice', component: BlogComponent },
    { path: 'career-advice/:slug', component: BlogDetailsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'search-result', component: SearchResultComponent },
    { path: 'company-registration-form', component: CompanyRegistrationFormComponent},
    { path: 'company-edit-form/:id', component: CompanyEditFormComponent},

    { path: 'resume-layout1/:id', component: ResumeLayout1Component },
    { path: 'resume-layout2/:id', component: ResumeLayout2Component },
    { path: 'resume-layout3/:id', component: ResumeLayout3Component },
    { path: 'resume-layout4/:id', component: ResumeLayout4Component },
    { path: 'resume-layout5/:id', component: ResumeLayout5Component },

    { path: 'candidates', component: AllCandidateComponent },
    { path: 'candidates/:title', component: AllCandidateComponent },
    { path: 'applied-candidates/:id', component: CandidatesComponent },
    { path: 'candidate/:id/:title', component: CandidatesDetailsComponent },
    
    { path: 'search', component: AllJobsComponent },
    { path: 'search/:title', component: AllJobsComponent },
    { path: 'search/:title/:job_category', component: AllJobsComponent },
    { path: 'search/:title/:job_category/:country', component: AllJobsComponent },
    { path: 'search/:title/:job_category/:country/:state', component: AllJobsComponent },
    { path: 'search/:title/:job_category/:country/:state/:city', component: AllJobsComponent },
    { path: 'jobs', component: AllJobsComponent },
    { path: 'job/:id/:title', component: JobDetailsComponent },
    { path: 'jobs/:title', component: AllJobsComponent },
    { path: 'otp-validation/:id', component: OtpValidationComponent },
    { path: 'companies', component: AllCompaniesComponent },
    { path: 'company/:id/:title', component: CompanyDetailComponent },

    { path: '**', component: ErrorComponent } // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }