<!-- OTP Validation -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User">
                </div>
            </div>

            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>OTP Validation</h2>
                        <div *ngIf="saveSucess" class="alert alert-success">
                            <strong [class]="FadeOutSuccessMsg()">{{ successMessage }}</strong>
                        </div>
                        <div *ngIf="error" class="alert alert-danger">
                            {{ errorMessage }}
                        </div>
                        
                        <!-- <form  *ngIf="!isLoggedIn" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <input class="form-control" type="email" formControlName="emailid"
                                    placeholder="Email Id">
                                <div *ngIf="f.emailid.invalid && (f.emailid.dirty || f.emailid.touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="f.emailid.errors?.required">
                                        Email Id is required.
                                    </div>
                                    <div *ngIf="f.emailid.errors?.email">
                                        Email must be a valid email address
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <input class="form-control" type="password" formControlName="password"
                                    placeholder="Password">
                                <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="f.password.errors?.required">
                                        Password is required.
                                    </div>
                                </div>
                            </div>

                            <button type="submit" class="btn" [disabled]="!loginForm.valid">Login Here</button>
                        </form> -->

                        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>

                        <p class="p-login">Haven't Received OTP?
                            <span *ngIf="counterStatus">Resend it in : {{transform(counter)}}</span>
                            <button type="button" class="resend-otp-btn" *ngIf="!counterStatus" [disabled]="resendedOTP == true" (click)="resendOTP()">Resend OTP</button>
                        </p>
                    </div>

                    <div class="end">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End OTP Validation -->
