<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Banner -->
<div class="banner-area">
    <!-- <div class="banner-shape"><img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape"></div> -->
        
        <div class="container-fluid">
            <div class="row">
                <div class="col-10 col-md-6 col-lg-7">
                    <div class="banner-content">
                        <div class="d-table">
                            <div class="d-table-cell" (click)="showTrendingKeywords()">
                                <div class="text-container-hero">
                            <h1>Connect, Apply, Succeed</h1><h1> Opportunities Await on <span>Abroad Work</span></h1>
                            <p>Unlock your career potential with our diverse job opportunities today!</p>
                        </div>
                            <!-- <div class="banner-check"> -->
                            <div class="banner-form-area">
                                <form [formGroup]="searchForm">
                                    <div class="row">
                                        <div class="col-lg-2 col-md-12">
                                            <div class="form-group">
                                                <label><i class='bx bx-search'></i></label>
                                                <input type="text" class="form-control" placeholder="Search" formControlName="title">
                                            </div>
                                        </div>

                                        <div class="col-lg-2 col-md-12">
                                            <div class="form-group">
                                                <label class="labels"><i class='bx bx-category'></i></label>
                                                <select class="nice-select" *ngIf="isJobCategoryLoaded" placeholder="All Categories" formControlName="job_category" id="job_category">
                                                    <option value="">All Categories</option>
                                                    <option *ngFor="let item of jobCategoryList" [value]="item.id.toString()">{{ item.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-lg-2 col-md-12">
                                            <div class="form-group">
                                                <label class="labels"><i class='bx bx-location-plus'></i></label>
                                                <select class="nice-select" *ngIf="isCountryLoaded" (change)="getState($event.target.value)" placeholder="Country" formControlName="country" id="country">
                                                    <option value="">Country</option>
                                                    <option *ngFor="let item of countryList" [value]="item.id.toString()">{{ item.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <!--  *ngIf="isStateLoaded == true" -->
                                       <div class="col-lg-2 col-md-12"  [ngClass]="isStateLoaded == true ? 'state_drop' : 'state_drop_none'">
                                            <div class="form-group">
                                                <label class="labels"><i class='bx bx-location-plus'></i></label>
                                                <select class="nice-select" (change)="getCity($event.target.value)" placeholder="State" formControlName="state" id="state">
                                                    <option value="">State</option>
                                                    <option *ngFor="let item of stateList" [value]="item.id.toString()">{{ item.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <!-- *ngIf="isCityLoaded == true" -->
                                        <div class="col-lg-2 col-md-12"  [ngClass]="isCityLoaded == true ? 'state_drop' : 'state_drop_none'">
                                            <div class="form-group">
                                                <label class="labels"><i class='bx bx-location-plus'></i></label>
                                                <select class="nice-select" placeholder="City" formControlName="city" id="city">
                                                    <option value="">City</option>
                                                    <option *ngFor="let item of cityList" [value]="item.id.toString()">{{ item.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-12" > 
                                            <button type="button" class="btn" (click)="searchJobs()">Search Job <i class='bx bx-search'></i></button>

                                        </div>

                                    </div> 

                                </form>
                            </div>

                            <!-- <div class="banner-key" *ngIf="trendingKeywords;else no_data">
                                <ul>
                                    <li><span>Trending Keywords</span></li>
                                    <li><a routerLink="/jobs">Account Manager,</a></li>
                                    <li><a routerLink="/jobs">Administrative,</a></li>
                                    <li><a routerLink="/jobs">Android,</a></li>
                                    <li><a routerLink="/jobs">Angular,</a></li>
                                    <li><a routerLink="/jobs">appASP.NET</a></li>
                                </ul>
                            </div>

                            <ng-template #no_data>
                                No Data Found!!!
                            </ng-template> -->

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-4 col-md-6 col-lg-4 hero-img-holder">
                    <!-- <img src="assets/img/home-img2.png" alt="img"> -->
                    <img ngSrc="assets/img/home-img2.png" priority height="50" width="400"/>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-12">
                <div class="banner-img">
                    <img src="assets/img/home-one/banner/banner-main.jpg" alt="Shape">
                    <img src="assets/img/home-one/banner/banner1.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner3.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner4.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner5.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner6.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner7.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner8.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner9.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner10.jpg" alt="Shape">
                    <img src="assets/img/home-one/banner/banner11.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner12.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner13.png" alt="Shape">
                    <div class="inner">
                        <img src="assets/img/home-one/banner/banner2.png" alt="Shape">
                        <p>Outsourcing all or part of your personnel management function can bring real</p>
                    </div> 
                </div>
                <img src="assets/img/home-img2.png" alt="img">
            </div> -->
        </div>
    <!-- </div> -->
</div>
<!-- End Banner -->

<!-- Work -->
<section class="work-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Working Process</span>
            <h2>See How It Works</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-verify"></i>
                    <span>STEP 01</span>
                    <h3>Register An Account</h3>
                    <p>Create your personalized account effortlessly on Abroad Work, unlock exclusive features and a seamless platform tailored to your unique professional journey.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-file"></i>
                    <span>STEP 02</span>
                    <h3>Search Your Job</h3>
                    <p>Navigate through a vast array of opportunities with Abroad Work's powerful search tools, ensuring you find the perfect job match tailored to your skills and aspirations.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>STEP 03</span>
                    <h3>Apply For Job</h3>
                    <p>Streamline your application process with a user-friendly interface, allowing you to submit applications efficiently on Abroad Work and take the next step toward your dream career.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work -->

<!-- Explore -->
<!-- <section class="explore-area ptb-100">
    <div class="container">
        <div class="explore-item">
            <div class="section-title">
                <span class="sub-title">Explore New Life</span>
                <h2>Put Your Cv In Front Of Great Employers</h2>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut tur
                incidunt laborequaerat voluptatem.</p>
            <ul>
                <li><a class="left-btn" routerLink="/">Upload Your Resume <i class="flaticon-upload"></i></a></li>
                <li>
                    <span>CEO Message</span>
                    <a class="right-btn popup-youtube" href="https://www.youtube.com/watch?v=07d2dXHYb94&t=88s"><i
                            class='bx bx-play'></i></a>
                </li>
            </ul>
        </div>
    </div>
</section> -->
<!-- End Explore -->

<!-- Counter -->
<!-- <div class="counter-area pt-100">
    <div class="counter-shape"><img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape"></div>

    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-hiring"></i>
                    <p>CANDIDATES</p>
                    <h3><span class="odometer" data-count="87360">00</span> </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-candidate"></i>
                    <p>TOTAL JOBS</p>
                    <h3><span class="odometer" data-count="45257">00</span> </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-working-at-home"></i>
                    <p>EMPLOYERS</p>
                    <h3><span class="odometer" data-count="9650">00</span> </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-choice"></i>
                    <p>JOB APPLICATIONS</p>
                    <h3><span class="odometer" data-count="70299">00</span> </h3>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Counter -->

<!-- Company -->
<!-- <section class="company-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="section-title">
                    <span class="sub-title">Employers Offering Job</span>
                    <h2>Company Offering Job</h2>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="cmn-link">
                    <a href="employers.html">
                        <i class="flaticon-right-arrow one"></i>
                        All Employers
                        <i class="flaticon-right-arrow two"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Featured</span>
                    </div>
                    <div class="top">
                        <a href="employer-details.html">
                            <img src="assets/img/home-one/company1.png" alt="Brand">
                        </a>
                        <h3>
                            <a href="employer-details.html">Pi Agency</a>
                        </h3>
                        <span>Part Time Job</span>
                        <p>
                            <i class="flaticon-appointment"></i>
                            10 min ago / Austria, Vienna
                        </p>
                    </div>
                    <div class="bottom">
                        <ul>
                            <li>Pay Relocation Free</li>
                            <li>Remote Work</li>
                            <li>Duration: 5 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>50K</h4>
                        <a href="employer-details.html">
                            <i class="flaticon-right-arrow"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Featured</span>
                    </div>
                    <div class="top">
                        <a href="employer-details.html">
                            <img src="assets/img/home-one/company2.png" alt="Brand">
                        </a>
                        <h3>
                            <a href="employer-details.html">Kn It</a>
                        </h3>
                        <span>Permanent Job</span>
                        <p>
                            <i class="flaticon-appointment"></i>
                            9 min ago / Tirana, Albania
                        </p>
                    </div>
                    <div class="bottom">
                        <ul>
                            <li>Graphic Designer</li>
                            <li>Remote Work</li>
                            <li>Duration: 2 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>56K</h4>
                        <a href="employer-details.html">
                            <i class="flaticon-right-arrow"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Featured</span>
                    </div>
                    <div class="top">
                        <a href="employer-details.html">
                            <img src="assets/img/home-one/company3.png" alt="Brand">
                        </a>
                        <h3>
                            <a href="employer-details.html">Orbit Inc.</a>
                        </h3>
                        <span>Part Time Job</span>
                        <p>
                            <i class="flaticon-appointment"></i>
                            8 min ago / Doha, Qatar
                        </p>
                    </div>
                    <div class="bottom">
                        <ul>
                            <li>Product Manager</li>
                            <li>Remote Work</li>
                            <li>Duration: 5 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>70K</h4>
                        <a href="employer-details.html">
                            <i class="flaticon-right-arrow"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Featured</span>
                    </div>
                    <div class="top">
                        <a href="employer-details.html">
                            <img src="assets/img/home-one/company4.png" alt="Brand">
                        </a>
                        <h3>
                            <a href="employer-details.html">Dev Roside</a>
                        </h3>
                        <span>Full Time Job</span>
                        <p>
                            <i class="flaticon-appointment"></i>
                            15 min ago / UK, England
                        </p>
                    </div>
                    <div class="bottom">
                        <ul>
                            <li>Design & Developer</li>
                            <li>Remote Work</li>
                            <li>Duration: 2 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>89K</h4>
                        <a href="employer-details.html">
                            <i class="flaticon-right-arrow"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Featured</span>
                    </div>
                    <div class="top">
                        <a href="employer-details.html">
                            <img src="assets/img/home-one/company5.png" alt="Brand">
                        </a>
                        <h3>
                            <a href="employer-details.html">Roshu.co</a>
                        </h3>
                        <span>Part Time Job</span>
                        <p>
                            <i class="flaticon-appointment"></i>
                            10 min ago / Cardiff, England
                        </p>
                    </div>
                    <div class="bottom">
                        <ul>
                            <li>Internet Operator</li>
                            <li>Remote Work</li>
                            <li>Duration: 2 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>66K</h4>
                        <a href="employer-details.html">
                            <i class="flaticon-right-arrow"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Featured</span>
                    </div>
                    <div class="top">
                        <a href="employer-details.html">
                            <img src="assets/img/home-one/company6.png" alt="Brand">
                        </a>
                        <h3>
                            <a href="employer-details.html">Omti. Med</a>
                        </h3>
                        <span>Part Time Job</span>
                        <p>
                            <i class="flaticon-appointment"></i>
                            40 min ago / Tokyo, Japan
                        </p>
                    </div>
                    <div class="bottom">
                        <ul>
                            <li>Caring Officer</li>
                            <li>Remote Work</li>
                            <li>Duration: 2 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>50K</h4>
                        <a href="employer-details.html">
                            <i class="flaticon-right-arrow"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Featured</span>
                    </div>
                    <div class="top">
                        <a href="employer-details.html">
                            <img src="assets/img/home-one/company7.png" alt="Brand">
                        </a>
                        <h3>
                            <a href="employer-details.html">Rahbar</a>
                        </h3>
                        <span>Full Time Job</span>
                        <p>
                            <i class="flaticon-appointment"></i>
                            7 min ago / Washington, US
                        </p>
                    </div>
                    <div class="bottom">
                        <ul>
                            <li>Media Connector</li>
                            <li>Remote Work</li>
                            <li>Duration: 3 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>87K</h4>
                        <a href="employer-details.html">
                            <i class="flaticon-right-arrow"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Featured</span>
                    </div>
                    <div class="top">
                        <a href="employer-details.html">
                            <img src="assets/img/home-one/company8.png" alt="Brand">
                        </a>
                        <h3>
                            <a href="employer-details.html">Doblin. Fo</a>
                        </h3>
                        <span>Part Time Job</span>
                        <p>
                            <i class="flaticon-appointment"></i>
                            12 min ago / California, US
                        </p>
                    </div>
                    <div class="bottom">
                        <ul>
                            <li>Private Officer</li>
                            <li>Remote Work</li>
                            <li>Duration: 1 Year</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>50K</h4>
                        <a href="employer-details.html">
                            <i class="flaticon-right-arrow"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Company -->

<!-- Location (Only for Indonesia and Philippines Countries) -->
<section class="location-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="section-title">
                    <span class="sub-title">Wanted Location</span>
                    <h2>The Places Near You</h2>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="location-slider owl-theme owl-carousel">
            <div class="location-item" (click)="gotoQatar()">
                <div class="top">
                    <img ngSrc="assets/img/home-one/Qatar.jpg" priority height="50" width="50"/>
                </div>
                <h3>Qatar</h3>
            </div>

            <div class="location-item" (click)="gotoUAE()">
                <div class="top">
                    <img ngSrc="assets/img/home-one/UAE.jpg" priority height="50" width="50"/>
                </div>
                <h3>UAE</h3>
            </div>

            <div class="location-item" (click)="gotoPhilippines()">
                <div class="top">
                    <img ngSrc="assets/img/home-one/Philippines.jpg" priority height="50" width="50"/>
                </div>
                <h3>Philippines</h3>
            </div>

            <div class="location-item" (click)="gotoKuwait()">
                <div class="top">
                    <img ngSrc="assets/img/home-one/Kuwait.jpg" priority height="50" width="50"/>
                </div>
                <h3>Kuwait</h3>
            </div>

            <div class="location-item" (click)="gotoOman()">
                <div class="top">
                    <img ngSrc="assets/img/home-one/Oman.jpg" priority height="50" width="50"/>
                </div>
                <h3>Oman</h3>
            </div>

            <div class="location-item" (click)="gotoKSA()">
                <div class="top">
                    <img ngSrc="assets/img/home-one/KSA.jpg" priority height="50" width="50"/>
                </div>
                <h3>KSA</h3>
            </div>

            <div class="location-item" (click)="gotoBahrain()">
                <div class="top">
                    <img ngSrc="assets/img/home-one/Bahrain.jpg" priority height="50" width="50"/>
                </div>
                <h3>Bahrain</h3>
            </div>
        </div>
    </div>
</section>
<!-- End Location -->

<!-- Jobs (Only for Malysia and Singapore Countries) -->
<!-- <section class="job-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="section-title">
                    <span class="sub-title">Recent Jobs</span>
                    <h2>Job You Will Love</h2>
                </div>
            </div>
        </div>

        <div id="container" class="row">
            <div class="col-sm-6 col-lg-12" *ngFor="let item of jobData">
                <div class="job-item">
                    <a routerLink="/job/{{item.id}}/{{item.jobTitle}}" style="text-decoration: none;">
                        <div class="feature-top-right">
                            <span>Latest</span>
                        </div>

                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <div class="job-left">
                                    <img src="{{item.imgPhotoUrl}}" alt="Logo">
                                    <h3>{{ item.title }}</h3>
                                    <p>{{ item.employer_company.company_test }}</p>
                                    <ul>
                                        <li><i class='bx bxs-briefcase'></i> {{ item.country.currency }} {{ item.min_salary }} - {{ item.country.currency }} {{ item.max_salary }}</li>
                                        <li><i class='bx bx-location-plus'></i> {{ item.city.name }}, {{ item.state.name }}, {{ item.country.name }}</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="job-right">
                                    <ul>
                                        <li><i class="flaticon-customer"></i>{{ item.job_type.name }}</li>
                                        <li><i class="flaticon-mortarboard"></i>{{ item.job_qualification.name }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="job-browse">
            <p>A tons of top tech jobs are waiting for you. <a routerLink="/jobs" style="text-decoration: none;">Browse all jobs</a></p>
        </div>
    </div>
</section> -->
<!-- End Jobs -->

<!-- Feedback -->
<section class="feedback-area pt-100">
    <div class="feedback-shape"><img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape"></div>

    <div class="container">
        <div class="section-title">
            <span class="sub-title">Feedbacks</span>
            <h2>What Users Are Saying</h2>
        </div>

        <div class="feedback-slider owl-theme owl-carousel">
            <div class="feedback-item">
                <h3>Jubra Ward</h3>
                <span>CEO at Alfa School</span>
                <p>“I’m ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniaxn ullamco laboris nisi ut at voluptatem.</p>
                <h4><i class="flaticon-left-quote"></i> Just Awesome</h4>
                <img src="assets/img/home-one/feedback1.jpg" alt="Feedback">
            </div>

            <div class="feedback-item">
                <h3>Robin William</h3>
                <span>Manager Support Act It</span>
                <p>“I’m ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniaxn ullamco laboris nisi ut at voluptatem.</p>
                <h4><i class="flaticon-left-quote"></i> Very Reliable</h4>
                <img src="assets/img/home-one/feedback2.jpg" alt="Feedback">
            </div>

            <div class="feedback-item">
                <h3>Tom Henry</h3>
                <span>Director at Alfa School</span>
                <p>“I’m ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniaxn ullamco laboris nisi ut at voluptatem.</p>
                <h4><i class="flaticon-left-quote"></i> Looking Nice</h4>
                <img src="assets/img/home-one/feedback3.jpg" alt="Feedback">
            </div>
        </div>
    </div>
</section>
<!-- End Feedback -->

<!-- Blog -->
<section class="blog-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="section-title">
                    <span class="sub-title">Our Blogs</span>
                    <h2>Recent News Post</h2>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="cmn-link">
                    <a routerLink="/career-advice"><i class="flaticon-right-arrow one"></i> Browse All Blogs <i
                            class="flaticon-right-arrow two"></i></a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4" *ngFor="let item of allBlogs">
                <div class="blog-item">
                    <div class="top d-flex justify-content-center">
                        <a routerLink="/career-advice/{{ item.slug }}"><img src="{{ item.imgPhotoUrl }}" alt="Blog"></a>
                    </div>
                    <span>{{ item.blog_categories.blog_category_name }}</span>
                    <h3><a routerLink="/career-advice/{{ item.slug }}">{{ item.blog_title }}</a></h3>
                    <div class="cmn-link">
                        <a routerLink="/career-advice/{{ item.slug }}"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog -->

<!-- Partner -->
<!-- <div class="partner-area pt-100 pb-70">
    <div class="container">
        <div class="partner-slider owl-theme owl-carousel">
            <div class="partner-item">
                <img src="assets/img/home-one/Artboard.png" alt="Partner">
                <img src="assets/img/home-one/Artboard.png" alt="Partner">
            </div>

            <div class="partner-item">
                <img src="assets/img/home-one/Mahad_Jobs_Logo.png" alt="Partner">
                <img src="assets/img/home-one/Mahad_Jobs_Logo.png" alt="Partner">
            </div>

            <div class="partner-item">
                <img src="assets/img/home-one/Frame.png" alt="Partner">
                <img src="assets/img/home-one/Frame.png" alt="Partner">
            </div>
        </div>
    </div>
</div> -->
<!-- End Partner -->

<!-- App -->
<!-- <section class="app-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-content">
                    <div class="section-title">
                        <span class="sub-title">Download The App</span>
                        <h2>Get More In Our Application Sit Back And Enjoy</h2>
                    </div>
                    <ul>
                        <li><a href="#" target="_blank"><img src="assets/img/home-one/app1.png" alt="App"></a></li>
                        <li><a href="#" target="_blank"><img src="assets/img/home-one/app2.png" alt="App"></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="app-img">
                    <img src="assets/img/home-one/app4.png" alt="App">
                    <img src="assets/img/home-one/app3.png" alt="Shape">
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End App -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
