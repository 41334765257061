<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Edit Job</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>
                            <a routerLink="/employer-dashboard">Dashboard</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Edit Job</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Edit Job -->
<div class="post-job-area ptb-100">
    <div class="container">
        <div *ngIf="saveSucess" class="alert alert-success">
            <strong [class]="FadeOutSuccessMsg()">{{ successMessage }}</strong>
        </div>
        <div *ngIf="error" class="alert alert-danger">
            {{ errorMessage }}
        </div>
        <form [formGroup]="editJobsForm" (ngSubmit)="editJobs()">
            <div class="post-item">
                <div class="section-title">
                    <h2>Edit Job</h2>
                </div>

                <div class="row">

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Name Of Company:</label>
                            <input class="form-control" type="text" placeholder="Company Name" value="{{ companyName }}" readonly>
                            <input type="hidden" formControlName="employer_companies_id" [(ngModel)]="employeeCompanyId">
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Job Title:</label>
                            <input type="text" class="form-control" placeholder="Web Developer" [formControlName]="'title'">
                            <div *ngIf="f.title.invalid && (f.title.dirty || f.title.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.title.errors?.required">
                                    Title is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Job Type:</label>
                            <select class="form-control" [formControlName]="'job_type_id'" id="job_type_id"
                                *ngIf="isJobTypeLoaded">
                                <option value="">Select</option>
                                <option *ngFor="let jobTypeRows of jobTypeList" [ngValue]="jobTypeRows.id.toString()">
                                    {{ jobTypeRows.name }}
                                </option>
                            </select>
                            <div *ngIf="f.job_type_id.invalid && (f.job_type_id.dirty || f.job_type_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.job_type_id.errors?.required">
                                    Select Job Type
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Industry Type:</label>
                            <select class="form-control" [formControlName]="'industry_type_id'" id="industry_type_id"
                                *ngIf="isIndustryTypeLoaded">
                                <option value="">Select</option>
                                <option *ngFor="let industryTypeRows of industryTypeList"
                                    [ngValue]="industryTypeRows.id.toString()">
                                    {{ industryTypeRows.name }}
                                </option>
                            </select>
                            <div *ngIf="f.industry_type_id.invalid && (f.industry_type_id.dirty || f.industry_type_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.industry_type_id.errors?.required">
                                    Select Industry Type
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Job Category:</label>
                            <select class="form-control" [formControlName]="'job_category_id'" id="job_category_id"
                                *ngIf="isJobCategoryLoaded">
                                <option value="">Select</option>
                                <option *ngFor="let jobCategoryRows of jobCategoryList"
                                    [ngValue]="jobCategoryRows.id.toString()">
                                    {{ jobCategoryRows.name }}
                                </option>
                            </select>
                            <div *ngIf="f.job_category_id.invalid && (f.job_category_id.dirty || f.job_category_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.job_category_id.errors?.required">
                                    Select Job Category
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Job Experience:</label>
                            <select class="form-control" [formControlName]="'job_experience_id'" id="job_experience_id"
                                *ngIf="isJobExperienceLoaded">
                                <option value="">Select</option>
                                <option *ngFor="let jobExperienceRows of jobExperienceList"
                                    [ngValue]="jobExperienceRows.id.toString()">
                                    {{ jobExperienceRows.min_exp }}-{{ jobExperienceRows.max_exp }} years
                                </option>
                            </select>
                            <div *ngIf="f.job_experience_id.invalid && (f.job_experience_id.dirty || f.job_experience_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.job_experience_id.errors?.required">
                                    Select Job Experience
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Minimum Salary:</label>
                            <!-- <select class="form-control" formControlName="job_salary_id" id="job_salary_id"
                                *ngIf="isJobSalaryLoaded">
                                <option value="">Set Minimum Salary</option>
                                <option *ngFor="let jobSalaryRows of jobSalaryList"
                                    [ngValue]="jobSalaryRows.id.toString()">
                                    {{ jobSalaryRows.min_salary }}-{{ jobSalaryRows.max_salary }}
                                </option>
                            </select> -->
                            <input type="text" class="form-control" formControlName="min_salary" placeholder="Minimum Salary per Annum">
                            <div *ngIf="f.min_salary.invalid && (f.min_salary.dirty || f.min_salary.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.min_salary.errors?.required">
                                    Select Minimum Job Salary
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Maximum Salary:</label>
                            <!-- <select class="form-control" formControlName="job_salary_id" id="job_salary_id"
                                *ngIf="isJobSalaryLoaded">
                                <option value="">Set Maximum Salary</option>
                                <option *ngFor="let jobSalaryRows of jobSalaryList"
                                    [ngValue]="jobSalaryRows.id.toString()">
                                    {{ jobSalaryRows.min_salary }}-{{ jobSalaryRows.max_salary }}
                                </option>
                            </select> -->
                            <input type="text" class="form-control" formControlName="max_salary" placeholder="Maximum Salary per Annum">
                            <div *ngIf="f.max_salary.invalid && (f.max_salary.dirty || f.max_salary.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.max_salary.errors?.required"> Select Maximum Job Salary </div>
                                <div *ngIf="this.editJobsForm.get('max_salary').errors?.minIsGreaterThanMax"> Maximum Salary Should Be Greater Than Minimum Salary. </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-3 col-md-12">
                        <div class="form-group">
                            <label>Job Salary:</label>
                            <select class="form-control" [formControlName]="'job_salary_id'" id="job_salary_id"
                                *ngIf="isJobSalaryLoaded">
                                <option value="">Select</option>
                                <option *ngFor="let jobSalaryRows of jobSalaryList"
                                    [ngValue]="jobSalaryRows.id.toString()">
                                    {{ jobSalaryRows.min_salary }}-{{ jobSalaryRows.max_salary }}
                                </option>
                            </select>
                            <div *ngIf="f.job_salary_id.invalid && (f.job_salary_id.dirty || f.job_salary_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.job_salary_id.errors?.required">
                                    Select Job Salary
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Currency:</label>
                            <select class="form-control" [formControlName]="'currency_id'" id="currency_id"
                                *ngIf="isCountryLoaded">
                                <option value="">Select</option>
                                <option *ngFor="let currencyRows of countryList" [ngValue]="currencyRows.id.toString()"> {{ currencyRows.currency }} </option>
                            </select>
                            <div *ngIf="f.currency_id.invalid && (f.currency_id.dirty || f.currency_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.currency_id.errors?.required">
                                    Select Currency
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Job Qualification:</label>
                            <select class="form-control" [formControlName]="'job_qualification_id'"
                                id="job_qualification_id" *ngIf="isJobQualificationLoaded">
                                <option value="">Select</option>
                                <option *ngFor="let jobQualificationRows of jobQualificationList"
                                    [ngValue]="jobQualificationRows.id.toString()">
                                    {{ jobQualificationRows.name }}
                                </option>
                            </select>
                            <div *ngIf="f.job_qualification_id.invalid && (f.job_qualification_id.dirty || f.job_qualification_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.job_qualification_id.errors?.required">
                                    Select Job Qualification
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Job Level:</label>
                            <select class="form-control" [formControlName]="'job_level_id'" id="job_level_id"
                                *ngIf="isJobLevelLoaded">
                                <option value="">Select</option>
                                <option *ngFor="let jobLevelRows of jobLevelList"
                                    [ngValue]="jobLevelRows.id.toString()">
                                    {{ jobLevelRows.name }}
                                </option>
                            </select>
                            <div *ngIf="f.job_level_id.invalid && (f.job_level_id.dirty || f.job_level_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.job_level_id.errors?.required">
                                    Select Job Level
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Country:</label>
                            <select class="form-control" [formControlName]="'country_id'" id="country_id"
                                *ngIf="isCountryLoaded" (change)="getState($event.target.value)">
                                <option value="">Select</option>
                                <option *ngFor="let countryRows of countryList" [ngValue]="countryRows.id.toString()">
                                    {{ countryRows.name }}
                                </option>
                            </select>
                            <div *ngIf="f.country_id.invalid && (f.country_id.dirty || f.country_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.country_id.errors?.required">
                                    Select Country
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>State:</label>
                            <select class="form-control" [formControlName]="'state_id'" id="state_id"
                                *ngIf="isCountryLoaded" (change)="getCity($event.target.value)">
                                <option value="">Select</option>
                                <option *ngFor="let stateRows of stateList" [ngValue]="stateRows.id.toString()">
                                    {{ stateRows.name }}
                                </option>
                            </select>
                            <div *ngIf="f.state_id.invalid && (f.state_id.dirty || f.state_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.state_id.errors?.required">
                                    Select State
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>City:</label>
                            <select class="form-control" [formControlName]="'city_id'" id="city_id" *ngIf="isCountryLoaded">
                                <option value="">Select</option>
                                <option *ngFor="let cityRows of cityList" [ngValue]="cityRows.id.toString()">
                                    {{ cityRows.name }}
                                </option>
                            </select>
                            <div *ngIf="f.city_id.invalid && (f.city_id.dirty || f.city_id.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.city_id.errors?.required">
                                    Select City
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Key Skills:</label>
                            <!-- <ng-select formControlName="key_skills_ids" bindLabel="name"
                                bindValue="" [items]="keySkillsList" [multiple]="true"
                                [closeOnSelect]="false" [searchable]="true"
                                [hideSelected]="true" placeholder="Select Skill"
                                [(ngModel)]="selectedSkills">
                            </ng-select> -->
                            <ng-select [multiple]="true" formControlName="key_skills_ids" (ngModel)="selectedSkills" [maxSelectedItems]="3">
                                <ng-option [value]="'custom'">Custom</ng-option>
                                <ng-option *ngFor="let item of keySkillsList" [value]="item.id" [disabled]="item.disabled">{{item.name}}</ng-option>
                            </ng-select>
                            <input type="hidden" formControlName="key_skills_ids" [(ngModel)]="selectedSkillId">
                            <div *ngIf="f.key_skills_ids.invalid && (f.key_skills_ids.dirty || f.key_skills_ids.touched)"
                                class="alert alert-danger">
                                <div *ngIf="f.key_skills_ids.errors?.required">
                                    Select Key Skills
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 publish-job">
                        <div class="form-group"> 
                            <label>Do you want to publish this job?</label>
                        </div>
                        <div class="form-check form-switch">
                            <input
                                mdbCheckbox
                                checked="submit_type"
                                (ngModel)="submitTypeToggle"
                                class="form-check form-switch form-check-input"
                                formControlName="submit_type" id="submit_type"
                                type="checkbox"/>
                        </div>
                        <div *ngIf="f.submit_type.invalid && (f.submit_type.dirty || f.submit_type.touched)" class="alert alert-danger">
                            <div *ngIf="f.submit_type.errors?.required">
                                This is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Job Description:</label>
                            <textarea [formControlName]="'job_description'" id="job_description" class="form-control"
                                cols="30" rows="10"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
                        <div class="form-group">
                            <label>Job Responsibilities:</label>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
                        <div class="form-group">
                            <button type="button" (click)="addResponsibility()">Add Job Responsibility</button>
                        </div>
                    </div>

                    <div class="row" formArrayName="jobResponsibilityArray" *ngFor="let item of jobResponsibilityFormGroup.controls; let i = index" formArrayName="job_responsibilities" formGroupName="{{i}}">
                        <div class="row" formGroupName="{{i}}">
                            <div class="col-lg-10 col-md-12">
                                <div class="form-group">
                                    <input class="form-control" type="text" placeholder="Enter Job Responsibilities" formControlName="job_responsibility">
                                    <div *ngIf="item.controls.job_responsibility.invalid && (item.controls.job_responsibility.dirty || item.controls.job_responsibility.touched)" class="alert alert-danger">
                                        <div *ngIf="item.controls.job_responsibility.errors?.required"> Job Responsibilities are Required. </div>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-lg-2 col-md-12">
                                <div class="form-group">
                                    <button type="button" (click)="removeResponsibility(i)">Remove Responsibility</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>
                    
                    <div class="col-lg-5 col-md-12">
                        <div class="form-group">
                            <label>Add Questions for Candidates</label>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
                        <div class="form-group">
                            <button type="button" (click)="addContact()">Add Question</button>
                        </div>
                    </div>

                    <div class="row" formArrayName="questionsArray" *ngFor="let item of contactFormGroup.controls; let i = index" formArrayName="questions" formGroupName="{{i}}">
                        <div class="row" formGroupName="{{i}}">
                            <div class="col-lg-2 col-md-12">
                                <div class="form-group">
                                    <input formControlName="isMandatory" type="radio" id="{{i}}" value="1"> <label for="{{i}}">Mandatory Question</label> <br>
                                    <input formControlName="isMandatory" type="radio" id="{{i}}" value="0"> <label for="{{i}}">Optional Question</label>
                                    <!-- <div *ngIf="item.controls.isMandatory.invalid && (item.controls.isMandatory.dirty || item.controls.isMandatory.touched)" class="alert alert-danger">
                                        <div *ngIf="item.controls.isMandatory.errors?.required"> This is Required. </div>
                                    </div> -->
                                </div>
                            </div>
        
                            <div class="col-lg-8 col-md-12">
                                <div class="form-group">
                                    <input class="form-control" type="text" placeholder="Enter Question" formControlName="question">
                                    <!-- <div *ngIf="item.controls.question.invalid && (item.controls.question.dirty || item.controls.question.touched)" class="alert alert-danger">
                                        <div *ngIf="item.controls.question.errors?.required"> Questions are Required. </div>
                                    </div> -->
                                </div>
                            </div>
        
                            <div class="col-lg-2 col-md-12">
                                <div class="form-group">
                                    <button type="button" (click)="removeQuestions(i)">Remove Question</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <button type="submit" class="btn" [disabled]="!editJobsForm.valid">Edit Job</button>
            </div>
        </form>
    </div>
</div>
<!-- End Edit Job -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
