<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-end">
                    <div class="col-lg-8 col-md-12">
                        <div class="left two">
                            <img src="{{imgPhotoUrl}}" onerror="this.src='../../../../assets/img/default1.png'" alt="Company Logo" style="width:230px; height: 220px;">
                            <h2>{{ jobDetails?.title }}</h2>
                            <ul>
                                <li><i class='bx bx-pie-chart-alt-2'></i> {{ jobDetails?.job_category?.name }}</li>
                                <li><i class='bx bx-time'></i> Posted date: {{ jobDetails?.createdAt | dateAgo }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="right">
                            <a class="cmn-btn" routerLink="/login" *ngIf="isLoggedIn == false">Apply Now <i class='bx bx-plus'></i></a>
                            
                            <a class="cmn-btn" *ngIf="!isCandidateApplied && isLoggedIn == true && currentUser?.user_type == 2 && isValidForJobApplication == 'true'" data-bs-toggle="modal" data-bs-target="#exampleModal">Apply Now <i class='bx bx-plus'></i></a>
                            
                            <a class="cmn-btn" routerLink="/candidate-dashboard" *ngIf="!isCandidateApplied && isLoggedIn == true && currentUser?.user_type == 2 && isValidForJobApplication == 'false'">Apply Now<i class='bx bx-plus'></i></a>
                            
                            <a class="cmn-btn" *ngIf="!isCandidateSaved && isLoggedIn == true && currentUser?.user_type == 2" (click)="saveJob()"><i class='bx bx-heart'></i> Save</a>
                            
                            <a class="cmn-btn" (click)="deleteSaveJob()" *ngIf="isCandidateSaved && isLoggedIn == true && currentUser?.user_type == 2"><i class='bx bxs-heart'></i>Un-Save</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Job Details -->
<div class="job-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="details-item">
                    <div class="details-inner">
                        <h3>Job Description</h3>
                        <p>{{ jobDetails?.job_description }}</p>
                    </div>

                    <div class="details-inner">
                        <h3>Required Skills</h3>
                        <ul *ngFor="let item of jobDetails?.jobs_key_skills">
                            <li><i class='bx bx-message-square-check'></i>{{ item.key_skill.name }}</li>
                        </ul>
                    </div>

                    <div class="details-inner">
                        <h3>Job Responsibilities</h3>
                        <ul *ngFor="let item of jobDetails?.job_responsibilities">
                            <li> <i class='bx bx-message-square-check'></i>{{ item.job_responsibility }} </li>
                        </ul>
                    </div>

                    <div class="details-inner">
                        <h3>Questions From Recruiters</h3>
                        <ul *ngFor="let item of jobDetails?.questions">
                            <li><i class='bx bx-message-square-check'></i>{{ item.question }} </li>
                        </ul>
                    </div>

                    <div class="details-inner">
                        <h3>Our Company</h3>
                        <p>{{ jobDetails?.employer_company.about_company }}</p>
                    </div>

                    <div class="details-inner">
                        <h3>Our Mission</h3>
                        <p>{{ jobDetails?.employer_company.company_mission }}</p>
                    </div>

                    <div class="details-inner">
                        <h3>Our Vision</h3>
                        <p>{{ jobDetails?.employer_company.company_vision }}</p>
                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="widget-area">

                    <div class="information widget-item">
                        <h3>Job Information</h3>

                        <ul>
                            <li>
                                <img src="assets/icons/salary.png" alt="Details" style="width: 20px;">
                                <h4>Salary</h4>
                                <span>{{ jobDetails?.min_salary }} {{ jobDetails?.currency.currency }} - {{
                                    jobDetails?.max_salary }} {{ jobDetails?.currency.currency }}</span>
                            </li>
                            <li>
                                <img src="assets/icons/category.png" alt="Details" style="width: 20px;">
                                <h4>Category</h4>
                                <span>{{ jobDetails?.job_category.name }}</span>
                            </li>
                            <li>
                                <img src="assets/icons/job-type.png" alt="Details" style="width: 20px;">
                                <h4>Job Type</h4>
                                <span>{{ jobDetails?.job_type.name }}</span>
                            </li>
                            <li>
                                <img src="assets/icons/qualification.png" alt="Details" style="width: 20px;">
                                <h4>Qualification</h4>
                                <span>{{ jobDetails?.job_qualification.name }}</span>
                            </li>
                            <li>
                                <img src="assets/icons/location.png" alt="Details" style="width: 20px;">
                                <h4>Location</h4>
                                <span>{{ jobDetails?.city.name }}, {{ jobDetails?.state.name }},
                                    {{ jobDetails?.country.name }}</span>
                            </li>
                            <li>
                                <img src="assets/icons/posted.png" alt="Details" style="width: 20px;">
                                <h4>Posted</h4>
                                <span>{{ jobDetails?.createdAt | dateAgo }}</span>
                            </li>
                            <li>
                                <img src="assets/icons/experience.png" alt="Details" style="width: 20px;">
                                <h4>Experience</h4>
                                <span>{{ jobDetails?.job_experience.min_exp }}-{{ jobDetails?.job_experience.max_exp }}
                                    Years</span>
                            </li>
                            <li>
                                <img src="assets/icons/organization-name.png" alt="Details" style="width: 20px;">
                                <h4>Industry</h4>
                                <span>{{ jobDetails?.industry_type.name }}</span>
                            </li>
                            <li>
                                <img src="assets/icons/level.png" alt="Details" style="width: 20px;">
                                <h4>Level</h4>
                                <span>{{ jobDetails?.job_level.name }}</span>
                            </li>
                        </ul>
                    </div>

                    <div class="owner">
                        <div class="top">
                            <h3>Owner Info</h3>
                            <img src="assets/icons/location.png" alt="Details" style="width: 20px;">
                            <span>{{ jobDetails?.city.name }}, {{ jobDetails?.state.name }},
                                {{ jobDetails?.country.name }}</span>
                        </div>

                        <div class="information widget-item">
                            <ul>
                                <li>
                                    <img src="assets/icons/organization-name.png" alt="Details" style="width: 20px;">
                                    <h4>Organiation Name</h4>
                                    <a href="tel:{{ jobDetails?.employer_company.website_url }}">{{
                                        jobDetails?.employer_company.company_name }}</a>
                                </li>
                                <li>
                                    <img src="assets/icons/phone.png" alt="Details" style="width: 20px;">
                                    <h4>Phone</h4>
                                    <a href="tel:{{ jobDetails?.employer_company.mobileno }}">{{
                                        jobDetails?.employer_company.mobileno }}</a>
                                </li>
                                <li>
                                    <img src="assets/icons/email.png" alt="Details" style="width: 20px;">
                                    <h4>Email</h4>
                                    <a href="mailto:{{ jobDetails?.employer_company.emailid }}">{{
                                        jobDetails?.employer_company.emailid }}</a>
                                </li>
                                <li>
                                    <img src="assets/icons/website.png" alt="Details" style="width: 20px;">
                                    <h4>Website</h4>
                                    <a href="{{ jobDetails?.employer_company.website_url }}" target="_blank">{{
                                        jobDetails?.employer_company.website_url }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Job Details -->

<!-- Code for Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title fs-2" style="margin-bottom: 0 !important;" id="exampleModalLabel" *ngIf="showResumePage">Select Resume</h4>
                <h4 class="modal-title fs-2" style="margin-bottom: 0 !important;" id="exampleModalLabel" *ngIf="showQuestionPage">Recruiters Wants To Know</h4>
                <h4 class="modal-title fs-2" style="margin-bottom: 0 !important;" id="exampleModalLabel" *ngIf="showDocumentPage">Documentation</h4>
                <h4 class="modal-title fs-2" style="margin-bottom: 0 !important;" id="exampleModalLabel" *ngIf="showSummaryPage">Confirm The Information</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <div *ngIf="showResumePage">
                    <div style="display: flex; justify-content: center;"
                        *ngFor="let item of candidateResume; let i=index" (change)="setResume($event)">
                        <input class="register-input" type="radio" name="candidateResume" id="radio{{ item.id }}"
                            value="{{ item.id }}">
                        <label class="apply-label" for="radio{{ item.id }}"> {{ item.resume_name }} </label>
                    </div>
                    <hr>
                    <div style="display: flex; justify-content: center;" (change)="setResume($event)">
                        <input class="register-input" type="radio" name="candidateResume" id="abroadworkCV" value="0">
                        <label class="apply-label" for="abroadworkCV"> Abroadwork Cv </label>
                    </div>
                    <hr>
                    <div>
                        <form [formGroup]="candidateResumeForm" enctype="multipart/form-data">
                            <label class="modal-bottom">Upload Another Resume</label>
                            <input class="form-control modal-bottom" type="file" formControlName="file"
                                (change)="onResumeUpload($event)">
                            <button type="button" class="btn btn-sm btn-primary upload-cv-button"
                                *ngIf="isResumeUploaded" (click)="uploadCV()">Upload Resume</button>
                        </form>
                    </div>
                </div>

                <div *ngIf="showQuestionPage">
                    <p>Answer below questions to help recruiters to get to know more about you.</p>
                    <form [formGroup]="myForm">
                        <div *ngFor="let item of jobDetails?.questions; let i = index">
                            <div class="form-group" style="margin-bottom: 10px;">
                                <label>{{ item.question }} <span class="text-danger"
                                        *ngIf="item.isMandatory == 1">*</span> </label>
                                <input class="form-control" type="text" formControlName="answer{{i}}" />
                                <div *ngIf="getFormControl(i).invalid && getFormControl(i).touched">This field is
                                    required.</div>
                            </div>
                        </div>
                    </form>
                </div>

                <div *ngIf="showDocumentPage">

                    <div style="display: flex; justify-content: center;">
                        <div class="row">
                            <div class="col-lg-12 col-md-12" style="margin-bottom: 20px;">
                                <table *ngIf="candidateDocument.length > 0" class="table table-striped table-responsive">
                                    <thead>
                                        <tr>
                                            <th>Select/Deselect</th>
                                            <th>Document Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of candidateDocument; let i=index;">
                                            <td><input type="checkbox"
                                                    (change)="selectDocument(item.id, item.document_type)" value="1" />
                                            </td>
                                            <td>{{ item.document_name }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div *ngIf="candidateDocument.length < 5" style="display: grid;justify-content: space-around;margin-bottom: 20px;">
                                    <button class="btn btn-sm btn-primary" style="margin-bottom: 15px;" (click)="viewPassportUpload()">Add Passport</button>
                                    <button class="btn btn-sm btn-primary" style="margin-bottom: 15px;" (click)="viewCertificateUpload()">Add Certificate</button>
                                    <button class="btn btn-sm btn-primary" style="margin-bottom: 15px;" (click)="viewCoverLetterUpload()">Add Cover Letter</button>
                                    <button class="btn btn-sm btn-primary" style="margin-bottom: 15px;" (click)="viewOtherUpload()">Add Other Document</button>
                                </div>
                                <div>
                                    <h2 *ngIf="passportPage == true">Upload Passport</h2>
                                    <h2 *ngIf="certificatePage == true">Upload Certificate</h2>
                                    <h2 *ngIf="coverLetterPage == true">Upload Cover Letter</h2>
                                    <h2 *ngIf="otherPage == true">Upload Other Documents</h2>
                                    <div class="row"
                                        *ngIf="passportPage == true || certificatePage == true || coverLetterPage == true || otherPage == true">
                                        <div class="col-lg-12 col-md-4">
                                            <form [formGroup]="candidateResumeForm" enctype="multipart/form-data">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="col-lg-12 col-md-12">
                                                            <input class="form-control" type="file" formControlName="file" (change)="onFileUpload($event)">
                                                        </div>
                                                        <div class="resume-action-button" style="display: flex;justify-content: space-evenly;margin-top: 40px;margin-bottom: 50px;">
                                                            <button *ngIf="passportPage == true" type="button" class="btn btn-sm btn-primary" (click)="uploadFile(1)">Upload Passport</button>
                                                            <button *ngIf="certificatePage == true" type="button" class="btn btn-sm btn-primary" (click)="uploadFile(2)">Upload Certificate</button>
                                                            <button *ngIf="coverLetterPage == true" type="button" class="btn btn-sm btn-primary" (click)="uploadFile(3)">Upload Cover Letter</button>
                                                            <button *ngIf="otherPage == true" type="button" class="btn btn-sm btn-primary" (click)="uploadFile(4)">Upload Document</button>
                                                        </div>
                                                    </div>
                                                </div>
                                        </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <hr>
                <div style="display: flex; justify-content: center;" (change)="setResume($event)">
                    <input class="register-input" type="radio" name="candidateDocument" id="abroadworkCV" value="0">
                    <label class="apply-label" for="abroadworkCV"> Abroadwork Cv </label> 
                </div> -->

                    <!-- <div class="row">
                        <div class="col-lg-12 col-md-12" *ngIf="candidateDocumentData.length < 5"
                            style="display: flex;justify-content: space-around;margin-bottom: 35px;">
                            <button class="btn btn-sm btn-primary" (click)="viewPassportUpload()">Add Passport</button>
                            <button class="btn btn-sm btn-primary" (click)="viewCertificateUpload()">Add
                                Certificate</button>
                            <button class="btn btn-sm btn-primary" (click)="viewCoverLetterUpload()">Add Cover
                                Letter</button>
                            <button class="btn btn-sm btn-primary" (click)="viewOtherUpload()">Add Other
                                Document</button>
                        </div>
                        <form [formGroup]="candidateDocumentForm" enctype="multipart/form-data">
                            <label class="modal-bottom">Upload Another Resume</label>
                            <input class="form-control modal-bottom" type="file" formControlName="file"
                                (change)="onResumeUpload($event)">
                            <button type="button" class="btn btn-sm btn-primary upload-cv-button"
                                *ngIf="isResumeUploaded" (click)="uploadCV()">Upload Resume</button>
                        </form>
                    </div> -->

                </div>

                <div *ngIf="showSummaryPage">
                    <h2>Candidate Information</h2>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div>
                                <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Name"/>
                                <h3 class="summary-heading">Name:</h3>
                                <label class="summary-label">{{currentUser.first_name}}{{currentUser.last_name}}</label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div>
                                <img src="assets/icons/email.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Email"/>
                                <h3 class="summary-heading">Email Id:</h3>
                                <label class="summary-label">{{currentUser.emailid}}</label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div>
                                <img src="assets/icons/phone.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Phone"/>
                                <h3 class="summary-heading">Mobile Number:</h3>
                                <label class="summary-label">{{currentUser.mobileno}}</label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div>
                                <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Title"/>
                                <h3 class="summary-heading">Job Title:</h3>
                                <label class="summary-label">{{currentUser.job_title}}</label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div>
                                <img src="assets/icons/location.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Location"/>
                                <h3 class="summary-heading">Location:</h3>
                                <label class="summary-label">{{currentUser.city.name}}, {{currentUser.state.name}},
                                    {{currentUser.country.name}}</label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div>
                                <img src="assets/icons/cv.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Resume"/>
                                <h3 class="summary-heading">Resume:</h3>
                                <label class="summary-label" *ngIf="resumeData.length > 0">{{ resumeData[0].resume_name }}</label>
                                <label class="summary-label">Abroadwork CV</label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12" *ngIf="submitted_passport.length > 0">
                            <div>
                                <img src="assets/icons/passport.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Passport"/>
                                <h3 class="summary-heading">Passport:</h3>
                                <label class="summary-label">{{ submitted_passport[0].document_name }}</label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12" *ngIf="submitted_certificate.length > 0">
                            <div>
                                <img src="assets/icons/certificate.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Certificate"/>
                                <h3 class="summary-heading">Educational Certificate:</h3>
                                <label class="summary-label">{{ submitted_certificate[0].document_name }}</label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12" *ngIf="submitted_cover_letter.length > 0">
                            <div>
                                <img src="assets/icons/cover-letter.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Cover Letter"/>
                                <h3 class="summary-heading">Cover Letter:</h3>
                                <label class="summary-label">{{ submitted_cover_letter[0].document_name }}</label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12" *ngIf="submitted_document1.length > 0">
                            <div>
                                <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Other Document"/>
                                <h3 class="summary-heading">Other Document:</h3>
                                <label class="summary-label">{{ submitted_document1[0].document_name }}</label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12" *ngIf="submitted_document2.length > 0">
                            <div>
                                <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Other Document"/>
                                <h3 class="summary-heading">Other Document:</h3>
                                <label class="summary-label">{{ submitted_document2[0].document_name }}</label>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <h2>Job Information</h2>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div>
                                <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Title"/>
                                <h3 class="summary-heading">Job Title:</h3>
                                <label class="summary-label">{{jobDetails?.title}}</label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div>
                                <img src="assets/icons/location.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Job Location"/>
                                <h3 class="summary-heading">Job Location:</h3>
                                <label class="summary-label">{{jobDetails?.city.name}}, {{jobDetails?.state.name}},
                                    {{jobDetails?.country.name}}</label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div>
                                <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Experience"/>
                                <h3 class="summary-heading">Required Experience:</h3>
                                <label class="summary-label">{{jobDetails?.job_experience.min_exp}} -
                                    {{jobDetails?.job_experience.max_exp}}</label>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div>
                                <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Responsibilities"/>
                                <h3 class="summary-heading">Job Responsibilities:</h3>
                                <div *ngFor="let item of jobDetails?.job_responsibilities">
                                    <label class="summary-label">{{item.job_responsibility}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div>
                                <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Questions"/>
                                <h3 class="summary-heading">Recruiters Questions:</h3>
                                <div *ngFor="let item of jobDetails?.questions; let i = index">
                                    <label class="summary-label">{{item.question}} : {{ questionAnswer['answer' + i] }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="cancelApplication()">Cancel</button>

                <button type="button" class="btn btn-primary" *ngIf="showResumePage" [disabled]="!isResumeSelected"
                    (click)="gotoQuestionsPage()">Next</button>
                <button type="button" class="btn btn-primary" *ngIf="showQuestionPage" [disabled]="!myForm.valid"
                    (click)="gotoDocumentPage()">Next</button>
                <button type="button" class="btn btn-primary" *ngIf="showDocumentPage"
                    (click)="gotoSummaryPage();">Next</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" *ngIf="showSummaryPage"
                    (click)="applyJob()">Apply For Job</button>
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->