<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-end">
                    <div class="col-lg-8 col-md-12">
                        <div class="left two">
                            <img src="{{ imgPhotoUrl }}" onerror="this.src='../../../../assets/img/default1.png'" alt="Details" style="width:230px; height: 220px;">
                            <h2 style="left:100px">{{ userData?.first_name }} {{ userData?.last_name }}</h2>
                            <h3 style="left:100px;color: white;"><i class='bx bx-phone-call'></i> {{ userData?.mobileno }} | <i class='bx bx-mail-send'></i> {{ userData?.emailid }}</h3>
                            <!-- <ul>
                                <li><i class='bx bx-box'></i> UX/UI Designer</li> 
                                <li><i class='bx bx-phone-call'></i> <a>{{ userData.mobileno }}</a></li>
                            </ul> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Candidate Details -->
<div class="person-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="details-item">
                    <div class="profile">
                        <h3>{{ userData?.first_name }} {{ userData?.last_name }} Profile</h3>
                        <p>{{ userData?.about_me }}</p>
                    </div>

                    <div class="work bottom-item" *ngIf="userData?.candidate_experiences?.length > 0">
                        <h3>Work Experience</h3>
                        <div *ngFor="let data of userData.candidate_experiences">
                            <ul>
                                <li>{{ data?.organization }}</li>
                                <li><span>{{data?.working_from_month}}/{{data?.working_from_year}} - {{data?.working_till_month}}/{{data?.working_till_year}}</span></li>
                            </ul>
                            <h4>{{ data?.designation }}</h4>
                            <p>{{ data?.description }}</p>
                        </div>
                    </div>

                    <div class="work bottom-item" *ngIf="userData?.candidate_educations?.length > 0">
                        <h3>Education</h3>
                        <div *ngFor="let data of userData.candidate_educations">
                            <ul>
                                <li>{{ data?.university?.name }}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="work bottom-item" *ngIf="userData?.candidate_key_skills?.length > 0">
                        <h3>Skills</h3>
                        <div *ngFor="let data of userData.candidate_key_skills">
                            <ul>
                                <li>{{ data?.key_skill?.name }}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="work bottom-item" *ngIf="userData?.candidate_languages?.length > 0">
                        <h3>Languages</h3>
                        <div *ngFor="let data of userData.candidate_languages">
                            <ul>
                                <li>{{ data?.language?.name }}</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="widget-area">
                    <div class="information widget-item">
                        <h3>Candidate Overview</h3>

                        <ul>
                            <li>
                                <img src="assets/icons/salary.png" alt="Details" style="width: 20px;">
                                <h4>Salary:</h4>
                                <span>{{ userData?.current_salary }} {{ userData?.currency.currency }}</span>
                            </li>
                            <li>
                                <img src="assets/icons/experience.png" alt="Details" style="width: 20px;">
                                <h4>Experience:</h4>
                                <span>{{ userData?.total_experience }} Years</span>
                            </li>
                            <li>
                                <img src="assets/icons/age-group.png" alt="Details" style="width: 20px;">
                                <h4>Age:</h4>
                                <span>{{ userData?.age }}</span>
                            </li>
                            <li>
                                <img src="assets/icons/sex.png" alt="Details" style="width: 20px;">
                                <h4>Gender:</h4>
                                <span>{{ gender }}</span>
                            </li>
                            <!-- <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Languages:</h4>
                                <span>Bangla, English, Arbi, Spanish</span>
                            </li>
                            <li>
                                <img src="assets/img/job-details-icon.png" alt="Details">
                                <h4>Qualification:</h4>
                                <span>Certificate, Associate Degree</span>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Candidate Details -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->