<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>My Jobs</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>
                            <a routerLink="/employer-dashboard">Dashboard</a>
                        </li>
                        <li><span>/</span></li>
                        <li>My Jobs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-100">
    <div class="container">
        <div *ngIf="saveSucess" class="alert alert-success">
            <strong [class]="FadeOutSuccessMsg()">{{ successMessage }}</strong>
        </div>
        <div *ngIf="error" class="alert alert-danger">
            {{ errorMessage }}
        </div>
        <div class="row">
            <ng-template #no_data>
                <div class="col-lg-6 col-md-12">
                    <ul class="job-list-item align-items-center">
                        <li *ngIf="EmployerJobs.length == 0">
                            <a href="#">No Jobs Posted Yet</a>
                        </li>
                    </ul>
                </div>
            </ng-template>
            <div class="col-lg-12 col-md-12" *ngIf="EmployerJobs.length > 0;else no_data">
                <ul class="job-list-item align-items-center">
                    <li *ngIf="EmployerJobs.length > 0">
                        <a href="#">Jobs Found <span>({{ totalItems }})</span></a>
                    </li>
                    <li>
                        <span class="sort">Sort By:</span>
                        <form>
                            <div class="form-group">
                                <select>
                                    <option>Title</option>
                                    <option>Another option</option>
                                    <option>A option</option>
                                    <option>Potato</option>
                                </select>
                            </div>
                        </form>
                    </li>
                </ul>

                <div class="employer-item" *ngFor="let rows of EmployerJobs
                | paginate : {
              itemsPerPage: itemsPerPage,
              currentPage: page,
              totalItems: totalItems
            };
                let i = index">
                    <a routerLink="/job-details/{{ rows.id }}">
                        <img src="{{ apiBaseUrl }}employer/{{ rows.web_user.photo }}" width="70" alt="Employer">
                        <h3>{{ rows.title }}</h3>
                        <ul>
                            <li><i class="flaticon-send"></i> {{ rows.city.name }}, {{ rows.state.name }},
                                {{ rows.country.name }}</li>
                            <li>{{ rows.createdAt | dateAgo }}</li>
                        </ul>
                        <p>{{ rows.job_description }}</p>
                    </a>

                    <span *ngIf="rows.status==0 && rows.submit_type==2" class="btn btn-sm btn-outline-warning rounded mr-2">Pending</span>
                    <span *ngIf="rows.status==1 && rows.submit_type==2" class="btn btn-sm btn-outline-success rounded mr-2">Approved</span>
                    <span *ngIf="rows.status==2 && rows.submit_type==2" class="btn btn-sm btn-outline-danger rounded mr-2">Rejected</span>
                    &nbsp;
                    <span *ngIf="rows.submit_type==1" class="btn btn-sm btn-outline-danger rounded mr-2">Not
                        Published</span>
                    <span *ngIf="rows.submit_type==2"
                        class="btn btn-sm btn-outline-success rounded mr-2">Published</span>

                    <span class="span-two">
                        <span routerLink="/edit-job/{{ rows.id }}" *ngIf="rows.submit_type==1">Edit | </span>
                        <span (click)="deleteJobs(rows.id, i)">Delete</span>
                    </span>

                </div>
                <div class="pagination-area">
                    <pagination-controls class="pagi" (pageChange)="employerJobsListData((page = $event))"
                        (directionLinks)="true" (responsive)="true"></pagination-controls>
                </div>
                <!--<div class="pagination-area">
                    <ul>
                        <li><a routerLink="/jobs">Prev</a></li>
                        <li><a routerLink="/jobs">1</a></li>
                        <li><a routerLink="/jobs">2</a></li>
                        <li><a routerLink="/jobs">3</a></li>
                        <li><a routerLink="/jobs">Next</a></li>
                    </ul>
                </div>-->
            </div>
        </div>
    </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
