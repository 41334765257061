import { Component, OnInit } from '@angular/core';
import { JobsService } from '../../../service/jobs/jobs.service';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

  JobsList: any = [];

  //pagination
  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  totalItems: any;

  //for images  
  apiBaseUrl = environment.apiBaseUrl;
  currentUser: any;
  getId: any;
  saveSucess: boolean;
  error: boolean;
  successMessage: string;
  errorMessage: any;
  savedJobs: any;

  constructor(
    private jobsService: JobsService,
  ) { }

  ngOnInit(): void {
    this.employerJobsListData(this.page);
    this.getSavedJobs();
  }

  employerJobsListData(page: any) {
    this.jobsService.GetJobsListData(page, this.itemsPerPage).subscribe((res: any) => {
      this.JobsList = res.data;
      this.totalItems = res.paging.total;
    });
  }

  getSavedJobs(): any {
    this.jobsService.GetCandidateFavouriteJobsListData(this.page, this.itemsPerPage).subscribe((res: any) => {
      this.savedJobs = res.data;
    })
  }

  saveJob(id:any): any {
    this.jobsService.AddCandidateFavouriteJob(id).subscribe((res: any) => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = "Job Updated Successfully";
    }, (err) => {
      console.log(err);
      this.error = true;
      if (err.status == 200) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.saveSucess = false;
    }, 5000);
  }

  FadeOutErrorMsg() {
    setTimeout(() => {
      this.error = false;
    }, 5000);
  }

}
