<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Resume</h2>
                    <ul style="display: contents;">
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>
                            <a routerLink="/{{redirectURL}}">Dashboard</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Resume</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- resume type -->
<div class="template-list" style="margin-top: 80px;position: relative;" *ngIf="currentUser.user_type == 2">
    <div class="container">
        <div class="flex">
            <div class="button-container">
                <button type="button" routerLink="/resume/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout1.png" width="130px"></button>
                <button type="button" routerLink="/resume-layout2/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout2.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout3/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout3.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout4/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout4.png" width="130px"></button>
                <button type="button"  routerLink="/resume-layout5/{{resumeData.id}}">
                    <img src="../../../../assets/img/layout5.png" width="130px"></button>
            </div>
        </div>
    </div>
</div>


<!-- <link href='https://fonts.googleapis.com/css?family=Lora:400,700,400italic|Open+Sans:300,400,500,700|Waiting+for+the+Sunrise|Shadows+Into+Light' rel='stylesheet' type='text/css'> -->

<div class="person-details-area resume-area ptb-100">
    <div class="default-layout" id="default">
        <div class="container">
            <div class="row">

                <div class="resume-body">
                    <div class="left">
                        <div class="name-text">
                            <h1>{{ resumeData.name }}</h1>
                            <p>{{ resumeData.emailid }}</p>
                            <p>{{ resumeData.mobileno }}</p>
                        </div>
                    </div>
                    <div class="right">
                        <div class="inner">
                            <section>
                                <h1>About Me</h1>
                                <p>{{ resumeData.about_me }}</p>
                            </section>
                            <h1>Experience</h1>
                            <section *ngFor="let exps of resumeData.candidate_experiences">
                                <p><em>{{ exps.organization }} | {{ exps.designation }}</em></p>
                                <p>{{ exps.description }}</p>
                            </section>
                            <section>
                                <h1>Technical Skills</h1>
                                <ul class="skill-set" *ngFor="let skills of resumeData.candidate_key_skills">
                                    <li>{{ skills.key_skill.name }}</li>
                                </ul>
                            </section>
                            <section *ngFor="let edu of resumeData.candidate_educations">
                                <h1>Education</h1>
                                <p>{{ edu.university.name }} | <em>{{ edu.course_type.name }}</em></p>
                            </section>
                        </div>
                    </div>
                </div>

                <!-- <div class="wrapper clearfix">

                    <div class="left">
                  
                      <div class="name-hero">
                  
                        <div class="me-img"></div>
                  
                        <div class="name-text">
                  
                          <h1>{{ resumeData.name }}</h1>
                          <p>{{ resumeData.emailid }}</p>
                          <p>{{ resumeData.mobileno }}</p>
                  
                        </div>
                  
                      </div>
                  
                    </div>
                    <div class="right">
                  
                      <div class="inner">
                        <section>
                            <h1>About Me</h1>
                            <p>{{ resumeData.about_me }}</p>
                        </section>
                        <h1>Experience</h1>
                        <section *ngFor="let exps of resumeData.candidate_experiences">
                          <p>Winter 2015 - Present <em>{{ exps.organization }} | {{ exps.designation }}</em></p>
                          <p>{{ exps.description }}</p>
                        </section>
                        <section>
                          <h1>Technical Skills</h1>
                          <ul class="skill-set" *ngFor="let skills of resumeData.candidate_key_skills">
                            <li>{{ skills.key_skill.name }}</li>
                          </ul>
                        </section>
                        <section *ngFor="let edu of resumeData.candidate_educations">
                          <h1>Education</h1>
                          <p>{{ edu.university.name }} | <em>{{ edu.course_type.name }}</em></p>
                        </section>
                      </div>
                  
                    </div>
                  
                  </div> -->

            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->