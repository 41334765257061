import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EmployerUsersService {
  REST_API: string;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}employer/`;
    } else {
      this.REST_API = `${environment.apiurl}employer/`;
    }
  }

  AddEmployerUser(data:any): Observable<any> {
    let API_URL = `${this.REST_API}add-user`;
    return this.httpClient.post(API_URL, data).pipe(catchError(this.handleError))
  }

  GetAllEmployerUserList(page: any, itemsPerPage: any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}get-users`).pipe(catchError(this.handleError))
  }

  GetEmployerUserById(id:any): Observable<any> {
    return this.httpClient.get(`${this.REST_API}get-users/${id}`).pipe(catchError(this.handleError))
  }

  UpdateEmployerUser(id:any, data:any): Observable<any> {
    let API_URL = `${this.REST_API}update-user/${id}`;
    return this.httpClient.put(API_URL, data).pipe(catchError(this.handleError))
  }

  DeleteEmployerUser(id:any, data:any): Observable<any> {
    let API_URL = `${this.REST_API}update-user/${id}`;
    return this.httpClient.put(API_URL, data).pipe(catchError(this.handleError))
  }

  AddPermission(data:any): Observable<any> {
    let API_URL = `${this.REST_API}add-permission`;
    return this.httpClient.post(API_URL, data).pipe(catchError(this.handleError))
  }

  GetPermission(webUserId:any,adminId:any): Observable<any> {
    let API_URL = `${this.REST_API}${webUserId}/get-permission/${adminId}`;
    return this.httpClient.get(API_URL).pipe(catchError(this.handleError))
  }

  GetAllPermission(): Observable<any> {
    let API_URL = `${this.REST_API}get-permission`;
    return this.httpClient.get(API_URL).pipe(catchError(this.handleError))
  }

  UpdatePermission(webUserId:any, data:any): Observable<any> {
    let API_URL = `${this.REST_API}update-permission/${webUserId}`;
    return this.httpClient.put(API_URL, data).pipe(catchError(this.handleError))
  }

  // Error
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }

}
