import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../../service/login/token-storage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private token: TokenStorageService,
    private router: Router,
    private ngZone: NgZone) { }

  ngOnInit(): void {
  }

  logout(): void {
    this.token.signOut();
    this.ngZone.run(() => this.router.navigateByUrl('/login'))
  }
}
