import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CandidateCertificateService {
  REST_API: string;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}candidate/`;
    } else {
      this.REST_API = `${environment.apiurl}candidate/`;
    }
  }

  AddCandidateCertificate(data:any): Observable<any> {
    let API_URL = `${this.REST_API}add-certificate`;
    return this.httpClient.post(API_URL, data).pipe(catchError(this.handleError))
  }

  GetCandidateCertificateList(page: any, itemsPerPage: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}get-candidate-certificate`, { params: queryParams });
  }

  GetCandidateCertificateDetails(id:any) {
    let API_URL = `${this.REST_API}get-candidate-certificate/${id}`;
    return this.httpClient.get(API_URL).pipe(map((res: any) => { return res || {} }),catchError(this.handleError))
  }

  UpdateCandidateCertificate(id: any, data: any): Observable<any> {
    let API_URL = `${this.REST_API}update-certificate/${id}`;
    return this.httpClient.put(API_URL, data).pipe(catchError(this.handleError))
  }

  DeleteCandidateCertificate(id: any) {
    let API_URL = `${this.REST_API}delete-certificate/${id}`;
    return this.httpClient.delete(API_URL).pipe(catchError(this.handleError))
  }

   // Error
   handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }

}
