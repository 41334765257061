import { TokenStorageService } from './../../../service/login/token-storage.service';
import { Observable, Subscription } from 'rxjs';
import { Component, OnInit, NgZone, enableProdMode, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { CrudService } from '../../../service/web_users/web_users.service';
import { CandidateEducationService } from '../../../service/candidate_education/candidate_education.service';
import { environment } from './../../../../environments/environment';
import { JobsService } from '../../../service/jobs/jobs.service';
import { EducationService } from '../../../service/education/education.service';
import { CourseService } from '../../../service/course/course.service';
import { UniversityService } from '../../../service/university/university.service';
import { CourseTypeService } from '../../../service/course-type/course-type.service';
import { CandidateExperienceService } from 'src/app/service/candidate_experience/candidate-experience.service';
import { CandidateSkillService } from 'src/app/service/candidate_skill/candidate-skill.service';
import { KeySkillsService } from 'src/app/service/key-skills/key-skills.service'
import { CountryService } from '../../../service/country/country.service';
import { StateService } from '../../../service/state/state.service';
import { CityService } from '../../../service/city/city.service';
import * as moment from 'moment';
import { CandidateCertificateService } from '../../../service/candidate-certificate/candidate-certificate.service';
import { CandidateResumeService } from 'src/app/service/candidate-resume/candidate-resume.service';
import { LanguageService } from 'src/app/service/language/language.service';
import { AgencyService } from '../../../service/agency/agency.service';
import { CandidateDocumentService } from 'src/app/service/candidate-document/candidate-document.service';
import { JobCategoryService } from 'src/app/service/job-category/job-category.service';
import { ImageCroppedEvent, LoadedImage, base64ToFile  } from 'ngx-image-cropper';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './candidate-dashboard.component.html',
  styleUrls: ['./candidate-dashboard.component.scss'],
})

export class CandidateDashboardComponent implements OnInit {

  imageChangedEvent: Event | null = null;
  croppedImage: any = '';

  getId: any;
  userData: any;
  saveSucess: boolean = false;
  error: boolean = false;
  errorMessage: string = '';
  successMessage: string = '';
  CandidateEducation: any = [];
  candidateProfileForm: FormGroup;
  candidateChangePasswordForm: FormGroup;
  candidateEducationForm: FormGroup;
  candidateEducationEditForm: FormGroup;
  showCandidateEducationForm: boolean = false;
  showEditCandidateEducationForm: boolean = false;
  showCandidateEducationList: boolean = true;
  genderList: any = [
    { id: 'm', value: 'Male' },
    { id: 'f', value: 'Female' },
  ];
  CandidateAppliedJobs: any = [];
  CandidateFavouriteJobs: any = [];

  educationList: any;
  courseList: any;
  universityList: any;
  courseTypeList: any;

  //pagination
  page: number = 1;
  totalItems: any;
  itemsPerPage = environment.pagination.itemsPerPage;
  totalCandidateEducationItems: any;
  totalCandidateExperienceItems: any;
  totalCandidateCertificateItems: any;
  totalCandidateSkillItems: any;
  totalCandidateLanguageItems: any;
  totalFavItems: any;

  //for images
  apiBaseUrl = environment.apiBaseUrl;
  currentUser: any;
  imgPhotoUrl: String;

  skill = [];
  selectedSkills: Array<string> = [];

  showCandidateExperienceForm: boolean = false;
  showEditCandidateExperienceForm: boolean = false;
  showCandidateExperienceList: boolean = true;
  CandidateExperience: any;
  candidateExperienceForm: FormGroup;
  isCurrentCompanyToggle: Number = 0;
  candidateExperienceEditForm: FormGroup;

  showCandidateSkillForm: boolean = false;
  showEditCandidateSkillForm: boolean = false;
  showCandidateSkillList: boolean = true;
  CandidateSkill: any;
  candidateSkillForm: FormGroup;
  candidateSkillEditForm: FormGroup;
  keySkillDropdown: any;
  newSkill: any;
  keySkillData = [];
  countryList: any;
  isCountryLoaded: boolean;
  stateList: any;
  cityList: any;
  candidateResumeForm: FormGroup;
  calAge: any;

  working_from_year: any;
  working_from_month: any;
  working_till_year: any;
  working_till_month: any;
  startDate: moment.Moment;
  endDate: moment.Moment;
  totalWork: number;

  edit_working_from_year: any;
  edit_working_from_month: any;
  edit_working_till_year: any;
  edit_working_till_month: any;
  edit_startDate: moment.Moment;
  edit_endDate: moment.Moment;
  edit_totalWork: number;

  university = [];
  selectedUniversity: Array<string> = [];
  universityId: any;
  skill_: any;
  isCurrencyLoaded: boolean;
  currencyList: any;

  newUniversity: any;
  isEducationLoaded: boolean = false;
  isShown: boolean = true;

  showCertificateList: boolean = true;
  showCertificateForm: boolean = false;
  showEditCertificateForm: boolean = false;

  addCandidateCertificateForm: FormGroup;
  candidateCertificate: any;
  editCandidateCertificateForm: FormGroup;

  profileCompleted: number = 9;
  
  totalAppliedItems: any;

  showResumeUpload: boolean = false;
  // showEditResumeForm: boolean = false;
  showResumeList: boolean = true;
  candidateResume: any;
  totalResumeItems: any;

  show: boolean = false;
  confirm_show: boolean = false;
  old_show: boolean = false;

  addCandidateLanguageForm: FormGroup;
  languageDropdown: any;
  language_: any;
  language = [];
  candidateLanguage: any;
  showCandidateLanguageForm: boolean = false;
  showCandidateLanguageList: boolean = true;
  newLanguage: any;
  keyLanguageData = [];
  selectedLanguages: Array<string> = [];
  isAgeValid: boolean = true;

  // imageChangedEvent: any = '';
  // croppedImage: any = '';
  showEditImage: boolean = false;
  isProfileDone: boolean = false;
  isEducationCompleted: boolean = false;
  isExperienceCompleted: boolean = false;
  isSkillCompleted: boolean = false;
  isCertificateCompleted: boolean = false;
  isResumeCompleted: boolean = false;
  isLanguageCompleted: boolean = false;
  isDocumentCompleted: boolean = false;
  showNotification: boolean = false;
  isShowMore = false;
  allJobs: any;
  filterData: any;
  selectedStateArray = [];

  showDocumentList: boolean = true;
  showDocumentUpload: boolean = false;
  passportPage: boolean = false;
  certificatePage: boolean = false;
  coverLetterPage: boolean = false;
  otherPage: boolean = false;
  candidateDocumentForm: FormGroup;
  candidateDocumentData: any;
  totalDocumentItems: any;
  candidateDocument: any;
  dialCodeList: any;

  isJobCategoryLoaded : boolean = false;
  jobCategoryList: any;

  showProfile: boolean = true;
  originalFilename: any;
  isValidForApplication: string;

  constructor(
    public formBuilder: FormBuilder,
    private token: TokenStorageService,
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private jobsService: JobsService,
    private educationService: EducationService,
    private courseService: CourseService,
    private universityService: UniversityService,
    private courseTypeService: CourseTypeService,
    private candidateEducationService: CandidateEducationService,
    private candidateExperienceService: CandidateExperienceService,
    private candidateSkillService: CandidateSkillService,
    private keySkillService: KeySkillsService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private certificateService: CandidateCertificateService,
    private candidateResumeService: CandidateResumeService,
    private languageService: LanguageService,
    private agencyService: AgencyService,
    private candidateDocumentService: CandidateDocumentService,
    private jobCategoryService: JobCategoryService,
    private cookieService : CookieService
  ) {
    this.currentUser = this.token.getUser();

    this.candidateProfileForm = this.formBuilder.group({
      photo: [''],
      photoSource: [''],
      first_name: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]+$')]],
      last_name: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]+$')]],
      username: ['', Validators.required],
      emailid: ['', [Validators.required, Validators.email]],
      dial_code: ['', Validators.required],
      mobileno: ['', Validators.required],
      age: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(2),
        ],
      ],
      current_salary: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*$')],
      ],
      total_experience: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(2),
        ],
      ],
      gender: ['', Validators.required],
      about_me: ['', Validators.required],
      dob: ['', Validators.required],
      country_id: [''],
      state_id: [''],
      city_id: [''],
      job_category_id: ['', Validators.required],
      job_title: [''],
      currency_id: ['']
    });

    this.candidateChangePasswordForm = this.formBuilder.group({
      old_password: ['',
        [Validators.required,
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
        Validators.pattern(/[!@#$]/),
        ]],
      password: ['',
        [Validators.required,
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
        Validators.pattern(/[!@#$]/),
        ]],
      confirm_password: ['',
        [Validators.required,
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
        Validators.pattern(/[!@#$]/),
        ]]
    });

    this.candidateEducationForm = this.formBuilder.group({
      education_id: ['', Validators.required],
      course_id: ['', [Validators.required]],
      university_id: ['', [Validators.required]],
      course_type: [''],
      passing_year: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(4),
          Validators.maxLength(4),
        ],
      ],
      marks: ['', [Validators.required, Validators.pattern("^[0-9.$%]*$")]],
    });

    this.candidateEducationEditForm = this.formBuilder.group({
      education_id: ['', Validators.required],
      course_id: ['', [Validators.required]],
      university_id: ['', [Validators.required]],
      course_type: [''],
      passing_year: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(4),
          Validators.maxLength(4),
        ],
      ],
      marks: ['', [Validators.required, Validators.pattern("^[0-9.$%]*$")]],
    });

    this.candidateExperienceForm = this.formBuilder.group({
      organization: ['', Validators.required],
      designation: ['', Validators.required],
      is_current_company: ['', Validators.required],
      working_from_year: ['',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(4),
          Validators.maxLength(4),
        ]],
      working_from_month: ['',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(2),
          Validators.max(12)
        ]],
      working_till_year: [''],
      working_till_month: [''],
      total_duration: ['', Validators.required],
      current_salary: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      description: ['', Validators.required],
      notice_period: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.pattern(/$/)]],
      currency_id: ['', Validators.required]
    });

    this.candidateExperienceEditForm = this.formBuilder.group({
      organization: ['', Validators.required],
      designation: ['', Validators.required],
      is_current_company: ['', Validators.required],
      working_from_year: ['',
      [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(4),
        Validators.maxLength(4),
      ]],
    working_from_month: ['',
      [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(2),
        Validators.max(12)
      ]],
      working_till_year: [''],
      working_till_month: [''],
      total_duration: ['', Validators.required],
      current_salary: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      description: ['', Validators.required],
      notice_period: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.pattern(/$/)]],
      currency_id: ['', Validators.required]
    });

    this.candidateSkillForm = this.formBuilder.group({
      key_skills_ids: ['', Validators.required]
    });

    this.candidateResumeForm = this.formBuilder.group({
      file: [''],
      resume: ['']
    });

    this.addCandidateCertificateForm = this.formBuilder.group({
      certificate_name: ['', Validators.required],
      certificate_number: [''],
      issuing_organization: ['', Validators.required],
      issue_date: ['', Validators.required],
      // expiry_date: ['', this.compareDate.bind(this)],
      expiry_date: [''],
      country_id: ['', Validators.required],
      state_id: ['', Validators.required],
      city_id: ['', Validators.required]
    });

    this.editCandidateCertificateForm = this.formBuilder.group({
      certificate_name: ['', Validators.required],
      certificate_number: [''],
      issuing_organization: ['', Validators.required],
      issue_date: ['', Validators.required],
      // expiry_date: ['', this.compareDate.bind(this)],
      expiry_date: [''],
      country_id: ['', Validators.required],
      state_id: ['', Validators.required],
      city_id: ['', Validators.required]
    });

    this.addCandidateLanguageForm = this.formBuilder.group({
      language_id: ['', Validators.required]
    });

    this.candidateDocumentForm = this.formBuilder.group({
      file: [''],
      document_type: [''],
      document: ['']
    });

    this.candidateExperienceForm.get('is_current_company').valueChanges.subscribe(value => {
      if (value == true || value == 1) {
        this.candidateExperienceForm.get('working_till_year').clearValidators();
        this.candidateExperienceForm.get('working_till_year').setErrors(null);
        this.candidateExperienceForm.get('working_till_year').updateValueAndValidity();

        this.candidateExperienceForm.get('working_till_month').clearValidators();
        this.candidateExperienceForm.get('working_till_month').setErrors(null);
        this.candidateExperienceForm.get('working_till_month').updateValueAndValidity();
      } else if (value == false || value == 0 || value == null || value == undefined) {
        this.candidateExperienceForm.get('working_till_year').setValidators([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(4),
          Validators.maxLength(4)
        ]);
        this.candidateExperienceForm.get('working_till_year').updateValueAndValidity();

        this.candidateExperienceForm.get('working_till_month').setValidators([
          Validators.required,
          Validators.maxLength(2),
          Validators.max(12),
          Validators.pattern('^[0-9]*$'),
        ]);
        this.candidateExperienceForm.get('working_till_month').updateValueAndValidity();
      }
    });

    this.candidateExperienceEditForm.get('is_current_company').valueChanges.subscribe(value => {
      if (value == true || value == 1) {
        this.candidateExperienceEditForm.get('working_till_year').clearValidators();
        this.candidateExperienceEditForm.get('working_till_year').setErrors(null);
        this.candidateExperienceEditForm.get('working_till_year').updateValueAndValidity();

        this.candidateExperienceEditForm.get('working_till_month').clearValidators();
        this.candidateExperienceEditForm.get('working_till_month').setErrors(null);
        this.candidateExperienceEditForm.get('working_till_month').updateValueAndValidity();
      } else if (value == false || value == 0 || value == null || value == undefined) {
        this.candidateExperienceEditForm.get('working_till_year').setValidators([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(4),
          Validators.maxLength(4)
        ]);
        this.candidateExperienceEditForm.get('working_till_year').updateValueAndValidity();

        this.candidateExperienceEditForm.get('working_till_month').setValidators([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(2),
          Validators.max(12)
        ]);
        this.candidateExperienceEditForm.get('working_till_month').updateValueAndValidity();
      }
    });

    this.countryService.getCountryDropdown().subscribe((res: any) => {
      this.isCountryLoaded = true;
      this.countryList = res.data;
      this.dialCodeList = res.data;
    });

  }

  get f() {
    return this.candidateProfileForm.controls;
  }

  get fPass() {
    return this.candidateChangePasswordForm.controls;
  }

  get fEdu() {
    return this.candidateEducationForm.controls;
  }

  get eduEdit() {
    return this.candidateEducationEditForm.controls;
  }

  get fExp() {
    return this.candidateExperienceForm.controls;
  }

  get fEditExp() {
    return this.candidateExperienceEditForm.controls;
  }

  get fCert() {
    return this.addCandidateCertificateForm.controls;
  }

  get fCertEdit() {
    return this.editCandidateCertificateForm.controls;
  }

  get fLang() {
    return this.addCandidateLanguageForm.controls;
  }

  get gender() {
    return this.candidateProfileForm.get('gender');
  }

  compareDate(control : FormControl) {
    const issueDate = this.addCandidateCertificateForm?.get('issue_date')?.value;
    const expiryDate = control.value;

    if (issueDate > expiryDate) {
      return { isIssueDateGreater : true }
    } else if (issueDate == expiryDate) {
      return { isIssueDateGreater : true }
    }
  }

  fileChangeEvent(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.showEditImage = true;
      this.originalFilename = file.name; // Get the original filename
      this.imageChangedEvent = event; // Assign the event to imageChangedEvent for the cropper
    } else {
      console.error('No file selected');
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
    const file = new File([this.croppedImage], this.originalFilename, { type: 'image/png' });
    this.candidateProfileForm.patchValue({ photoSource: file });
  }

  imageLoaded() {}

  cropperReady() {}

  loadImageFailed() {}

  changeCity(e): any {
    alert('test');
  }

  ngOnInit(): void {
    this.getCandidateProfileDetails();
    this.candidateAppliedJobsListData(this.page);
    this.candidateFavouriteJobsListData(this.page);
    this.candidateEducationListData(this.page);
    this.candidateExperienceListData(this.page);
    this.candidateSkillListData(this.page);
    this.educationDropdown();
    this.universityDropdown();
    this.getKeySkillDropdown();
    this.getCurrencyDropdwon();
    this.candidateCertificateList(this.page);
    this.candidateResumeList(this.page);
    this.candidateLanguageListData(this.page);
    this.getKeyLanguageDropdown();
    this.getAllJobs();
    this.candidateDocumentList(this.page);
    this.jobCategoryDropdown();
    this.isValidForJobApplication();

    this.candidateProfileForm.get('mobileno').valueChanges.subscribe(value => {
      const dialCode = this.candidateProfileForm.get('dial_code').value;
      const numberLength = this.countryList.filter((item:any) => { return item.id == dialCode });
      const numberLength_ = JSON.parse(numberLength[0].number_length);
      const validPattern = /^[0-9]*$/.test(value);
      if(!validPattern){
        this.candidateProfileForm.get('mobileno').setErrors({ 'invalidMobileNumber': false, pattern: true });
      } else if (!numberLength_.includes(value.length)) {
        this.candidateProfileForm.get('mobileno').setErrors({ 'invalidMobileNumber':true, pattern: false })
      } else {
        this.candidateProfileForm.get('mobileno').setErrors(null);
      }
    });

  }

  jobCategoryDropdown() {
    this.jobCategoryService.getJobCategoryDropdown().subscribe((res: any) => {
      this.isJobCategoryLoaded = true;
      this.jobCategoryList = res.data;
    });
  }

  getCandidateProfileDetails() {
    this.crudService.GetCandidateProfileDetail().subscribe((res: any) => {

      res.data.country_id ? this.getState(`${res.data.country_id?.toString()}: ${res.data.country_id?.toString()}`) : this.getState(`${res.data.dial_code?.toString()}: ${res.data.dial_code?.toString()}`);
      this.getCity(`${res.data.state_id?.toString()}: ${res.data.state_id?.toString()}`);

      this.candidateProfileForm.patchValue({
        photo: res.data.photo || '',
        photoSource: null,
        first_name: res.data.first_name || '',
        last_name: res.data.last_name || '',
        username: res.data.username || '',
        emailid: res.data.emailid || '',
        dial_code: res.data.dial_code || '',
        mobileno: res.data.mobileno?.toString() || '',
        age: this.calAge || res.data.age?.toString() || '',
        // age: this.calAge || '',
        current_salary: res.data.current_salary?.toString() || '',
        total_experience: res.data.total_experience?.toString() || '',
        gender: res.data.gender || '',
        about_me: res.data.about_me || '',
        dob: res.data.dob || '',
        country_id: res.data.country_id?.toString() || res.data.dial_code || '',
        state_id: res.data.state_id?.toString() || '',
        city_id: res.data.city_id?.toString() || '',
        job_category_id: res.data.job_category?.id.toString() || '',
        job_title: res.data.job_title?.toString() || '',
        currency_id: res.data.currency_id?.toString() || res.data.dial_code || ''
      });

      this.checkProfileStatus(res.data);
      this.userData = res.data;
      this.token.saveUser(this.userData);

      if (!this.userData.photo_url) {
        this.imgPhotoUrl = '../../../../assets/img/default1.png';
      } else {
        this.imgPhotoUrl = `${environment.apiBaseUrl}candidate/profile/${this.userData.photo}`;
      }

      if (res.data.first_name != null &&
        res.data.last_name != null &&
        res.data.username != null &&
        res.data.emailid != null &&
        res.data.mobileno != null &&
        res.data.age != null &&
        res.data.current_salary != null &&
        res.data.total_experience != null &&
        res.data.gender != null &&
        res.data.about_me != null &&
        res.data.dob != null &&
        res.data.country_id != null &&
        res.data.state_id != null &&
        res.data.city_id != null &&
        res.data.job_category_id != null &&
        res.data.job_title != null &&
        res.data.currency_id != null) {
        this.profileCompleted = +this.profileCompleted + +13;
        console.log("profile score", this.profileCompleted);
      }
     //this.saveProfileScore();

      if (this.userData.candidate_applied_jobs.length > 0) {
        const temp = this.userData.candidate_applied_jobs.some((item) => item.selection_state != null);
        temp ? this.showNotification = !this.showNotification : this.showNotification;
        this.selectedStateArray.push(this.userData.candidate_applied_jobs.filter((item) => item.selection_state != null));
      }
    });
  }

  expandItems() {
    this.isShowMore = !this.isShowMore;
  }

  educationDropdown() {
    this.educationService.getEducationDropdown().subscribe((res: any) => {
      this.isEducationLoaded = true;
      this.educationList = res.data;
    });
  }

  universityDropdown() {
    this.universityService.getUniversityDropdown().subscribe((res: any) => {
      this.universityList = res.data;
      this.university = this.universityList.map(item => ({ id: item.id, name: item.name }));
    });
  }

  addNewUniversity = (university: any) => {
    this.newUniversity = university;
    let payload = {
      name: this.newUniversity,
      status: "1"
    };
    this.universityService.addUniversity(payload).subscribe((res: any) => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'University Added Successfully';
      this.universityDropdown();
      this.candidateEducationForm.controls['university_id'].setValue(res.data.id);
    });
  }

  courseTypeDropdown(educationId: string) {
    const toArray = educationId.split(": ");
    const educatioId_ = toArray[1];
    this.courseService.getCourseListDropdown(educatioId_).subscribe((res: any) => {
      this.courseTypeList = res.data;
    });
  }

  candidateAppliedJobsListData(page: any) {
    this.jobsService.GetCandidateAppliedJobsListData(page, this.itemsPerPage).subscribe((res: any) => {
      this.CandidateAppliedJobs = res.data;
      this.totalAppliedItems = res.paging.total;
      this.CandidateAppliedJobs.map(element => {
        if (element.job.web_user.photo != null) {
          element.appliedJobProfileUrl = `${environment.apiBaseUrl}employer/${element.job.web_user.photo}`;
        } else {
          element.appliedJobProfileUrl = '../../../../assets/img/default1.png';
        }
      });

    });
  }

  candidateFavouriteJobsListData(page: any) {
    this.jobsService.GetCandidateFavouriteJobsListData(page, this.itemsPerPage).subscribe((res: any) => {
      this.CandidateFavouriteJobs = res.data;
      this.totalFavItems = res.paging.total;
      this.CandidateFavouriteJobs.map(element => {
        if (element.job.web_user.photo != null) {
          element.appliedJobProfileUrl = `${environment.apiBaseUrl}employer/${element.job.web_user.photo}`;
        } else {
          element.appliedJobProfileUrl = '../../../../assets/img/default1.png';
        }
        element.jobTitle = element.job.title.replace(/[\s\/]+/g, '-').toLowerCase();
      });
    });
  }

  onSubmit(): any {
    const formData = new FormData();
    formData.append('name', this.candidateProfileForm.get('name')?.value);
    formData.append(
      'emailid',
      this.candidateProfileForm.get('emailid')?.value
    );
    formData.append(
      'mobileno',
      this.candidateProfileForm.get('mobileno')?.value
    );
    formData.append(
      'gender',
      this.candidateProfileForm.get('gender')?.value
    );
    formData.append(
      'about_me',
      this.candidateProfileForm.get('about_me')?.value
    );
    formData.append(
      'current_salary',
      this.candidateProfileForm.get('current_salary')?.value
    );
    formData.append(
      'total_experience',
      this.candidateProfileForm.get('total_experience')?.value
    );
    if (this.candidateProfileForm.get('photoSource')?.value) {
      formData.append('photo', this.candidateProfileForm.get('photoSource')?.value);
    }
    if (this.candidateProfileForm.get('dob')?.value) {
      formData.append(
        'dob',
        this.candidateProfileForm.get('dob')?.value
      );
    }
    if (this.candidateProfileForm.get('age')?.value) {
      formData.append('age', this.candidateProfileForm.get('age')?.value);
    }
    if (this.candidateProfileForm.get('dial_code')?.value) {
      formData.append('dial_code', this.candidateProfileForm.get('dial_code')?.value);
    }
    if (this.candidateProfileForm.get('country_id')?.value) {
      formData.append(
        'country_id',
        this.candidateProfileForm.get('country_id')?.value
      );
    }
    if (this.candidateProfileForm.get('state_id')?.value) {
      formData.append(
        'state_id',
        this.candidateProfileForm.get('state_id')?.value
      );
    }
    if (this.candidateProfileForm.get('city_id')?.value) {
      formData.append(
        'city_id',
        this.candidateProfileForm.get('city_id')?.value
      );
    }
    if (this.candidateProfileForm.get('resume')?.value) {
      formData.append(
        'resume',
        this.candidateProfileForm.get('resume')?.value
      );
    }
    if (this.candidateProfileForm.get('job_category_id')?.value) {
      formData.append('job_category_id', this.candidateProfileForm.get('job_category_id')?.value);
    }
    if (this.candidateProfileForm.get('job_title')?.value) {
      formData.append('job_title', this.candidateProfileForm.get('job_title')?.value);
    }
    if (this.candidateProfileForm.get('currency_id')?.value) {
      formData.append(
        'currency_id',
        this.candidateProfileForm.get('currency_id')?.value
      );
    }

    this.crudService.updateCandidateProfile(formData).subscribe(
      (res: any) => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = 'Profile Update Successfully';
        this.userData.photo_url = res.data.photo_url;
        // this.getCandidateProfileDetails();
        window.location.reload();
        this.showProfile = true;
      },
      (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 150) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      }
    );
  }

  updateCandidatePassword(): any {
    const oldPassword = this.candidateChangePasswordForm.get('old_password').value;
    const password = this.candidateChangePasswordForm.get('password').value;
    const newPassword = this.candidateChangePasswordForm.get('confirm_password').value;
    let payload = { oldPassword: oldPassword, password: password };

    if (password === newPassword) {
      this.crudService.updateCandidatePassword(payload).subscribe(() => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = 'Password Chanaged Successfully';
        this.candidateChangePasswordForm.reset();
        this.logout();
      },
        (err) => {
          console.log(err);
          this.error = true;
          if (err.status == 150) {
            this.errorMessage = err.error.message;
          } else if (err.status == 409) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = err.statusText;
          }
        });
    } else {
      this.error = true;
      this.errorMessage = "Password Does Not Matched!!"
    }
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.saveSucess = false;
    }, 1500);
  }

  logout(): void {
    this.token.signOut();
    this.ngZone.run(() => this.router.navigateByUrl('/login'));
  }

  candidateEducationListData(page: any) {
    this.candidateEducationService.GetCandidateEducation(page, this.itemsPerPage).subscribe((res: any) => {
      window.sessionStorage.setItem("CandidateEducation", JSON.stringify(res.data.data));
      this.CandidateEducation = res.data.data;
      this.totalCandidateEducationItems = res.data.paging.total;
      this.checkEducationStatus(res.data.data);
      this.isValidForJobApplication();
    });
  }

  getSavedCandidateEducation() {
    this.CandidateEducation = this.token.getCandidateEducation();
  }

  deleteCandidateEducation(id: any, i: any) {
    if (window.confirm('Do you want to delete?')) {
      this.candidateEducationService
        .deleteCandidateEducation(id)
        .subscribe(
          (res) => {
            this.saveSucess = true;
            this.error = false;
            this.successMessage = 'Education Deleted Successfully';
            this.candidateEducationListData(this.page);
            this.showCandidateEducationForm = false;
            this.showEditCandidateEducationForm = false;
            this.showCandidateEducationList = true;
          },
          (err) => {
            console.log(err);
            this.error = true;
            if (err.status == 150) {
              this.errorMessage = err.error.message;
            } else if (err.status == 409) {
              this.errorMessage = err.error.message;
            } else {
              this.errorMessage = err.statusText;
            }
          }
        );
    }
  }

  addCandidateEducation(): any {
    this.showCandidateEducationForm = true;
    this.showEditCandidateEducationForm = false;
    this.showCandidateEducationList = false;
    this.candidateEducationForm.reset();
  }

  editCandidateEducation(id): any {
    this.showEditCandidateEducationForm = true;
    this.showCandidateEducationList = false;
    this.showCandidateEducationForm = false;
    this.candidateEducationEditForm.reset();

    this.getId = id;

    this.candidateEducationService.GetCandidateEducationDetail(this.getId).subscribe((res: any) => {
      this.getCourse(`${res.data.data[0].education_id.toString()}: ${res.data.data[0].education_id.toString()}`);
      this.candidateEducationEditForm.setValue({
        education_id: res.data.data[0].education_id.toString(),
        course_id: res.data.data[0].course_id.toString(),
        university_id: res.data.data[0].university_id.toString(),
        course_type: res.data.data[0].course_type?.toString() || '',
        passing_year: res.data.data[0].passing_year,
        marks: res.data.data[0].marks,
      });
    });
  }

  getCourse(educationId: string) {
    const toArray = educationId.split(': ');
    const education_id = toArray[1];
    this.courseService.getCourseListDropdown(education_id).subscribe((res: any) => {
      this.courseList = res.data;
    });
  }

  viewCandidateEducation(): any {
    this.showCandidateEducationForm = false;
    this.showEditCandidateEducationForm = false;
    this.showCandidateEducationList = true;
    this.getSavedCandidateEducation();
  }

  candidateEducationSubmit(): any {
    const universityId = this.candidateEducationForm.get('university_id').value;
    this.candidateEducationForm.controls['university_id'].setValue(universityId.toString());
    this.candidateEducationService.AddCandidateEducation(this.candidateEducationForm.value).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Education Added Successfully';
      this.candidateEducationListData(this.page);
      this.showCandidateEducationForm = false;
      this.showEditCandidateEducationForm = false;
      this.showCandidateEducationList = true;
    }, (err) => {
      console.log(err);
      this.error = true;
      if (err.status == 150) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  candidateEducationUpdate(): any {
    this.candidateEducationService.updateCandidateEducation(this.getId, this.candidateEducationEditForm.value)
      .subscribe(() => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = 'Education Updated Successfully';
        this.candidateEducationListData(this.page);
        this.showCandidateEducationForm = false;
        this.showEditCandidateEducationForm = false;
        this.showCandidateEducationList = true;
      },
        (err) => {
          console.log(err);
          this.error = true;
          if (err.status == 200) {
            this.errorMessage = err.error.message;
          } else if (err.status == 409) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = err.statusText;
          }
        }
      );
  }

  viewCandidateExperience(): any {
    this.showCandidateExperienceForm = false;
    this.showEditCandidateExperienceForm = false;
    this.showCandidateExperienceList = true;
    this.getSavedCandidateExperience();
  }

  addCandidateExperience(): any {
    this.showCandidateExperienceForm = true;
    this.showEditCandidateExperienceForm = false;
    this.showCandidateExperienceList = false;
    this.candidateExperienceForm.reset();
  }

  candidateExperienceListData(page: any) {
    this.candidateExperienceService.GetCandidateExperience(page, this.itemsPerPage).subscribe((res: any) => {
      window.sessionStorage.setItem("CandidateExperience", JSON.stringify(res.data.data));
      this.CandidateExperience = res.data.data;
      this.totalCandidateExperienceItems = res.data.paging.total;
      this.checkExperienceStatus(res.data.data);
      this.isValidForJobApplication();
    });
  }

  getSavedCandidateExperience() {
    this.CandidateExperience = this.token.getCandidateExperience();
  }

  editCandidateExperience(id): any {
    this.showEditCandidateExperienceForm = true;
    this.showCandidateExperienceList = false;
    this.showCandidateExperienceForm = false;
    this.candidateExperienceForm.reset();

    this.getId = id;

    this.candidateExperienceService.GetCandidateExperienceDetail(this.getId).subscribe((res: any) => {
      // this.getCourse(`${res.data.education_id.toString()}: ${res.data.education_id.toString()}`);
      this.candidateExperienceEditForm.patchValue({
        organization: res.data.organization,
        designation: res.data.designation.toString(),
        is_current_company: res.data.is_current_company,
        working_from_year: res.data.working_from_year.toString(),
        working_from_month: res.data.working_from_month.toString(),
        working_till_year: res.data.working_till_year.toString(),
        working_till_month: res.data.working_till_month.toString(),
        total_duration: res.data.total_duration.toString(),
        current_salary: res.data.current_salary.toString(),
        description: res.data.description.toString(),
        notice_period: res.data.notice_period.toString(),
        currency_id: res.data.currency_id.toString(),
      });
      this.editHideWorking(res.data);
    });
  }

  deleteCandidateExperience(id: any) {
    if (window.confirm('Do you want to delete?')) {
      this.candidateExperienceService.DeleteCandidateExperience(id).subscribe((res) => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = 'Experience Deleted Successfully';
        this.candidateExperienceListData(this.page);
        this.showCandidateExperienceForm = false;
        this.showEditCandidateExperienceForm = false;
        this.showCandidateExperienceList = true;
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
    }
  }

  candidateExperienceSubmit(): any {
    const isCurrentCompany = this.candidateExperienceForm.get('is_current_company').value == 1 ? 1 : 0;
    if (isCurrentCompany == 1) {
      this.candidateExperienceForm.controls['working_till_year'].setValue(moment().format('YYYY').toString());
      this.candidateExperienceForm.controls['working_till_month'].setValue(moment().format('MM').toString());
    }
    this.candidateExperienceForm.controls['is_current_company'].setValue(isCurrentCompany.toString());
    this.candidateExperienceService.AddCandidateExperience(this.candidateExperienceForm.value).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Experience Added Successfully';
      this.candidateExperienceForm.reset();
      this.candidateExperienceListData(this.page);
      this.showCandidateExperienceForm = false;
      this.showEditCandidateExperienceForm = false;
      this.showCandidateExperienceList = true;
    }, (err) => {
      console.log(err);
      this.error = true;
      if (err.status == 200) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  candidateExperienceUpdate(): any {
    const isCurrentCompany = this.candidateExperienceEditForm.get('is_current_company').value == 1 ? 1 : 0;
    if (isCurrentCompany == 1) {
      this.candidateExperienceEditForm.controls['working_till_year'].setValue(moment().format('YYYY').toString());
      this.candidateExperienceEditForm.controls['working_till_month'].setValue(moment().format('MM').toString());
    }
    this.candidateExperienceEditForm.controls['is_current_company'].setValue(isCurrentCompany.toString());
    this.candidateExperienceService.UpdateCandidateExperience(this.getId, this.candidateExperienceEditForm.value).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Experience Updated Successfully';
      this.candidateExperienceListData(this.page);
      this.showCandidateExperienceForm = false;
      this.showEditCandidateExperienceForm = false;
      this.showCandidateExperienceList = true;
    },
      (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      }
    );
  }

  viewCandidateSkill(): any {
    this.showCandidateSkillForm = false;
    this.showEditCandidateSkillForm = false;
    this.showCandidateSkillList = true;
    this.getSavedCandidateSkill();
  }

  addCandidateSkill(): any {
    this.showCandidateSkillForm = true;
    this.showEditCandidateSkillForm = false;
    this.showCandidateSkillList = false;
    this.candidateSkillForm.reset();
  }

  candidateSkillListData(page: any) {
    this.candidateSkillService.GetCandidateSkill(page, this.itemsPerPage).subscribe((res: any) => {
      window.sessionStorage.setItem("CandidateSkill", JSON.stringify(res.data.data));
      this.CandidateSkill = res.data.data;
      this.totalCandidateSkillItems = res.data.paging.total;
      this.checkSkillStatus(res.data.data);
      this.isValidForJobApplication();
    });
  }

  getSavedCandidateSkill() {
    this.CandidateSkill = this.token.getCandidateSkill();
  }

  candidateSkillSubmit(): any {
    this.candidateSkillService.AddCandidateSkill(this.candidateSkillForm.value).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Skills Added Successfully';
      this.candidateSkillListData(this.page);
      this.showCandidateSkillForm = false;
      this.showEditCandidateSkillForm = false;
      this.showCandidateSkillList = true;
      this.candidateSkillForm.reset();
    }, (err) => {
      console.log(err);
      this.error = true;
      if (err.status == 200) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  deleteCandidateSkill(id: any) {
    if (window.confirm('Do you want to delete?')) {
      this.candidateSkillService.DeleteCandidateSkill(id).subscribe((res) => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = 'Skill Deleted Successfully';
        this.candidateSkillListData(this.page);
        this.showCandidateSkillForm = false;
        this.showEditCandidateSkillForm = false;
        this.showCandidateSkillList = true;
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
    }
  }

  getKeySkillDropdown() {
    this.keySkillService.getKeySkillsDropdown().subscribe((res: any) => {
      this.skill = res.data;
      // this.skill_ = this.CandidateSkill?.map(item => { return item.key_skills_id });
      // this.skill = this.keySkillDropdown.filter(item => !this.skill_?.includes(item.id));
    })
  }

  createNewSkill = (skill: any) => {
    this.newSkill = skill;
    let payload = {
      name: this.newSkill,
      status: "1"
    };
    this.keySkillService.addKeySkill(payload).subscribe((res: any) => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Skills Added Successfully';
      this.getKeySkillDropdown();
      this.selectedSkills.push(res.data.id);
    }, (err) => {
      console.log(err);
      this.error = true;
      if (err.status == 200) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  getState(countryId: string) {
    const toArray = countryId.split(": ");
    const country_id = toArray[1];
    this.stateService.getStateListDropdown(country_id).subscribe((res: any) => {
      this.stateList = res.data;
    });
  }

  getCity(stateId: string) {
    const toArray = stateId.split(": ");
    const state_id = toArray[1];
    this.cityService.getCityListDropdown(state_id).subscribe((res: any) => {
      this.cityList = res.data;
    });
  }

  navigateResume() {
    const resumeTitle = this.currentUser.first_name.replace(/[\s\/]+/g, '-').toLowerCase() + '-' + this.currentUser.last_name.replace(/[\s\/]+/g, '-').toLowerCase();
    this.router.navigateByUrl(`/resume/${this.currentUser.id}/${resumeTitle}`);
  }

  downloadResume() {
    window.open(`${environment.apiurl}candidate/resume-download/${this.currentUser.id}`, "_blank");
  }

  onResumeUpload(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.candidateResumeForm.patchValue({
        resume: file,
      });
    }
  }

  uploadCV() {
    const formData = new FormData();
    formData.append('file', this.candidateResumeForm.get('resume')?.value);
    this.crudService.addCandidateResume(formData).subscribe(res => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Resume Added Successfully';
      // this.candidateResumeService.getCandidateResume(this.page, this.itemsPerPage).subscribe((res: any) => { this.candidateResume = res.data.data; this.totalResumeItems = res.data.paging.total; });
      this.candidateResumeList(this.page);
      this.showResumeUpload = false;
      this.showResumeList = true;
    }, (err) => {
      console.log(err);
      this.error = true;
      if (err.status == 200) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  previewResume(id: any) {
    this.candidateResumeService.getCandidateResume(this.page, this.itemsPerPage).subscribe((res: any) => {
      res.data.data.map((item) => {
        if (item.id == id) {
          window.open(`${environment.apiBaseUrl}candidate/resume/${item.resume_name}`, "_blank");
        }
      })
    })
  }

  calculateTotalWorking() {
    var sDate = '' + this.working_from_month + '-' + this.working_from_year;
    var eDate = this.working_till_year ? '' + this.working_till_month + '-' + this.working_till_year : moment().format('MM-YYYY');
    this.startDate = moment(sDate, 'MM-YYYY');
    this.endDate = moment(eDate, 'MM-YYYY');
    this.totalWork = this.endDate.diff(this.startDate, 'years');
    if (this.totalWork < 0) {
      this.candidateExperienceForm.get('total_duration').setErrors({ 'invalidDate': true });
    } else if (this.totalWork == 0) {
      this.candidateExperienceForm.get('total_duration').setErrors({ 'invalidTotal': true });
    } else {
      this.candidateExperienceForm.get('total_duration').setErrors({ 'invalidDate': false });
      this.candidateExperienceForm.get('total_duration').setErrors({ 'invalidDate': null });
      this.candidateExperienceForm.get('total_duration').updateValueAndValidity();

      this.candidateExperienceForm.get('total_duration').setValue(this.totalWork);
    }
  }

  calculateTotalWorkingEdit() {
    var sDate = '' + this.edit_working_from_month + '-' + this.edit_working_from_year;
    if (this.edit_working_till_month != null && this.edit_working_till_year != null) {
      var eDate = '' + this.edit_working_till_month + '-' + this.edit_working_till_year;
      this.edit_startDate = moment(sDate, 'MM-YYYY');
      this.edit_endDate = moment(eDate, 'MM-YYYY');
      this.edit_totalWork = this.edit_endDate.diff(this.edit_startDate, 'months');
      if (this.edit_totalWork < 0) {
        this.candidateExperienceEditForm.get('total_duration').setErrors({ 'invalidEditDate': true });
      } else if (this.edit_totalWork == 0) {
        this.candidateExperienceEditForm.get('total_duration').setErrors({ 'invalidEditTotal': true });
      } else {
        this.candidateExperienceEditForm.get('total_duration').setErrors({ 'invalidEditDate': false });
        this.candidateExperienceEditForm.get('total_duration').setErrors({ 'invalidEditDate': null });
        this.candidateExperienceEditForm.get('total_duration').updateValueAndValidity();
        this.candidateExperienceEditForm.get('total_duration').setValue(this.edit_totalWork);
      }

    } else {
      var eDate = moment().format('MM-YYYY');
      this.edit_startDate = moment(sDate, 'MM-YYYY');
      this.edit_endDate = moment(eDate, 'MM-YYYY');
      this.edit_totalWork = this.edit_endDate.diff(this.edit_startDate, 'months');

      if (this.edit_totalWork < 0) {
        this.candidateExperienceEditForm.get('total_duration').setErrors({ 'invalidEditDate': true });
      } else if (this.edit_totalWork == 0) {
        this.candidateExperienceEditForm.get('total_duration').setErrors({ 'invalidEditTotal': true });
      } else {
        this.candidateExperienceEditForm.get('total_duration').setErrors({ 'invalidEditDate': false });
        this.candidateExperienceEditForm.get('total_duration').setErrors({ 'invalidEditDate': null });
        this.candidateExperienceEditForm.get('total_duration').updateValueAndValidity();
        this.candidateExperienceEditForm.get('total_duration').setValue(this.edit_totalWork);
      }

    }
  }

  calculateAge() {
    const dateOfBirth = this.candidateProfileForm.get('dob').value;
    this.calAge = moment().diff(dateOfBirth, 'years');
    if (this.calAge <= 18) {
      this.error = true;
      this.errorMessage = "Candidate Age Must Be Greater Than 18 Years.";
      this.candidateProfileForm.controls['age'].patchValue(this.calAge);
      this.isAgeValid = false;
    } else {
      this.candidateProfileForm.controls['age'].patchValue(this.calAge);
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Candidate Age is Greater Than 18 Years.';
      this.isAgeValid = true;
    }
  }

  addHideWorking() {
    this.isShown = !this.isShown;
    if (this.isShown == false) {
      this.candidateExperienceForm.patchValue({ 'working_till_month': null });
      this.candidateExperienceForm.patchValue({ 'working_till_year': null });
      this.candidateExperienceForm.patchValue({ 'total_duration': null });
    }
    this.calculateTotalWorking();
  }

  hideWorking() {
    this.isShown = !this.isShown;
    if (this.isShown == false) {
      this.candidateExperienceEditForm.patchValue({ 'working_till_month': null });
      this.candidateExperienceEditForm.patchValue({ 'working_till_year': null });

      this.candidateExperienceForm.patchValue({ 'working_till_month': null });
      this.candidateExperienceForm.patchValue({ 'working_till_year': null });
    }
  }

  editHideWorking(data) {
    if (data.is_current_company == 1) {
      this.isShown = false;
      this.candidateExperienceEditForm.patchValue({ 'working_till_month': null });
      this.edit_working_till_year = null;
      this.calculateTotalWorkingEdit();
    } else {
      this.isShown = true;
      this.calculateTotalWorkingEdit();
    }

  }

  getToday() {
    return new Date().toISOString().split('T')[0]
  }

  getCurrencyDropdwon() {
    this.countryService.getCountryDropdown().subscribe((res: any) => {
      this.isCurrencyLoaded = true;
      this.currencyList = res.data;
    });
  }

  viewCertificateList() {
    this.showCertificateForm = false;
    this.showEditCertificateForm = false;
    this.showCertificateList = true;
    this.getSavedCandidateCertificate();
  }

  viewAddCertificate() {
    this.showCertificateForm = true;
    this.showEditCertificateForm = false;
    this.showCertificateList = false;
    // Reset the form here.
  }

  editCertificateForm(id) {
    this.showCertificateForm = false;
    this.showEditCertificateForm = true;
    this.showCertificateList = false;

    this.getId = id;

    this.certificateService.GetCandidateCertificateDetails(this.getId).subscribe((res: any) => {
      this.getState(`${res.data.data[0].country_id?.toString()}: ${res.data.data[0].country_id?.toString()}`);
      this.getCity(`${res.data.data[0].state_id?.toString()}: ${res.data.data[0].state_id?.toString()}`);
      console.log("response is ",res.data.data[0].certificate_name.toString() )
      this.editCandidateCertificateForm.patchValue({
        certificate_name: res.data.data[0].certificate_name.toString()?res.data.data[0].certificate_name.toString():"vijay",
        certificate_number: res.data.data[0].certificate_number.toString(),
        issuing_organization: res.data.data[0].issuing_organization.toString(),
        issue_date: res.data.data[0].issue_date.toString(),
        expiry_date: res.data.data[0].expiry_date.toString(),
        country_id: res.data.data[0].country_id.toString(),
        state_id: res.data.data[0].state_id.toString(),
        city_id: res.data.data[0].city_id.toString()
      });
      // this.editHideWorking(res.data);
    });
    
  }

  deleteCertificate(id) {
    if (window.confirm('Do you Want to Delete this Certificate?')) {
      this.certificateService.DeleteCandidateCertificate(id).subscribe(() => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = 'Certificate Deleted Successfully';
        this.candidateCertificateList(this.page);
        this.showCertificateForm = false;
        this.showEditCertificateForm = false;
        this.showCertificateList = true;
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
    }
  }

  candidateCertificateSubmit() {
    this.certificateService.AddCandidateCertificate(this.addCandidateCertificateForm.value).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Certificate Details Added Successfully.';
      this.addCandidateCertificateForm.reset();
      this.candidateCertificateList(this.page);
      this.showCertificateForm = false;
      this.showEditCertificateForm = false;
      this.showCertificateList = true;
    }, (err) => {
      console.log(err);
      this.error = true;
      if (err.status == 200) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  candidateCertificateList(page: any) {
    this.certificateService.GetCandidateCertificateList(page, this.itemsPerPage).subscribe((res: any) => {
      window.sessionStorage.setItem("CandidateCertificate", JSON.stringify(res.data.data));
      this.candidateCertificate = res.data.data;
      this.totalCandidateCertificateItems = res.data.paging.total;
      this.checkCertificateStatus(res.data.data);
      this.isValidForJobApplication();
    })
  }

  getSavedCandidateCertificate() {
    this.candidateCertificate = this.token.getCandidateCertificate();
  }

  updateCandidateCertificate() {
    this.certificateService.UpdateCandidateCertificate(this.getId, this.editCandidateCertificateForm.value).subscribe((res: any) => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Certificate Updated Successfully';
      this.candidateCertificateList(this.page);
      this.showCertificateForm = false;
      this.showEditCertificateForm = false;
      this.showCertificateList = true;
    },
      (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
  }

  viewResumeList() {
    this.showResumeUpload = false;
    this.showResumeList = true;
    this.getSavedCandidateResume();
  }

  viewAddResume() {
    this.showResumeUpload = true;
    this.showResumeList = false;
    // Reset the Upload here.
  }

  candidateResumeList(page: any) {
    this.candidateResumeService.getCandidateResume(page, this.itemsPerPage).subscribe((res: any) => {
      window.sessionStorage.setItem("CandidateResume", JSON.stringify(res.data.data));
      this.candidateResume = res.data.data;
      this.totalResumeItems = res.data.paging.total;
      this.checkResumeStatus(res.data.data);
      this.isValidForJobApplication();
    })
  }

  getSavedCandidateResume() {
    this.candidateResume = this.token.getCandidateResume();
  }

  deleteCandidateResume(id: any) {
    this.candidateResumeService.deleteCandidateResume(id).subscribe((res: any) => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Resume Deleted Successfully';
      this.candidateResumeList(this.page);
      this.showResumeUpload = false;
      this.showResumeList = true;
    },
      (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
  }

  showpassword() {
    this.show = !this.show;
  }

  showConfirmPassword() {
    this.confirm_show = !this.confirm_show
  }

  showOldPassword() {
    this.old_show = !this.old_show;
  }

  viewCandidateLanguage(): any {
    this.showCandidateLanguageForm = false;
    this.showCandidateLanguageList = true;
    this.getSavedCandidateLanguage();
  }

  addCandidateLanguage(): any {
    this.showCandidateLanguageForm = true;
    this.showCandidateLanguageList = false;
    this.addCandidateLanguageForm.reset();
  }

  candidateLanguageListData(page: any) {
    this.languageService.getCandidateLanguage(page, this.itemsPerPage).subscribe((res: any) => {
      window.sessionStorage.setItem("CandidateLanguage", JSON.stringify(res.data.data));
      this.candidateLanguage = res.data.data;
      this.totalCandidateLanguageItems = res.data.paging.total;
      this.checkLanguageStatus(res.data.data);
      this.isValidForJobApplication();
    });
  }

  candidateLanguageSubmit(): any {
    this.languageService.addCandidateLanguage(this.addCandidateLanguageForm.value).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Language Added Successfully';
      this.addCandidateLanguageForm.reset();
      this.candidateLanguageListData(this.page);
      this.showCandidateLanguageForm = false;
      this.showCandidateLanguageList = true;  
    }, (err) => {
      console.log(err);
      this.error = true;
      if (err.status == 200) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  deleteCandidatelanguage(id: any) {
    if (window.confirm('Do you want to delete?')) {
      this.languageService.deleteCandidateLanguage(id).subscribe((res) => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = 'Language Deleted Successfully';
        this.candidateLanguageListData(this.page);
        this.showCandidateLanguageForm = false;
        this.showCandidateLanguageList = true;  
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
    }
  }

  getSavedCandidateLanguage() {
    this.candidateLanguage = this.token.getCandidateLanguage();
  }

  getKeyLanguageDropdown() {
    this.languageService.languageDropdown().subscribe((res: any) => {
      this.language = res.data;
    })
  }

  createNewLanguage = (language: any) => {
    this.newLanguage = language;
    let payload = {
      name: this.newLanguage,
      status: "1"
    };
    this.languageService.addLanguage(payload).subscribe((res: any) => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Language Added Successfully';
      this.getKeyLanguageDropdown();
      this.selectedLanguages.push(res.data.id);
    }, (err) => {
      console.log(err);
      this.error = true;
      if (err.status == 200) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  // saveProfileScore() {
  //   window.sessionStorage.setItem("profileScore", this.profileCompleted.toString());
  // }

  checkProfileStatus(data: any) {
    if (
      data.first_name != null &&
      data.last_name != null &&
      data.username != null &&
      data.emailid != null &&
      data.mobileno != null &&
      data.age != null &&
      data.current_salary != null &&
      data.total_experience != null &&
      data.gender != null &&
      data.about_me != null &&
      data.dob != null &&
      data.country_id != null &&
      data.state_id != null &&
      data.city_id != null &&
      data.job_title != null &&
      data.currency_id != null &&
      data.photo_url != ""
    ) {
      this.isProfileDone = !this.isProfileDone;
    }
  }

  checkEducationStatus(data: any) {
    if(this.isEducationCompleted == false) {
      if (data.length > 0) {
        this.isEducationCompleted = true;
        this.profileCompleted = +this.profileCompleted + +13;
       //this.saveProfileScore();
      }
    } else if (data.length == 0) {
      this.isEducationCompleted = false;
      this.profileCompleted = +this.profileCompleted - +13;
       //this.saveProfileScore();
    }
  }
  checkExperienceStatus(data: any) {
    if(this.isExperienceCompleted == false) {
      if (data.length > 0) {
        this.isExperienceCompleted = true;
        this.profileCompleted = +this.profileCompleted + +13;
       //this.saveProfileScore();
      }
    } else if (data.length == 0) {
      this.isExperienceCompleted = false;
      this.profileCompleted = +this.profileCompleted - +13;
       //this.saveProfileScore();
    }
  }
  checkSkillStatus(data: any) {
    if(this.isSkillCompleted == false) {
      if (data.length > 0) {
        this.isSkillCompleted = true;
        this.profileCompleted = +this.profileCompleted + +13;
       //this.saveProfileScore();
      }
    } else if (data.length == 0) {
      this.isSkillCompleted = false;
      this.profileCompleted = +this.profileCompleted - +13;
       //this.saveProfileScore();
    }
  }
  checkCertificateStatus(data: any) {
    if(this.isCertificateCompleted == false) {
      if (data.length > 0) {
        this.isCertificateCompleted = true;
        this.profileCompleted = +this.profileCompleted + +13;
       //this.saveProfileScore();
      }
    } else if (data.length == 0) {
      this.isCertificateCompleted = false;
      this.profileCompleted = +this.profileCompleted - +13;
       //this.saveProfileScore();
    }
  }
  checkResumeStatus(data: any) {
    if(this.isResumeCompleted == false) {
      if (data.length > 0) {
        this.isResumeCompleted = true;
        this.profileCompleted = +this.profileCompleted + +13;
       //this.saveProfileScore();
      }
    } else if (data.length == 0) {
      this.isResumeCompleted = false;
      this.profileCompleted = +this.profileCompleted - +13;
       //this.saveProfileScore();
    }
  }
  checkLanguageStatus(data: any) {
    if(this.isLanguageCompleted == false) {
      if (data.length > 0) {
        this.isLanguageCompleted = true;
        this.profileCompleted = +this.profileCompleted + +13;
       //this.saveProfileScore();
      }
    } else if (data.length == 0) {
      this.isLanguageCompleted = false;
      this.profileCompleted = +this.profileCompleted - +13;
       //this.saveProfileScore();
    }
  }
  checkDocumentStatus(data: any) {
    if (data.length > 0) {
      this.isDocumentCompleted = true;
    } else {
      this.isDocumentCompleted = false;
    }
  }

  getAllJobs() {
    this.agencyService.getAllJobs(this.page, this.itemsPerPage).subscribe((res: any) => {
      this.allJobs = res.data.data;
      this.filterData = this.allJobs.filter((item) => item.title == this.currentUser.job_title);
    })
  }

  viewPassportUpload() {
    this.showDocumentList = false;
    this.showDocumentUpload = true;
    this.passportPage = true;
    this.certificatePage = false;
    this.coverLetterPage = false;
    this.otherPage = false;
  }

  viewCertificateUpload() {
    this.showDocumentList = false;
    this.showDocumentUpload = true;
    this.passportPage = false;
    this.certificatePage = true;
    this.coverLetterPage = false;
    this.otherPage = false;
  }

  viewCoverLetterUpload() {
    this.showDocumentList = false;
    this.showDocumentUpload = true;
    this.passportPage = false;
    this.certificatePage = false;
    this.coverLetterPage = true;
    this.otherPage = false;
  }

  viewOtherUpload() {
    this.showDocumentList = false;
    this.showDocumentUpload = true;
    this.passportPage = false;
    this.certificatePage = false;
    this.coverLetterPage = false;
    this.otherPage = true;
  }

  viewDocumentList() {
    this.showDocumentList = true;
    this.showDocumentUpload = false;
    this.getSavedCandidateDocument();
  }

  candidateDocumentList(page: any) {
    this.candidateDocumentService.getCandidateDocument(page, this.itemsPerPage).subscribe((res: any) => {
      window.sessionStorage.setItem("CandidateDocument", JSON.stringify(res.data.data));
      this.candidateDocumentData = res.data.data;
      this.totalDocumentItems = res.data.paging.total;
      this.checkDocumentStatus(res.data.data);
      // if(res.data.data.length > 0){
      //     this.profileCompleted = +this.profileCompleted + +13;
      //     console.log("Document score", this.profileCompleted); 
      // }
      ////this.saveProfileScore();
    })
  }

  getSavedCandidateDocument() {
    this.candidateDocument = this.token.getCandidateDocument();
  }

  onFileUpload(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.candidateDocumentForm.patchValue({ document: file });
    }
  }

  uploadFile(id) {
    const formData = new FormData();
    formData.append('file', this.candidateDocumentForm.get('document')?.value);
    formData.append('document_type', id);
    this.candidateDocumentService.addCandidateDocument(formData).subscribe(res => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Document Added Successfully';
      this.candidateDocumentList(this.page);
      this.showDocumentList = true;
      this.showDocumentUpload = false;
    }, (err) => {
      console.log(err);
      this.error = true;
      if (err.status == 200) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  previewFile(id: any) {
    this.candidateDocumentService.getCandidateDocument(this.page, this.itemsPerPage).subscribe((res: any) => {
      res.data.data.map((item) => {
        if (item.id == id) {
          window.open(`${environment.apiBaseUrl}candidate/documents/${item.document_name}`, "_blank");
        }
      })
    })
  }

  deleteCandidateDocument(id: any) {
    this.candidateDocumentService.deleteCandidateDocument(id).subscribe((res: any) => {
      this.saveSucess = true; 
      this.error = false;
      this.successMessage = 'Document Deleted Successfully';
      this.candidateDocumentList(this.page);
      this.showDocumentList = true;
      this.showDocumentUpload = false;
    },
      (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
  }

  isValidForJobApplication() {
    
    const candidateEdu = this.token.getCandidateEducation();
    const candidateSkill = this.token.getCandidateSkill();
    const candidateResume = this.token.getCandidateResume();
    const candidateLanguage = this.token.getCandidateLanguage();

    const KEY = 'isValidForApplication';

    if (candidateEdu.length == 0 || candidateSkill.length == 0 || candidateResume.length == 0 || candidateLanguage.length == 0) {
      this.isValidForApplication = 'false';
      // window.sessionStorage.setItem('isValidForApplication','false');
      this.cookieService.set(KEY, 'false');
    } else {
      this.isValidForApplication = 'true';
      // window.sessionStorage.setItem('isValidForApplication','true');
      this.cookieService.set(KEY, 'true');
    }
  }

  gotoJobDetail(id:number, title:string) {
    const jobTitle = title.replace(/[\s\/]+/g, '-').toLowerCase();
    this.ngZone.run(() => this.router.navigateByUrl(`/job/${id}/${jobTitle}`));
  }
}