import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from "@angular/forms";
import { Component, OnInit, NgZone } from '@angular/core';
import { BlogService } from 'src/app/service/blog/blog.service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { TokenStorageService } from "src/app/service/login/token-storage.service";
import { Location } from "@angular/common";

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})

export class BlogDetailsComponent implements OnInit {

  getSlug: any;
  blogDetail: any;
  imgPhotoUrl: any;
  allBlogCategory: any;

  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  totalItems: any;
  allBlog: any;
  nextBlog: any;
  previousBlog: any;
  nextBlogDate: string;
  previousBlogDate: string;
  commentForm: FormGroup;
  saveSucess: boolean;
  error: boolean;
  successMessage: string;
  candidateExperienceForm: any;
  errorMessage: any;
  blogComments: any;
  count: any;
  parentCommentId: any;
  currentUser: any;
  getLocalName: string;
  getLocalEmailId: string;
  isLoggedIn: boolean = false;
  getLocalSaved: string;
  getId : number = 0;

  constructor(
    public formBuilder: FormBuilder,
    private blogService: BlogService,
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private tokenStorageService: TokenStorageService,
    private datePipe: DatePipe,
    private location: Location
  ) {
    this.getSlug = this.activatedRoute.snapshot.paramMap.get('slug');

    this.currentUser = this.tokenStorageService.getUser();
    if (Object.keys(this.currentUser).length > 0) {
      this.isLoggedIn = true;
    }

    this.commentForm = this.formBuilder.group({
      parent_id: [''],
      blog_id: [''],
      name: ['', Validators.required],
      email_id: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.required, Validators.minLength(2)]],
      is_saved: [''],
      createdAt: ['']
    });

    this.blogService.getBlogDetail(this.getSlug).subscribe((res: any) => {
      this.blogDetail = res.data;
      this.getId = this.blogDetail.id;
      if (this.blogDetail.blog_image) {
        this.imgPhotoUrl = `${environment.apiBaseUrl}admin/blog/img/${this.blogDetail.blog_image}`;
      } else {
        this.imgPhotoUrl = '../../../../assets/img/default1.png';
      }

      this.commentForm.setValue({
        parent_id: '',
        blog_id: this.getId,
        name: this.isLoggedIn == true ? this.currentUser.first_name + ' ' + this.currentUser.last_name : this.getLocalName != null ? this.getLocalName : '',
        email_id: this.isLoggedIn == true ? this.currentUser.emailid : this.getLocalName != null ? this.getLocalEmailId : '',
        comment: '',
        is_saved: this.getLocalSaved || '',
        createdAt: ''
      });

      this.getAllLatestBlog();
      this.getPreviouseNextBlog();
      this.getAllComment();

    });

    this.getLocalName = localStorage.getItem('name');
    this.getLocalEmailId = localStorage.getItem('emailId');
    this.getLocalSaved = localStorage.getItem('is_saved');

  }

  get f () {
    return this.commentForm.controls;
  }

  ngOnInit(): void {
    this.getAllBlogCategory();
    // this.getAllLatestBlog();
    // this.getPreviouseNextBlog();
    // this.getAllComment();
    this.getCount();
  }

  getAllBlogCategory() {
    this.blogService.getAllBlogCategory().subscribe((res:any) => {
      this.allBlogCategory = res.data.data;
    })
  }

  getAllLatestBlog() {
    this.blogService.getLatestBlog().subscribe((res:any) => {
      this.allBlog = res.data.data.filter((item:any) => { return item.id != this.getId });
      this.allBlog.map((item:any) => {
        item.recentBlogUrl = `${environment.apiBaseUrl}admin/blog/img/${item.blog_image}`;
        item.blogDate = moment(item.updatedAt).format('DD MMMM, YYYY')
      });
    })
  }

  getPreviouseNextBlog() {
    this.blogService.getAllBlogs(this.page, this.itemsPerPage).subscribe((res:any) => {
      const index = res.data.data.findIndex((a:any) => a.id == this.getId);
      this.nextBlog = res.data.data[index + 1];
      this.previousBlog = res.data.data[index - 1];
      this.nextBlogDate = this.nextBlog ? moment(this.nextBlog?.updatedAt).format('DD MMMM, YYYY') : '';
      this.previousBlogDate = this.nextBlog ? moment(this.nextBlog?.updatedAt).format('DD MMMM, YYYY') : '';
    });
  }

  getCount() {
    this.blogService.getCount().subscribe((res:any) => {
      this.count = res.data;
    })
  }

  getReplyToId(id:any){
    this.parentCommentId = id;
  }

  addComment() {
    const value = this.commentForm.get('is_saved').value;
    if (value == 1) { 
      localStorage.setItem('name', this.commentForm.get('name')?.value); 
      localStorage.setItem('emailId', this.commentForm.get('email_id')?.value); 
      localStorage.setItem('is_saved', this.commentForm.get('is_saved')?.value);
    }
    if (value == '' || value == undefined) { this.commentForm.controls['is_saved'].setValue(0); }
    if (this.parentCommentId != '') { this.commentForm.controls['parent_id'].setValue(this.parentCommentId); }
    const currentDateTime = new Date();
    const currentDateTime_ = this.datePipe.transform(currentDateTime, 'dd-MM-yyyy HH:mm:ss');
    this.commentForm.controls['createdAt'].setValue(currentDateTime_);
    this.blogService.addBlogComment(this.commentForm.value).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Comment Added Successfully';
      this.commentForm.reset();
      window.location.reload();
    },(err) => {
      console.log(err);
      this.error = true;
      if (err.status == 200) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  getAllComment() {
    this.blogService.getBlogComments(this.getId).subscribe((res:any) => {
      this.blogComments = res.data;
    })
  }

}
