<!-- Login -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User">
                </div>
            </div>

            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>Login</h2> 
                        <div *ngIf="saveSucess" class="alert alert-success">
                            <strong [class]="FadeOutSuccessMsg()">{{ successMessage }}</strong>
                        </div>
                        <div *ngIf="error" class="alert alert-danger">
                            {{ errorMessage }}
                        </div>
                        <form class="form-inline" *ngIf="!isLoggedIn" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input class="form-control" type="email" formControlName="emailid"
                                            placeholder="Email Id">
                                        <div *ngIf="f.emailid.invalid && (f.emailid.dirty || f.emailid.touched)"
                                            class="alert alert-danger">
                                            <div *ngIf="f.emailid.errors?.required">
                                                Email Id is required.
                                            </div>
                                            <div *ngIf="f.emailid.errors?.email">
                                                Email must be a valid email address
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group login-div">
                                        <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" placeholder="Password">
                                        <button class="show-password-btn" type="button" (click)="showpassword()">
                                            <i class="bx bx-lock" aria-hidden="true" *ngIf="!show"></i>
                                            <i class="bx bx-lock-open" aria-hidden="true" *ngIf="show"></i>
                                        </button>
                                        <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)"
                                            class="alert alert-danger">
                                            <div *ngIf="f.password.errors?.required">
                                                Password is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <button type="submit" class="btn" [disabled]="!loginForm.valid">Login Here</button>

                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Forgot Password? Reset Here!</button>
                            
                            <p class="p-login">Don't have a Account? <span class="span-class" (click)="gotoRegister()">Register Here</span></p>
                        </form>
                    </div>

                    <div class="end">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->

<!-- Code For Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Enter your Registered Email Id</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <input class="form-control" type="email" [(ngModel)]="registerEmailId" placeholder="Email Id">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="forgotPassword()">Reset Password</button>
        </div>
      </div>
    </div>
</div>

