import { Component } from '@angular/core';
import { CrudService } from '../../../service/web_users/web_users.service';
import { environment } from './../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { TokenStorageService } from '../../../service/login/token-storage.service';

@Component({
  selector: 'app-resume-layout3',
  templateUrl: './resume-layout3.component.html',
  styleUrls: ['./resume-layout3.component.scss']
})
export class ResumeLayout3Component {
  
  resumeData: any = [];
  genderList: any = [
    { value: "m", name: "Male" },
    { value: "f", name: "Female" },
  ];
  getId: any;
  redirectURL: any;

  //for images  
  apiBaseUrl = environment.apiBaseUrl;
  imgPhotoUrl: string;
  currentUser: any;

  constructor(
    private crudService: CrudService,
    private activatedRoute: ActivatedRoute,
    private token: TokenStorageService,
  ) {
    this.currentUser = this.token.getUser();
    this.getId = this.activatedRoute.snapshot.paramMap.get('id');
    this.crudService.GetCandidateProfileDetailId(this.getId).subscribe((res: any) => {
      let filteredArr = this.genderList.filter(item => item.value == res.data.gender);
      this.resumeData = res.data;
      this.resumeData.gender=filteredArr[0].name;
      if(this.resumeData.user_type == 1) {
        this.redirectURL = "candidate-dashboard";
      } else {
        this.redirectURL = "employer-dashboard";
      }
      if(!this.resumeData.photo_url){
        this.imgPhotoUrl = "../../../../assets/img/default1.png";
      } else {
        this.imgPhotoUrl = this.resumeData.photo_url;
      }
    });
  }

  ngOnInit(): void {
  }

}
