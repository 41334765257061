// import { WebUsersService } from 'src/app/service/web_users/WebUsers';
import { Component, OnInit, NgZone } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { CrudService } from 'src/app/service/web_users/web_users.service';

@Component({
  selector: 'app-otp-validation',
  templateUrl: './otp-validation.component.html',
  styleUrls: ['./otp-validation.component.scss']
})
export class OtpValidationComponent implements OnInit {
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };
  result: any;
  countDown: Subscription;
  counter = 5;
  tick = 1000;
  counterStatus = true;
  apiData : any;
  getId: string;
  successMessage: string;
  errorMessage: string;
  saveSucess: boolean = false;
  error: boolean = false;
  resendedOTP: boolean = false;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private webUserService: CrudService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.getId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  onOtpChange(otp) {
    this.result = otp;
    const payload = { is_valid: 1 };
    if(this.config.length == this.result.length){
      if(this.result === this.apiData.otp) {
        this.successMessage = "OTP Matched!! Email validation is completed, redirecting to Login."
        this.webUserService.validateEmail(payload, this.getId).subscribe(() => {});
        setTimeout(() => {
          this.ngZone.run(() => this.router.navigateByUrl('/login'))
        },2000);
      } else {
        this.error = true;
        this.errorMessage = "OTP not matched!!"
      }
    }
  }

  ngOnInit() {
    this.getOtpData();
    this.timer();
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

  timer():any {
    this.countDown = timer(0, this.tick).pipe(take(this.counter)).subscribe(() => { --this.counter;
      if (this.counter == 0) {
        this.countDown.unsubscribe();
        this.counterStatus = false;
      }
    });
  }

  resendOTP() {
    this.webUserService.ResendOTP(this.getId).subscribe(() => {
      this.resendedOTP = true;
      this.saveSucess = true;
      this.error = false;
      this.successMessage = "OTP has been Send on Registered Email Address";
      this.getOtpData();
    }, (err) => {
      console.log(err);
      this.error = true;
      if (err.status == 200) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  getOtpData() {
    this.webUserService.GetCandidateProfileDetailId(this.getId).subscribe((res: any) => {
      this.apiData = res.data;
    })
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.saveSucess = false;
    }, 5000);
  }

}
