import { Injectable } from '@angular/core';
import { Jobs } from './Jobs';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class JobsService {
  REST_API: string;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}`;
    } else {
      this.REST_API = `${environment.apiurl}`;
    }
  }

  // Add
  AddJobs(data: Jobs): Observable<any> {
    let API_URL = `${this.REST_API}jobs/add-jobs`;
    return this.httpClient.post(API_URL, data).pipe( catchError(this.handleError) )
  }

  GetJobs(page: any, itemsPerPage: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}jobs/jobs`, { params: queryParams });
  }

  GetJobsDetail(id: any): Observable<any> {
    let API_URL = `${this.REST_API}jobs/jobs-details/${id}`;
    return this.httpClient.get(API_URL)
      .pipe(map((res: any) => {
        return res || {}
      }),
        catchError(this.handleError)
      )
  }

  // Delete
  deleteJobs(id: any): Observable<any> {
    let API_URL = `${this.REST_API}jobs/jobs/${id}`;
    return this.httpClient.delete(API_URL).pipe(
      catchError(this.handleError)
    )
  }

  getEmployerCompaniesDropdown() {
    return this.httpClient.get(`${this.REST_API}select-list/employer-companies`);
  }

  GetEmployerJobsListData(page: any, itemsPerPage: any, id:any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}employer/${id}/jobs`, { params: queryParams });
  }

  GetJobsListData(page: any, itemsPerPage: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}jobs/jobs`, { params: queryParams });
  }

  // Update
  updateJob(id: any, data: any): Observable<any> {
    let API_URL = `${this.REST_API}/jobs/update-jobs/${id}`;
    return this.httpClient.put(API_URL, data)
      .pipe(
        catchError(this.handleError)
      )
  }

  GetCandidateAppliedJobsListData(page: any, itemsPerPage: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}candidate/applied-jobs`, { params: queryParams });
  }

  GetCandidateFavouriteJobsListData(page: any, itemsPerPage: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}candidate/favourite-jobs`, { params: queryParams });
  }

  
  AddCandidateFavouriteJob(id: any) {
    let API_URL = `${this.REST_API}jobs/add-favourite-job/${id}`;
    return this.httpClient.get(API_URL).pipe(catchError(this.handleError))
  }
  
  RemoveCandidateFavouriteJobs(id: any) {
    let API_URL = `${this.REST_API}jobs/delete-favourite-job/${id}`;
    return this.httpClient.delete(API_URL).pipe(catchError(this.handleError))
  }

  ApplyJobs(id: any, data:any): Observable<any> {
    let API_URL = `${this.REST_API}jobs/apply-job/${id}`;
    return this.httpClient.put(API_URL, data).pipe(catchError(this.handleError))
  }

  // Error
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }
}
