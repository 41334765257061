import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  REST_API: string;

  constructor(
    private httpClient: HttpClient
  ) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiAdminUrl}masters/`;
    } else {
      this.REST_API = `${environment.apiAdminUrl}masters/`;
    }
  }

  getLatestBlog() {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("sort", "DESC");
    queryParams = queryParams.set("limit_", "3");
    return this.httpClient.get(`${this.REST_API}get-all-blog`, { params: queryParams });
  }

  getAllBlogs(page: any, itemsPerPage: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}get-all-blog`, { params: queryParams });
  }

  getBlogDetail(slug:any) {
    let queryParams = new HttpParams();
    return this.httpClient.get(`${this.REST_API}get-blog-slug/${slug}`).pipe(map((res: any) => { return res || {} }), catchError(this.handleError) )
  }

  getAllBlogCategory() {
    return this.httpClient.get(`${this.REST_API}get-all-blog-category`);
  }

  addBlogComment(data:any) {
    return this.httpClient.post(`${this.REST_API}add-comment`, data);
  }

  getBlogComments(id:any) {
    let API_URL = `${this.REST_API}get-comments/${id}`
    return this.httpClient.get(API_URL).pipe(map((res: any) => { return res || {} }), catchError(this.handleError) )
  }

  getCount() {
    let API_URL = `${this.REST_API}blog-count`;
    return this.httpClient.get(API_URL).pipe(map((res: any) => { return res || {} }), catchError(this.handleError) )
  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }

}
