import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CrudService } from '../../../service/web_users/web_users.service';

@Component({
  selector: 'app-candidates-details',
  templateUrl: './candidates-details.component.html',
  styleUrls: ['./candidates-details.component.scss']
})
export class CandidatesDetailsComponent implements OnInit {
  getId: any;
  userData: any;
  imgPhotoUrl: string;
  gender: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService
  ) {
    this.getId = this.activatedRoute.snapshot.paramMap.get('id');
    
    this.crudService.GetCandidateProfileDetailId(this.getId).subscribe((res: any) => {
      this.userData = res.data;
      if (!this.userData.photo_url) {
        this.imgPhotoUrl = '../../../../assets/img/default1.png';
      } else {
        this.imgPhotoUrl = this.userData.photo_url;
      }
      this.gender = this.userData.gender == 'm' ? 'Male' : ( this.userData.gender == null ? '' : 'Female');
    })
  }

  ngOnInit(): void {
  }

}
