import { Component, OnInit, NgZone, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TokenStorageService } from '../../../service/login/token-storage.service';
import { EmployerCompaniesService } from 'src/app/service/employer_companies/employer_companies.service';
import { environment } from './../../../../environments/environment'
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {
  domain = "";
  currentUser: any;
  isLoggedIn = false;
  EmployerCompanies: any;
  totalItems: any;
  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private token: TokenStorageService,
    private router: Router,
    private ngZone: NgZone,
    private employerCompaniesService: EmployerCompaniesService,
    private cookieService : CookieService
  ) { }

  ngOnInit(): void {
    this.domain = this.document.location.hostname;
    if (this.token.getToken()) {
      this.isLoggedIn = true;
      this.currentUser = this.token.getUser();
    }
    this.employerCompaniesService.GetEmployerCompanies(this.page, this.itemsPerPage,this.currentUser.id).subscribe((res:any) => {
      this.EmployerCompanies = res.data;
    })
  }

  gotoRegister(): any {
    this.ngZone.run(() => this.router.navigateByUrl('/register'));
  }

  gotoLogin(): any {
    this.ngZone.run(() => this.router.navigateByUrl('/login'));
  }

  checkCompanyStatus() {
    const companyId = this.currentUser.admin_id == null ? this.currentUser.id : this.currentUser.admin_id;
    this.employerCompaniesService.GetEmployerCompanies(this.page, this.itemsPerPage,companyId).subscribe((res:any) => {
      this.EmployerCompanies = res.data;
      if(this.EmployerCompanies[0].status == 0) {
        alert("Your Company is In-Active. Activate your Company from Dashboard -> Company Details");
      } else {
        this.ngZone.run(() => this.router.navigateByUrl('/post-a-job'));
      }
    })
  }

  gotoMyJobs() {
    this.token.setTest(true);
  }

  changeLanguageToID() {
    this.cookieService.delete('googtrans', '/', '.abroadwork.id' );
    const value = '/en/id';
    document.cookie = 'googtrans' + "=" + value;
  }

  changeLanguageToMalay() {
    this.cookieService.delete('googtrans', '/', '.abroadwork.id' );
    const value = '/en/ms';
    document.cookie = 'googtrans' + "=" + value;
  }

  changeLanguageToEnglish() {
    this.cookieService.delete('googtrans', '/', '.abroadwork.id' );
    const value = '/en/en';
    document.cookie = 'googtrans' + "=" + value;
  }

}
