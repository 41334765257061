import { JobCategory } from './../../../service/job-category/JobCategory';
import { JobExperienceService } from './../../../service/job-experience/job-experience.service';
import { JobQualificationService } from './../../../service/job-qualification/job-qualification.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { AgencyService } from '../../../service/agency/agency.service';
import { CountryService } from '../../../service/country/country.service';
import { StateService } from '../../../service/state/state.service';
import { CityService } from 'src/app/service/city/city.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { JobTypeService } from 'src/app/service/job-type/job-type.service';
import { JobCategoryService } from 'src/app/service/job-category/job-category.service';
import { environment } from './../../../../environments/environment';
import { TokenStorageService } from 'src/app/service/login/token-storage.service';
import { Router, ActivatedRoute, Event, NavigationStart } from '@angular/router';
import * as moment from 'moment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-all-jobs',
  templateUrl: './all-jobs.component.html',
  styleUrls: ['./all-jobs.component.scss']
})
export class AllJobsComponent implements OnInit {

  term: any;
  apiData: any;
  searchCount: boolean;
  paging: any;
  isCountryLoaded: boolean = false;
  countryList: any;
  stateList: any;
  searchForm: FormGroup;
  educationList: any;
  jobTypeList: any;
  isJobTypeLoaded: boolean = false;
  isJobCategoryLoaded: boolean = false;
  jobCategoryList: any;
  isJobQualificationLoaded: boolean = false;
  jobQualificationList: any;
  isJobExperienceLoaded: boolean;
  jobExperienceList: any;
  showBoxes: boolean = true;
  showList: boolean = false;

  //pagination
  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  totalItems: any;

  currentUser: any;
  profileCompleted: string;
  isLoggedIn: boolean = false;
  isSearchValueExists: boolean = false;
  isDashboardSearch: boolean = false;
  cityList: any;
  countryData: any;
  sortForm: FormGroup;
  queryData: any;
  searchQuery: any;

  isMobileView: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private agencyService: AgencyService,
    private countryService: CountryService,
    private stateService: StateService,
    private jobTypeService: JobTypeService,
    private jobCategoryService: JobCategoryService,
    private jobQualificationService: JobQualificationService,
    private jobExperienceService: JobExperienceService,
    private token: TokenStorageService,
    private cityService: CityService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private location: Location
  ) {

    this.profileCompleted = this.token.getProfileScore();

    this.searchForm = this.formBuilder.group({
      title: [''],
      job_type: [''],
      job_category: [''],
      country: [''],
      state: [''],
      city: [''],
      qualification: [''],
      experience: [''],
      salary: [''],
      currencyId: ['']
    });

    this.sortForm = this.formBuilder.group({ sort: [''] });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.activatedRoute.queryParams.subscribe(params => {
      this.queryData = params;
    });

    this.isDashboardSearch = JSON.parse(window.localStorage.getItem("dashboardSearch"));
    this.searchQuery = JSON.parse(window.localStorage.getItem("dashboard-search"));

    if (this.isDashboardSearch == true) {

      if (this.searchQuery?.country) {
        this.getState(this.searchQuery?.country);
        if (this.searchQuery?.state) {
          this.getCity(this.searchQuery?.state);
        }
      }

      this.searchForm.patchValue({
        title: this.searchQuery.title || '',
        job_type: [''],
        job_category: this.searchQuery?.job_category || '',
        country: this.searchQuery?.country || '',
        state: this.searchQuery?.state || '',
        city: this.searchQuery?.city || '',
        qualification: [''],
        experience: [''],
        salary: [''],
        currencyId: ['']
      })

      this.agencyService.getAllJobsFilter(this.page, this.itemsPerPage, this.searchForm.value, this.sortForm.value).subscribe((res: any) => {
        this.apiData = res.data.data;
        this.paging = res.data.paging;
        this.totalItems = res.data.paging.total;
        this.apiData.length > 0 ? this.isSearchValueExists = true : this.isSearchValueExists = false;
        this.apiData.map((item) => {
          if (item.photo) {
            item.imgPhotoUrl = `${environment.apiBaseUrl}candidate/profile/${item.photo}`;
          } else {
            item.imgPhotoUrl = '../../../../assets/img/default1.png';
          }
          item.jobTitle = item.title.replace(/[\s\/]+/g, '-').toLowerCase();
        });
      })

    } else if (this.isDashboardSearch == null || this.isDashboardSearch == false) {
      this.getAllJobs(this.page);
    }

  }

  ngOnInit(): void {
    if (this.token.getToken()) {
      this.isLoggedIn = true;
      this.currentUser = this.token.getUser();
    }
    if (window.screen.width <= 768) {
      this.isMobileView = true;
    }
    
    this.countryDropdown();
    this.qualificationDropdown();
    this.jobTypeDropdown();
    this.jobCategoryDropdown();
    this.jobExperienceDropdown();
  }

  getAllJobs(page: any) {
    this.agencyService.getAllJobsFilter(page, this.itemsPerPage, this.searchForm.value, this.sortForm.value).subscribe((res: any) => {
      this.apiData = res.data.data;
      this.paging = res.data.paging;
      this.totalItems = res.data.paging.total;
      this.apiData.length > 0 ? this.isSearchValueExists = true : this.isSearchValueExists = false;
      this.apiData.map((item) => {
        if (item.web_user.photo) {
          item.imgPhotoUrl = `${environment.apiBaseUrl}candidate/${item.photo}`;
        } else {
          item.imgPhotoUrl = '../../../../assets/img/default1.png';
        }
        item.jobTitle = item.title.replace(/[\s\/]+/g, '-').toLowerCase();
      });
    })
  }

  countryDropdown() {
    this.countryService.getCountryDropdown().subscribe((res: any) => {
      this.isCountryLoaded = true;
      this.countryList = res.data;
    });
  }

  getState(countryId: string) {
    this.stateService.getStateListDropdown(countryId).subscribe((res: any) => {
      this.stateList = res.data;
    });
    if (countryId) {
      this.countryService.getCountryDropdown().subscribe((res: any) => {
        this.countryData = res.data.filter((item: any) => { return item.id == countryId });
        this.searchForm.patchValue({ currencyId: this.countryData[0].id })
      });
    } else {
      this.searchForm.patchValue({ currencyId: '' })
    }
  }

  getCity(stateId: string) {
    this.cityService.getCityListDropdown(stateId).subscribe((res: any) => {
      this.cityList = res.data;
    });
  }

  // setCountry(countryId: any) {
  //   this.countryData = this.countryList.filter((item: any) => { return item.id == countryId });
  //   this.searchForm.patchValue({ country: this.countryData[0].id })
  // }

  qualificationDropdown() {
    this.jobQualificationService.getJobQualificationDropdown().subscribe((res: any) => {
      this.isJobQualificationLoaded = true;
      this.jobQualificationList = res.data;
    });
  }

  jobTypeDropdown() {
    this.jobTypeService.getJobTypeDropdown().subscribe((res: any) => {
      this.isJobTypeLoaded = true;
      this.jobTypeList = res.data;
    });
  }

  jobCategoryDropdown() {
    this.jobCategoryService.getJobCategoryDropdown().subscribe((res: any) => {
      this.isJobCategoryLoaded = true;
      this.jobCategoryList = res.data;
    });
  }

  jobExperienceDropdown() {
    this.jobExperienceService.getJobExperienceDropdown().subscribe((res: any) => {
      this.isJobExperienceLoaded = true;
      this.jobExperienceList = res.data;
    })
  }

  searchFilter(): any {
    this.agencyService.getAllJobsFilter(this.page, this.itemsPerPage, this.searchForm.value, this.sortForm.value).subscribe((res: any) => {
      this.apiData = res.data.data;
      this.apiData.length > 0 ? this.isSearchValueExists = true : this.isSearchValueExists = false;
      this.paging = res.data.paging;
      this.totalItems = res.data.paging.total;
      this.apiData.map((item) => {
        if (item.photo) {
          item.imgPhotoUrl = `${environment.apiBaseUrl}candidate/profile/${item.photo}`;
        } else {
          item.imgPhotoUrl = '../../../../assets/img/default1.png';
        }
        item.jobTitle = item.title.replace(/[\s\/]+/g, '-').toLowerCase();
      });
    });
  }

  resetFilter(): any {
    this.searchForm.reset();
    window.sessionStorage.removeItem("dashboard-search");
    window.sessionStorage.removeItem("dashboardSearch");
    this.router.navigate([], { queryParams: { 'job_category': null }, queryParamsHandling: 'merge' });
    this.getAllJobs(this.page);
    this.searchForm.get('job_type').setValue('');
    this.searchForm.get('job_category').setValue('');
    this.searchForm.get('country').setValue('');
    this.searchForm.get('state').setValue('');
    this.searchForm.get('city').setValue('');
    this.searchForm.get('qualification').setValue('');
    this.searchForm.get('experience').setValue('');
    this.searchForm.get('salary').setValue('');
    this.searchForm.get('currencyId').setValue('');
  }

  showListFunction() {
    this.showBoxes = false;
    this.showList = true;
  }

  showBoxesFunction() {
    this.showList = false;
    this.showBoxes = true;
  }

}
