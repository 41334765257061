<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Candidates</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a href="index.html">Home</a>
                        </li>
                        <li>
                            <span>/</span>
                        </li>
                        <li>
                            Candidates
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Filter -->
<div class="job-filter-area pt-100">
    <div class="container" *ngIf="isMobileView == false">
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="title" placeholder="e.g UI/UX Design">
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="age">
                            <option value="">Filter By Age</option>
                            <option *ngFor="let item of ageDropdown" [value]="item.id">{{item.value}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isJobCategoryLoaded" formControlName="candidate_category">
                            <option value="">Filter By Categories</option>
                            <option *ngFor="let item of jobCategoryList" [value]="item.id">{{item.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="country"
                            (change)="getState($event.target.value)">
                            <option value="" disabled selected>Filter By Country</option>
                            <option *ngFor="let countryRows of countryList" [value]="countryRows.id">{{ countryRows.name
                                }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="state"
                            (change)="getCity($event.target.value)">
                            <option value="" disabled selected>Filter By State</option>
                            <option *ngFor="let item of stateList" [value]="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" formControlName="city">
                            <option value="" disabled selected>Filter By City</option>
                            <option *ngFor="let item of cityList" [value]="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="education">
                            <option value="">Filter By Education</option>
                            <option *ngFor="let items of educationList" [value]="items.id.toString()">{{items.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="experience">
                            <option value="">Filter By Experiences</option>
                            <option *ngFor="let item of expDropdown" [ngValue]="item.id">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-8 col-lg-2 col-md-2">
                    <div class="row" id="btn_contain">
                        <div class="col-6">
                            <button type="submit" class="filter_btn" (click)="searchFilter()">Search</button>
                        </div>
                        <div class="col-6">
                            <button type="clear" class="filter_btn" (click)="resetFilter()">Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End Filter -->

<!-- Mobile Filters -->
<div class="collapse" id="collapseExample">
    <div class="job-filter-area">
        <div class="container">
            <form [formGroup]="searchForm">
                <div class="row">
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="title" placeholder="e.g UI/UX Design">
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="age">
                                <option value="">Filter By Age</option>
                                <option *ngFor="let item of ageDropdown" [ngValue]="item.id.toString()">{{item.value}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isJobCategoryLoaded" formControlName="candidate_category">
                                <option value="">Filter By Categories</option>
                                <option *ngFor="let item of jobCategoryList" [ngValue]="item.id.toString()">{{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="country"
                                (change)="getState($event.target.value)">
                                <option value="" disabled selected>Filter By Country</option>
                                <option *ngFor="let countryRows of countryList" [value]="countryRows.id">{{ countryRows.name
                                    }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="state"
                                (change)="getCity($event.target.value)">
                                <option value="" disabled selected>Filter By State</option>
                                <option *ngFor="let item of stateList" [value]="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" formControlName="city">
                                <option value="" disabled selected>Filter By City</option>
                                <option *ngFor="let item of cityList" [value]="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="education">
                                <option value="">Filter By Education</option>
                                <option *ngFor="let items of educationList" [value]="items.id.toString()">{{items.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="experience">
                                <option value="">Filter By Experiences</option>
                                <option *ngFor="let item of expDropdown" [ngValue]="item.id">{{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-8 col-lg-2 col-md-2">
                        <div class="row" id="btn_contain">
                            <div class="col-6">
                                <button type="submit" class="filter_btn" (click)="searchFilter()">Search</button>
                            </div>
                            <div class="col-6">
                                <button type="clear" class="filter_btn" (click)="resetFilter()">Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Mobile Filters -->

<!-- Showing -->
<div class="job-showing-area">
    <div class="container">
        <h4>Showing {{pagings?.page_length}} of {{pagings?.total}} results</h4>
        <div class="showing">
            <div class="row">
                <div style="width:30%">
                    <div class="left">
                        <form [formGroup]="sortForm">
                            <div class="form-group">
                                <select class="form-control" formControlName="sort" (change)="getAllCandidatesList(1)"
                                    style="width:auto">
                                    <option selected value="">Latest</option>
                                    <option value="ASC">Oldest</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div style="width:70%">
                    <div class="right">
                        <ul>
                            <li>
                                <a (click)="showBoxesFunction()"><i class='bx bx-dots-horizontal'></i></a>
                            </li>
                            <li>
                                <a (click)="showListFunction()"><i class='bx bx-menu'></i></a>
                            </li>
                            <li *ngIf="isMobileView == true">
                                <a data-bs-toggle="collapse" data-bs-target="#collapseExample"><i class='bx bx-filter-alt'></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Showing -->

<!-- Candidate -->
<div class="candidate-area pb-100">
    <div class="container">
        <div class="row" *ngIf="showBoxes">
            <div class="col-lg-6 col-md-12"
                *ngFor="let data of apiData | paginate : { itemsPerPage: 10, currentPage: page, totalItems: totalCandidates }; let i = index">
                <div class="candidate-item two">
                    <div class="left">
                        <a routerLink="/candidate/{{data.id}}/{{data.candidateTitle}}"
                            style="text-decoration: none;">{{data.first_name}} {{data.last_name}}</a>
                        <ul class="experience">
                            <li><img src="assets/icons/experience.png" alt="experience-icon">Experience:
                                <span>{{data.total_experience}} Years</span></li>
                        </ul>
                        <div class="cmn-link">
                            <a (click)="onClick(data.id,data.first_name,data.last_name)"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                        </div>
                    </div>
                    <img class="applied-img" src="{{data.imgPhotoUrl}}" onerror="this.src='../../../../assets/img/default1.png'" alt="Candidate">
                </div>
            </div>
            <div class="col-lg-12 col-md-12" *ngIf="isSearchValueExists == false">
                <div class="employer-item d-flex justify-content-center">
                    <h3>No Candidate Found!!</h3>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="showList">
            <!-- <div class="company-list-item" *ngFor="let data of apiData | paginate : { itemsPerPage: 10, currentPage: page, totalItems: totalCandidates }; let i = index">
                <a routerLink="/candidate-details/{{ data.id }}">
                    <img src="{{data.imgPhotoUrl}}" alt="Candidate">
                    <h3>{{data.first_name}} {{data.last_name}} test plz ingone jnd knkd nknd kjkd kn dk</h3>
                    <ul>
                        <li><img src="assets/icons/experience.png" alt="experience-icon" style="position: absolute !important; top: 60px !important; height: 18px !important; width: 18px !important; left: 135px !important;">Experience: <span>{{data.total_experience}} Years</span></li>
                    </ul>
                   
                </a>
                <div style="display: flex;">
                    <img src="assets/icons/experience.png" alt="experience-icon" style="height: 20px;width: 20px">Experience: <span>{{data.total_experience}} Years</span>
                </div>
                <span class="resume-link">
                    <a (click)="onClick(data.id)"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                </span>
            </div> -->
            <div class="company-list"
                *ngFor="let data of apiData | paginate : { itemsPerPage: 10, currentPage: page, totalItems: totalCandidates }; let i = index">

                <div>
                    <img src="{{data.imgPhotoUrl}}" onerror="this.src='../../../../assets/img/default1.png'" alt="Candidate">
                </div>

                <div class="company-list-data">
                    <a routerLink="/candidate/{{data.id}}/{{data.candidateTitle}}">
                        <h3>{{data.first_name}} {{data.last_name}}</h3>
                    </a>
                    <div class="exp-icon">
                        <img class="experience-icon" src="assets/icons/experience.png" alt="experience-icon" />
                        <p>Experience: <span>{{data.total_experience}} Years</span></p>
                    </div>
                    <span class="resume-link">
                        <a (click)="onClick(data.id, data.first_name, data.last_name)"><i class="flaticon-right-arrow one"></i> View Resume <i
                                class="flaticon-right-arrow two"></i></a>
                    </span>
                </div>
                <div class="col-lg-12 col-md-12" *ngIf="isSearchValueExists == false">
                    <div class="employer-item d-flex justify-content-center">
                        <h3>No Candidate Found!!</h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="pagination-area">
            <pagination-controls class="pagi" (pageChange)="getAllCandidatesList(page = $event)" (directionLinks)="true"
                (responsive)="true"></pagination-controls>
        </div>

    </div>
</div>
<!-- End Candidate -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->