import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  REST_API: string;

  constructor(
    private httpClient: HttpClient
  ) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}`;
    } else {
      this.REST_API = `${environment.apiurl}`;
    }
  }

  sendMail(data:any) {
    let API_URL = `${this.REST_API}from-website-contact-us`;
    return this.httpClient.post(API_URL, data).pipe(map((res: any) => { return res || {} }), catchError(this.handleError) )
  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }

}
