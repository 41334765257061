import { Injectable } from '@angular/core';
import { EmployerCompanies } from './EmployerCompanies';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EmployerCompaniesService {
  REST_API: string;
  currentUser: any;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}employer`;
    } else {
      this.REST_API = `${environment.apiurl}employer`;
    }
  }
 
  // Add
  AddEmployerCompanies(data: EmployerCompanies): Observable<any> {
    let API_URL = `${this.REST_API}/companies`;
    return this.httpClient.post(API_URL, data).pipe(catchError(this.handleError))
  }

  // Update
  updateEmployerCompanies(id: any, data: any): Observable<any> {
    let API_URL = `${this.REST_API}/companies/${id}`;
    return this.httpClient.put(API_URL, data).pipe(catchError(this.handleError))
  }

  GetEmployerCompanies(page: any, itemsPerPage: any, id: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}/${id}/companies`, { params: queryParams });
  }

  GetEmployerCompaniesList(page: any, itemsPerPage: any, sort:any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    queryParams = queryParams.set("isActive", 1);
    if(sort.sort === "id:asc") { queryParams = queryParams.set("sort", sort.sort); }
    return this.httpClient.get(`${this.REST_API}/companies`, { params: queryParams });
  }

  GetEmployerCompaniesFilter(page: any, itemsPerPage: any, filters:any, sort:any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    queryParams = queryParams.set('term', filters.title ? filters.title : "");
    queryParams = queryParams.set('sector', filters.sector ? filters.sector : "");
    queryParams = queryParams.set("country", filters.country ? filters.country : "");
    queryParams = queryParams.set('state', filters.state ? filters.state : "");
    queryParams = queryParams.set('city', filters.city ? filters.city : "");
    queryParams = queryParams.set('status', 1);
    if(sort.sort === "id:asc") { queryParams = queryParams.set("sort", sort.sort); }
    return this.httpClient.get(`${this.REST_API}/companies`, { params: queryParams });
  }

  GetCompanyDetails(id: any) {
    let queryParams = new HttpParams();
    return this.httpClient.get(`${this.REST_API}/companies/${id}`, { params: queryParams });
  }

  GetEmployerCompaniesDetail(id:any): Observable<any> {
    let API_URL = `${this.REST_API}/${id}/companies`;
    return this.httpClient.get(API_URL).pipe(map((res: any) => { return res || {} }),catchError(this.handleError))
  }

  // Delete
  deleteEmployerCompanies(id: any): Observable<any> {
    let API_URL = `${this.REST_API}/companies/${id}`;
    return this.httpClient.delete(API_URL).pipe(catchError(this.handleError))
  }

  // Error
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }

  searchCandidates(term:any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("term", term);
    return this.httpClient.get(`${this.REST_API}/employer-search`, {params: queryParams });
  }

  GetCandidateListofAppliedJob(id: any, page: any, itemsPerPage: any, filters: any, sort:any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    queryParams = queryParams.set('term', filters.term ? filters.term : "");
    queryParams = queryParams.set("qualification", filters.qualification ? filters.qualification : ""),
    queryParams = queryParams.set("country", filters.country ? filters.country : "");
    queryParams = queryParams.set('state', filters.state ? filters.state : "");
    queryParams = queryParams.set('city', filters.city ? filters.city : "");
    queryParams = queryParams.set("experience", filters.experience ? filters.experience : "");
    if(sort.sort === "id:asc") { queryParams = queryParams.set("sort", sort.sort); }
    return this.httpClient.get(`${this.REST_API}/applied-candidates/${id}`, {params: queryParams });
  }

  GetCandidateSelectedState(): Observable<any> {
    return this.httpClient.get(`${environment.apiurl}select-list/state`);
  }

  updateCandidateState(webUserId:any, jobId:any, data:any): Observable<any> {
    let API_URL = `${this.REST_API}/update-candidate-state/${jobId}/${webUserId}`;
    return this.httpClient.put(API_URL, data).pipe(catchError(this.handleError))
  }
}
