import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AgencyService {
  REST_API: string;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}agency`;
    } else {
      this.REST_API = `${environment.apiurl}agency`;
    }
  }

  getAllCandidates(page, itemPerPage, sort:any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemPerPage);
    if(sort.sort === "id:asc") { queryParams = queryParams.set("sort", sort.sort); }
    return this.httpClient.get(`${this.REST_API}/all-candidates`, {params: queryParams});
  }

  getAllCandidatesFilters(page, itemPerPage, filters: any, sort:any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemPerPage);
    queryParams = queryParams.set('term', filters.title ? filters.title : "");
    queryParams = queryParams.set("age", filters.age  ? filters.age : "");
    queryParams = queryParams.set("country", filters.country ? filters.country : "");
    queryParams = queryParams.set('state', filters.state ? filters.state : "");
    queryParams = queryParams.set('city', filters.city ? filters.city : "");
    queryParams = queryParams.set('candidate_category', filters.candidate_category ? filters.candidate_category : "");
    queryParams = queryParams.set("education", filters.education ? filters.education : "");
    queryParams = queryParams.set("experience", filters.experience ? filters.experience : "");
    if(sort.sort === "ASC") { queryParams = queryParams.set("sort", sort.sort); }
    return this.httpClient.get(`${this.REST_API}/all-candidate-filter`, {params: queryParams});
  }
  
  getAllJobs(page: any, itemsPerPage: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemsPerPage);
    return this.httpClient.get(`${this.REST_API}/all-jobs`, { params: queryParams });
  }

  getAllJobsFilter(page, itemPerPage, filters: any, sort:any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("page", page);
    queryParams = queryParams.set("limit", itemPerPage);
    queryParams = queryParams.set('term', filters.title ? filters.title : "");
    queryParams = queryParams.set("job_type", filters.job_type  ? filters.job_type : "");
    queryParams = queryParams.set("job_category", filters.job_category ? filters.job_category : "");
    queryParams = queryParams.set("qualification", filters.qualification ? filters.qualification : ""),
    queryParams = queryParams.set("country", filters.country ? filters.country : "");
    queryParams = queryParams.set('state', filters.state ? filters.state : "");
    queryParams = queryParams.set('city', filters.city ? filters.city : "");
    // queryParams = queryParams.set("education", filters.education);
    queryParams = queryParams.set("experience", filters.experience ? filters.experience : "");
    queryParams = queryParams.set("salary", filters.salary ? filters.salary : "");
    queryParams = queryParams.set("currencyId", filters.currencyId ? filters.currencyId : "");
    if(sort.sort === "id:asc") { queryParams = queryParams.set("sort", sort.sort); }
    return this.httpClient.get(`${this.REST_API}/all-jobs`, {params: queryParams});
  }

}
