import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {
    myJobs: boolean;
    constructor(private cookieService : CookieService) { }

    public setTest(value: boolean) {
        this.myJobs = value;
    }

    public getTest() {
        return this.myJobs;
    }

    signOut(): void {
        window.sessionStorage.clear();
        this.cookieService.delete('auth-token', '/');
        this.cookieService.delete('auth-user', '/');
    }

    public saveToken(token: string): void {
        // window.sessionStorage.removeItem(TOKEN_KEY);
        this.cookieService.delete(TOKEN_KEY, '/');

        // window.sessionStorage.setItem(TOKEN_KEY, token);
        this.cookieService.set(TOKEN_KEY, token);
    }

    public getToken(): string | null {
        // return window.sessionStorage.getItem(TOKEN_KEY);
        return this.cookieService.get(TOKEN_KEY);
    }

    public saveUser(user: any): void {
        // window.sessionStorage.removeItem(USER_KEY);
        // window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
        this.cookieService.set(USER_KEY, JSON.stringify(user));
    }

    public getUser(): any {
        // const user = window.sessionStorage.getItem(USER_KEY);
        const user = this.cookieService.get(USER_KEY) ? JSON.parse(this.cookieService.get(USER_KEY)) : null;
        if (user) { return user; }
        return {};
    }

    public getProfileScore(): string | null {
        return window.sessionStorage.getItem("profileScore");
    }

    public getCandidateEducation(): any {
        return JSON.parse(window.sessionStorage.getItem("CandidateEducation"));
    }

    public getCandidateExperience(): any {
        return JSON.parse(window.sessionStorage.getItem("CandidateExperience"));
    }

    public getCandidateSkill(): any {
        return JSON.parse(window.sessionStorage.getItem("CandidateSkill"));
    }
    
    public getCandidateCertificate(): any {
        return JSON.parse(window.sessionStorage.getItem("CandidateCertificate"));
    }

    public getCandidateResume(): any {
        return JSON.parse(window.sessionStorage.getItem("CandidateResume"));
    }

    public getCandidateLanguage():any {
        return JSON.parse(window.sessionStorage.getItem("CandidateLanguage"))
    }

    public getCandidateDocument(): any {
        return JSON.parse(window.sessionStorage.getItem("CandidateDocument"));
    }

    public getIsValidForJobApplication(): any {
        return window.sessionStorage.getItem("isValidForApplication");
    }
}