import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CrudService } from '../../../service/web_users/web_users.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from "@angular/forms";
import { CountryService } from '../../../service/country/country.service';
import { StateService } from '../../../service/state/state.service';
import { CityService } from '../../../service/city/city.service';
import * as moment from 'moment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  userTypeList: any = [
    { value: "1", name: "Employer" },
    { value: "2", name: "Candidate" },
  ];
  saveSucess: boolean = false;
  error: boolean = false;
  errorMessage: string = "";
  successMessage: string = "";
  webUsersForm: FormGroup;
  userType: Number = 1;
  isCountryLoaded: boolean;
  countryList: any;
  stateList: any;
  cityList: any;
  webUserData: any;
  calAge: number;
  jobSectorDropdown: any;
  isJobSectorDropdownLoaded: boolean = false;
  confirmPassword: String;

  show: boolean = false;
  confirm_show: boolean = false;

  checkboxFlag: boolean = false;
  isChecked: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private crudService: CrudService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService
  ) {
    this.webUsersForm = this.formBuilder.group({
      first_name: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]+$')]],
      last_name: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]+$')]],
      username: ['',Validators.required],
      emailid: ['', [Validators.required, Validators.email]],
      dial_code: ['', Validators.required],
      mobileno: ['', Validators.required],
      password: ['', 
        [Validators.required,
        Validators.required, 
        Validators.minLength(8),
        Validators.maxLength(50),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
        Validators.pattern(/[!@#$]/),
      ]],
      confirm_password:['', 
        [Validators.required,
        Validators.required, 
        Validators.minLength(8),
        Validators.maxLength(50),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
        Validators.pattern(/[!@#$]/),
      ]],
      user_type: ['', Validators.required],
      organization_name: ['', Validators.required],
      job_sector_id: [''],
      status: ['1'],
    });

    this.countryService.getCountryDropdown().subscribe((res: any) => {
      this.isCountryLoaded = true;
      this.countryList = res.data;
    });
  }

  get f() {
    return this.webUsersForm.controls;
  }

  ngOnInit(): void {
    this.countryDropdown();
    this.getJobSector();

    this.webUsersForm.get('mobileno').valueChanges.subscribe(value => {
      const dialCode = this.webUsersForm.get('dial_code').value;
      const numberLength = this.countryList.filter((item:any) => { return item.id == dialCode });
      const numberLength_ = JSON.parse(numberLength[0].number_length);
      const validPattern = /^[0-9]*$/.test(value);
      if(!validPattern){
        this.webUsersForm.get('mobileno').setErrors({ 'invalidMobileNumber': false, pattern: true });
      } else if (!numberLength_.includes(value.length)) {
        this.webUsersForm.get('mobileno').setErrors({ 'invalidMobileNumber':true, pattern: false })
      } else {
        this.webUsersForm.get('mobileno').setErrors(null);
      }
    });
  }

  onSubmit(): any {
    const password = this.webUsersForm.get('password')?.value;
    const confirmPassword = this.webUsersForm.get('confirm_password')?.value;
    const userType = this.webUsersForm.get('user_type')?.value;

    if (password === confirmPassword) {
      delete this.webUsersForm.value.confirm_password;
      this.crudService.AddWebUsers(this.webUsersForm.value).subscribe((res:any) => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = "OTP send successfully on registered Email Id.";
        this.webUsersForm.reset();
        setTimeout(() => {
          this.ngZone.run(() => this.router.navigateByUrl('/otp-validation/' + res.data.id))
        },2000);
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
    } else {
      this.error = true;
      this.errorMessage = "Password not matched!";
    }
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.saveSucess = false;
    }, 5000);
  }

  countryDropdown() {
    this.countryService.getCountryDropdown().subscribe((res: any) => {
      this.isCountryLoaded = true;
      this.countryList = res.data;
    });
  }

  getState(countryId: string) {
    const toArray = countryId.split(": ");
    const country_id = toArray[1];
    this.stateService.getStateListDropdown(country_id).subscribe((res: any) => {
      this.stateList = res.data;
    });
  }

  getCity(stateId: string) {
    const toArray = stateId.split(": ");
    const state_id = toArray[1];
    this.cityService.getCityListDropdown(state_id).subscribe((res: any) => {
      this.cityList = res.data;
    });
  }

  gotoLogin() {
    this.ngZone.run(() => this.router.navigateByUrl('/login'))
  }

  getToday() {
    return new Date().toISOString().split('T')[0]
  }

  getJobSector() {
    this.crudService.getJobSector().subscribe((res:any) => {
      this.isJobSectorDropdownLoaded = true;
      this.jobSectorDropdown = res.data;
    })
  }

  showpassword() {
    this.show = !this.show;
  }

  showConfirmPassword() {
    this.confirm_show = !this.confirm_show
  }

  tickCheckbox() {
    this.checkboxFlag = !this.checkboxFlag;
  }
}
