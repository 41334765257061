import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CrudService } from '../../../service/web_users/web_users.service';
import { EmployerCompaniesService } from '../../../service/employer_companies/employer_companies.service';
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { TokenStorageService } from '../../../service/login/token-storage.service';
import { environment } from './../../../../environments/environment';
import { JobsService } from '../../../service/jobs/jobs.service';
import { EmployerUsersService } from '../../../service/employer-user/employer-users.service';
import { CountryService } from 'src/app/service/country/country.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-dashboard',
  templateUrl: './employer-dashboard.component.html',
  styleUrls: ['./employer-dashboard.component.scss']
})
export class EmployerDashboardComponent implements OnInit {
  getId: any;
  EmployerCompanies: any = [];
  userData: any;
  saveSucess: boolean = false;
  error: boolean = false;
  errorMessage: string = "";
  successMessage: string = "";
  employerProfileForm: FormGroup;
  employerChangePasswordForm: FormGroup;
  employerCompaniesForm: FormGroup;
  employerCompaniesEditForm: FormGroup;
  showEmployerCompaniesForm: boolean = false;
  showEditEmployerCompaniesForm: boolean = false;
  showEmployerCompaniesList: boolean = true;
  showJobList: boolean = true;
  statusList: any = [
    { value: "1", name: "Active" },
    { value: "0", name: "In-Active" },
  ];
  showCandidiateList: boolean = false;
  imgPhotoUrl: String;

  //pagination
  page: number = 1;
  itemsPerPage = environment.pagination.itemsPerPage;
  totalItems: any;
  totalJobItems: any;
  totalDraftJobs: Number = 0;
  totalPublishedJobs: Number = 0;
  EmployerJobs: any = [];

  show: boolean = false;
  confirm_show: boolean = false;
  old_show: boolean = false;

  showUsersList: boolean = true;
  showUsersAdd: boolean = false;
  showUsersEdit: boolean = false;
  addUserForm: FormGroup;
  editUserForm: FormGroup;
  userId: any;

  currentUser: any;
  employerId: any;

  newUserData: any;
  permissionForm: FormGroup;
  isCompanyChecked: boolean = false;
  isCompany: boolean = false;
  isJobChecked: boolean = false;
  isJob: boolean = false;
  adminId: any;
  webUserId: any;
  userPermission: any;
  isPermission: any;
  isCountryLoaded: boolean = false;
  dialCodeList: any;
  isMyJobs: boolean;
  showProfile: boolean = true;
  originalFilename: any;
  imageChangedEvent: Event | null = null;
  croppedImage: any = '';
  showEditImage: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private token: TokenStorageService,
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private employerCompaniesService: EmployerCompaniesService,
    private jobsService: JobsService,
    private userService: EmployerUsersService,
    private countryService: CountryService
  ) {

    this.isMyJobs = token.getTest();

    this.crudService.GetEmployerProfileDetail().subscribe((res: any) => {
      this.employerProfileForm.patchValue({
        photo: res.data.photo || "",
        photoSource: null,
        first_name: res.data.first_name || "",
        last_name: res.data.last_name || "",
        emailid: res.data.emailid || "",
        dial_code: res.data.dial_code || "",
        mobileno: res.data.mobileno.toString() || "",
        organization_name: res.data.organization_name || ""
      });

      this.userData = res.data;

      if (!this.userData.photo_url) {
        this.imgPhotoUrl = "../../../../assets/img/default1.png";
      } else {
        this.imgPhotoUrl = `${environment.apiBaseUrl}employer/${this.userData.photo}`;
      }
    });

    this.employerProfileForm = this.formBuilder.group({
      photo: [''],
      photoSource: [''],
      first_name: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]+$')]],
      last_name: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]+$')]],
      emailid: ['', [Validators.required, Validators.email]],
      dial_code: ['', Validators.required],
      mobileno: ['', Validators.required],
      organization_name: ['']
    });

    this.employerChangePasswordForm = this.formBuilder.group({
      old_password: ['',
        [Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
        Validators.pattern(/[!@#$]/),
        ]
      ],
      password: ['',
        [Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
        Validators.pattern(/[!@#$]/),
        ]],
      confirm_password: ['',
        [Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
        Validators.pattern(/[!@#$]/),
        ]]
    });

    this.addUserForm = this.formBuilder.group({
      first_name: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]+$')]],
      last_name: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]+$')]],
      username: ['', Validators.required],
      emailid: ['', [Validators.required, Validators.email]],
      dial_code: ['', Validators.required],
      mobileno: [''],
      organization_name: [''],
      password: ['',
        [Validators.required,
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
        Validators.pattern(/[!@#$]/),
        ]]
    });

    this.editUserForm = this.formBuilder.group({
      first_name: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]+$')]],
      last_name: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]+$')]],
      username: ['', Validators.required],
      emailid: ['', [Validators.required, Validators.email]],
      dial_code: ['', Validators.required],
      mobileno: ['', Validators.required],
      organization_name: ['']
    });

    this.permissionForm = this.formBuilder.group({
      admin_id: [''],
      web_users_id: [''],
      show_job: [0],
      show_add_job: [0],
      show_edit_job: [0],
      show_delete_job: [0],
      show_candidate: [0],
      show_company: [0],
      // show_add_company: [0],
      show_edit_company: [0],
      show_delete_company: [0]
    });

  }

  get f() {
    return this.employerProfileForm.controls;
  }

  get fComp() {
    return this.employerCompaniesForm.controls;
  }

  get fUser() {
    return this.addUserForm.controls;
  }

  get eUser() {
    return this.editUserForm.controls;
  }

  get fPass() {
    return this.employerChangePasswordForm.controls;
  }

  fileChangeEvent(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.showEditImage = true;
      this.originalFilename = file.name; // Get the original filename
      this.imageChangedEvent = event; // Assign the event to imageChangedEvent for the cropper
    } else {
      console.error('No file selected');
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
    const file = new File([this.croppedImage], this.originalFilename, { type: 'image/png' });
    this.employerProfileForm.patchValue({ photoSource: file });
  }

  imageLoaded() {}

  cropperReady() {}

  loadImageFailed() {}

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    if (this.currentUser.admin_id == 0 || this.currentUser.admin_id == null) {
      this.employerId = this.currentUser.id;
    } else {
      this.employerId = this.currentUser.admin_id;
    }

    this.countryService.getCountryDropdown().subscribe((res:any) => {
      this.isCountryLoaded = true;
      this.dialCodeList = res.data;
    })
    
    this.employerCompaniesListData(this.page);
    this.employerJobsListData(this.page);
    this.getUserList(this.page, this.itemsPerPage);
    this.getAllPermissions();

    this.employerProfileForm.get('mobileno').valueChanges.subscribe(value => {
      const dialCode = this.employerProfileForm.get('dial_code').value;
      if (this.isCountryLoaded == true) {
        const numberLength = this.dialCodeList.filter((item:any) => { return item.id == dialCode });
        const numberLength_ = JSON.parse(numberLength[0].number_length);
        const validPattern = /^[0-9]*$/.test(value);
        if(!validPattern){
          this.employerProfileForm.get('mobileno').setErrors({ 'invalidMobileNumber': false, pattern: true });
        } else if (!numberLength_.includes(value.length)) {
          this.employerProfileForm.get('mobileno').setErrors({ 'invalidMobileNumber':true, pattern: false })
        } else {
          this.employerProfileForm.get('mobileno').setErrors(null);
        }
      }
    });

  }

  setMobileNumberValidation(id:any) {
    this.addUserForm.get('mobileno').valueChanges.subscribe(value => {
      if (this.isCountryLoaded == true) {
        const numberLength = this.dialCodeList.filter((item:any) => { return item.id == id });
        const numberLength_ = JSON.parse(numberLength[0].number_length);
        const validPattern = /^[0-9]*$/.test(value);
        if(value.length == 0){
          this.addUserForm.get('mobileno').setErrors({ required: true, 'invalidAddUserMobileNumber': false, pattern: false });
        } else if(!validPattern){
          this.addUserForm.get('mobileno').setErrors({ 'invalidAddUserMobileNumber': false, pattern: true, required: false });
        } else if (!numberLength_.includes(value.length)) {
            this.addUserForm.get('mobileno').setErrors({ 'invalidAddUserMobileNumber': true, pattern: false, required: false })
        } else {
          this.addUserForm.get('mobileno').setErrors(null);
        }
      }
    });
  }

  employerCompaniesListData(page: any) {
    this.employerCompaniesService.GetEmployerCompanies(page, this.itemsPerPage, this.employerId).subscribe((res: any) => {
      this.EmployerCompanies = res.data;
      this.totalItems = res.paging.total;
    });
  }

  onSubmit(): any {
    const formData = new FormData();
    if(this.employerProfileForm.get('first_name')?.value){ formData.append('first_name', this.employerProfileForm.get('first_name')?.value); }
    if(this.employerProfileForm.get('last_name')?.value){ formData.append('last_name', this.employerProfileForm.get('last_name')?.value); }
    if(this.employerProfileForm.get('emailid')?.value){ formData.append('emailid', this.employerProfileForm.get('emailid')?.value); }
    if(this.employerProfileForm.get('dial_code')?.value){ formData.append('dial_code', this.employerProfileForm.get('dial_code')?.value); }
    if(this.employerProfileForm.get('mobileno')?.value){ formData.append('mobileno', this.employerProfileForm.get('mobileno')?.value); }
    if(this.employerProfileForm.get('organization_name')?.value){ formData.append('organization_name', this.employerProfileForm.get('organization_name')?.value); }
    if (this.employerProfileForm.get('photoSource')?.value) { formData.append('photo', this.employerProfileForm.get('photoSource')?.value);
 }

    this.crudService.updateEmployerProfile(formData)
      .subscribe((res: any) => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = "Profile Update Successfully";
        this.userData.photo_url = res.data.photo_url;
        window.location.reload();
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
  }

  updateEmployerPassword(): any {
    const oldPassword = this.employerChangePasswordForm.get('old_password').value;
    const password = this.employerChangePasswordForm.get('password').value;
    const newPassword = this.employerChangePasswordForm.get('confirm_password').value;
    let payload = { oldPassword: oldPassword, password: password };

    if (password === newPassword) {
      this.crudService.updateEmployerPassword(payload).subscribe(() => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = "Password Chanaged Successfully";
        this.logout();
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
    } else {
      this.error = true;
      this.errorMessage = "Password Does Not Matched!!"
    }
  }

  deleteEmployerCompanies(id: any, i: any) {
    if (window.confirm('Are you sure you want to delete this company?')) {
      this.employerCompaniesService.deleteEmployerCompanies(id).subscribe((res) => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = "Company Deleted Successfully";
        this.ngZone.run(() => this.router.navigateByUrl('/company-registration-form'));
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
    }
  }

  FadeOutSuccessMsg() {
    setTimeout(() => {
      this.saveSucess = false;
    }, 5000);
  }

  logout(): void {
    this.token.signOut();
    this.ngZone.run(() => this.router.navigateByUrl('/login'))
  }

  addEmployerCompanies(): any {
    this.showEmployerCompaniesForm = true;
    this.showEditEmployerCompaniesForm = false;
    this.showEmployerCompaniesList = false;
    this.employerCompaniesForm.reset();
  }

  editEmployerCompanies(id): any {
    this.showEditEmployerCompaniesForm = true;
    this.showEmployerCompaniesList = false;
    this.showEmployerCompaniesForm = false;
    this.employerCompaniesEditForm.reset();

    this.getId = id;

    this.employerCompaniesService.GetEmployerCompaniesDetail(this.getId).subscribe((res: any) => {
      this.employerCompaniesEditForm.setValue({
        company_name: res.data.company_name,
        emailid: res.data.emailid,
        mobileno: res.data.mobileno.toString(),
        website_url: res.data.website_url,
        company_contactno: res.data.company_contactno,
        about_company: res.data.about_company,
        company_mission: res.data.company_mission,
        company_vision: res.data.company_vision,
        status: res.data.status.toString()
      });
    });
  }

  viewEmployerCompanies(): any {
    this.showEmployerCompaniesForm = false;
    this.showEditEmployerCompaniesForm = false;
    this.showEmployerCompaniesList = true;
    this.employerCompaniesListData(this.page);
  }

  employerCompaniesSubmit(): any {
    this.employerCompaniesService.AddEmployerCompanies(this.employerCompaniesForm.value)
      .subscribe(() => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = "Company Added Successfully";
        this.employerCompaniesForm.reset();
        this.viewEmployerCompanies();
        this.ngZone.run(() => this.router.navigateByUrl('/employer-dashboard'))
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
  }

  employerCompaniesUpdate(): any {
    this.employerCompaniesService.updateEmployerCompanies(this.getId, this.employerCompaniesEditForm.value)
      .subscribe(() => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = "Company Updated Successfully";
        this.viewEmployerCompanies();
        this.ngZone.run(() => this.router.navigateByUrl('/employer-dashboard'))
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
  }

  employerJobsListData(page: any) {
    this.jobsService.GetEmployerJobsListData(page, this.itemsPerPage, this.employerId).subscribe((res: any) => {
      this.EmployerJobs = res.data;
      this.totalJobItems = res.paging.total;
      this.EmployerJobs.map((item) => {
        if (item.submit_type == 0) {
          this.totalDraftJobs = +this.totalDraftJobs + +1;
        } else if (item.submit_type == 1) {
          this.totalPublishedJobs = +this.totalPublishedJobs + +1;
        }
      })
    });
  }

  deleteJobs(id: any, i: any) {
    if (window.confirm('Do you want to delete?')) {
      this.jobsService.deleteJobs(id).subscribe((res) => {
        this.saveSucess = true;
        this.error = false;
        this.successMessage = "Job Deleted Successfully";
        this.employerJobsListData(this.page);
      }, (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      });
    }
  }

  viewAppliedCandidates(): any {
    this.showJobList = false;
    this.showCandidiateList = true;
  }

  viewJobList(): any {
    this.showJobList = true;
    this.showCandidiateList = false;
  }

  showOldPassword() {
    this.old_show = !this.old_show;
  }

  showpassword() {
    this.show = !this.show;
  }

  showConfirmPassword() {
    this.confirm_show = !this.confirm_show;
  }

  viewUserList(): any {
    this.showUsersAdd = false;
    this.showUsersEdit = false;
    this.showUsersList = true;
    this.getUserList(this.page, this.itemsPerPage);
  }

  addUser(): any {
    this.showUsersAdd = true;
    this.showUsersEdit = false;
    this.showUsersList = false;
    this.addUserForm.reset();
  }

  editUser(id: any): any {
    this.showUsersAdd = false;
    this.showUsersEdit = true;
    this.showUsersList = false;
    this.editUserForm.reset();

    this.userId = id;

    this.userService.GetEmployerUserById(id).subscribe((res: any) => {
      this.editUserForm.setValue({
        first_name: res.data[0].first_name,
        last_name: res.data[0].last_name,
        username: res.data[0].username,
        emailid: res.data[0].emailid,
        dial_code: res.data[0].dial_code,
        mobileno: res.data[0].mobileno,
        organization_name: res.data[0].organization_name || ''
      });
    });

    this.editUserForm.get('mobileno').valueChanges.subscribe(value => {
      const dialCode = this.editUserForm.get('dial_code')?.value;
      if (this.isCountryLoaded == true) {
        const numberLength = this.dialCodeList.filter((item:any) => { return item.id == dialCode });
        const numberLength_ = JSON.parse(numberLength[0].number_length);
        const validPattern = /^[0-9]*$/.test(value);
        if(value.length == 0) {
          this.editUserForm.get('mobileno').setErrors({ required: true, 'invalidEditUserMobileNumber': false, pattern: false });
        } else if(!validPattern){
          this.editUserForm.get('mobileno').setErrors({ 'invalidEditUserMobileNumber': false, pattern: true, required: false });
        } else if (!numberLength_.includes(value.length)) {
          this.editUserForm.get('mobileno').setErrors({ 'invalidEditUserMobileNumber':true, pattern: false, required: false })
        } else {
          this.editUserForm.get('mobileno').setErrors(null);
        }
      }
    });

  }

  submitUser(): any {
    this.userService.AddEmployerUser(this.addUserForm.value).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'User Added Successfully';
      this.viewUserList();
    }, (err) => {
      console.log(err);
      this.error = true;
      if (err.status == 150) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  updateUser(): any {
    this.userService.UpdateEmployerUser(this.userId, this.editUserForm.value).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'User Updated Successfully';
      this.viewUserList();
    },
      (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      }
    );
  }

  deleteUser(id: any) {
    let payload = { status: '0' }
    this.userService.UpdateEmployerUser(id, payload).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'User Deleted Successfully';
      this.viewUserList();
    },
      (err) => {
        console.log(err);
        this.error = true;
        if (err.status == 200) {
          this.errorMessage = err.error.message;
        } else if (err.status == 409) {
          this.errorMessage = err.error.message;
        } else {
          this.errorMessage = err.statusText;
        }
      }
    );
  }

  getUserList(page: any, items: any): any {
    this.userService.GetAllEmployerUserList(page, items).subscribe((res: any) => {
      this.newUserData = res.data;
    })
  }

  navigateToEditCompany() {
    this.ngZone.run(() => this.router.navigateByUrl(`/company-edit-form/${this.employerId}`));
  }

  CompanyCheckBox(event) {
    if (event.target.checked) {
      this.isCompany = true;
      this.permissionForm.controls['show_company'].setValue(1);
      // this.permissionForm.controls['show_add_company'].setValue(1);
      this.permissionForm.controls['show_edit_company'].setValue(1);
      this.permissionForm.controls['show_delete_company'].setValue(1);
    }
    else {
      this.isCompany = false;
      this.permissionForm.controls['show_company'].setValue(0);
      // this.permissionForm.controls['show_add_company'].setValue(0);
      this.permissionForm.controls['show_edit_company'].setValue(0);
      this.permissionForm.controls['show_delete_company'].setValue(0);
    }
  }
  // AddCompany(event){
  //   if(event.target.checked){
  //     this.isCompanyChecked = true;
  //     this.permissionForm.controls['show_add_company'].setValue(1);
  //     this.permissionForm.controls['show_company'].setValue(1);
  //   }
  //   else{
  //     this.permissionForm.controls['show_add_company'].setValue(0);
  //   }
  // }
  EditCompany(event){
    if(event.target.checked){
      this.isCompanyChecked = true;
      this.permissionForm.controls['show_edit_company'].setValue(1);
      this.permissionForm.controls['show_company'].setValue(1);
    }
    else{
      this.permissionForm.controls['show_edit_company'].setValue(0);
    }
  }
  DeleteCompany(event){
    if(event.target.checked){
      this.isCompanyChecked = true;
      this.permissionForm.controls['show_delete_company'].setValue(1);
      this.permissionForm.controls['show_company'].setValue(1);
    }
    else{
      this.permissionForm.controls['show_delete_company'].setValue(0);
    }
  }
  
  JobCheckBox(event) {
    if (event.target.checked) {
      this.isJob = true;
      this.permissionForm.controls['show_job'].setValue(1);
      // this.permissionForm.controls['show_job_detail'].setValue(1);
      this.permissionForm.controls['show_add_job'].setValue(1);
      this.permissionForm.controls['show_edit_job'].setValue(1);
      this.permissionForm.controls['show_candidate'].setValue(1);
      this.permissionForm.controls['show_delete_job'].setValue(1);
    }
    else {
      this.isJob = false;
      this.permissionForm.controls['show_job'].setValue(0);
      // this.permissionForm.controls['show_job_detail'].setValue(0);
      this.permissionForm.controls['show_add_job'].setValue(0);
      this.permissionForm.controls['show_edit_job'].setValue(0);
      this.permissionForm.controls['show_candidate'].setValue(0);
      this.permissionForm.controls['show_delete_job'].setValue(0);
    }
  }
  AddJob(event){
    if(event.target.checked){
      this.isJobChecked = true;
      this.permissionForm.controls['show_add_job'].setValue(1);
      this.permissionForm.controls['show_job'].setValue(1);
    }
    else{
      this.permissionForm.controls['show_add_job'].setValue(0);
    }
  }
  EditJob(event){
    if(event.target.checked){
      this.isJobChecked = true;
      this.permissionForm.controls['show_edit_job'].setValue(1);
      this.permissionForm.controls['show_job'].setValue(1);
    }
    else{
      this.permissionForm.controls['show_edit_job'].setValue(0);
    }
  }
  ShowCandidate(event){
    if(event.target.checked){
      this.isJobChecked = true;
      this.permissionForm.controls['show_candidate'].setValue(1);
      this.permissionForm.controls['show_job'].setValue(1);
    }
    else{
      this.permissionForm.controls['show_candidate'].setValue(0);
    }
  }
  DeleteJob(event){
    if(event.target.checked){
      this.isJobChecked = true;
      this.permissionForm.controls['show_delete_job'].setValue(1);
      this.permissionForm.controls['show_job'].setValue(1);
    }
    else{
      this.permissionForm.controls['show_delete_job'].setValue(0);
    }
  }

  getIds(web_user_id_, admin_id_,) {
    this.adminId = admin_id_;
    this.webUserId = web_user_id_;
    // console.log("adminId", this.adminId);
    // console.log("webUserId", this.webUserId);
  }

  submitPermission() {
    this.permissionForm.controls['admin_id'].setValue(this.adminId);
    this.permissionForm.controls['web_users_id'].setValue(this.webUserId);
    this.getPermission();
    if (this.userPermission.length > 0) {
      this.updatePermission()
    } else {
      this.userService.AddPermission(this.permissionForm.value).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Permission Added Successfully';
      this.permissionForm.reset();
      this.ngZone.run(() => this.router.navigateByUrl('/employer-dashboard'));
    }, (err) => {
      console.log(err);
      this.permissionForm.reset();
      this.error = true;
      if (err.status == 150) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
    }
  }

  getPermission() {
    this.userService.GetPermission(this.webUserId, this.adminId).subscribe((res) => {
      this.userPermission = res.data;
      if(this.userPermission.length > 0) {
        this.permissionForm.setValue({
          admin_id: res.data[0]?.admin_id  ,        
          web_users_id: res.data[0]?.web_users_id  ,         
          show_job: res.data[0]?.show_job ,
          // show_job_detail: res.data[0]?.show_job_detail ,
          show_add_job: res.data[0]?.show_add_job ,
          show_edit_job: res.data[0]?.show_edit_job ,
          show_delete_job: res.data[0]?.show_delete_job ,
          show_candidate: res.data[0]?.show_candidate ,
          show_company: res.data[0]?.show_company ,
          // show_add_company: res.data[0]?.show_add_company ,
          show_edit_company: res.data[0]?.show_edit_company ,
          show_delete_company: res.data[0]?.show_delete_company 
        });
      } else {
        this.isCompany = false;
        this.isCompanyChecked = false;
        this.isJob = false;
        this.isJobChecked = false;
        this.permissionForm.setValue({
          admin_id: '' ,    
          web_users_id: '' ,       
          show_job: 0 ,
          // show_job_detail: 0 ,
          show_add_job: 0 ,
          show_edit_job: 0 ,
          show_delete_job: 0 ,
          show_candidate: 0 ,
          show_company: 0 ,
          // show_add_company: 0 ,
          show_edit_company: 0 ,
          show_delete_company: 0 
        });
      }
    })
  }

  getAllPermissions() {
    this.userService.GetAllPermission().subscribe((res:any) => {
      this.isPermission = res.data.some( item => item['web_users_id'] === this.currentUser.id );
    })
  }

  updatePermission() {
    this.userService.UpdatePermission(this.webUserId, this.permissionForm.value).subscribe(() => {
      this.saveSucess = true;
      this.error = false;
      this.successMessage = 'Permission Updated Successfully';
      this.permissionForm.reset();
      this.ngZone.run(() => this.router.navigateByUrl('/employer-dashboard'));
    }, (err) => {
      console.log(err);
      this.permissionForm.reset();
      this.error = true;
      if (err.status == 150) {
        this.errorMessage = err.error.message;
      } else if (err.status == 409) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.statusText;
      }
    });
  }

  navigateToJobDetails(jobId) {
    // this.ngZone.run(() => this.router.navigateByUrl(`/job/${jobId}`));
    this.router.navigateByUrl(`/job/${jobId}`);
  }

}
