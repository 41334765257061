import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UniversityService {
  REST_API: string;
  REST_ADMIN_API: string;

  constructor(private httpClient: HttpClient) {
    if (environment.production == false) {
      this.REST_API = `${environment.apiurl}`;
      this.REST_ADMIN_API = `${environment.apiAdminUrl}`;
    } else {
      this.REST_API = `${environment.apiurl}`;
      this.REST_ADMIN_API = `${environment.apiAdminUrl}`;
    }
  }

  getUniversityDropdown() {
    return this.httpClient.get(`${this.REST_API}select-list/university`);
  }

  addUniversity(data:any) {
    let API_URL = `${this.REST_ADMIN_API}masters/university`;
    return this.httpClient.post(API_URL, data).pipe(catchError(this.handleError))
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }
}
