<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Applied Candidates</h2>
                    <ul>
                        <li>
                            <img src="assets/img/logo2.png" alt="Image" style="width: 20px;">
                            <a href="index.html">Home</a>
                        </li>
                        <li>
                            <span>/</span>
                        </li>
                        <li>
                            Applied Candidates
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Filter -->
<div class="job-filter-area pt-100">
    <div class="container" *ngIf="isMobileView == false">
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="e.g UI/UX Design" formControlName="term">
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="country" (change)="getState($event.target.value)">
                            <option value="" disabled selected>Filter By Country</option>
                            <option *ngFor="let countryRows of countryList" [value]="countryRows.id">{{ countryRows.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="state" (change)="getCity($event.target.value)">
                                <option value="" disabled selected>Filter By State</option>
                                <option *ngFor="let item of stateList" [value]="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" formControlName="city">
                            <option value="">Filter By City</option>
                            <option *ngFor="let item of cityList" [ngValue]="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isQualificationLoaded" formControlName="qualification">
                            <option value="">Filter By Qualification</option>
                            <option *ngFor="let item of qualificationList" [ngValue]="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-2 col-md-2">
                    <div class="form-group">
                        <select class="form-control" *ngIf="isCountryLoaded" formControlName="experience">
                            <option value="">Filter By Experiences</option>
                            <option *ngFor="let item of expDropdown" [ngValue]="item.id">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div  class="col-sm-8 col-lg-2 col-md-2">
                    <div class="row" id="btn_contain">
                        <div class="col-6">
                            <button type="submit" class="filter_btn" style="margin-bottom: 20px;" (click)="searchFilter()">Search</button>
                        </div>
                        <div class="col-6">
                            <button type="clear" class="filter_btn" style="margin-bottom: 20px;" (click)="resetFilter()">Reset</button>
                        </div>
                    </div>
                </div>
                <!-- <div  class="col-sm-6 col-lg-6 col-md-6">
                    <div class="row" id="btn_contain">
                        <div class="col-6 col-sm-6 col-lg-3">
                            <button type="submit" class="filter_btn"  (click)="searchFilter()">Search</button>
                        </div>
                        <div class="col-6 col-sm-6 col-lg-3">
                            <button type="clear" class="filter_btn" (click)="resetFilter()">Reset</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </form>
    </div>
</div>
<!-- End Filter -->

<!-- Mobile Filters -->
<div class="collapse" id="collapseExample">
    <div class="job-filter-area">
        <div class="container">
            <form [formGroup]="searchForm">
                <div class="row">
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="e.g UI/UX Design" formControlName="term">
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="country" (change)="getState($event.target.value)">
                                <option value="" disabled selected>Filter By Country</option>
                                <option *ngFor="let countryRows of countryList" [value]="countryRows.id">{{ countryRows.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <div class="form-group">
                                <select class="form-control" *ngIf="isCountryLoaded" formControlName="state" (change)="getCity($event.target.value)">
                                    <option value="" disabled selected>Filter By State</option>
                                    <option *ngFor="let item of stateList" [value]="item.id">{{ item.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" formControlName="city">
                                <option value="">Filter By City</option>
                                <option *ngFor="let item of cityList" [ngValue]="item.id">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isQualificationLoaded" formControlName="qualification">
                                <option value="">Filter By Qualification</option>
                                <option *ngFor="let item of qualificationList" [ngValue]="item.id">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 col-md-2">
                        <div class="form-group">
                            <select class="form-control" *ngIf="isCountryLoaded" formControlName="experience">
                                <option value="">Filter By Experiences</option>
                                <option *ngFor="let item of expDropdown" [ngValue]="item.id">{{item.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div  class="col-sm-8 col-lg-2 col-md-2">
                        <div class="row" id="btn_contain">
                            <div class="col-6">
                                <button type="submit" class="filter_btn" style="margin-bottom: 20px;" (click)="searchFilter()">Search</button>
                            </div>
                            <div class="col-6">
                                <button type="clear" class="filter_btn" style="margin-bottom: 20px;" (click)="resetFilter()">Reset</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div  class="col-sm-6 col-lg-6 col-md-6">
                        <div class="row" id="btn_contain">
                            <div class="col-6 col-sm-6 col-lg-3">
                                <button type="submit" class="filter_btn"  (click)="searchFilter()">Search</button>
                            </div>
                            <div class="col-6 col-sm-6 col-lg-3">
                                <button type="clear" class="filter_btn" (click)="resetFilter()">Reset</button>
                            </div>
                        </div>
                    </div> -->
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Mobile Filter -->

<!-- Showing -->
<div class="job-showing-area">
    <div class="container">
        <h4>Showing {{paging?.page_length}} of {{paging?.total}} results</h4>
        <div class="showing">
            <div class="row">
                <div style="width:30%;">
                    <div class="left">
                        <form [formGroup]="sortForm">
                            <div class="form-group">
                                <select class="form-control" formControlName="sort" (change)="getListOfAppliedCandidates(1)" style="width:auto">
                                    <option selected value="">Latest</option>
                                    <option value="id:asc">Oldest</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div style="width:70%;">
                    <div class="right">
                        <ul>
                            <li>
                                <a (click)="showBoxesFunction()"><i class='bx bx-dots-horizontal'></i></a>
                            </li>
                            <li>
                                <a (click)="showListFunction()"><i class='bx bx-menu'></i></a>
                            </li>
                            <li *ngIf="isMobileView == true">
                                <a data-bs-toggle="collapse" data-bs-target="#collapseExample"><i class='bx bx-filter-alt'></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Showing -->

<!-- Candidate -->
<div class="candidate-area pb-100">
    <div class="container">
        <div class="row" *ngIf="showBoxes">
            <div class="col-lg-6 col-md-12" *ngFor="let data of apiData; index as index">
                <div class="candidate-item two" (click)="getIds(data.web_users_id)">
                    <div class="left">
                        <h3 *ngIf="data.selection_state != null"><a routerLink="/candidate/{{ data.web_user.id }}/{{ data.candidateTitle }}">{{data.web_user.first_name}} {{data.web_user.last_name}}</a> | {{data.selection_state.state_name}}</h3>
                        <h3 *ngIf="data.selection_state == null"><a routerLink="/candidate/{{ data.web_user.id }}/{{ data.candidateTitle }}">{{data.web_user.first_name}} {{data.web_user.last_name}}</a></h3>
                        <span>{{data.web_user.job_category.name}}</span>
                        <ul class="experience">
                            <li><img src="assets/icons/experience.png" alt="experience-icon">Experience: <span>{{data.web_user.total_experience}} years</span></li>
                            <li><img src="assets/icons/location.png" alt="Details">{{data.web_user.city.name}},{{data.web_user.state.name}},{{data.web_user.country.name}}</li>
                        </ul>
                    </div>
                    <img class="applied-img" src="{{data.imgPhotoUrl}}" onerror="this.src='../../../../assets/img/default1.png'" alt="Candidate">
                    <div class="applied-candidate-button">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#viewApplication" style="position: relative;margin-top: 15px;" (click)="getCandidateDetail()">View Application</button>
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" style="position: relative;margin-top: 15px;">Set Status</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12" *ngIf="isSearchValueExists == false">
                <div class="employer-item d-flex justify-content-center">
                    <h3>No Candidate Found!!</h3>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="showList">
            <div class="candidate-item two" *ngFor="let item of apiData | paginate : { itemsPerPage: 10, currentPage: page, totalItems: totalAppliedItems }; let i = index">
                <div class="left">
                        <h3 *ngIf="item.selection_state != null"><a routerLink="/candidate/{{ item.web_user.id }}/{{ item.candidateTitle }}">{{item.web_user.first_name}} {{item.web_user.last_name}}</a> | {{item.selection_state.state_name}}</h3>
                        <h3 *ngIf="item.selection_state == null"><a routerLink="/candidate/{{ item.web_user.id }}/{{ item.candidateTitle }}">{{item.web_user.first_name}} {{item.web_user.last_name}}</a></h3>
                        <span>{{item.job.job_category.name}}</span>
                        <ul class="experience">
                            <li><img src="assets/icons/experience.png" alt="experience-icon">Experience: <span>{{item.job.job_experience.min_exp}}-{{item.job.job_experience.max_exp}} years</span></li>
                            <li><img src="assets/icons/location.png" alt="Details">{{item.job.city.name}},{{item.job.state.name}},{{item.job.country.name}}</li>
                        </ul>
                </div>
                <img class="applied-img-list" src="{{item.imgPhotoUrl}}" onerror="this.src='../../../../assets/img/default1.png'" alt="Candidate">
                <div class="applied-candidate-button">
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#viewApplication" style="position: relative;margin-top: 15px;">View Application</button>
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="getIds(item.web_users_id)" style="position: relative;margin-top: 15px;">Set Status</button>
                </div>
            </div>
            <div class="col-lg-12 col-md-12" *ngIf="isSearchValueExists == false">
                <div class="employer-item d-flex justify-content-center">
                    <h3>No Candidate Found!!</h3>
                </div>
            </div>
        </div>

        <div class="pagination-area">
            <pagination-controls class="pagi" (pageChange)="getListOfAppliedCandidates(page = $event)" (directionLinks)="true" (responsive)="true"></pagination-controls>
        </div>
    </div>
</div>
<!-- End Candidate -->


<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->

<!-- Code for Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Set Candidate Status</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <ul style="list-style-type: none" (change)="updateState($event)">
                <li>
                    <label>
                        <input name="candidateStatus" id="candidateStatus" type="radio" value="1">Shortlisted
                    </label>
                </li>
                <li>
                    <label>
                        <input name="candidateStatus" id="candidateStatus" type="radio" value="2">Schedule Interview
                    </label>
                </li>
                <li>
                    <label>
                        <input name="candidateStatus" id="candidateStatus" type="radio" value="3">Interview is Scheduled
                    </label>
                </li>
                <li>
                    <label>
                        <input name="candidateStatus" id="candidateStatus" type="radio" value="4">Interview is in Process
                    </label>
                </li>
                <li>
                    <label>
                        <input name="candidateStatus" id="candidateStatus" type="radio" value="5">Selected
                    </label>
                </li>
                <li>
                    <label>
                        <input name="candidateStatus" id="candidateStatus" type="radio" value="6">On Hold
                    </label>
                </li>
                <li>
                    <label>
                        <input name="candidateStatus" id="candidateStatus" type="radio" value="7">Rejected
                    </label>
                </li>
                <li>
                    <label>
                        <input name="candidateStatus" id="candidateStatus" type="radio" value="8">Feedback Provided
                    </label>
                </li>
            </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="save()">Save changes</button>
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="viewApplication" tabindex="-1" aria-labelledby="viewApplicationModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Candidate Application</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" *ngFor="let item of candidateFilteredData; let i = index">
            <h2>Candidate Information</h2>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="applied-candidate-img">
                            <img src="{{item.imgPhotoUrl}}" onerror="this.src='../../../../assets/img/default1.png'" alt="Candidate">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div>
                            <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Name"/>
                            <h3 class="summary-heading">Name:</h3>
                            <label class="summary-label">{{item.web_user.first_name}}{{item.web_user.last_name}}</label>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div>
                            <img src="assets/icons/email.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Email"/>
                            <h3 class="summary-heading">Email Id:</h3>
                            <label class="summary-label">{{item.web_user.emailid}}</label>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div>
                            <img src="assets/icons/phone.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Phone"/>
                            <h3 class="summary-heading">Mobile Number:</h3>
                            <label class="summary-label">{{item.web_user.mobileno}}</label>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div>
                            <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Title"/>
                            <h3 class="summary-heading">Title:</h3>
                            <label class="summary-label">{{item.web_user.job_title}}</label>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div>
                            <img src="assets/icons/location.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Job Location"/>
                            <h3 class="summary-heading">Location:</h3>
                            <label class="summary-label">{{item.web_user.city.name}}, {{item.web_user.state.name}}, {{item.web_user.country.name}}</label>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div>
                            <img src="assets/icons/cv.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Resume"/>
                            <h3 class="summary-heading">Resume:</h3>
                            <label class="document-link" data-bs-dismiss="modal" (click)="viewResume(item.web_users_id, item.web_user.first_name, item.web_user.last_name)" *ngIf="item.candidate_resume != null">{{ item.candidate_resume.resume_name }}</label>
                            <label class="document-link" data-bs-dismiss="modal" (click)="viewResume(item.web_users_id, item.web_user.first_name, item.web_user.last_name)" *ngIf="item.candidate_resume == null">Abroadwork CV</label>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12" *ngIf="item.candidate_passport != null">
                        <div>
                            <img src="assets/icons/passport.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Passport"/>
                            <h3 class="summary-heading">Passport:</h3>
                            <label class="document-link" (click)="viewPassport(item.candidate_passport.document_name)">{{ item.candidate_passport.document_name }}</label>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12" *ngIf="item.candidate_certificate != null">
                        <div>
                            <img src="assets/icons/certificate.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Certificate"/>
                            <h3 class="summary-heading">Educational Certificate:</h3>
                            <label class="document-link" (click)="viewCertificate(item.candidate_certificate.document_name)">{{ item.candidate_certificate.document_name }}</label>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12" *ngIf="item.candidate_cover_letter != null">
                        <div>
                            <img src="assets/icons/cover-letter.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Cover Letter"/>
                            <h3 class="summary-heading">Cover Letter:</h3>
                            <label class="document-link" (click)="viewCoverLetter(item.candidate_cover_letter.document_name)">{{ item.candidate_cover_letter.document_name }}</label>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12" *ngIf="item.candidate_document1 != null">
                        <div>
                            <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Other Document"/>
                            <h3 class="summary-heading">Other Document1:</h3>
                            <label class="document-link" (click)="viewDocument1(item.candidate_document1.document_name)">{{ item.candidate_document1.document_name }}</label>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12" *ngIf="item.candidate_document2 != null">
                        <div>
                            <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Other Document"/>
                            <h3 class="summary-heading">Other Document2:</h3>
                            <label class="document-link" (click)="viewDocument2(item.candidate_document2.document_name)">{{ item.candidate_document2.document_name }}</label>
                        </div>
                    </div>
                </div>
                <hr>

                <h2>Job Information</h2>
                <div class="row">
                    <!-- <div class="col-lg-6 col-md-12">
                        <div>
                            <h3 class="summary-heading">Job Title:</h3>
                            <label class="summary-label">{{jobDetails.title}}</label>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div>
                            <h3 class="summary-heading">Job Location:</h3>
                            <label class="summary-label">{{jobDetails.city.name}}, {{jobDetails.state.name}}, {{jobDetails.country.name}}</label>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div>
                            <h3 class="summary-heading">Required Experience:</h3>
                            <label class="summary-label">{{jobDetails.job_experience.min_exp}} - {{jobDetails.job_experience.max_exp}}</label>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div>
                            <h3 class="summary-heading">Job Responsibilities:</h3>
                            <div *ngFor="let item of jobDetails.job_responsibilities">
                                <label class="summary-label">{{item.job_responsibility}}</label>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-lg-12 col-md-12">
                        <div>
                            <img src="assets/icons/website.png" style="float:left; height: 15px; width: 15px; position: relative; top: 6px;" alt="Questions"/>
                            <h3 class="summary-heading">Recruiters Questions:</h3>
                            <div *ngFor="let que of item.answer">
                                <label class="summary-label">{{que.question}}<span *ngIf="que.isMandatory == 1" style="color: red;"> * </span> : {{que.answer}}</label>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div class="modal-footer" *ngFor="let item of candidateFilteredData">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="viewResume(item.web_users_id)">View Resume</button> -->
        </div>
      </div>
    </div>
</div>